import { jwtService } from "app/auth/jwtService";
import { axiosApi } from "app/configs/axiosConfig";
import settingsConfig from "app/configs/settingConfig";


const searchTickets = async (ListTicketCurrentPage, size, requestBody, additionalParams = {}) => {
  try {
    let isLimitedUser;
    if (additionalParams.hasOwnProperty('limits')) {
      isLimitedUser = additionalParams.limits;
    } else {
      const userData = jwtService.getUser();
      const currentUserRole = userData.roleName;
      isLimitedUser = ![settingsConfig.roles.admin].flat().includes(currentUserRole);
    }

    const params = { limits: isLimitedUser, ...additionalParams };

    const response = await axiosApi.post(`/ticket/search/${ListTicketCurrentPage}/${size}`, requestBody, {
      params
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default searchTickets;
