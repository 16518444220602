import {createSlice} from "@reduxjs/toolkit";
import {convertToRaw, EditorState} from "draft-js";
import {
    validateAddress,
    validateAFM,
    validateAMKA, validateEmail,
    validateMobile,
    validateName,
    validatePhone,
    validateSurname
} from "app/main/Fields/ValidateFields";

const updateFilledStatus = (state) => {
    const areRepresentativeFieldsEmpty =
        !state.representativeAfm &&
        !state.representativeFirstName &&
        !state.representativeLastName &&
        !state.representativePhone &&
        !state.representativeMobile &&
        !state.representativeEmail
    ;

    if (areRepresentativeFieldsEmpty) {
        // If representative fields are empty, set representativeFilled to false
        state.representativeFilled = false;
    } else {
        // If any representative field is filled, check all three representative fields
        state.representativeFilled =
            state.isRepresentativeAfmValid &&
            state.isRepresentativeFirstNameValid &&
            state.isRepresentativeLastNameValid &&
            (state.isRepresentativePhoneValid || state.isRepresentativeMobileValid)
        ;
    }

    const representativeFields = [
        "representativeEmail",
        "representativePhone",
        "representativeMobile",
    ];

    representativeFields.forEach((field) => {
        const value = state[field];
        const isValid =
            state[`is${field.charAt(0).toUpperCase() + field.slice(1)}Valid`];
        if (value) {
            state.representativeFilled = state.representativeFilled && isValid;
        }
    });

    state.isAtLeastOneUserFieldValid =
        state.isFirstnameValid ||
        state.isSurnameValid ||
        state.isAfmValid ||
        state.isAmkaValid ||
        state.isPhoneValid ||
        state.isMobileValid ||
        state.isEmailValid ||
        state.isAddressValid;

    const atLeastOnePhoneValid = state.isMobileValid || state.isPhoneValid;

    const bothPhonesAttemptedButOneInvalid = (state.phone !== "" && state.mobile !== "") && !(state.isMobileValid && state.isPhoneValid);

    const mandatoryFieldsValid = state.isFirstnameValid &&
        state.isSurnameValid &&
        state.isAfmValid &&
        atLeastOnePhoneValid &&
        !bothPhonesAttemptedButOneInvalid;

    const optionalFieldsCondition = ((state.amka === "" || state.isAmkaValid) &&
        (state.email === "" || state.isEmailValid) &&
        (state.address === "" || state.isAddressValid));

    state.userFilled = mandatoryFieldsValid && optionalFieldsCondition;

}
const initialState = {
    firstname: "",
    isFirstnameValid: false,
    surname: "",
    isSurnameValid: false,
    afm: "",
    isAfmValid: false,
    amka: "",
    isAmkaValid: false,
    phone: "",
    isPhoneValid: false,
    mobile: "",
    isMobileValid: false,
    email: "",
    isEmailValid: false,
    address: "",
    isAddressValid: false,
    userFilled: false,
    isAtLeastOneUserFieldValid: false,
    hasBeneficiaryFetched: false,
    shouldCreateBeneficiary: true,
    beneficiaryId: null,
    originalBeneficiaryData: [],
    beneficiaryHasOpenTickets: false,
    totalOpenTickets: 0,
    currentBeneficiary: {
        id: null,
        firstName: null,
        lastName: null,
        afm: null,
        amka: null,
        phone: null,
        mobile: null,
        email: null,
        address: null,
        ama: null,
        ada: null,
        amoga: null,
        adt: null,
        phone2: null,
        isValid: false,
    },


    representativeAfm: "",
    isRepresentativeAfmValid: false,
    representativeFirstName: "",
    isRepresentativeFirstNameValid: false,
    representativeLastName: "",
    isRepresentativeLastNameValid: false,
    representativeEmail: "",
    isRepresentativeEmailValid: false,
    representativePhone: "",
    isRepresentativePhoneValid: false,
    representativeMobile: "",
    isRepresentativeMobileValid: false,
    representativeFilled: false,
    originalRepresentativeData: {
        id:null,
        afm: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        mobile: null
    },
    representativeId: null,
    currentRepresentative: {
        id:null,
        afm: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        mobile: null
    },
    erotisi: "",
    isErotisiValid: false,
    isPastedErotisi: false,
    uploadedImages: [],


};

const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        changeName: (state, action) => {
            state.firstname = action.payload.value;
            state.isFirstnameValid = action.payload.isValid;
            state.currentBeneficiary.firstName = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeSurname: (state, action) => {
            state.surname = action.payload.value;
            state.isSurnameValid = action.payload.isValid;
            state.currentBeneficiary.lastName = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeAfm: (state, action) => {
            state.afm = action.payload.value;
            state.isAfmValid = action.payload.isValid;
            state.currentBeneficiary.afm = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeAmka: (state, action) => {
            state.amka = action.payload.value;
            state.isAmkaValid = action.payload.isValid;
            state.currentBeneficiary.amka = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changePhone: (state, action) => {
            state.phone = action.payload.value;
            state.isPhoneValid = action.payload.isValid;
            state.currentBeneficiary.phone = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeErotisi: (state, action) => {
            state.erotisi = action.payload;
        },
        changeMobile: (state, action) => {
            state.mobile = action.payload.value;
            state.isMobileValid = action.payload.isValid;
            state.currentBeneficiary.mobile = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeEmail: (state, action) => {
            state.email = action.payload.value;
            state.isEmailValid = action.payload.isValid;
            state.currentBeneficiary.email = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeAddress: (state, action) => {
            state.address = action.payload.value;
            state.isAddressValid = action.payload.isValid;
            state.currentBeneficiary.address = action.payload.value === "" ? null : action.payload.value;
            updateFilledStatus(state);
        },
        changeRepresentativeAfm: (state, action) => {
            state.representativeAfm = action.payload.value;
            state.isRepresentativeAfmValid = action.payload.isValid;
            state.currentRepresentative.afm = state.representativeAfm;
            updateFilledStatus(state);
        },
        validateErotisiText: (state, action) => {
            const text = action.payload;
            const textWithoutHtml = text.replace(/<\/?[^>]+(>|$)/g, "");
            state.isErotisiValid = textWithoutHtml.trim().length > 10;
        },

        changeRepresentativeFirstName: (state, action) => {
            state.representativeFirstName = action.payload.value;
            state.isRepresentativeFirstNameValid = action.payload.isValid;
            state.currentRepresentative.firstName = state.representativeFirstName;
            updateFilledStatus(state);
        },
        changeRepresentativeLastName: (state, action) => {
            state.representativeLastName = action.payload.value;
            state.isRepresentativeLastNameValid = action.payload.isValid;
            state.currentRepresentative.lastName = state.representativeLastName;
            updateFilledStatus(state);
        },
        changeRepresentativeEmail: (state, action) => {
            state.representativeEmail = action.payload.value;
            state.isRepresentativeEmailValid = action.payload.isValid;
            state.currentRepresentative.email = action.payload.value ;
            updateFilledStatus(state);
        },
        changeRepresentativePhone: (state, action) => {
            state.representativePhone = action.payload.value;
            state.isRepresentativePhoneValid = action.payload.isValid;
            state.currentRepresentative.phone = action.payload.value;
            updateFilledStatus(state);
        },
        changeRepresentativeMobile: (state, action) => {
            state.representativeMobile = action.payload.value;
            state.isRepresentativeMobileValid = action.payload.isValid;
            state.currentRepresentative.mobile = action.payload.value ;
            updateFilledStatus(state);
        },
        setHasBeneficiaryFetched: (state, action) => {
            state.hasBeneficiaryFetched = action.payload;
        },
        setOriginalBeneficiaryData: (state, action) => {
            state.originalBeneficiaryData = action.payload;
        },
        resetOriginalBeneficiaryData: (state) => {
            state.originalBeneficiaryData = [];
        },
        setBeneficiaryHasOpenTickets: (state, action) => {
            state.beneficiaryHasOpenTickets = action.payload;
        },
        setOriginalRepresentativeData: (state, action) => {
            state.originalRepresentativeData = action.payload;
        },
        resetOriginalRepresentativeData: (state) => {
            state.originalRepresentativeData = {};
        },
        setRepresentativeId: (state, action) => {
            state.currentRepresentative.id = action.payload;
            state.representativeId = action.payload;
        },

        setErotisiEditorState: (state, action) => {
            state.erotisi = action.payload;

        },
        appendErotisiEditorState: (state, action) => {
            state.erotisi = action.payload;
            state.isPastedErotisi = true;
        },

        resetPastedFlag: (state) => {
            state.isPastedErotisi = false;
        },
        setBeneficiaryId: (state, action) => {
            state.beneficiaryId = action.payload;
            state.currentBeneficiary.id = action.payload;
        },
        setShouldCreateBeneficiary: (state, action) => {
            state.shouldCreateBeneficiary = action.payload;
        },
        addUploadedImage: (state, action) => {
            state.uploadedImages.push(action.payload);
        },
        resetUploadedImages: (state) => {
            state.uploadedImages = [];
        },
        updateBeneficiaryData: (state, action) => {
            if (action.payload) {
                const {
                    id,
                    firstName,
                    lastName,
                    amka,
                    phone,
                    mobile,
                    email,
                    address,
                    afm,
                } = action.payload;

                // Validation using the provided functions
                state.isFirstnameValid = validateName(firstName) === null;
                state.isSurnameValid = validateSurname(lastName) === null;
                state.isAmkaValid = validateAMKA(amka) === null;
                state.isAfmValid = validateAFM(afm) === null;
                state.isPhoneValid = validatePhone(phone) === null;
                state.isMobileValid = validateMobile(mobile) === null;
                state.isEmailValid = validateEmail(email) === null;
                state.isAddressValid = validateAddress(address) === null;

                // Assign validated data to state
                state.beneficiaryId = id ?? '';
                state.currentBeneficiary.id = id ?? '';

                state.firstname = firstName ?? '';
                state.currentBeneficiary.firstName = firstName ?? '';

                state.surname = lastName ?? '';
                state.currentBeneficiary.lastName = lastName ?? '';

                state.amka = amka ?? '';
                state.currentBeneficiary.amka = amka ?? '';

                state.afm = afm ?? '';
                state.currentBeneficiary.afm = afm ?? '';

                state.phone = phone ?? '';
                state.currentBeneficiary.phone = phone ?? '';

                state.mobile = mobile ?? '';
                state.currentBeneficiary.mobile = mobile ?? '';

                state.email = email ?? '';
                state.currentBeneficiary.email = email ?? '';

                state.address = address ?? '';
                state.currentBeneficiary.address = address ?? '';

                updateFilledStatus(state);
            }
        },


        updateRepresentativeData: (state, action) => {
            if (action.payload) {
                const {
                    id,
                    afm,
                    firstName,
                    lastName,
                    email,
                    phone,
                    mobile,
                } = action.payload;

                // Update the representative ID
                state.representativeId = id || state.representativeId;

                // Update the current representative details
                const getValueOrDefault = (value) => value ?? '';

                // Update the current representative details
                state.currentRepresentative = {
                    id: getValueOrDefault(id),
                    afm: getValueOrDefault(afm),
                    firstName: getValueOrDefault(firstName),
                    lastName: getValueOrDefault(lastName),
                    email: getValueOrDefault(email),
                    phone: getValueOrDefault(phone),
                    mobile: getValueOrDefault(mobile),
                };

                // Update individual fields and validation flags
                state.representativeAfm = afm ?? '' ;
                state.isRepresentativeAfmValid = !!afm;
                state.representativeFirstName = firstName ?? '' ;
                state.isRepresentativeFirstNameValid = !!firstName;
                state.representativeLastName = lastName ?? '' ;
                state.isRepresentativeLastNameValid = !!lastName;
                state.representativeEmail = email ?? '';
                state.isRepresentativeEmailValid = !!email;
                state.representativePhone = phone ?? '' ;
                state.isRepresentativePhoneValid = !!phone;
                state.representativeMobile = mobile ?? '';
                state.isRepresentativeMobileValid = !!mobile;
                updateFilledStatus(state);
            }
        },

        resetUserForm: (state) => {
            // Resetting most of the state directly from initialState
            Object.keys(initialState).forEach((key) => {
                if (key !== 'erotisiEditorState') {
                    state[key] = initialState[key];
                }
            });
            state.erotisiEditorState = initialState.erotisiEditorState;

            updateFilledStatus(state);
        },
        resetSearchParameter: (state, action) => {
            const param = action.payload;
            switch (param) {
                case 'afm':
                    state.afm = "";
                    state.isAfmValid = false;
                    state.currentBeneficiary.afm = null;
                    break;
                case 'amka':
                    state.amka = "";
                    state.isAmkaValid = false;
                    state.currentBeneficiary.amka = null;
                    break;
                // case 'phone':
                //     state.phone = "";
                //     state.isPhoneValid = false;
                //     state.currentBeneficiary.phone = null;
                //     break;
                // case 'mobile':
                //     state.mobile = "";
                //     state.isMobileValid = false;
                //     state.currentBeneficiary.mobile = null;
                //     break;
                case 'firstName':
                    state.firstname = "";
                    state.isFirstnameValid = false;
                    state.currentBeneficiary.firstName = null;
                    break;
                case 'lastName':
                    state.surname = "";
                    state.isSurnameValid = false;
                    state.currentBeneficiary.lastName = null;
                    break;
                case 'email':
                    state.email = "";
                    state.isEmailValid = false;
                    state.currentBeneficiary.email = null;
                    break;
                case 'address':
                    state.address = "";
                    state.isAddressValid = false;
                    state.currentBeneficiary.address = null;
                    break;
                default:
                    break;
            }
            state.currentBeneficiary.id = null;
            updateFilledStatus(state);
        },
        resetSearchParameteRepresentative: (state, action) => {
            const param = action.payload;
            switch (param) {
                case 'afm':
                    state.representativeAfm = "";
                    state.isRepresentativeAfmValid = false;
                    state.currentRepresentative.afm = null;
                    break;
                case 'phone':
                    state.representativePhone = "";
                    state.isRepresentativePhoneValid = false;
                    state.currentRepresentative.phone = null;
                    break;
                case 'firstName':
                    state.representativeFirstName = "";
                    state.isRepresentativeFirstNameValid = false;
                    state.currentRepresentative.firstName = null;
                    break;
                case 'lastName':
                    state.representativeLastName = "";
                    state.isRepresentativeLastNameValid = false;
                    state.currentRepresentative.lastName = null;
                    break;
                case 'email':
                    state.representativeEmail = "";
                    state.isRepresentativeEmailValid = false;
                    state.currentRepresentative.email = null;
                    break;
                case 'mobile':
                    state.representativeMobile = "";
                    state.isRepresentativeMobileValid = false;
                    state.currentRepresentative.mobile = null;
                    break;
                default:
                    break;
            }
            state.currentRepresentative.id = null;
            updateFilledStatus(state);
        },

        resetRepresentativeState: (state) => {
            state.representativeAfm = initialState.representativeAfm;
            state.isRepresentativeAfmValid = initialState.isRepresentativeAfmValid;
            state.representativeFirstName = initialState.representativeFirstName;
            state.isRepresentativeFirstNameValid = initialState.isRepresentativeFirstNameValid;
            state.representativeLastName = initialState.representativeLastName;
            state.isRepresentativeLastNameValid = initialState.isRepresentativeLastNameValid;
            state.representativeEmail = initialState.representativeEmail;
            state.isRepresentativeEmailValid = initialState.isRepresentativeEmailValid;
            state.representativePhone = initialState.representativePhone;
            state.isRepresentativePhoneValid = initialState.isRepresentativePhoneValid;
            state.representativeMobile = initialState.representativeMobile;
            state.isRepresentativeMobileValid = initialState.isRepresentativeMobileValid;
            state.representativeFilled = initialState.representativeFilled;
            state.originalRepresentativeData = initialState.originalRepresentativeData;
            state.representativeId = initialState.representativeId;
            state.currentRepresentative = initialState.currentRepresentative;
            updateFilledStatus(state);
        },

        resetUserFormExceptErotisi: (state) => {
            // Preserve erotisi and representative fields
            const preservedFields = {
                erotisi: state.erotisi,
                erotisiEditorState: state.erotisiEditorState,
                isErotisiValid: state.isErotisiValid,
                representativeAfm: state.representativeAfm,
                isRepresentativeAfmValid: state.isRepresentativeAfmValid,
                representativeFirstName: state.representativeFirstName,
                isRepresentativeFirstNameValid: state.isRepresentativeFirstNameValid,
                representativeLastName: state.representativeLastName,
                isRepresentativeLastNameValid: state.isRepresentativeLastNameValid,
                representativeEmail: state.representativeEmail,
                isRepresentativeEmailValid: state.isRepresentativeEmailValid,
                representativePhone: state.representativePhone,
                isRepresentativePhoneValid: state.isRepresentativePhoneValid,
                representativeMobile: state.representativeMobile,
                isRepresentativeMobileValid: state.isRepresentativeMobileValid,
                representativeFilled: state.representativeFilled,
                originalRepresentativeData: state.originalRepresentativeData,
                representativeId: state.representativeId,
                currentRepresentative: state.currentRepresentative,
            };

            // Remove all other keys except the preserved ones
            Object.keys(state).forEach((key) => {
                if (!Object.keys(preservedFields).includes(key)) {
                    delete state[key];
                }
            });

            Object.assign(state, {...initialState, ...preservedFields});

            // Update status based on the new state
            updateFilledStatus(state);
        },

    },
});

export const {
    changeName,
    changeSurname,
    changeAfm,
    changeAmka,
    changePhone,
    changeMobile,
    changeEmail,
    changeAddress,
    changeRepresentativeAfm,
    changeRepresentativeFirstName,
    changeRepresentativeLastName,
    changeRepresentativeEmail,
    changeRepresentativePhone,
    changeRepresentativeMobile,
    setErotisiEditorState,
    representativeFilled,
    updateBeneficiaryData,
    resetUserForm,
    setHasBeneficiaryFetched,
    setOriginalBeneficiaryData,
    resetOriginalBeneficiaryData,
    setBeneficiaryId,
    setOriginalRepresentativeData,
    resetOriginalRepresentativeData,
    setRepresentativeId,
    setShouldCreateBeneficiary,
    resetUserFormExceptErotisi,
    setBeneficiaryHasOpenTickets,
    validateErotisiText,
    addUploadedImage,
    resetUploadedImages,
    appendErotisiEditorState,
    resetPastedFlag,
    resetSearchParameter,
    updateRepresentativeData,
    resetSearchParameteRepresentative,
    resetRepresentativeState
} = userInfoSlice.actions;

export default userInfoSlice.reducer;


