import { axiosApi } from "app/configs/axiosConfig";

// POST Create Announcement
export const postAnnouncement = async (announcement) => {
    try {
        const response = await axiosApi.post('/announcement', announcement);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// PUT Update Announcement
export const putAnnouncement = async (announcement) => {
    try {
        const response = await axiosApi.put('/announcement', announcement);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// GET Download Announcement File
export const getAnnouncementFileDownload = async (fileId) => {
    try {
        const response = await axiosApi.get(`/announcement/file/download/${fileId}`);
        return response.data.url;
    } catch (error) {
        throw error;
    }
};

export const getAnnouncementFiles = async (announcement) => {
    try {
        const response = await axiosApi.get(`/announcement/file/${announcement}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// POST Upload File to Announcement
export const postAnnouncementFile = async (announcementId, fileData) => {
    try {
        const formData = new FormData();
        formData.append('filedata', fileData);
        const response = await axiosApi.post(`/announcement/file/${announcementId}`, formData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// GET Retrieve Announcement
export const getAnnouncement = async (announcementId) => {
    try {
        const response = await axiosApi.get(`/announcement/${announcementId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// GET Retrieve Announcements by Carrier ID
export const getAnnouncementsByCarrierId = async (carrierId) => {
    try {
        const response = await axiosApi.get(`/announcement/byCarrierId/${carrierId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

