import React from 'react';
import { Modal, Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const OpenTicketsModal = ({ open, onClose, beneficiaryTickets, beneficiaryId, carriers }) => {
    const tickets = beneficiaryTickets[beneficiaryId] || {};

    const getTableRows = () => {
        return Object.values(tickets).map((ticket, index) => {
            const carrierTitle = carriers?.find(carrier => carrier.id === ticket.carrierId)?.title || "Άγνωστος Φορέας";
            return (
                <TableRow key={index}>
                    <TableCell>{carrierTitle}</TableCell>
                    <TableCell sx={{ color: 'red', fontWeight: 'bold' }}>{ticket.status4}</TableCell>
                    <TableCell>{ticket.status5}</TableCell>
                    <TableCell>{ticket.status8}</TableCell>
                </TableRow>
            );
        });
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}
            >
                <Box
                    sx={{
                        width: '90vw',
                        height: '90vh',
                        backgroundColor: 'white',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: 24,
                        padding: 2,
                        borderRadius: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 2,
                            borderBottom: '1px solid #ccc',
                            backgroundColor: 'white',
                            position: 'sticky',
                            top: 0,
                            zIndex: 1
                        }}
                    >
                        <Typography variant="h6">Ιστορικό Δικαιούχου</Typography>
                        <Button
                            onClick={onClose}
                            sx={{
                                backgroundColor: '#003476 !important',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#002244 !important'
                                }
                            }}
                        >
                            Κλείσιμο
                        </Button>
                    </Box>
                    <Box sx={{ overflowY: 'auto', flexGrow: 1, backgroundColor: "#edf0f6", padding: 2 }}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="ticket table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><Typography fontWeight="bold">Φορέας</Typography></TableCell>
                                        <TableCell><Typography fontWeight="bold">Ανοιχτό</Typography></TableCell>
                                        <TableCell><Typography fontWeight="bold">Κλειστό</Typography></TableCell>
                                        <TableCell><Typography fontWeight="bold">Reopen</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getTableRows()}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default OpenTicketsModal;
