import {
    validateAddress, validateAFM, validateAMKA,
    validateEmail,
    validateMobile,
    validateName,
    validatePhone,
    validateSurname
} from "app/main/Fields/ValidateFields";

// Mapping field changes to friendly titles
const fieldMapping = {
    firstNameChanged: "Όνομα",
    lastNameChanged: "Επώνυμο",
    phoneChanged: "Τηλέφωνο",
    mobileChanged: "Κινητό",
    emailChanged: "Email",
    addressChanged: "Διεύθυνση",
    afmChanged: "ΑΦΜ",
    amkaChanged: "ΑΜΚΑ"
};

export const getBeneficiaryFieldChanges = (originalData, currentData) => {
    // Function to check if the object is empty or null
    const isEmptyOrNullObject = (obj) => !obj || Object.keys(obj).length === 0;

    // If either originalData or currentData is empty/null, return no changes
    if (isEmptyOrNullObject(originalData) || isEmptyOrNullObject(currentData)) {
        return Object.keys(fieldMapping).reduce((acc, key) => {
            acc[key] = { changed: false, label: fieldMapping[key] };
            return acc;
        }, {});
    }

    const isEmptyOrNull = (val) => val === "" || val === null;

    // Helper function to check if two values are different considering empty or null values
    const valueHasChanged = (val1, val2) => {
        return !isEmptyOrNull(val1) || !isEmptyOrNull(val2) ? val1 !== val2 : false;
    };

    // Check each field for changes and validity
    const firstNameChanged = valueHasChanged(originalData.firstName, currentData.firstName) && !validateName(currentData.firstName);
    const lastNameChanged = valueHasChanged(originalData.lastName, currentData.lastName) && !validateSurname(currentData.lastName);
    const phoneChanged = valueHasChanged(originalData.phone, currentData.phone) && !validatePhone(currentData.phone);
    const mobileChanged = valueHasChanged(originalData.mobile, currentData.mobile) && !validateMobile(currentData.mobile);
    const emailChanged = valueHasChanged(originalData.email, currentData.email) && !validateEmail(currentData.email);
    const addressChanged = valueHasChanged(originalData.address, currentData.address) && !validateAddress(currentData.address);
    const afmChanged = valueHasChanged(originalData.afm, currentData.afm) && !validateAFM(currentData.afm);
    const amkaChanged = valueHasChanged(originalData.amka, currentData.amka) && !validateAMKA(currentData.amka);

    // Return object with both the change status and the friendly titles
    return {
        firstNameChanged: { changed: firstNameChanged, label: fieldMapping.firstNameChanged },
        lastNameChanged: { changed: lastNameChanged, label: fieldMapping.lastNameChanged },
        phoneChanged: { changed: phoneChanged, label: fieldMapping.phoneChanged },
        mobileChanged: { changed: mobileChanged, label: fieldMapping.mobileChanged },
        emailChanged: { changed: emailChanged, label: fieldMapping.emailChanged },
        addressChanged: { changed: addressChanged, label: fieldMapping.addressChanged },
        afmChanged: { changed: afmChanged, label: fieldMapping.afmChanged },
        amkaChanged: { changed: amkaChanged, label: fieldMapping.amkaChanged }
    };
};
