import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCarriers,
  fetchThematicsByCarrierId,
  setSelectedCarrierSets,
  addNewestSet,
  fetchSubthematicsByParentId
} from "store/teamsSlice";
import { Box, TextField, MenuItem, Button, Typography } from "@mui/material";
import { styled } from '@mui/system';

const CustomSelect = styled('div')((props) => ({
  width: "100%",
  maxWidth: "100%",
  "& .MuiInputLabel-outlined": {
    fontSize: "13px",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(1)",
    background: props.backgroundColor,
    padding: "0 4px",
  },
  "& .MuiInputLabel-outlined.Mui-focused": {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "13px",
  },
}));

const CustomMenuItem = styled(MenuItem)({
  fontSize: "13px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
});

const CustomTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-input": {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "13px",
    backgroundColor: "white",
  },
});

const AddButton = styled(Button)(({ disabled }) => ({
  marginTop: "10px",
  backgroundColor: disabled ? "#b0b0b0" : "#003476 !important",
  '&:hover': {
    backgroundColor: disabled ? "#b0b0b0" : "#002157",
  },
}));

const RemoveButton = styled(Button)(({ disabled }) => ({
  marginLeft: "10px",
  backgroundColor: disabled ? "#b0b0b0 !important" : "#dc2626 !important",
  color: disabled ? "gray !important" : "white !important",

  '&:hover': {
    backgroundColor: disabled ? "#b0b0b0 !important" : "#b30000 !important",
  },
}));







function CarrierThematics() {
  const dispatch = useDispatch();
  const carrierSets = useSelector((state) => state.teamsSlice.carrierSets);
  const selectedCarrierSets = useSelector((state) => state.teamsSlice.selectedCarrierSets);

  const carriers = carrierSets[carrierSets.length - 1].carriers;
  const thematics = carrierSets[carrierSets.length - 1].thematics;

  useEffect(() => {
    const lastCarrierSetIndex = carrierSets.length - 1;
    if (carrierSets[lastCarrierSetIndex].carriers.length === 0) {
      dispatch(fetchCarriers(lastCarrierSetIndex));
    }
  }, [dispatch, carrierSets]);


  const handleCarrierChange = (selectedOption, index) => {
    const newSelectedCarrierSets = [...selectedCarrierSets];
    newSelectedCarrierSets[index] = {
      selectedCarrier: selectedOption,
      selectedThematics: null,
    };
    dispatch(setSelectedCarrierSets(newSelectedCarrierSets));

    if (selectedOption !== null) {
      dispatch(fetchThematicsByCarrierId({ carrierId: selectedOption.value, setIndex: index }));
    }
  };


  const handleThematicsChange = (selectedOptions, index) => {
    const newSelectedCarrierSets = [...selectedCarrierSets];
    const updatedSelectedCarrierSet = {
      ...newSelectedCarrierSets[index],
      selectedThematics: selectedOptions,
    };
    newSelectedCarrierSets[index] = updatedSelectedCarrierSet;
    dispatch(setSelectedCarrierSets(newSelectedCarrierSets));

    // Fetch subthematics for all selected thematics
    selectedOptions.forEach(option => {
      dispatch(fetchSubthematicsByParentId(option.value));
    });
  };

  const handleSubthematicsChange = (selectedOptions, carrierSetIndex, thematicIndex) => {
    const newSelectedCarrierSets = [...selectedCarrierSets];
    const updatedCarrierSet = {
      ...newSelectedCarrierSets[carrierSetIndex],
      selectedSubthematics: {
        ...newSelectedCarrierSets[carrierSetIndex].selectedSubthematics,
        [thematicIndex]: selectedOptions
      }
    };
    newSelectedCarrierSets[carrierSetIndex] = updatedCarrierSet;
    dispatch(setSelectedCarrierSets(newSelectedCarrierSets));
  };



  const addNewSet = () => {
    dispatch(addNewestSet());
    const newSelectedCarrierSets = [...selectedCarrierSets];
    newSelectedCarrierSets.push({ selectedCarrier: null, selectedThematics: null });
    dispatch(setSelectedCarrierSets(newSelectedCarrierSets));
  };

  const removeSet = (index) => {
    const newSelectedCarrierSets = [...selectedCarrierSets];
    newSelectedCarrierSets.splice(index, 1);
    dispatch(setSelectedCarrierSets(newSelectedCarrierSets));
  };


  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
      {selectedCarrierSets.map((selectedCarrierSet, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mb={2}
          width="100%"

          backgroundColor={index % 2 === 0 ? "#f0f0f0" : "#ffffff"}
          marginBottom="16px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            flexWrap="wrap"
          >
            <Box width="33%">
              <CustomTextField
                select
                label="Φορέας"
                value={selectedCarrierSet.selectedCarrier ? selectedCarrierSet.selectedCarrier.value : ""}
                onChange={(event) =>
                  handleCarrierChange(
                    event.target.value ? { value: event.target.value } : null,
                    index
                  )
                }
                variant="outlined"

              >
                {carrierSets[index].carriers.map((carrier) => (
                  <MenuItem key={carrier.id} value={carrier.id} >
                    {carrier.title}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Box>
            {selectedCarrierSet.selectedCarrier && (
              <Box width="67%" px={1}>
                <CustomTextField
                  select
                  multiple
                  label="Θεματικές Ενότητες"
                  value={
                    selectedCarrierSet.selectedThematics
                      ? selectedCarrierSet.selectedThematics.map((thematic) => thematic.value)
                      : []
                  }
                  onChange={(event) => {
                    const value = event.target.value;
                    if (Array.isArray(value) && value.includes("all")) {
                      handleThematicsChange(
                        carrierSets[index].thematics.map((thematic) => ({ value: thematic.id })),
                        index
                      );
                    } else {
                      handleThematicsChange(
                        value.map((val) => ({ value: val })),
                        index
                      );
                    }
                  }}
                  SelectProps={{
                    multiple: true,
                  }}
                  variant="outlined"

                >
                  <MenuItem value="all" >
                    Επιλογή Όλων
                  </MenuItem>
                  {carrierSets[index].thematics.map((thematic) => (
                    <MenuItem
                      key={thematic.id}
                      value={thematic.id}

                    >
                      {thematic.title.length > 100
                        ? `${thematic.title.slice(0, 100)}...`
                        : thematic.title}
                    </MenuItem>
                  ))}
                </CustomTextField>
              </Box>
            )}
          </Box>
          {selectedCarrierSet.selectedThematics && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              width="100%"
              flexWrap="wrap"
              marginTop="16px"
            >
              {selectedCarrierSet.selectedThematics.map((thematic, thematicIndex) => (
                <Box key={`subthematic-${thematic.id}-${thematicIndex}`} width="25%" px={1}>
                  <Typography variant="body2" align="center" style={{ marginBottom: '8px' }}>
                    {carrierSets[index].thematics.find(t => t.id === thematic.value)?.title}
                  </Typography>
                  <CustomTextField
                    select
                    label={`Θέμα ${thematicIndex + 1}`}
                    value={selectedCarrierSet.selectedSubthematics?.[thematicIndex]?.value || []}
                    onChange={(event) => handleSubthematicsChange(
                      { value: event.target.value },
                      index,
                      thematicIndex
                    )}
                    variant="outlined"
                    fullWidth
                    SelectProps={{
                      multiple: true,
                    }}
                  >
                    {carrierSets[index].subthematics
                      .filter(subthematic => subthematic.parentId === thematic.value)
                      .map((subthematic) => (
                        <MenuItem
                          key={subthematic.id}
                          value={subthematic.id}
                        >
                          {subthematic.title}
                        </MenuItem>
                      ))}
                  </CustomTextField>
                </Box>
              ))}
            </Box>
          )}

          {selectedCarrierSet.selectedCarrier && (
            selectedCarrierSets.length > 1 && (
              <RemoveButton
                onClick={() => removeSet(index)}
                variant="outlined"
              >
                Διαγραφή
              </RemoveButton>
            )
          )}
        </Box>
      ))}
      {selectedCarrierSets[selectedCarrierSets.length - 1].selectedCarrier && (
        <AddButton
          onClick={addNewSet}
          variant="contained"

        >
          Προσθήκη Έξτρα Φορέα
        </AddButton>
      )}
    </Box>
  );

}

export default CarrierThematics;