import React from 'react';
import { Box, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { setFromDate, setSearchValues, setToDate } from 'store/ticketFetchSlice';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid, getYear, format } from 'date-fns'; // Import format utility from date-fns

function FromToDateFilter() {
  const dispatch = useDispatch();
  const fromDate = useSelector((state) => state.ticketFetchSlice.searchValues.fromDate?.value || null);
  const toDate = useSelector((state) => state.ticketFetchSlice.searchValues.toDate?.value || null);
  const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

  // Convert ISO string dates back to Date objects for the DatePicker component
  const fromDateObj = fromDate ? new Date(fromDate) : null;
  const toDateObj = toDate ? new Date(toDate) : null;

  // Handle changes to the fromDate
  const handleFromDateChange = (newValue) => {
    if (isValid(newValue) && getYear(newValue) > 2000) {
      // Convert Date object to ISO string
      const isoDate = format(newValue, 'yyyy-MM-dd');
      dispatch(setFromDate(isoDate)); // Store ISO date in Redux state
      dispatch(setSearchValues({ fieldName: 'fromDate', value: isoDate }));
    }
  };

  // Handle changes to the toDate
  const handleToDateChange = (newValue) => {
    if (isValid(newValue) && getYear(newValue) > 2000) {
      // Convert Date object to ISO string
      const isoDate = format(newValue, 'yyyy-MM-dd');
      dispatch(setToDate(isoDate)); // Store ISO date in Redux state
      dispatch(setSearchValues({ fieldName: 'toDate', value: isoDate }));
    }
  };

  // Clear the fromDate in Redux state
  const clearFromDate = () => {
    dispatch(setSearchValues({ fieldName: 'fromDate', value: null }));
    dispatch(setFromDate(null));
  };

  // Clear the toDate in Redux state
  const clearToDate = () => {
    dispatch(setSearchValues({ fieldName: 'toDate', value: null }));
    dispatch(setToDate(null));
  };

  return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box display="flex" justifyContent="space-between" gap={2}>
          <Box display="flex">
            <DatePicker
                label="Από Ημερομηνία"
                value={fromDateObj} // Use Date object for the DatePicker
                onChange={handleFromDateChange}
                format="dd/MM/yyyy"
                size="small"
                disabled={ticketsLoading}
                slotProps={{ textField: { size: 'small' } }}
            />
            {fromDate && (
                <IconButton onClick={clearFromDate} disabled={ticketsLoading}>
                  <ClearIcon />
                </IconButton>
            )}
          </Box>
          <Box display="flex">
            <DatePicker
                label="Έως Ημερομηνία"
                value={toDateObj} // Use Date object for the DatePicker
                onChange={handleToDateChange}
                format="dd/MM/yyyy"
                slotProps={{ textField: { size: 'small' } }}
                minDate={fromDateObj} // Use Date object for minDate
                disabled={ticketsLoading}
            />
            {toDate && (
                <IconButton onClick={clearToDate} disabled={ticketsLoading}>
                  <ClearIcon />
                </IconButton>
            )}
          </Box>
        </Box>
      </LocalizationProvider>
  );
}

export default FromToDateFilter;
