import { useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

import * as yup from "yup";
import { useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import { showMessage } from "store/messageSlice";
import RolesFetched from "./RolesFetched";
import { axiosApi } from "app/configs/axiosConfig";
/**
 * Form Validation Schema
 */
const phoneRegExp = /^(2\d|69)\d{8}$/;

const schema = yup.object().shape({
  username: yup.string().required("Πρέπει να εισάγετε όνομα χρήστη"),
  firstName: yup.string().required("Πρέπει να εισάγετε το Όνομα"),
  lastName: yup.string().required("Πρέπει να εισάγετε το Επώνυμο"),
  email: yup
    .string()
    .email("Πρέπει να εισάγετε έγκυρο email")
    .required("Πρέπει να εισάγετε email"),
  password: yup
    .string()
    .required("Παρακαλώ εισάγετε τον κωδικό σας.")
    .min(8, "Ο κωδικός είναι πολύ σύντομος - πρέπει να είναι τουλάχιστον 8 χαρακτήρες."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Οι κωδικοί πρέπει να ταιριάζουν"),
    selectedRole: yup.string().required("Πρέπει να επιλέξετε ρόλο"),
    afm: yup
    .string()
    .nullable()
    .test('afm', 'Πρέπει να είναι 9 ψηφία', function (afm) {
      if (!afm || afm.length === 0) return true;
      if (/^\d+$/.test(afm) && afm.length === 9) return true;
      return this.createError({ message: 'Πρέπει να είναι αριθμός και 9 ψηφία' });
    }),
  
  phone: yup
    .string()
    .nullable()
    .test('phone', 'Πρέπει να είναι 10 ψηφία', function (phone) {
      if (!phone || phone.length === 0) return true;
      if (phoneRegExp.test(phone) && phone.length === 10) return true;
      return this.createError({ message: 'Πρέπει να είναι έγκυρος αριθμός τηλεφώνου και 10 ψηφία' });
    }),
});
  
const defaultValues = {
  username: "",
  firstName: "",
  lastName: "",
  phone: "",
  afm: "",
  email: "",
  password: "",
  passwordConfirm: "",
  selectedRole: "",
};

function SignUpPageAdmin() {
  const dispatch = useDispatch();
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [selectedRole, setSelectedRole] = useState("");
  async function onSubmit({
    username,
    firstName,
    lastName,
    phone,
    afm,
    email,
    password,
  }) {
    let mobile = null;
    let phoneNumber = null;

    if (phone && phone.startsWith("69")) {
      mobile = phone;
    } else if (phone && phone.startsWith("2")) {
      phoneNumber = phone;
    }

    if (errors.phone) {
      phoneNumber = null;
      mobile = null;
    }

    if (errors.afm ) {
      afm = null;
    }
  
    const userData = {
      username,
      firstName,
      lastName,
      phone: phoneNumber === '' ? null : phoneNumber,
  mobile: mobile === '' ? null : mobile,
      afm: afm === '' ? null : afm,
      password,
      email,
    };

    const apiEndpoints = {
      ROLE_ADMIN: "admin/register/admin",
      ROLE_FO_SUPERVISOR: "admin/register/frontOfficer/supervisor",
      ROLE_FO_TEAM_LEADER: "admin/register/frontOfficer/teamleader",
      ROLE_FO_AGENT: "admin/register/frontOfficer/agent",
      ROLE_BO_SUPERVISOR: "admin/register/backofficer/supervisor",
      ROLE_BO_TEAM_LEADER: "admin/register/backofficer/teamleader",
      ROLE_BO_AGENT: "admin/register/backofficer/agent",
      ROLE_SA_SUPERVISOR: "admin/register/specializedAgent/supervisor",
      ROLE_SA_TEAM_LEADER: "admin/register/specializedAgent/teamleader",
      ROLE_SA_AGENT: "admin/register/specializedAgent/agent",
    };

    const endpoint = apiEndpoints[selectedRole];

    try {
      const response = await axiosApi.post(endpoint, userData);
      const responseData = response.data;
      const userId = responseData.id;
      await enableUser(userId);
      dispatch(
        showMessage({
          message: "Επιτυχής εγγραφή! Ο χρήστης δημιουργήθηκε.",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
      reset();
      setSelectedRole("");
    } catch (error) {
      if (error.response) {
        console.error("Error registering user:", error.response.data);
        dispatch(
          showMessage({
              message: "Απέτυχε η δημιουργία χρήστη",
              autoHideDuration: 2000,
              anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
              },
              variant: "error", 
          })
      );
      
      }
    }
  }

  async function enableUser(userId) {
    try {
      const response = await axiosApi.patch(`/admin/user/enable/${userId}`);
    } catch (error) {
      if (error.response) {
        console.error("Error details:", error.response.data);
      }
    }
  }

  return (
    <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0 h-screen">
<Paper className="relative flex items-center justify-center w-full sm:w-1/2 h-full py-0 px-2 sm:px-8 md:px-16 lg:px-24 xl:px-32 2xl:px-40 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none">    <Link className="absolute top-4 left-4" to="/tickets">
        <Button 
            variant="contained" 
            style={{ backgroundColor: '#003476', color: 'white' }}
        >
            Πίσω στην Αρχική
        </Button>
    </Link>

    <div className="items-center w-full">
        <div className="flex items-center justify-center w-full mt-4">
            <img className="w-12 mr-4" src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="logo"/>
            <Typography className="text-4xl text-center font-extrabold tracking-tight leading-tight">
                Εγγραφή
            </Typography>
        </div>

        <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-16"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Controller
                name="username"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        label="Username"
                        autoFocus
                        type="name"
                        error={!!errors.username}
                        helperText={errors?.username?.message}
                        variant="outlined"
                        required
                        fullWidth
                        sx={{
                            marginBottom: 1,
                        }}
                    />
                )}
            />

            <Controller
                name="firstName"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="Όνομα"
                        autoFocus
                        type="name"
                        error={!!errors.firstName}
                        helperText={errors?.firstName?.message}
                        variant="outlined"
                        required
                        fullWidth
                    />
                )}
            />

            <Controller
                name="lastName"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="Επώνυμο"
                        autoFocus
                        type="name"
                        error={!!errors.lastName}
                        helperText={errors?.lastName?.message}
                        variant="outlined"
                        required
                        fullWidth
                    />
                )}
            />

            <Controller
                name="phone"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="Τηλέφωνο"
                        autoFocus
                        type="tel"
                        error={!!errors.phone}
                        helperText={errors?.phone?.message}
                        variant="outlined"
                        fullWidth
                    />
                )}
            />

            <Controller
                name="afm"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="ΑΦΜ"
                        autoFocus
                        type="tel"
                        error={!!errors.afm}
                        helperText={errors?.afm?.message}
                        variant="outlined"
                        fullWidth
                    />
                )}
            />

            <Controller
                name="email"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="Email"
                        type="email"
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        variant="outlined"
                        required
                        fullWidth
                    />
                )}
            />

            <Controller
                name="password"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="Password"
                        type="password"
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete="new-password"
                    />
                )}
            />

            <Controller
                name="passwordConfirm"
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        sx={{
                            marginBottom: 1,
                        }}
                        label="Password (Confirm)"
                        type="password"
                        error={!!errors.passwordConfirm}
                        helperText={errors?.passwordConfirm?.message}
                        variant="outlined"
                        required
                        fullWidth
                        autoComplete="new-password"
                    />
                )}
            />

            <Controller
                name="selectedRole"
                control={control}
                defaultValue={selectedRole}
                rules={{required: "You must select a role."}}
                render={({field}) => (
                    <RolesFetched
                        selectedRole={field.value}
                        setSelectedRole={(role) => {
                            field.onChange(role);
                            setSelectedRole(role);
                        }}
                    />
                )}
            />
            {errors.selectedRole && <p>{errors.selectedRole.message}</p>}


            <Button
                variant="contained"
                style={{
                    backgroundColor: !(
                        dirtyFields.username &&
                        dirtyFields.firstName &&
                        dirtyFields.lastName &&
                        dirtyFields.email &&
                        dirtyFields.password &&
                        dirtyFields.passwordConfirm &&
                        dirtyFields.selectedRole
                    ) ||
                    Object.keys(errors).some(
                        (key) =>
                            ['username', 'firstName', 'lastName', 'email', 'password', 'passwordConfirm', 'selectedRole'].includes(key)
                    ) ? '#c0c0c0' : '#003476',
                    color: 'white'
                }}
                className="w-full mt-2"
                aria-label="Register"
                disabled={
                    !(
                        dirtyFields.username &&
                        dirtyFields.firstName &&
                        dirtyFields.lastName &&
                        dirtyFields.email &&
                        dirtyFields.password &&
                        dirtyFields.passwordConfirm &&
                        dirtyFields.selectedRole
                    ) ||
                    Object.keys(errors).some(
                        (key) =>
                            ['username', 'firstName', 'lastName', 'email', 'password', 'passwordConfirm', 'selectedRole'].includes(key)
                    )
                }
                type="submit"
                size="large"
            >
                Δημιουργία Χρήστη
            </Button>

        </form>
    </div>
</Paper>

        <Box
            className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
            sx={{backgroundColor: "primary.light"}}
        >
            <svg
                className="absolute inset-0 pointer-events-none"
                viewBox="0 0 960 540"
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMidYMax slice"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <Box
                        component="g"
                        sx={{ color: "primary.main" }}
                        className="opacity-20"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="100"
                    >
                        <circle r="234" cx="196" cy="23" />
                        <circle r="234" cx="790" cy="491" />
                    </Box>
                </svg>
                <Box
                    component="svg"
                    className="absolute -top-64 -right-64 opacity-20"
                    sx={{ color: "primary.light" }}
                    viewBox="0 0 220 192"
                    width="220px"
                    height="192px"
                    fill="none"
                >
                    <defs>
                        <pattern
                            id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                            x="0"
                            y="0"
                            width="20"
                            height="20"
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x="0" y="0" width="4" height="4" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect
                        width="220"
                        height="192"
                        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                    />
                </Box>
            </Box>
    </div>
  );
}

export default SignUpPageAdmin;
