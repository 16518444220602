export function createRequestBody(params) {
    const keys = [
        'title', 'fromDate', 'toDate', 'ticketFlg', 'priorityFlg',
        'representativeFlg', 'followUpFlg', 'applicationFlg', 
        'beneficiaryId', 'representativeId', 'thematicId', 
        'ticketStatuses', 'assigneeUserId', 'uuid' , 'groupId', 'whoIsCreated','carrierId','sourceApplicationId','teams','orderDto',"fromGroupRoleId"
    ];

    let body = {};

    keys.forEach(key => {
        body[key] = params[key] ?? null;
    });
    return body;
}
