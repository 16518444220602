import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import { getAllThematics } from 'app/Api Calls/GnosiakiCalls';
import { fetchAllCarriers, getAllQuestionsAndAnswers } from 'app/Api Calls/CarriersThematicsCalls';


function ExportToExcelAll() {
  const [thematicsData, setThematicsData] = useState([]);
  const [carriersData, setCarriersData] = useState([]);
  const [qnaData, setQnaData] = useState([]);

  useEffect(() => {
    getAllThematics()
      .then(response => {
        setThematicsData(response);
      })
      .catch(error => console.error(error));

    fetchAllCarriers()
      .then(response => {
        setCarriersData(response);
      })
      .catch(error => console.error(error));

    getAllQuestionsAndAnswers()
      .then(response => {
        setQnaData(response);
      })
      .catch(error => console.error(error));
  }, []);

  const handleExport = () => {
    let tempFlatData = [];
  
    // Sort carriersData by id in ascending order
    const sortedCarriersData = [...carriersData].sort((a, b) => a.id - b.id);
    
    const processLeaf = (carrier, thematic, subThematic, subSubThematic) => {
      tempFlatData.push({
        'Φορέας ID': carrier.id,
        'Φορέας': carrier.title,
        'Φορέας IsActive': carrier.isActive ? 'ΝΑΙ' : 'ΟΧΙ', 
        'Θεματική Ενότητα ID': thematic ? thematic.id : '',
        'Θεματική Ενότητα': thematic ? thematic.title : '',
        'Θεματική Ενότητα IsActive': thematic && thematic.isActive ? 'ΝΑΙ' : 'ΟΧΙ',
        'Θέμα ID': subThematic ? subThematic.id : '',
        'Θέμα': subThematic ? subThematic.title : '',
        'Θέμα IsActive': subThematic && subThematic.isActive  ? 'ΝΑΙ' : 'ΟΧΙ',
        'Υποθέμα ID': subSubThematic ? subSubThematic.id : '',
        'Υποθέμα': subSubThematic ? subSubThematic.title : '',
        'Υποθέμα IsActive': subSubThematic && subSubThematic.isActive ? 'ΝΑΙ' : 'ΟΧΙ',
      });
    };
    
    
  
    sortedCarriersData.forEach(carrier => {
      const carrierThematics = thematicsData.filter(thematic => thematic.carrierId === carrier.id && !thematic.parentId);
      carrierThematics.forEach(thematic => {
        const thematicSubThematics = thematicsData.filter(subThematic => subThematic.parentId === thematic.id);
        if (thematicSubThematics.length === 0) {
          processLeaf(carrier, thematic);
        } else {
          thematicSubThematics.forEach(subThematic => {
            const subThematicSubThematics = thematicsData.filter(subSubThematic => subSubThematic.parentId === subThematic.id);
            if (subThematicSubThematics.length === 0) {
              processLeaf(carrier, thematic, subThematic);
            } else {
              subThematicSubThematics.forEach(subSubThematic => {
                processLeaf(carrier, thematic, subThematic, subSubThematic);
              });
            }
          });
        }
      });
    });

  const ws = XLSX.utils.json_to_sheet(tempFlatData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, 'output.xlsx');
};

  return (
    <Button variant="contained" sx={{backgroundColor: '#1b5e20 !important'}} onClick={handleExport}>
      Εξαγωγή σε EXCEL Όλης της Γνωσιακής
    </Button>
  );
}

export default ExportToExcelAll;
