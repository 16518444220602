// UserEditDialog.js
import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    CircularProgress,
    FormHelperText
} from '@mui/material';
import {
    validateName,
    validateAFM,
    validateEmail,
    validatePhone,
    validateSurname,
    validateUserSurname
} from "app/main/Fields/ValidateFields";

const UserEditDialog = ({ user, open, onClose, onSave, editedUser , setEditedUser , isProfilePage}) => {


    const [errors, setErrors] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    useEffect(() => {
        if (user) {
            setEditedUser({
                id: user.id,
                firstName: user.firstName || null,
                lastName: user.lastName || null,
                email: user.email || null,
                phone: user.phone || null,
                // Conditionally include isEnabled based on isProfilePage
                ...(isProfilePage ? {} : { isEnabled: user.isEnabled || null }),
                // Conditionally include afm based on isProfilePage
                ...(isProfilePage ? {} : { afm: user.afm || null }),
            });
        }
    }, [user, isProfilePage]);

    useEffect(() => {
        if (user && editedUser) {
            const isChanged = user.firstName !== editedUser.firstName ||
                user.lastName !== editedUser.lastName ||
                user.email !== editedUser.email ||
                user.phone !== editedUser.phone ||
                (!isProfilePage && user.afm !== editedUser.afm);
            setHasChanged(isChanged);
        }
    }, [editedUser, user, isProfilePage]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedUser(prevState => ({
            ...prevState,
            [name]: value,
        }));
        validateField(name, value); // Validate field on change
    };

    const validateField = (name, value) => {
        let errorMessage = null;
        switch (name) {
            case 'firstName':
                errorMessage = validateName(value);
                break;
            case 'lastName':
                errorMessage = validateUserSurname(value);
                break;
            case 'email':
                errorMessage = validateEmail(value);
                break;
            case 'phone':
                errorMessage = validatePhone(value);
                break;
            case 'afm':
                errorMessage = validateAFM(value);
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({ ...prevErrors, [name]: errorMessage }));
    };

    const handleSave = async () => {
        // Validate all fields before save
        Object.keys(editedUser).forEach(key => validateField(key, editedUser[key]));
        const hasErrors = Object.values(errors).some(error => error != null);
        if (hasErrors) {
            return; // Stop save if there are validation errors
        }

        setIsUpdating(true);
        try {
            await onSave(editedUser);
        } catch (error) {
            console.error("Update failed:", error);
        } finally {
            setIsUpdating(false);
            onClose();
        }
    };



    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Επεξεργασία Χρήστη</DialogTitle>
            <DialogContent>
                {isUpdating && <CircularProgress size={24} style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                }}/>}
                <TextField
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    margin="dense"
                    label="Όνομα"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="firstName"
                    value={editedUser?.firstName || ''}
                    onChange={handleChange}
                />
                <TextField
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    margin="dense"
                    label="Επώνυμο"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="lastName"
                    value={editedUser?.lastName || ''}
                    onChange={handleChange}
                />
                <TextField
                    error={!!errors.email}
                    helperText={errors.email}
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    name="email"
                    value={editedUser?.email || ''}
                    onChange={handleChange}
                />
                <TextField
                    error={!!errors.phone}
                    helperText={errors.phone}
                    margin="dense"
                    label="Τηλέφωνο"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="phone"
                    value={editedUser?.phone || ''}
                    onChange={handleChange}
                />
                {
                    !isProfilePage && (
                        <TextField
                            error={!!errors.afm}
                            helperText={errors.afm}
                            margin="dense"
                            label="ΑΦΜ"
                            type="text"
                            fullWidth
                            variant="outlined"
                            name="afm"
                            value={editedUser?.afm || ''}
                            onChange={handleChange}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isUpdating}>Ακύρωση</Button>
                <Button onClick={handleSave} disabled={isUpdating || !hasChanged || Object.values(errors).some(error => error !== null)}>Αποθήκευση</Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserEditDialog;
