import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, CircularProgress, Box, List, ListItem, ListItemText, DialogActions, Button, Typography } from "@mui/material";
import { searchTeams } from "app/Api Calls/SearchTeams";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedTeam, setTeams} from "store/userAssignmentSlice";

const TeamSearchStep = ({ open, onClose, user, onDelete, onAddToTeams }) => {
    const dispatch = useDispatch();
    // Assuming the state shape and that teams are stored at state.userAssignment.userTeams
    const teams = useSelector(state => state.userAssignmentSlice.userTeams);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!open || !user) return;

        setLoading(true);
        dispatch(setTeams(user.id))
            .unwrap()
            .then(() => setError(''))
            .catch((error) => {
                console.error("Failed to fetch teams:", error.message);
                setError('Failed to load teams. Please try again.');
            })
            .finally(() => setLoading(false));
    }, [open, user, dispatch]);


    // const handleTeamSelect = (team) => {
    //     dispatch(setSelectedTeam(team));
    //     onTeamSelect(team);
    // };

    return (
        <Box
            sx={{
                width: '100%',
                maxHeight: '60vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '20px',
                overflowY: 'auto'
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 20px', marginBottom: "20px" }}>
                <Button
                    variant="contained"
                    onClick={onDelete}
                    sx={{ backgroundColor: "#F42C22 !important", color: 'white !important' }}
                >
                    Διαγραφή Απο ομάδα
                </Button>
                <Button
                    variant="contained"
                    onClick={onAddToTeams}
                    sx={{ backgroundColor: "#ED6C02 !important", color: 'white !important' }}
                >
                    Προσθήκη σε ομάδες
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                    <Typography color="error">{error}</Typography>
                </Box>
            ) : teams.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                    <Typography>Ο χρήστης δεν υπάρχει σε καμία ομάδα</Typography>
                </Box>
            ) : (
                <>
                <Typography sx={{ textAlign: 'center', marginBottom: '10px' }}>
                    Λίστα ομάδων χρήστη
                </Typography>
                <List>

                    {teams.map((team) => (
                        <ListItem  key={team.id}>
                            <ListItemText primary={team.name} />
                        </ListItem>
                    ))}
                </List>
                </>
            )}
        </Box>
    );
};


export default TeamSearchStep;
