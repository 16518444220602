import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, List, ListItem, Typography } from '@mui/material';
import { searchTeams } from "app/Api Calls/SearchTeams";
import settingsConfig from "app/configs/settingConfig";
import ListItemText from "@mui/material/ListItemText";
import { assignUserToTeam, setTeamAssignments } from "store/userAssignmentSlice";
import { useDispatch, useSelector } from "react-redux";

const TeamSearchActiveWizardStep = ({ selectedUser, onNoTicketsFound }) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const teamAssignments = useSelector((state) => state.userAssignmentSlice.teamAssignments);
    const tickets = useSelector((state) => state.userAssignmentSlice.tickets);
    const teamUserAssignments = useSelector(state => state.userAssignmentSlice.teamUserAssignments);

    useEffect(() => {
        const prepareAssignments = async () => {
            setLoading(true);
            try {
                const ticketsGroupedByTeam = tickets.reduce((acc, ticket) => {
                    (acc[ticket.teamId] = acc[ticket.teamId] || []).push(ticket);
                    return acc;
                }, {});

                const response = await searchTeams(0, 100, null, null, null, null, selectedUser.id, null);
                if (response && response.elements) {
                    let assignments = [];
                    let allHaveAssignableUsers = true;

                    response.elements.forEach(team => {
                        const teamGroupRole = settingsConfig.groupRoles.find(gr => gr.id === team.groupRole.id);
                        if (!teamGroupRole) return; // Skip if no matching group role is found

                        const teamLeaderRoleIds = teamGroupRole.roles.filter(r => r.description === "Team Leader").map(r => r.id);

                        let assignableUsers = team.userCollection.filter(user =>
                            user.isEnabled &&
                            user.id !== selectedUser.id &&
                            user.roleCollection.some(role => role.id === selectedUser.roleCollection[0].id)
                        );

                        if (assignableUsers.length === 0) {
                            assignableUsers = team.userCollection.filter(user =>
                                user.isEnabled &&
                                user.id !== selectedUser.id &&
                                user.roleCollection.some(role => teamLeaderRoleIds.includes(role.id))
                            );
                        }

                        if (assignableUsers.length > 0) {
                            assignments.push({
                                teamId: team.id,
                                teamName: team.name,
                                tickets: ticketsGroupedByTeam[team.id] || [],
                                users: assignableUsers
                            });
                        } else {
                            assignments.push({
                                teamId: team.id,
                                teamName: team.name,
                                tickets: ticketsGroupedByTeam[team.id] || [],
                                users: []
                            });
                            allHaveAssignableUsers = false;
                        }
                    });

                    dispatch(setTeamAssignments(assignments));
                    if (!allHaveAssignableUsers) {
                        // Handle the case where not all teams have assignable users
                        console.log("Not all teams have assignable users");
                    }
                }
            } catch (error) {
                console.error('Failed to prepare team user assignments:', error);
            } finally {
                setLoading(false);
            }
        };

        if (tickets.length > 0) {
            prepareAssignments();
        }
    }, [selectedUser, tickets]);

    const handleUserAssignmentSelection = (teamId, userId) => {
        dispatch(assignUserToTeam({teamId, userId}));
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <>
            <Typography>Επιλογή χρήστη για ανάθεση tickets ανά ομάδα:</Typography>
            {teamAssignments.length > 0 ? (
                teamAssignments.map((assignment, index) => {
                    // Only render the team if there are tickets available
                    if (assignment.tickets.length > 0) {
                        return (
                            <Box key={index} my={2}>
                                <Typography
                                    variant="h6">{`Ομάδα: ${assignment.teamName} - Tickets: ${assignment.tickets.length}`}</Typography>
                                {assignment.users.length > 0 ? (
                                    <List>
                                        {assignment.users.map(user => (
                                            <ListItem
                                                key={user.id}
                                                button
                                                onClick={() => handleUserAssignmentSelection(assignment.teamId, user.id)}
                                                selected={teamUserAssignments[assignment.teamId] === user.id}
                                            >
                                                <ListItemText primary={`${user.firstName} ${user.lastName}`}
                                                              secondary={`Ρόλος: ${user.roleCollection.map(role => role.roleName).join(', ')}`}/>
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : <Typography>Δεν βρέθηκαν κατάλληλοι χρήστες για ανάθεση.</Typography>}
                            </Box>
                        );
                    }
                    return null; // Return null if no tickets are available for the team
                })
            ) : <Typography>Δεν βρέθηκαν ομάδες με διαθέσιμα tickets.</Typography>}
        </>
    );
}

    export default TeamSearchActiveWizardStep;
