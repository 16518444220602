import React, {useState, useEffect} from 'react';
import {Typography, Modal, Box, Button, Paper} from '@mui/material';
import {getAnnouncementFiles, getAnnouncementFileDownload} from "app/Api Calls/AnnouncmentsCalls";
import {FileLogo} from "app/main/tickets/CRUD Ticket/EditTicket/Files/Filelogo";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function determineDateColor(validUntil) {
    if (!validUntil) return 'gray';
    const now = new Date();
    const validDate = new Date(validUntil);
    return validDate >= now ? 'green' : 'red';
}

function AnnouncementModal({open, handleClose, announcement}) {
    const [files, setFiles] = useState([]);
    const [displayAnnouncement, setDisplayAnnouncement] = useState(null);

    useEffect(() => {
        if (open && announcement) {
            setDisplayAnnouncement(announcement);
            getAnnouncementFiles(announcement.id)
                .then(setFiles)
                .catch(error => console.error('Error fetching announcement files:', error));
        } else {
            setDisplayAnnouncement(null);
            setFiles([]);
        }
    }, [open, announcement]);

    const handleFileClick = async (file) => {
        try {
            const fileUrl = await getAnnouncementFileDownload(file.id);
            const displayableExtensions = ['pdf', 'jpg', 'jpeg', 'png'];
            if (displayableExtensions.includes(file.extension.toLowerCase())) {
                window.open(fileUrl, '_blank');
            } else {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <Modal
            aria-labelledby="announcement-modal"
            aria-describedby="announcement-modal-description"
            open={open}
            onClose={() => {
                handleClose();
            }}
            closeAfterTransition
            sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
        >
            <Box sx={{
                bgcolor: '#EDF0F6',
                p: 4,
                width: '90vw',
                maxHeight: '80vh',
                overflowY: 'auto',
                marginTop: '1vh'
            }}>
                {displayAnnouncement ? (
                    <>
                        <Typography variant="h5" gutterBottom>{displayAnnouncement.title}</Typography>
                        <div dangerouslySetInnerHTML={{__html: displayAnnouncement.bodyText}}/>
                        <Typography variant="body1" color="textSecondary" gutterBottom>
                            Δημοσιεύθηκε: {formatDate(displayAnnouncement.createdAt)}
                        </Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                            <EventAvailableIcon sx={{
                                fontSize: 20,
                                color: determineDateColor(announcement?.validUntil)
                            }}/>
                            {announcement?.validUntil ? (
                                <Typography variant="body2" component="div" sx={{
                                    ml: 0.5,
                                    color: determineDateColor(announcement?.validUntil)
                                }}>
                                    Ενεργό έως: {formatDate(announcement?.validUntil)}
                                </Typography>
                            ) : (
                                <Typography variant="body2" component="div" sx={{
                                    ml: 0.5,
                                    color: 'gray'
                                }}>
                                    Δεν υπάρχει ημερομηνία
                                </Typography>
                            )}
                        </Box>


                        {/* Display files */}
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2}}>
                            {files.map((file, index) => (
                                <Paper key={index} sx={{
                                    p: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    width: 'fit-content',
                                    cursor: 'pointer'
                                }}
                                       onClick={() => handleFileClick(file)}>
                                    <FileLogo extension={file.extension}/>
                                    <Typography variant="body2" sx={{mr: 1}}>{file.originFileName}</Typography>
                                </Paper>
                            ))}
                        </Box>
                    </>
                ) : null}
                {/* Close button */}
                <Button onClick={handleClose}
                        sx={{
                            mt: 2,
                            bgcolor: '#003476 !important',
                            color: '#fff',
                            '&:hover': {
                                bgcolor: '#002350 !important'
                            }
                        }}>Κλείσιμο</Button>
            </Box>
        </Modal>
    );
}

export default AnnouncementModal;
