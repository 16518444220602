import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TeamCreate from './TeamCreate';
import TeamsTable from './TeamsTable';

function TeamsCrud() {
  const [refreshKey, setRefreshKey] = useState(0);

  const handleTeamCreate = () => {
    setRefreshKey(oldKey => oldKey + 1);
  }

  const handleTeamUpdate = () => {
    setRefreshKey(oldKey => oldKey + 1);
  }

  

  return(
    <Box>
      <TeamCreate onTeamCreate={handleTeamCreate} />
      <TeamsTable refreshKey={refreshKey} onTeamUpdate={handleTeamUpdate} />
    </Box>
  )
}

export default TeamsCrud;
