import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TaxisPostCode from "app/Api Calls/TaxisPostCode";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { jwtService } from "app/auth/jwtService";
import { showMessage } from "store/messageSlice";
import { setErrorMessage } from "store/userSlice";

function Taxis() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const taxisLoginUrl = process.env.REACT_APP_TAXIS_LOGOUT_URL;
  if (!localStorage.getItem("shouldRedirect")) {
    localStorage.setItem("shouldRedirect", "false");
  }
  if (!localStorage.getItem("codeFromRedirect")) {
    localStorage.setItem("codeFromRedirect", "");
  }
  
  useEffect(() => {
    if (location.pathname !== '/tnetauth') return;

    const shouldRedirect = localStorage.getItem("shouldRedirect") === "true";

    const queryParams = new URLSearchParams(location.search);
    const codeFromURL = queryParams.get("code");

    if (codeFromURL && !shouldRedirect) {

      localStorage.setItem("codeFromRedirect", codeFromURL);
      localStorage.setItem("shouldRedirect", "true");
      window.location.href = process.env.REACT_APP_TAXIS_LOGOUT_URL;
      return;
    }

    const storedCode = localStorage.getItem("codeFromRedirect");

    if (storedCode && shouldRedirect) {

      setLoading(true);
      TaxisPostCode(storedCode)
        .then((data) => {
          console.log("[Taxis] TaxisPostCode success:", data);

          if (data && data.accessToken && data.refreshAccessToken) {

            jwtService.signInWithTaxis(
              data.accessToken,
              data.refreshAccessToken
            )
              .then(() => {
                navigate('/create-ticket');
              })
              .catch((error) => {
                console.error("Error:", error);
                dispatch(
                  showMessage({
                    message: "Η Είσοδος με Taxis απέτυχε",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    variant: "error",
                  })
                );
                localStorage.setItem("shouldRedirect", "false");
                localStorage.setItem("codeFromRedirect", "");
                dispatch(setErrorMessage("Η Είσοδος με Taxis απέτυχε"));
                navigate("/sign-in");
              });
          }
        })
        .catch((error) => {
          if (error.message === "Taxis not 200 error") {
            dispatch(
              showMessage({
                message: "Υπήρξε πρόβλημα με την απόκριση της υπηρεσίας Taxis.",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "error",
              })
            );
            localStorage.setItem("shouldRedirect", "false");
            localStorage.setItem("codeFromRedirect", "");
            dispatch(
              setErrorMessage(
                "Υπήρξε πρόβλημα με την απόκριση της υπηρεσίας Taxis."
              )
            );
          } else {
            dispatch(
              showMessage({
                message: "Η Είσοδος με Taxis απέτυχε.",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                variant: "error",
              })
            );
            localStorage.setItem("shouldRedirect", "false");
            localStorage.setItem("codeFromRedirect", "");
            dispatch(setErrorMessage("Η Είσοδος με Taxis απέτυχε."));
          }
          navigate("/sign-in");
        })
        .finally(() => {
          setLoading(false);
          localStorage.setItem("shouldRedirect", "false");
          localStorage.setItem("codeFromRedirect", "");
        });
    }
  }, []);

  return (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {loading && (
        <div style={{ textAlign: 'center', border: '1px solid #e0e0e0', borderRadius: '8px', padding: '16px', width: '300px', backgroundColor: '#fff' }}>
          <img src={`${process.env.PUBLIC_URL}/logo/govgrthumb.png`} alt="Logo" style={{ maxWidth: '100%' }} />
          <p>Αναμονή για σύνδεση μέ κωδικούς Δημόσιας Διοίκησης</p>
          <div style={{ marginTop: '16px' }}>
            <CircularProgress />
          </div>
        </div>
      )}
    </div>
  );
}

export default Taxis;
