import React, { memo } from 'react';

function SplashScreen() {
  return (
    <div id="fuse-splash-screen">
      <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="logo" />
      <div id="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div></div>
      </div>
    </div>
  );
}

export default memo(SplashScreen);
