import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {toggleIsSupervisor} from "store/ticketFetchSlice";

function SupervisorSwitch() {
    const isSupervisor = useSelector((state) => state.ticketFetchSlice.isSupervisor);
    const dispatch = useDispatch();
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

    const handleChange = (event) => {
        dispatch(toggleIsSupervisor());
    };
    const label = isSupervisor ? "Χωρίς Όρια" : "Με Όρια";


    return (
        <FormControlLabel
            control={
                <Switch
                    disabled={ticketsLoading}
                    checked={isSupervisor}
                    onChange={handleChange}
                    name="isSupervisorSwitch"
                />
            }
            label={label}
        />
    );
}

export default SupervisorSwitch;
