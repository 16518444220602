import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    Checkbox,
    FormControlLabel,
    Switch
} from '@mui/material';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import updateQuestionAnswer from "app/Api Calls/UpdateQNA";

const EditQnADialog = ({ open, handleClose, qna, onSave }) => {
    const [title, setTitle] = useState(qna.title);
    const [question, setQuestion] = useState(qna.question);
    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(qna.answer).contentBlocks))
    );
    const [isActive, setIsActive] = useState(qna.isActive === 1);
    const [isPublished, setIsPublished] = useState(qna.isPublished || false);

    const handleSave = async () => {
        const updatedQnA = {
            ...qna,
            title,
            question,
            answer: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            isActive: isActive ? 1 : 0,
            isPublished
        };

        try {
            await updateQuestionAnswer(updatedQnA);
            console.log('QnA updated successfully');
            onSave(updatedQnA); // You might want to refresh or update the parent component's state here
        } catch (error) {
            console.error('Failed to update QnA', error);
            // Handle the error appropriately
        }

        handleClose();
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
            <DialogTitle>Επεξεργασία Ερώτησης/Απάντησης</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Τίτλος"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Ερώτηση"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                />
                <div style={{ border: '1px solid #F1F1F1', padding: '5px', minHeight: '200px', marginTop: '20px' }}>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                            inline: {
                                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                bold: { className: 'bordered-option-classname' },
                                italic: { className: 'bordered-option-classname' },
                                underline: { className: 'bordered-option-classname' },
                                strikethrough: { className: 'bordered-option-classname' },
                                monospace: { className: 'bordered-option-classname' },
                            },
                            blockType: {
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                            },
                            fontSize: { className: 'bordered-option-classname' },
                            fontFamily: {
                                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                            },
                            list: {
                                inDropdown: false,
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                                options: ['unordered', 'ordered', 'indent', 'outdent'],
                            },
                            textAlign: {
                                inDropdown: false,
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                                options: ['left', 'center', 'right', 'justify'],
                            },
                            history: {
                                inDropdown: false,
                                className: 'bordered-option-classname',
                                options: ['undo', 'redo'],
                            },
                        }}
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                            name="isActive"
                            color="primary"
                        />
                    }
                    label="Ενεργό"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={isPublished}
                            onChange={(e) => setIsPublished(e.target.checked)}
                            name="isPublished"
                            color="primary"
                        />
                    }
                    label="Δημοσιευμένο"
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Άκυρο</Button>
                <Button onClick={handleSave}>Αποθήκευση</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditQnADialog;
