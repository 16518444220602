import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataFromSubSubthematic,
  fetchQuestionsAndAnswersByThematicId,
  setCurrentPage,
  setResults,
  setSelectedSubSubthematic,
  setSubSubthematics
} from 'store/knowledgeBasePageSlice';
import CategoriesDropdown from "../CategoriesDropdown";

function SubSubThematicSelector({isCreatePage, onChange}) {
  const dispatch = useDispatch();
  const selectedSubThematic = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedSubthematic
  );
  const subSubthematics = useSelector(
    (state) => state.knowledgeBasePageSlice.subSubthematics
  );
  const isLoadingSubSubThematics = useSelector(
    (state) => state.knowledgeBasePageSlice.isLoadingSubSubThematics
  );
  const selectedSubSubthematic = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedSubSubthematic
  );
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);

  useEffect(() => {
    if (selectedSubThematic) {
      dispatch(fetchDataFromSubSubthematic({isCreatePage , parentId: selectedSubThematic.value}));
    }else {
      dispatch(setSelectedSubSubthematic(null));
      dispatch(setSubSubthematics([]));

    }
  }, [selectedSubThematic, dispatch]);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      dispatch(setSelectedSubSubthematic(selectedOption));
      dispatch(setCurrentPage(0));
      dispatch(fetchQuestionsAndAnswersByThematicId(selectedOption.value));
      if (!isCreatePage && onChange) {
        onChange(selectedOption.value);
      }
    } else {
      dispatch(setSelectedSubSubthematic(null));
      dispatch(setResults([]));
    }
  };
  




  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectedSubThematic && subSubthematics.length > 0  &&  (
        <CategoriesDropdown
          options={subSubthematics.map((subSubThematic) => ({
            value: subSubThematic.id,
            label: subSubThematic.title,
            id: subSubThematic.id
          }))}
          isDisabled={activeStatus && activeStatus.id === 5}
          value={selectedSubSubthematic}
          onChange={handleChange}
          placeholder="Υποθέμα"
          backgroundColor="white"
          isLoading={isLoadingSubSubThematics}
        />
      )}
    </>
  );
  
}

export default SubSubThematicSelector;
