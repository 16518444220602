import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    username: null,
    email: null,
    roleName: null,
    id: null,
    errorMessage: null,
    userGroupId: null,
    userRoleId: null,
    shouldRedirect: false,
    taxisCode: null,
    teamIds: [],
    carriers: [],
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            const { username, email, roleName, id, userRoleId, userGroupId, firstName, lastName, carriers,teamIds } = action.payload;
            state.username = username;
            state.email = email;
            state.roleName = roleName;
            state.id = id;
            state.userRoleId = userRoleId;
            state.userGroupId = userGroupId;
            state.firstName = firstName;
            state.lastName = lastName;
            state.teamIds = teamIds;
            state.carriers = carriers;
            state.teamIds = teamIds
            localStorage.setItem('user', JSON.stringify({ username, email, roleName, id, userRoleId, userGroupId, firstName, lastName, carriers,teamIds }));
        },
        logoutUser: (state) => {
            Object.assign(state, initialState);
        },
        userLoggedOut: (state, action) => initialState,
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShouldRedirect: (state, action) => {
            state.shouldRedirect = action.payload;
        },
        setTaxisCode: (state, action) => {
            state.taxisCode = action.payload;
        },
        setUserGroupId: (state, action) => {
            state.userGroupId = action.payload;
        },
        setUserRoleId: (state, action) => {
            state.userRoleId = action.payload;
        },
    }
});

export const { setUserGroupId, setUserRoleId, userLoggedOut, setErrorMessage, setShouldRedirect, setTaxisCode, setUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
