import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './Root';
import './input.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <Root />
    </>
);

