import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {
    fetchAllCarriers,
    fetchCarrierByThematicId,
    fetchQnaByThematicId,
    fetchSubSubthematic,
    fetchSubthematic,
    fetchThematicsByCarrierIdCall
} from 'app/Api Calls/CarriersThematicsCalls';
import {getActiveThematicsByCarrierId, getActiveThematicsByParentId} from 'app/Api Calls/GetActiveThematics';
import fetchThematicById from 'app/Api Calls/GetThematicById';
import {axiosApi} from 'app/configs/axiosConfig';

const initialState = {
    activeTab: 0,
    selectedCarrier: null,
    selectedThematic: null,
    selectedSubthematic: null,
    selectedSubSubthematic: null,
    carriers: [],
    thematics: [],
    subthematics: [],
    subSubthematics: [],
    results: [],
    article: null,
    isLoading: false,
    isLoadingCarriers: false,
    isLoadingThematics: false,
    isLoadingSubThematics: false,
    isLoadingSubSubThematics: false,
    isModalOpen: false,
    searchQuery: '',
    debouncedSearchQuery: '',
    currentPage: 0,
    perPage: 4,
    totalPages: 0,
    activeFlags: [],
    categoriesFetched: false,
    searchFetched: false,
    searchResults: []
    // changeRepresentativeAfm: '',
    // changeRepresentativeFirstName: '',
    // changeRepresentativeLastName: '',
    // changeRepresentativeEmail: '',
    // changeRepresentativePhone: '',
    // changeRepresentativeMobile: '',
};

export const fetchDataFromSubthematic = createAsyncThunk(
    'knowledgeBasePageSlice/fetchDataFromSubthematic',
    async ({isCreatePage, parentId}, {rejectWithValue}) => {
        try {
            if (isCreatePage) {
                return await getActiveThematicsByParentId(parentId);
            } else {
                return await fetchSubthematic(parentId);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue([]);
        }
    }
);


export const fetchDataFromSubSubthematic = createAsyncThunk(
    'knowledgeBasePageSlice/fetchDataFromSubSubthematic',
    async ({isCreatePage, parentId}, {rejectWithValue}) => {
        try {
            if (isCreatePage) {
                return await getActiveThematicsByParentId(parentId);
            } else {
                return await fetchSubSubthematic(parentId);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue([]);
        }
    }
);


export const fetchQuestionsAndAnswersByThematicId = createAsyncThunk(
    'knowledgeBasePageSlice/fetchQuestionsAndAnswersByThematicId',
    async (id, {dispatch, rejectWithValue}) => {
        try {
            dispatch(setIsLoading(true));
            const result = await fetchQnaByThematicId(id);
            dispatch(setIsLoading(false));
            return result;
        } catch (error) {
            dispatch(setIsLoading(false));
            console.error(error);
            return rejectWithValue([]);
        }
    }
);

export const fetchThematicsByCarrierId = createAsyncThunk(
    'knowledgeBasePageSlice/fetchThematicsByCarrierId',
    async ({isCreatePage, carrierId}, {rejectWithValue}) => {
        try {
            if (isCreatePage) {
                // If isCreatePage is true, call the API for active thematics
                return await getActiveThematicsByCarrierId(carrierId);
            } else {
                return await fetchThematicsByCarrierIdCall(carrierId);
            }
        } catch (error) {
            console.error(error);
            return rejectWithValue([]);
        }
    }
);


export const fetchCarriers = createAsyncThunk(
    'knowledgeBasePageSlice/fetchCarriers',
    async (_, {rejectWithValue}) => {
        try {
            const response = await fetchAllCarriers();
            return response.sort((a, b) => a.priority - b.priority);

        } catch (error) {
            console.error(error);
            return rejectWithValue([]);
        }
    }
);

export const fetchSearchResults = createAsyncThunk(
    'knowledgeBasePageSlice/fetchSearchResults',
    async ({searchQuery, currentPage, perPage, thematicId}, {dispatch}) => {
        try {
            dispatch(setIsLoading(true));
            const response = await axiosApi.post(
                `/questionNAnswer/search/${currentPage}/${perPage}`,
                {
                    question: searchQuery,
                    thematicId,
                }
            );

            if (response.data && response.data.elements) {
                return {
                    totalPages: response.data.totalPages - 1,
                    searchResultsArray: Object.values(response.data.elements),
                };
            }
            throw new Error('Unexpected response format');
        } catch (error) {
            console.error('Error fetching search results', error);
            throw error;
        } finally {
            dispatch(setIsLoading(false));
        }
    }
);

export const fetchCarrierAndThenSetThematic = createAsyncThunk(
    'knowledgeBasePageSlice/fetchCarrierAndThenSetThematic',
    async (thematicId, {dispatch}) => {
        const thematicsArray = [];
        let fetchedThematic = await fetchThematicById(thematicId);

        // Fetch all parent thematics until parentId is null
        while (fetchedThematic.parentId !== null) {
            thematicsArray.push(fetchedThematic);
            fetchedThematic = await fetchThematicById(fetchedThematic.parentId);
        }

        // Add the fetchedThematic (thematic with no parent ID) to the thematicsArray
        thematicsArray.push(fetchedThematic);

        // Reverse the thematics array to maintain the correct order
        const reversedThematicsArray = thematicsArray.reverse();

        // Set the main thematic
        const thematicData = {value: fetchedThematic.id, label: fetchedThematic.title};
        dispatch(setSelectedThematic(thematicData));

        // Set the subthematics and subsubthematics based on the reversed array
        reversedThematicsArray.forEach((thematic, index) => {
            const thematicData = {value: thematic.id, label: thematic.title};
            if (index === reversedThematicsArray.length - 1) {
                // Last thematic in the reversed array
                dispatch(setSelectedSubSubthematic(null)); // Set subsubthematic as null
                if (reversedThematicsArray.length === 2) {
                    // Subthematic
                    const subthematicData = {value: thematic.id, label: thematic.title};
                    dispatch(setSelectedSubthematic(subthematicData)); // Set subthematic
                } else if (index > 0) {
                    // Subsubthematic
                    const subthematicData = {
                        value: reversedThematicsArray[index - 1].id,
                        label: reversedThematicsArray[index - 1].title
                    };
                    dispatch(setSelectedSubthematic(subthematicData)); // Set subthematic
                    dispatch(setSelectedSubSubthematic(thematicData)); // Set subsubthematic
                }
            } else {
                dispatch(setSelectedSubthematic(thematicData)); // Set subthematic
            }
        });

        // Fetch the carrier data using the main thematic's carrierId
        const carrierId = fetchedThematic.carrierId;
        const fetchedCarrier = await fetchCarrierByThematicId(carrierId);
        const carrierData = {value: fetchedCarrier.id, label: fetchedCarrier.title, useGeo: fetchedCarrier.useGeo};
        dispatch(setSelectedCarrier(carrierData));
    }
);

export const ProsoriniApothikefsiSetThematic = () => {
    return (dispatch, getState) => {
        let thematicId = getState().knowledgeBasePageSlice.selectedSubSubthematic?.value || getState().knowledgeBasePageSlice.selectedSubthematic?.value || getState().knowledgeBasePageSlice.selectedThematic?.value || null;

        if (!thematicId) {
            dispatch(setSelectedCarrier({value: 12, label: '1555'}));
            dispatch(setSelectedThematic({value: 5242, label: 'Ενέργειες για Κλείσιμο Ticket'}));
            dispatch(setSelectedSubthematic({value: 1512, label: 'Προσωρινή Αποθήκευση'}));

            thematicId = getState().knowledgeBasePageSlice.selectedSubSubthematic?.value || getState().knowledgeBasePageSlice.selectedSubthematic?.value || getState().knowledgeBasePageSlice.selectedThematic?.value || null;
        }

        return thematicId;  // Returning the updated thematicId
    }
}

export const setConfirmationThunk = createAsyncThunk(
    'knowledgeBasePage/setConfirmation',
    async (resolution, {getState}) => {
        if (!resolution) return null;

        const state = getState();
        let newState = {...state.knowledgeBasePageSlice};

        if (resolution.labelEn === 'Line Failure' || resolution.labelEn === 'Wrong Number') {
            newState = {
                ...newState,
                selectedCarrier: {value: '12', label: '1555'},
                selectedThematic: {value: '5242', label: 'Ενέργειες για Κλείσιμο Ticket'},
                selectedSubthematic: resolution.labelEn === 'Line Failure'
                    ? {value: '5244', label: 'Έπεσε η Γραμμή'}
                    : {value: '5243', label: 'Λάθος Αριθμός'},
                subSubthematics: [],
                selectedSubSubthematic: null,
            };
        }

        const thematicId = newState.selectedSubSubthematic?.value ||
            newState.selectedSubthematic?.value ||
            newState.selectedThematic?.value ||
            null;

        return {newState, thematicId};
    }
);

const knowledgeBasePageSlice = createSlice({
    name: 'knowledgeBasePage',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        setSelectedCarrier: (state, action) => {
            state.selectedCarrier = action.payload;
        },
        setSelectedThematic: (state, action) => {
            state.selectedThematic = action.payload;
        },
        setSelectedSubthematic: (state, action) => {
            state.selectedSubthematic = action.payload;
        },
        setSelectedSubSubthematic: (state, action) => {
            state.selectedSubSubthematic = action.payload;
        },
        setCarriers: (state, action) => {
            state.carriers = action.payload;
        },
        setThematics: (state, action) => {
            state.thematics = action.payload;
        },
        setSubthematics: (state, action) => {
            state.subthematics = action.payload;
        },
        setSubSubthematics: (state, action) => {
            state.subSubthematics = action.payload;
        },
        setResults: (state, action) => {
            if (!action.payload || action.payload.length === 0) {
                state.results = [];
                state.resultsCount = 0;
                state.pageCount = 0;
            } else {
                state.results = action.payload;
                state.resultsCount = action.payload.length;
                state.pageCount = Math.ceil(action.payload.length / state.perPage);
            }
        },
        setArticle: (state, action) => {
            state.article = action.payload;
        },

        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingCarriers: (state, action) => {
            state.isLoadingCarriers = action.payload;
        },
        setIsLoadingThematics: (state, action) => {
            state.isLoadingThematics = action.payload;
        },
        setIsLoadingSubthematics: (state, action) => {
            state.isLoadingSubThematics = action.payload;
        },
        setIsLoadingSubSubthematics: (state, action) => {
            state.isLoadingSubSubThematics = action.payload;
        },
        setIsModalOpen: (state, action) => {
            state.isModalOpen = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload;
        },
        setDebouncedSearchQuery: (state, action) => {
            state.debouncedSearchQuery = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setActiveFlags: (state, action) => {
            state.activeFlags = action.payload;
        },
        setTotalPages: (state, action) => {
            state.totalPages = action.payload;
        },
        searchFetched: (state, action) => {
            state.searchFetched = action.payload;
        },
        categoriesFetched: (state, action) => {
            state.categoriesFetched = action.payload;
        },
        setSearchResults(state, action) {
            state.searchResults = action.payload;
        },
        resetKnowledgeBase: (state) => {
            const carriers = state.carriers;
            Object.keys(state).forEach((key) => {
                if (key !== 'carriers') {
                    delete state[key];
                }
            });
            Object.assign(state, {...initialState});
            state.carriers = carriers;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDataFromSubthematic.pending, (state) => {
                state.isLoadingSubThematics = true;
            })
            .addCase(fetchDataFromSubthematic.fulfilled, (state, action) => {
                state.isLoadingSubThematics = false;
                state.subthematics = action.payload;
            })

            .addCase(fetchDataFromSubSubthematic.pending, (state) => {
                state.isLoadingSubSubThematics = true;
            })
            .addCase(fetchDataFromSubSubthematic.fulfilled, (state, action) => {
                state.isLoadingSubSubThematics = false;
                state.subSubthematics = action.payload;
            })
            .addCase(fetchQuestionsAndAnswersByThematicId.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchQuestionsAndAnswersByThematicId.fulfilled, (state, action) => {
                state.isLoading = false;
                state.results = action.payload;
                state.resultsCount = action.payload.length;
                state.totalPages = Math.ceil(action.payload.length / state.perPage);
                state.searchFetched = true;
            })

      .addCase(fetchThematicsByCarrierId.pending, (state) => {
        state.isLoadingThematics = true;
      })
      .addCase(fetchThematicsByCarrierId.fulfilled, (state, action) => {
        state.isLoadingThematics = false;
        state.thematics = action.payload;
      })
      .addCase(fetchCarriers.pending, (state) => {
        state.isLoadingCarriers = true;
      })
      .addCase(fetchCarriers.fulfilled, (state, action) => {
        state.isLoadingCarriers = false;
        state.carriers = action.payload;

      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.searchResults = action.payload.searchResultsArray;
        state.totalPages = action.payload.totalPages;
        
      })
      .addCase(fetchSearchResults.rejected, (state) => {
        state.totalPages = 0;
        state.searchResults = [];
            })
            .addCase(setConfirmationThunk.fulfilled, (state, action) => {
                const {newState, thematicId} = action.payload;
                state.selectedCarrier = newState.selectedCarrier;
                state.selectedThematic = newState.selectedThematic;
                state.selectedSubthematic = newState.selectedSubthematic;
                state.selectedSubSubthematic = newState.selectedSubSubthematic;
                state.subSubthematics = newState.subSubthematics;
                state.isLoading = false;
            })
            .addCase(fetchCarrierAndThenSetThematic.pending, (state, action) => {
                state.isLoading = true; // Set loading to true when the thunk is pending
            })
            .addCase(fetchCarrierAndThenSetThematic.fulfilled, (state, action) => {
                state.isLoading = false; // Set loading to false when the thunk is fulfilled
            })
            .addCase(fetchCarrierAndThenSetThematic.rejected, (state, action) => {
                state.isLoading = false; // Set loading to false when the thunk is rejected
            });
    },
});

export const {
    setActiveTab,
    setSelectedCarrier,
    setSelectedThematic,
    setSelectedSubthematic,
    setSelectedSubSubthematic,
    setSubthematics,
    setSubSubthematics,
    setResults,
    setArticle,
    setIsLoading,
    setIsModalOpen,
    setSearchQuery,
    setDebouncedSearchQuery,
    setCurrentPage,
    setActiveFlags,
    setSearchResults,
    setTotalPages,
    resetKnowledgeBase,

} = knowledgeBasePageSlice.actions;

export default knowledgeBasePageSlice.reducer;
