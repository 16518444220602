import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {  setCarrier } from 'store/ticketFetchSlice';
import { fetchCarriers } from 'store/knowledgeBasePageSlice';

const CarrierFilter = () => {
  const dispatch = useDispatch();
  const carriers = useSelector((state) => state.knowledgeBasePageSlice.carriers);
  const selectedCarrierId = useSelector((state) => state.ticketFetchSlice.carrier);
  const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

    useEffect(() => {
    if (!carriers || carriers.length === 0) {
      dispatch(fetchCarriers());
    }
  }, [dispatch, carriers]);
 
  const handleChange = (event) => {
    const selectedCarrierId = event.target.value;
    dispatch(setCarrier(selectedCarrierId));
  };

  const handleClear = (event) => {
    event.stopPropagation();
    dispatch(setCarrier(null)); 
  };
  
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel 
        id="carrier-label" 
        sx={{ backgroundColor: '#edf0f6' }} 
      >
        Φορέας
      </InputLabel>
      <Select
        labelId="carrier-label"
        id="carrier-select"
        value={selectedCarrierId || ''}
        onChange={handleChange}
        disabled={ticketsLoading}
        size='small'
        label="Carrier"
        input={
          <OutlinedInput
            id="carrier-select-outlined"
            endAdornment={
              selectedCarrierId && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClear} style={{ marginRight: 8 }} disabled={ticketsLoading}  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        }
      >
        {carriers?.map((carrier) => (
          <MenuItem key={carrier.id} value={carrier.id}>
            {carrier.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CarrierFilter;
