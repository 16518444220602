import { axiosApi } from "app/configs/axiosConfig";

export const getBeneficiaryByAfm = async (afm, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      afm: afm
    });
    return response.data.elements[0];
  } catch (error) {
    console.error(error);
  }
};

// Get Beneficiary By Amka
export const getBeneficiaryByAmka = async (amka, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      amka: amka
    });
    return response.data.elements[0];
  } catch (error) {
    console.error(error);
  }
};

export const getBeneficiaryBySurname = async (surname, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      lastName: surname
    });
    // Return all matching beneficiaries instead of just the first one
    return response.data;
  } catch (error) {
    console.error(error);
  }
};


// Get Beneficiary By Mobile
export const getBeneficiaryByPhone = async (phone, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      phone: phone
    });
    return response.data.elements;
  } catch (error) {
    console.error(error);
  }
};

export const getBeneficiaryByMobile = async (mobile, page, size) => {
  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, {
      mobile: mobile
    });
    return response.data.elements;
  } catch (error) {
    console.error(error);
  }
};

export const searchBeneficiaries = async (searchType, searchValue, page = 0, size = 100) => {
  // Create the payload with all possible fields, initialized to null or empty
  const searchPayload = {

  };

  // Populate the payload based on the search type
  switch (searchType) {
    case "ΑΦΜ":
      searchPayload.afm = searchValue;
      break;
    case "ΑΜΚΑ":
      searchPayload.amka = searchValue;
      break;
    case "Επώνυμο":
      searchPayload.lastName = searchValue;
      break;
    case "Τηλέφωνο":
      searchPayload.phone = searchValue;
      break;
    case "Κινητό":
      searchPayload.mobile = searchValue;
      break;
    default:
      throw new Error("Invalid search type");
  }

  try {
    const response = await axiosApi.post(`/beneficiary/search/${page}/${size}`, searchPayload);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};




  export const searchUsers = async ({ searchPayload }, page, size) => {
    try {
      const response = await axiosApi.post(`/admin/user/search/${page}/${size}`, searchPayload);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  
  
