import { axiosApi } from "app/configs/axiosConfig";

export const submitComment = async (id, comment) => {
  try {
    const response = await axiosApi.post(`/ticketNote`, {
      ticketId: id,
      note: comment,    
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchCommentsForTicket = async (id) => {
  try {
    const response = await axiosApi.get(`/ticketNote/byTicketId/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

