import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { updateThematic } from 'app/Api Calls/CarriersThematicsCalls';

function EditModal({ isOpen, onClose, thematic, onUpdate, isCarrier }) {
  const [title, setTitle] = useState(thematic.title);
  const [isActive, setIsActive] = useState(thematic.isActive);
  const [isPublished, setIsPublished] = useState(thematic.isPublished);



  const handleSave = async () => {
    try {

      const carrierId = thematic.carrierId
      const externalId = null;
      const parentId = thematic.parentId
      const thematicId = thematic.id;

      await updateThematic(thematicId, title, isActive, carrierId, externalId, parentId, isPublished);

      onUpdate();
      onClose();
    } catch (error) {
      console.error('Error saving thematic:', error);
    }
  };


  return (
    <Dialog open={isOpen} 
    onClose={onClose} 
    sx={{
      zIndex: 9999,
      '& .MuiDialog-paper': { 
        width: '80%', 
        maxWidth: '700px',
      },
    }}
   >
      <DialogTitle>Επεξεργασία {isCarrier ? 'Φορέα' : 'Θεματικής'}</DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                label="Title"
                type="text"
                fullWidth
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                <Switch
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                    color="primary"
                />
                <div style={{marginLeft: '10px'}}>Is Active</div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                <Switch
                    checked={isPublished}
                    onChange={() => setIsPublished(!isPublished)}
                    color="primary"
                />
                <div style={{marginLeft: '10px'}}>Is Published</div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">Κλείσιμο</Button>
            <Button onClick={handleSave} color="primary">Αποθήκευση</Button>
        </DialogActions>
    </Dialog>
  );
}

export default EditModal;
