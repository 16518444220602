import React, { useState } from 'react';
import { Button, Modal, Box } from '@mui/material';
import IstorikoTicket from 'app/main/tickets/create-ticket/IstorikoTicket';
import { useSelector, useDispatch } from 'react-redux';
import {setFollowUpModalState, setSelectedCarrier} from "store/historyTicketSlice";

const FollowUpButtonModal = () => {
    const open = useSelector((state) => state.historyTicketSlice.isFollowUpModalOpen);
    const knowledgeSelectedCarrier = useSelector(state => state.knowledgeBasePageSlice.selectedCarrier);
    const dispatch = useDispatch();


    const handleOpen = () => {
        // Match the carrier in historyTicketSlice with the one from knowledgeBasePageSlice when opening
        dispatch(setSelectedCarrier(knowledgeSelectedCarrier?.value));
        dispatch(setFollowUpModalState(true));
    };

    const handleClose = () => {
        dispatch(setFollowUpModalState(false));
        dispatch(setSelectedCarrier(null));
    };

    return (
        <div>
            <Button variant="contained" sx={{ backgroundColor: "#ed6c02 !important" }} onClick={handleOpen}>
                Επιλογή Follow up Ticket
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80vw',
                        height: '80vh',
                        bgcolor: '#f5f5f5',
                        border: '2px solid #000',
                        boxShadow: 24,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            position: 'sticky',
                            top: 0,
                            bgcolor: 'background.paper',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '8px 0'
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handleClose}
                            sx={{ backgroundColor: "#003476 !important", marginRight: '32px' }}
                        >
                            Κλείσιμο
                        </Button>
                    </Box>
                    <Box sx={{ flex: 1, overflow: 'auto', padding: 4 }}>
                        <IstorikoTicket followUpMode = {true}/>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default FollowUpButtonModal;
