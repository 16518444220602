import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { Button,LinearProgress } from '@mui/material';
import { useRef } from 'react';
import { searchTeams } from 'app/Api Calls/SearchTeams';
import { createTeam, fetchUsersWithId, updateTeam } from 'app/Api Calls/TeamsRequiredApis';

const ExcelTeamUploader = () => {
  const [teams, setTeams] = useState([]);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [existingTeams, setExistingTeams] = useState([]);
  const [warnings, setWarnings] = useState([]);

  const handleFileUpload = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onload = async (evt) => { 
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      await fetchExistingTeams(data); 
    };
    
    reader.readAsBinaryString(file);

    
};

//We make normal names to check if they are the same
const normalizeName = name => 
    name.replace(/\s+/g, ' ').trim().normalize('NFC').toLowerCase();

//We pass in the create or ypdate ticket without extra gaps
    const removeExtraSpaces = str => 
    str.replace(/\s+/g, ' ').trim();

// Async function to fetch existing teams
const fetchExistingTeams = async (excelData) => {
  try {
      const groupRoleIds = [...new Set(excelData.map(row => row['Ρόλος ομάδας']))];
      let allTeamsForAllRoles = [];

      for (const groupRoleId of groupRoleIds) {
          const initialResponse = await searchTeams(0, 100, groupRoleId);
          let allTeams = initialResponse.elements;
          const totalPages = initialResponse.totalPages;

          for (let currentPage = 1; currentPage < totalPages; currentPage++) {
              const response = await searchTeams(currentPage, 100, groupRoleId);
              allTeams = allTeams.concat(response.elements);
          }
          allTeamsForAllRoles = allTeamsForAllRoles.concat(allTeams);
      }
      // Use the fetched teams as needed
      setExistingTeams(allTeamsForAllRoles);
      parseData(excelData, allTeamsForAllRoles);
  } catch (error) {
      console.error('Error fetching all teams:', error);
  }
};

// Function to parse new team data
const parseData = (data, existingTeams) => {
  const parsedTeams = {};
  const warnings = new Set();
  
  // Extracting and normalizing the names of existing teams, storing in a Set for efficient lookup
  const existingTeamNames = new Set(existingTeams.map(team => normalizeName(team.name)));
  
  // Iterating through the Excel data rows
  data.forEach((row) => {
    const originalTeamName = row['Όνομα απαντητικής ομάδας'];
    const normalizedTeamName = normalizeName(originalTeamName);

    // Check if the normalizedTeamName already exists in existingTeams
    if (!existingTeamNames.has(normalizedTeamName)) {
      // If team doesnt exist, proceed with parsing logic
      if (!parsedTeams[originalTeamName]) {
        // Handle 'Περιφέρεια (Geo1)' value
        let geographyCollection = [];
        if (row['Περιφέρεια (Geo1)'] !== 'ΌΛΑ') {
          geographyCollection = String(row['Περιφέρεια (Geo1)']).split(',').map(id => ({ id: parseInt(id, 10) }));
        }
        console.log(row['Θεματικές κατηγορίες']);

        parsedTeams[originalTeamName] = {
          name: removeExtraSpaces(originalTeamName),
          nameEn: row['Όνομα στα (Αγγλικά)'],
          afm: row['ΑΦΜ'],
          sourceApplicationCollection: String(row['Πλατφόρμα']).split(',').map(id => ({ id: parseInt(id, 10) })),
          geographyCollection,
          thematicCollection: String(row['Θεματικές κατηγορίες']).replace('.', ',').split(',').map(thematicId => ({ thematicId: parseInt(thematicId, 10) })),
          assignmentMethodCode: parseInt(row['Μέθοδος Ανάθεσης'], 10),
          groupRoleId: parseInt(row['Ρόλος ομάδας'], 10),
          members: []
        };
      }
      
      parsedTeams[originalTeamName].members.push({
        name: row['Όνομα'],
        surname: row['Επώνυμο'],
        afm: row['ΑΦΜ'],
      });
    } else {
      // If team exists, create a warning message
      warnings.add(`Team ${originalTeamName} υπάρχει ήδη.`);
    }
  });

  // Updating the state with potential warnings
  setWarnings([...warnings]);
  
  // Converting the parsedTeams object into an array and proceeding with the save logic
  const teamsArray = Object.values(parsedTeams);
  handleSaveTeams(teamsArray, existingTeams);
};

  
  
  const generateNotFoundExcel = (users) => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users Not Found");
    XLSX.writeFile(wb, "users_not_found.xlsx");
  };

  const handleSaveTeams = async (parsedTeams, existingTeams) => {
    console.log("Called with", parsedTeams, existingTeams);
    const usersNotFound = [];
    
    for (const team of parsedTeams) {
      try {
        const userCollection = [];
        const userSet = new Set(); // A set to store IDs of users already added to userCollection
  
        // Check if the team is already existing in the existingTeams
        const existingTeam = existingTeams.find(eTeam => eTeam.name === team.name);
  
        // Get members of the existing team
        const existingMembers = existingTeam ? existingTeam.userCollection.map(u => u.id) : [];
  
        for (const member of team.members) {
          const requestBody = { afm: member.afm };
          const userData  = await fetchUsersWithId(0, 10, requestBody);
          const user = userData.elements && userData.elements[0];
  
          if (user) {
            // Check if the user is new to the existing team and not added to userCollection yet
            if (!existingMembers.includes(user.id) && !userSet.has(user.id)) {
              userCollection.push({id: user.id});
              userSet.add(user.id); 
            }
          } else {
            console.error('User not found', member.name, member.surname, member.afm);
            usersNotFound.push(member);
          }
        }
  
        const requestBody = {
          name: team.name,
          nameEn: team.nameEn,
          sourceApplicationCollection: team.sourceApplicationCollection,
          userCollection,
          geographyCollection: team.geographyCollection,
          thematicCollection: team.thematicCollection,
          assignmentMethodCode: team.assignmentMethodCode,
          groupRoleId: team.groupRoleId,
        };
  
        // If the team already exists and there are new users, update it
        if (existingTeam && userCollection.length > 0) {
          await updateTeam(existingTeam.id, requestBody);
          console.log(`Team ${team.name} updated successfully`);
        } else if (!existingTeam) {
          // If the team doesn't exist, create a new one
          await createTeam(requestBody);
          console.log(`Team ${team.name} created successfully`);
        }
      } catch (error) {
        console.error(`Error processing team ${team.name}:`, error);
      } finally {
        setIsLoading(false);
    }
    }
  
    if (usersNotFound.length > 0) {
      generateNotFoundExcel(usersNotFound);
    }
  };
  


 
  
  return (
    <div>
<Button
  variant="contained"
  style={{ backgroundColor: "#175935", color: "white" }}
  onClick={() => fileInputRef.current.click()}
>
  Ανέβασμα Excel Αρχείου
</Button>

<input
  type="file"
  ref={fileInputRef}
  style={{ display: "none" }}
  accept=".xls,.xlsx"
  onChange={handleFileUpload}
/>

{isLoading && <LinearProgress />}

{warnings && warnings.map((warning, index) => (
        <p key={index} style={{ color: 'red' }}>{warning}</p>
      ))}

    </div>
  );
};

export default ExcelTeamUploader;