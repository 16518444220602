import { axiosApi } from "app/configs/axiosConfig";

const getTicketHistory = async (ticketId) => {
  try {
    const response = await axiosApi.get(`/ticketHistory/byTicketId/${ticketId}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw error; 
  }
};

export default getTicketHistory;
