import React, {useState, useEffect} from 'react';
import {
    List,
    ListItem,
    ListItemText,
    Typography,
    CircularProgress,
    Button,
    Paper,
    Box
} from '@mui/material';
import PushPinIcon from "@mui/icons-material/PushPin";
import {getAnnouncementsByCarrierId} from "app/Api Calls/AnnouncmentsCalls";
import {formatDate} from "app/main/Reusable Components/formatDateToDayMonthYear";
import EditAnnouncementDialog from "app/Announcments/Admin/EditAnnouncmentDialog";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

function determineDateColor(validUntil) {
    if (!validUntil) return 'gray';
    const now = new Date();
    const validDate = new Date(validUntil);
    return validDate >= now ? 'green' : 'red';
}

function formatIsActive(isActive) {
    return {
        text: isActive ? 'Ναι' : 'Όχι',
        color: isActive ? 'green' : 'red'
    };
}

const AnnouncementsListAdmin = ({refreshFlag, refreshAnnouncements, carriers, selectedCarrier}) => {
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [currentAnnouncement, setCurrentAnnouncement] = useState(null);

    const fetchAnnouncements = async () => {
        if (selectedCarrier) {
            setLoading(true);
            try {
                const response = await getAnnouncementsByCarrierId(selectedCarrier.id);
                const filteredAndSortedAnnouncements = response
                    .sort((a, b) => b.sticky - a.sticky);
                setAnnouncements(filteredAndSortedAnnouncements);
            } catch (error) {
                console.error('Failed to fetch announcements:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        fetchAnnouncements();
    }, [selectedCarrier, refreshFlag]);

    const handleEditClick = (announcement) => {
        setCurrentAnnouncement(announcement);
        setEditDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setEditDialogOpen(false);
        setCurrentAnnouncement(null);
    };


    return (
        <Box sx={{ p: 2 }}>
            {selectedCarrier ? (
                loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                ) : announcements.length > 0 ? (
                    <List sx={{ width: '100%' }}>
                        {announcements.map((announcement, index) => (
                            <Paper key={index} elevation={2} sx={{
                                my: 1, p: 2, bgcolor: '#fafafa',
                                '&:hover': {
                                    bgcolor: '#e0e0e0'
                                },
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1), 0 -2px 4px rgba(0,0,0,0.1)'
                            }}>
                                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {announcement.sticky && <PushPinIcon sx={{ color: 'red', mr: 2 }} />}
                                    <ListItemText
                                        primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }} component="div">{announcement.title}</Typography>}
                                        secondary={
                                            <>
                                                <Typography variant="body2" color="textSecondary" component="div">
                                                    Δημοσιεύθηκε: {formatDate(announcement.createdAt)}
                                                </Typography>
                                                <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                                                    <EventAvailableIcon sx={{
                                                        fontSize: 20,
                                                        color: determineDateColor(announcement.validUntil)
                                                    }}/>
                                                    {announcement.validUntil ? (
                                                        <Typography variant="body2" sx={{
                                                            ml: 0.5,
                                                            color: determineDateColor(announcement.validUntil)
                                                        }}>
                                                            Ενεργό έως: {formatDate(announcement.validUntil)}
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="body2" sx={{
                                                            ml: 0.5,
                                                            color: 'gray'
                                                        }}>
                                                            Δεν υπάρχει ημερομηνία
                                                        </Typography>
                                                    )}
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Typography variant="body2" component="span">
                                                        isActive:
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ ml: 0.5, color: formatIsActive(announcement.isActive).color }} component="span">
                                                        {formatIsActive(announcement.isActive).text}
                                                    </Typography>
                                                </Box>

                                            </>
                                        }
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Button variant="contained" sx={{ backgroundColor: "#ED6C02 !important", minWidth: 100 }}
                                                onClick={() => handleEditClick(announcement)}>
                                            Επεξεργασία
                                        </Button>
                                    </Box>
                                </ListItem>
                            </Paper>
                        ))}
                    </List>
                ) : (
                    <Typography sx={{ m: 2 }}>Δεν υπάρχουν Ανακοινώσεις για τον Φορέα</Typography>
                )
            ) : null}
            <EditAnnouncementDialog
                carriers={carriers}
                open={editDialogOpen}
                handleClose={handleCloseDialog}
                announcement={currentAnnouncement}
                refreshAnnouncements={refreshAnnouncements}
            />
        </Box>
    );
};


export default AnnouncementsListAdmin;
