import React, {useState} from 'react';
import {Button, Modal, Box, Typography, CircularProgress, List, ListItem, ListItemText, Paper} from '@mui/material';
import {useSelector} from "react-redux";
import {getAnnouncementsByCarrierId} from "app/Api Calls/AnnouncmentsCalls";
import AnnouncementDetail from "app/main/tickets/create-ticket/Announcments/AnnounmentDetail";
import PushPinIcon from '@mui/icons-material/PushPin';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AnnouncementsDisplay from "app/Announcments/AnnouncementsDisplay";

function determineDateColor(validUntil) {
    if (!validUntil) return 'gray';
    const now = new Date();
    const validDate = new Date(validUntil);
    return validDate >= now ? 'green' : 'red';
}

function formatDate(dateString) {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function Announcements() {
    const [open, setOpen] = useState(false);
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDetailedHeader, setShowDetailedHeader] = useState(false);
    const selectedCarrier = useSelector(state => state.knowledgeBasePageSlice.selectedCarrier);

    const handleOpen = () => {
        setOpen(true);
        setLoading(true);
        setShowDetailedHeader(true);
        if (selectedCarrier) {
            getAnnouncementsByCarrierId(selectedCarrier.value)
                .then(response => {
                    const filteredAndSortedAnnouncements = response
                        .filter(ann => ann.isActive)
                        .sort((a, b) => b.sticky - a.sticky);
                    setAnnouncements(filteredAndSortedAnnouncements);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Failed to fetch announcements:', error);
                    setLoading(false);
                });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setShowDetailedHeader(false);
        setAnnouncements([]);
    };


    return (
        <div>
            <Button variant="contained" sx={{bgcolor: "#003476 !important", color: "#fff"}} onClick={handleOpen}>
                Ανακοινώσεις
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                <Box sx={{
                    bgcolor: 'background.paper',
                    maxWidth: '75vw',
                    width: '75vw',
                    maxHeight: '80vh',
                    height: '80vh',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 4,
                    pt: 0
                }}>
                    {loading ? (
                        <CircularProgress sx={{alignSelf: 'center', marginTop: 'auto', marginBottom: 'auto'}}/>
                    ) : (
                        <AnnouncementsDisplay
                            announcements={announcements}
                            selectedCarrier={selectedCarrier}
                            showDetailedHeader={showDetailedHeader}
                            closeModal={handleClose}
                        />
                    )}
                </Box>
            </Modal>
        </div>
    );
}

export default Announcements;

