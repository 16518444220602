import {createSlice} from "@reduxjs/toolkit";

export const representativeFetchSlice = createSlice({
    name: 'representativeFetchSlice',
    initialState: {
        lastRepresentativeSearchParams: {
            afm: null,
            phone: null,
            mobile: null
        },
        fetchedRepresentatives: [],
        isLoadingRepresentative: false,
        hasRepresentativeFetched: false,
        shouldCreateRepresentative: true,
        representativeData: null,
    },

    reducers: {
        setRepresentativeLastSearchParams: (state, action) => {
            Object.keys(action.payload).forEach(key => {
                const value = action.payload[key];
                state.lastRepresentativeSearchParams[key] = value;
            });
        },

        setFetchedRepresentatives: (state, action) => {
            if (Array.isArray(action.payload)) {
                const uniqueNewRepresentatives = action.payload.filter(newRepresentative =>
                    !state.fetchedRepresentatives.some(existingRepresentative => existingRepresentative.id === newRepresentative.id)
                );
                state.fetchedRepresentatives = [...state.fetchedRepresentatives, ...uniqueNewRepresentatives];
            } else if (action.payload) {
                if (!state.fetchedRepresentatives.some(existingRepresentative => existingRepresentative.id === action.payload.id)) {
                    state.fetchedRepresentatives.push(action.payload);
                }
            }
        },
        setEditOriginalRepresentative: (state, action) => {
            state.editOriginalBeneficiary = action.payload;
        },
        updateRepresentativeData: (state, action) => {
            state.representativeData = action.payload;
        },
        setHasRepresentativeFetched: (state, action) => {
            state.hasRepresentativeFetched = action.payload;
        },
        clearSpecificLastSearchParam: (state, action) => {
            const param = action.payload;
            if (state.lastRepresentativeSearchParams.hasOwnProperty(param)) {
                state.lastRepresentativeSearchParams[param] = null;
            }
        },
        setIsLoadingRepresentative: (state, action) => {
            state.isLoadingRepresentative = action.payload;
        },
        clearFetchedRepresentatives: (state) => {
            state.fetchedRepresentatives = [];
        },
        setAllRepresentativeLastSearchParams: (state, action) => {
            state.lastRepresentativeSearchParams = {
                ...action.payload,
            };
        },
        clearRepresentativeLastSearchParams: (state) => {
            state.lastRepresentativeSearchParams.afm = null;
            state.lastRepresentativeSearchParams.phone = null;
            state.lastRepresentativeSearchParams.mobile = null;
        },

        setShouldCreateRepresentative: (state, action) => {
            state.shouldCreateRepresentative = action.payload;
        },
    }
});

export const {
    setShouldCreateRepresentative,
    clearSpecificLastSearchParam,
    setIsLoadingRepresentative,
    setRepresentativeLastSearchParams,
    clearRepresentativeLastSearchParams,
    setAllRepresentativeLastSearchParams,
    setFetchedRepresentatives,
    clearFetchedRepresentatives,
    updateRepresentativeData,
    setHasRepresentativeFetched,
} = representativeFetchSlice.actions;

export default representativeFetchSlice.reducer;
