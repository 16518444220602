import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {
    changeAddress,
    changeAfm,
    changeAmka,
    changeEmail,
    changeMobile,
    changeName,
    changePhone,
    changeRepresentativeAfm,
    changeRepresentativeEmail,
    changeRepresentativeFirstName,
    changeRepresentativeLastName,
    changeRepresentativeMobile,
    changeRepresentativePhone,
    changeSurname, representativeFilled, resetRepresentativeState, setBeneficiaryId,
    setHasBeneficiaryFetched,
    setOriginalBeneficiaryData,
    setOriginalRepresentativeData,
    setRepresentativeId,
    setShouldCreateBeneficiary, updateBeneficiaryData,
} from "store/userInfoSlice";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import fetchRepresentativeById from "app/Api Calls/GetRepresentativeById";
import {setEditOriginalBeneficiary, setEditOriginalRepresentative, setIsUserInfoFetched} from "store/editTicketSlice";
import {useRef} from "react";
import {
    setBeneficiaryLastSearchParams,
    setFetchedBeneficiaries, setIsLoadingBeneficiary,
} from "store/beneficiaryFetchSlice";
import UserInfo from "app/main/tickets/create-ticket/UserInfo";
import fetchBeneficiaryById from "app/Api Calls/FetchBeneficiaryById";
import {
    setFetchedRepresentatives,
    setHasRepresentativeFetched,
    setRepresentativeLastSearchParams, setShouldCreateRepresentative
} from "store/representaticeFetchSlice";

function BeneficiaryRepresentativeAccordion({ isEditMode, expandedPanels, onPanelToggle,panelRefs})
{
    const isUserInfoFetched = useSelector(
        (state) => state.editTicketSlice.isUserInfoFetched
    );
    const ticket = useSelector((state) => state.editTicketSlice.ticket);
    const ticketFetched = useSelector((state) => state.editTicketSlice.isTicketFetched);
    const isFromAccordion = useRef(true);
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            ((!isUserInfoFetched && ticketFetched))
        ) {
            isFromAccordion.current = true;
            dispatch(setIsLoadingBeneficiary(true));
            fetchUserInfoData()
                .then(() => {
                    isFromAccordion.current = false;
                    dispatch(setIsLoadingBeneficiary(false));
                })
                .catch((error) => {
                    console.error("Fetching user info failed:", error);
                    dispatch(setIsLoadingBeneficiary(false));
                    isFromAccordion.current = false;
                });


        }
    }, [ticketFetched]);

    const fetchUserInfoData = async () => {
        if (ticket && ticket.beneficiaryId) {
            const fetchedBeneficiary = await fetchBeneficiaryById(
                ticket.beneficiaryId
            );

            if (fetchedBeneficiary) {
                dispatch(setOriginalBeneficiaryData(fetchedBeneficiary));
                if (isEditMode) {
                    //Adds the first beneficiary initially fetched
                    dispatch(setEditOriginalBeneficiary(fetchedBeneficiary));
                    dispatch(updateBeneficiaryData(fetchedBeneficiary));
                }
                const keyObj = {
                    afm: fetchedBeneficiary.afm || null,
                    amka: fetchedBeneficiary.amka || null,
                    mobile: fetchedBeneficiary.mobile || null,
                    phone: fetchedBeneficiary.phone || null,
                };

                // Additional dispatch logic
                dispatch(setBeneficiaryLastSearchParams(keyObj));
                dispatch(setFetchedBeneficiaries(fetchedBeneficiary));
                dispatch(setShouldCreateBeneficiary(false));
                dispatch(
                    changeName({
                        value: fetchedBeneficiary.firstName || "",
                        isValid: !!fetchedBeneficiary.firstName,
                    })
                );
                dispatch(
                    changeSurname({
                        value: fetchedBeneficiary.lastName || "",
                        isValid: !!fetchedBeneficiary.lastName,
                    })
                );
                dispatch(
                    changeAfm({
                        value: fetchedBeneficiary.afm || "",
                        isValid: !!fetchedBeneficiary.afm,
                    })
                );
                dispatch(
                    changeAmka({
                        value: fetchedBeneficiary.amka || "",
                        isValid: !!fetchedBeneficiary.amka,
                    })
                );
                dispatch(
                    changePhone({
                        value: fetchedBeneficiary.phone || "",
                        isValid: !!fetchedBeneficiary.phone,
                    })
                );
                dispatch(
                    changeMobile({
                        value: fetchedBeneficiary.mobile || "",
                        isValid: !!fetchedBeneficiary.mobile,
                    })
                );
                dispatch(
                    changeEmail({
                        value: fetchedBeneficiary.email || "",
                        isValid: !!fetchedBeneficiary.email,
                    })
                );
                dispatch(
                    changeAddress({
                        value: fetchedBeneficiary.address || "",
                        isValid: !!fetchedBeneficiary.address,
                    })
                );
                dispatch(setHasBeneficiaryFetched(true));
                dispatch(setIsUserInfoFetched(true));
                dispatch(setBeneficiaryId(fetchedBeneficiary.id));
            }
        }
        if (ticket && ticket.representativeId) {
            const fetchedRepresentative = await fetchRepresentativeById(
                ticket?.representativeId
            );
            if (fetchedRepresentative) {
                const keyObj = {
                    afm: fetchedRepresentative.afm || null,
                    phone: fetchedRepresentative.phone || null,
                    mobile: fetchedRepresentative.mobile || null,
                };
                dispatch(setOriginalRepresentativeData(fetchedRepresentative));
                dispatch(setEditOriginalRepresentative(fetchedRepresentative))
                dispatch(setRepresentativeLastSearchParams(keyObj));
                dispatch(setFetchedRepresentatives(fetchedRepresentative));
                dispatch(setShouldCreateRepresentative(false));
                dispatch(
                    changeRepresentativeAfm({
                        value: fetchedRepresentative.afm || "",
                        isValid: !!fetchedRepresentative.afm,
                    })
                );
                dispatch(
                    changeRepresentativeFirstName({
                        value: fetchedRepresentative.firstName || "",
                        isValid: !!fetchedRepresentative.firstName,
                    })
                );
                dispatch(
                    changeRepresentativeLastName({
                        value: fetchedRepresentative.lastName || "",
                        isValid: !!fetchedRepresentative.lastName,
                    })
                );
                dispatch(
                    changeRepresentativeEmail({
                        value: fetchedRepresentative.email || "",
                        isValid: !!fetchedRepresentative.email,
                    })
                );
                dispatch(
                    changeRepresentativePhone({
                        value: fetchedRepresentative.phone || "",
                        isValid: !!fetchedRepresentative.phone,
                    })
                );
                dispatch(
                    changeRepresentativeMobile({
                        value: fetchedRepresentative.mobile || "",
                        isValid: !!fetchedRepresentative.mobile,
                    })
                );
                dispatch(setRepresentativeId(fetchedRepresentative.id));
                dispatch(setHasRepresentativeFetched(true));
            }
        } else {
            // Reset to initial state if no representative is
            dispatch(resetRepresentativeState());
        }

    };

    return (
        <Accordion
            className="bg-gray-200"
            expanded={expandedPanels.includes("Δικαιούχος και Αντιπρόσωπος")}
            onChange={() => onPanelToggle("Δικαιούχος και Αντιπρόσωπος")}
            ref={panelRefs["Δικαιούχος και Αντιπρόσωπος"]}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <h3>Δικαιούχος και αντιπρόσωπος</h3>
            </AccordionSummary>
            <AccordionDetails>
                <UserInfo
                    isEditMode={true}
                />
            </AccordionDetails>
        </Accordion>
    );
}

export default BeneficiaryRepresentativeAccordion;
