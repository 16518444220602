import React from 'react';
import { Typography } from '@mui/material';

const ConfirmToggleStep = ({ user }) => {
    return (
        <Typography>
            Είστε σίγουροι ότι θέλετε να {user.isEnabled ? 'απενεργοποιήσετε' : 'ενεργοποιήσετε'} τον/την {user.firstName} {user.lastName};
        </Typography>
    );
};

export default ConfirmToggleStep;
