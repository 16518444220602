import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Typography, List, ListItem, ListItemText} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {assignUserToTeam, updateTickets} from "store/userAssignmentSlice";
import {fetchTeamById} from "app/Api Calls/GetTeamById";
import settingsConfig from "app/configs/settingConfig";
import {removeUserFromTeams} from "app/Api Calls/AddRemoveUserTeams";
import Button from "@mui/material/Button";
import {showMessage} from "store/messageSlice";

const SelectedTeamDetailsStep = ({user, onNoTicketsFound, handleGoBack}) => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const [teamDetails, setTeamDetails] = useState(null);
    const [assignableUsers, setAssignableUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const {tickets, selectedTeam} = useSelector((state) => state.userAssignmentSlice)

    useEffect(() => {
        if (!selectedTeam) return;

        const fetchAndProcessTeamDetails = async () => {
            setLoading(true);
            try {
                const teamData = await fetchTeamById(selectedTeam.id);
                setTeamDetails(teamData);
                // Assuming teamData correctly fetched and includes groupRole and userCollection
                const teamGroupRole = settingsConfig.groupRoles.find(gr => gr.id === teamData.groupRole.id);
                if (!teamGroupRole) return; // Skip if no matching group role is found

                const teamLeaderRoleIds = teamGroupRole.roles.filter(r => r.description === "Team Leader").map(r => r.id);

                let filteredUsers = [];
                if (teamGroupRole) {
                    // First, try to find users with the same role as the current user
                    const currentUserRoleIds = user.roleCollection.map(role => role.id);
                    filteredUsers = teamData.userCollection.filter(userItem =>
                        userItem.roleCollection.some(role => currentUserRoleIds.includes(role.id)) &&
                        userItem.id !== user.id &&
                        userItem.isEnabled
                    );

                    // If no users with the same role found, then look for team leaders
                    if (filteredUsers.length === 0) {
                        filteredUsers = teamData.userCollection.filter(userItem =>
                            teamLeaderRoleIds.includes(userItem.roleCollection[0].id) &&
                            userItem.id !== user.id &&
                            userItem.isEnabled
                        );
                    }
                }

                setAssignableUsers(filteredUsers);
            } catch (error) {
                console.error('Failed to fetch team details:', error);
                onNoTicketsFound(user.id, !user.isEnabled);
            } finally {
                setLoading(false);
            }
        };

        fetchAndProcessTeamDetails();
    }, [selectedTeam, user.id]);


    const handleConfirmAssignment = async () => {
        if (!teamDetails || !selectedUserId) return;
        setLoading(true);
        // Assuming all tickets should be assigned to selectedUserId
        const assignedTickets = tickets.map(ticket => ({
            ...ticket,
            assigneeUser: selectedUserId,
        }));

        try {
            await dispatch(updateTickets(assignedTickets)).unwrap();

            // If successful, show success message for assignment
            dispatch(showMessage({
                message: "Τα ticket ανατέθηκαν με επιτυχία.",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));

            // Proceed to remove the user from the selected team
            try {
                await removeUserFromTeams(user.id, [selectedTeam.id]);
                handleGoBack();
                dispatch(showMessage({
                    message: "Ο χρήστης αφαιρέθηκε από την ομάδα επιτυχώς.",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                }));
            } catch (error) {
                console.error('Error removing user from the team:', error);
                // Handle error for removing user from the team
                dispatch(showMessage({
                    message: "Σφάλμα κατά την αφαίρεση του χρήστη από την ομάδα.",
                    variant: "error",
                }));
            }
        } catch (error) {
            console.error('Failed to assign tickets:', error);
            // Handle error for ticket assignment
            dispatch(showMessage({
                message: "Σφάλμα κατά την ανάθεση των ticket.",
                variant: "error",
            }));
        } finally {
            setLoading(false);
        }
    };


    const handleUserSelection = (userId) => {
        setSelectedUserId(userId);
    };

    function createRoleDescriptionMap(config) {
        const map = {};
        config.groupRoles.forEach(group => {
            group.roles.forEach(role => {
                map[role.roleName] = role.description;
            });
        });
        return map;
    }
    const roleDescriptionMap = createRoleDescriptionMap(settingsConfig);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress/>
            </Box>
        );
    }

    return (
        <>
            <Typography variant="h5">Χρήστες ομάδας για ανάθεση: {teamDetails?.name}</Typography>
            <List>
                {assignableUsers.length > 0 ? (
                    assignableUsers.map(user => (
                        <ListItem   sx={{
                            backgroundColor: user.id === selectedUserId ? '#f0f0f0' : 'inherit',
                            '&:hover': {
                                backgroundColor: '#f0f0f0'
                            }
                        }} key={user.id} button onClick={() => handleUserSelection(user.id)}>
                            <ListItemText primary={`${user.firstName} ${user.lastName}`}
                                          secondary={`Ρόλος: ${user.roleCollection.map(role => roleDescriptionMap[role.roleName] || role.roleName).join(', ')}`}/>
                        </ListItem>
                    ))
                ) : (
                    <Typography>Δεν βρέθηκαν κατάλληλοι χρήστες για ανάθεση στην ομάδα {teamDetails?.name}.</Typography>
                )}
            </List>
            <Button
                variant="contained"
                onClick={handleConfirmAssignment}
                disabled={!selectedUserId || loading}
                sx={{backgroundColor: "#003476 !important"}}
            >
                Επιβεβαίωση
            </Button>
        </>
    );
};

export default SelectedTeamDetailsStep;

