import React, {useState} from 'react';
import {Box, Typography, List, ListItem, ListItemText, ListItemIcon, Paper, Button} from '@mui/material';
import AnnouncementModal from "app/Announcments/AnnounmentModal";
import PushPinIcon from "@mui/icons-material/PushPin";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";

function formatDate(dateString) {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function determineDateColor(validUntil) {
    if (!validUntil) return 'gray';
    const now = new Date();
    const validDate = new Date(validUntil);
    return validDate >= now ? 'green' : 'red';
}

function AnnouncementsDisplay({announcements, selectedCarrier, showDetailedHeader = false,closeModal}) {
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (announcement) => {
        setSelectedAnnouncement(announcement);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedAnnouncement(null);
    };


    const filteredAndSortedAnnouncements = announcements
        .filter(ann => ann.isActive)
        .sort((a, b) => b.sticky - a.sticky);

    return (
        <Box sx={{width: '100%', overflowY: 'auto', bgcolor: '#ffffff'}}>
            {!showDetailedHeader ? (
                <Typography component="div" variant="h6" sx={{ml: 2, mt: 1, mb: 2}}>
                    Ανακοινώσεις Φορέα {selectedCarrier?.label ? ` - ${selectedCarrier?.label}` : ''}
                </Typography>
            ) : (
                <Box sx={{
                    position: 'sticky', top: 0, zIndex: 1,
                    bgcolor: 'background.paper', px: 4, py: 2,
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2"
                                sx={{flexGrow: 1, textAlign: 'center'}}>
                        Ανακοινώσεις του Φορέα {selectedCarrier?.label}
                    </Typography>
                    <Button variant="contained" sx={{bgcolor: "#003476 !important", color: "#fff"}}
                            onClick={closeModal}>
                        Κλείσιμο
                    </Button>
                </Box>
            )}
            {filteredAndSortedAnnouncements.length > 0 ? (
                <List sx={{width: "100%", p: 0}}>
                    {filteredAndSortedAnnouncements.map((announcement, index) => (
                        <Paper key={index} elevation={2} sx={{
                            my: 1,
                            bgcolor: '#fafafa',
                            '&:hover': {
                                bgcolor: '#e0e0e0'
                            },
                            cursor: 'pointer',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1), 0 -2px 4px rgba(0,0,0,0.1)'
                        }}>
                            <ListItem button onClick={() => handleOpen(announcement)}
                                      sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          p: 2,
                                          m: 0,
                                          width: '100%',
                                          height: '100%'
                                      }}>
                                {announcement.sticky && (
                                    <ListItemIcon sx={{minWidth: 'auto', mr: 2}}>
                                        <PushPinIcon sx={{color: 'red', verticalAlign: 'middle'}}/>
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    primary={<Typography component="div" variant="subtitle1"
                                                         sx={{fontWeight: 'bold'}}>{announcement.title}</Typography>}
                                    secondary={
                                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                            <Typography variant="body2" component="div" color="textSecondary">
                                                Δημοσιεύθηκε: {formatDate(announcement.createdAt)}
                                            </Typography>
                                            <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                                                <EventAvailableIcon  sx={{
                                                    fontSize: 20,
                                                    color: determineDateColor(announcement.validUntil)
                                                }}/>
                                                {announcement.validUntil ? (
                                                    <Typography component="div" variant="body2" sx={{
                                                        ml: 0.5,
                                                        color: determineDateColor(announcement.validUntil)
                                                    }}>
                                                        Ενεργό έως: {formatDate(announcement.validUntil)}
                                                    </Typography>
                                                ) : (
                                                    <Typography component="div" variant="body2" sx={{
                                                        ml: 0.5,
                                                        color: 'gray'
                                                    }}>
                                                        Δεν υπάρχει ημερομηνία
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        </Paper>
                    ))}
                </List>
            ) : (
                <Typography component="div" sx={{ m: 2 }}>Δεν υπάρχουν Ανακοινώσεις για τον Φορέα</Typography>
            )}

            {/* Render AnnouncementModal component */}
            <AnnouncementModal open={open} handleClose={handleClose} announcement={selectedAnnouncement}/>
        </Box>
    );

}

export default AnnouncementsDisplay;
