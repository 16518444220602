import React, { useState } from 'react';
import EditModal from './EditModal'; 

function EditDeleteButtons({ thematic, isCarrier,onUpdate }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemData, setItemData] = useState({ title: '', isActive: false });

  const handleEdit = (e) => {
    e.stopPropagation(); // Prevent event from propagating to parent elements
    setItemData({ title: thematic.title, isActive: thematic.isActive }); 
    setIsModalOpen(true);
  };

  const handleDelete = (e) => {
    e.stopPropagation(); // Prevent event from propagating to parent elements
    // Implement the delete logic here
    console.log(`Delete item with ID: ${thematic.id}`);
  };

  const handleSave = (editedData) => {
    // Implement the save logic here
    console.log('Saved data:', editedData);
    setIsModalOpen(false);
  };

  return (
    <div>
    <div className="w-auto flex items-center justify-end space-x-2">
      <button
        className="bg-blue-500 text-white py-1 px-4 rounded focus:outline-none"
        style={{ zIndex: 1000 }}
        onClick={handleEdit}
      >
        Επεξεργασία
      </button>
      <button
        className="bg-red-500 text-white py-1 px-4 rounded focus:outline-none"
        style={{ zIndex: 1000 }}
        onClick={handleDelete}
      >
        Διαγραφή
      </button>

      
    </div>
    {isModalOpen && (
        <EditModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          thematic={thematic}
          onUpdate={onUpdate}
          isCarrier={isCarrier}
        />
      )}
    </div>
  );
}

export default EditDeleteButtons;
