import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleIcon from "@mui/icons-material/People";
import settingsConfig from "../configs/settingConfig";
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import { PersonAdd } from "@mui/icons-material";
import CampaignIcon from "@mui/icons-material/Campaign";
import RecentActorsIcon from '@mui/icons-material/RecentActors';

const UserMenu = () => {
    const username = useSelector((state) => state.user.username);
    const role = useSelector((state) => state.user.roleName);
    const [userMenu, setUserMenu] = useState(null);
    const isSubmittingTicket = useSelector((state) => state.ticketSlice.isSubmitingTicket);
    const isLoadingBeneficiary = useSelector((state) => state.beneficiaryFetchSlice.isLoadingBeneficiary);
    const userMenuClick = (event) => {
        if (isSubmittingTicket || isLoadingBeneficiary) {
            return;
        }
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (
        <>
            <Button
                className="min-h-8 min-w-40 rounded-full"
                onClick={userMenuClick}
                color="inherit"
            >
                <div className="flex-col items-end">
                    <Typography
                        sx={{ fontSize: '0.9rem', fontWeight: 'bold', display: 'flex', color: 'black', textTransform: 'lowercase' }} component="span" className="font-semibold flex text-black lowercase">
                        {username}
                    </Typography>

                    <Typography
                        className="text-11 font-medium capitalize"
                        color="text.secondary"
                        sx={{ fontSize: '0.75rem', fontWeight: 'bold', display: 'flex', color: 'gray', textTransform: 'lowercase' }}
                    >
                        {role &&
                            role
                                .toString()
                                .split("_") // Split string at underscores
                                .slice(1) // Remove the first element ("ROLE")
                                .map(
                                    (word) =>
                                        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                                ) // Capitalize the first letter of each word
                                .join(" ")}{" "}
                        {/* Join words back together with spaces */}
                        {(!role ||
                                (Array.isArray(role) && role.length === 0)) &&
                            "Guest"}
                    </Typography>
                </div>

                {(username && role) ? (
                    <Avatar className="md:mx-4" alt="user photo" />
                ) : (
                    <Avatar className="md:mx-4">{username}</Avatar>
                )}
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                classes={{
                    paper: "py-8",
                }}
            >
                {!role || role.length === 0 ? (
                    <>
                        <MenuItem
                            component={Link}
                            to="/sign-in"
                            role="button"
                            onClick={userMenuClose}
                        >
                            <ListItemIcon className="min-w-40">
                            </ListItemIcon>
                            <ListItemText primary="Sign In" />
                        </MenuItem>
                        <MenuItem
                            component={Link}
                            to="/sign-up"
                            role="button"
                            onClick={userMenuClose}
                        >
                            <ListItemIcon className="min-w-40">
                            </ListItemIcon>
                            <ListItemText primary="Sign Up" />
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem
                            component={Link}
                            to="/profile"
                            onClick={userMenuClose}
                            role="button"
                        >
                            <ListItemIcon className="min-w-40">
                                <AccountCircleOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Προφίλ" />
                        </MenuItem>

                        {settingsConfig.roles.admin === role && (
                            <>
                                <MenuItem
                                    component={NavLink}
                                    to="/sign-up"
                                    onClick={userMenuClose}
                                    role="button"
                                >
                                    <ListItemIcon className="min-w-40">
                                        <PersonAdd />
                                    </ListItemIcon>
                                    <ListItemText primary="Προσθήκη Χρήστη" />
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="/users"
                                    onClick={userMenuClose}
                                    role="button"
                                >
                                    <ListItemIcon className="min-w-40">
                                        <PeopleIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Λίστα Χρηστών" />
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="/teams"
                                    onClick={userMenuClose}
                                    role="button"
                                >
                                    <ListItemIcon className="min-w-40">
                                        <GroupsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Απαντητικές Ομάδες" />
                                </MenuItem>

                                <MenuItem
                                    component={Link}
                                    to="/knowledge-base"
                                    onClick={userMenuClose}
                                    role="button"
                                >
                                    <ListItemIcon className="min-w-40">
                                        <CategoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Γνωσιακή (Under Construction)" />
                                </MenuItem>
                                <MenuItem
                                    component={Link}
                                    to="/announcments-page"
                                    onClick={userMenuClose}
                                    role="button"
                                >
                                    <ListItemIcon className="min-w-40">
                                        <CampaignIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Ανακοινώσεις" />
                                </MenuItem>

                            </>
                        )}
                        {(settingsConfig.roles.admin === role || settingsConfig.roles.supervisor.includes(role)) && (
                            <MenuItem
                                component={NavLink}
                                to="/beneficiary-panel"
                                onClick={userMenuClose}
                                role="button"
                            >
                                <ListItemIcon className="min-w-40">
                                    <RecentActorsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Δικαιούχοι" />
                            </MenuItem>
                        )}
                    </>
             
                )}
                <MenuItem
                    component={NavLink}
                    to="/sign-out"
                    onClick={userMenuClose}
                    role="button"
                    sx={{ color: 'red' }}
                >
                    <ListItemIcon className="min-w-40" sx={{ color: 'red' }}>
                        <LogoutOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Αποσύνδεση" />
                </MenuItem>
            </Popover>
        </>
    );
};

export default UserMenu;
