import { axiosApi } from "app/configs/axiosConfig";

export async function getActionCodeById(code) {
    try {
        const response = await axiosApi.get(`/domain/actionCode/${code}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to fetch action code: ${error}`);
        throw error;
    }
}