import React, {useRef, useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import {useDispatch} from "react-redux";
import UsersFilters from "./UsersFilters";
import {AdminChangePasswordOfUser} from "app/Api Calls/AdminToChangeUserPassword";
import {showMessage} from "store/messageSlice";
import {disableUser, enableUser} from "app/Api Calls/EnableAndDisableUsers";
import UsersExcelUpload from "app/main/Excel/UsersExcelUpload";
import {disableTaxis, enableTaxis} from "app/Api Calls/EnableAndDiasbleTaxisForUsers";
import UserEditDialog from "./UserEditDialog";
import {adminToUpdateUser, updateUser} from "app/Api Calls/UpdateUser";
import UserStatusToggleWizard from "app/main/Panels/UserPanel/ActiveWizard/UserStatusToggleWizard";
import UserTeamsDialog from "app/main/Panels/UserPanel/TeamWizard/UserTeamDialog";

const UsersTable = () => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationError, setValidationError] = useState("");
    const refreshFunction = useRef(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [editedUser, setEditedUser] = useState(null);
    const [isToggleWizardOpen, setIsToggleWizardOpen] = useState(false);
    const [isTeamsDialogOpen, setIsTeamsDialogOpen] = useState(false);

    const handleChangePage = (event, newPage) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
         setPage(0);
    };

    const transformRole = (role) => {
        if (role) {
            const words = role.replace("ROLE_", "").toLowerCase().split("_");
            return words
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ");
        }
    };

    const handleOpenDialog = (user) => {
        setSelectedUser(user);
        setSelectedUserId(user.id);
        setOpen(true);
    };

    const handleOpenEditDialog = (user) => {
        setSelectedUser(user);
        setEditDialogOpen(true);
    };


    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedUserId(null);
        setPassword("");
        setConfirmPassword("");
    };

    const resetPage = () => {
        setPage(0);
    };

    const handleOpenTeamsDialog = (user) => {
        setSelectedUser(user);
        setIsTeamsDialogOpen(true);
    };

    const handleChangePassword = async () => {
        if (password !== confirmPassword) {
            setValidationError("Οι κωδικοί δεν ταιριάζουν");
            return;
        }
        if (password.length < 7 || confirmPassword.length < 7) {
            setValidationError("Ο κωδικός πρέπει να έχει τουλάχιστον 7 χαρακτήρες");
            return;
        }
        setLoading(true);

        try {
            const response = await AdminChangePasswordOfUser(
                selectedUserId,
                password
            );

            if (response == 200) {
                dispatch(
                    showMessage({
                        message: "O Κωδικός άλλαξε επιτυχώς",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "success",
                    })
                );
            }
        } catch (error) {
            console.error("Failed to change password: ", error);
            dispatch(
                showMessage({
                    message: "Αποτυχία Ενημέρωσης κωδικού",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        } finally {
            setValidationError("");
            setLoading(false);
            handleCloseDialog();
        }
    };

    const handleEnable = async (id) => {
        try {
            await enableUser(id);
            dispatch(showMessage({
                message: "Ο χρήστης ενεργοποιήθηκε επιτυχώς.",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
            if (refreshFunction.current) {
                refreshFunction.current();
            }
        } catch (error) {
            console.error("Error enabling user:", error);
            // Dispatch an error message if there's a problem
            dispatch(showMessage({
                message: "Πρόβλημα κατά την ενεργοποίηση του χρήστη.",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
        }
    };


    const handleDisable = async (id) => {
        try {
            await disableUser(id);
            dispatch(showMessage({
                message: "Ο χρήστης απενεργοποιήθηκε επιτυχώς.",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
            if (refreshFunction.current) {
                refreshFunction.current();
            }
        } catch (error) {
            console.error("Error disabling user:", error);
            dispatch(showMessage({
                message: "Πρόβλημα κατά την απενεργοποίηση του χρήστη.",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
        }
    };

    const handleEnableTaxis = async (id) => {
        await enableTaxis(id);
        if (refreshFunction.current) {
            refreshFunction.current();
        }
    };

    const handleDisableTaxis = async (id) => {
        await disableTaxis(id);
        if (refreshFunction.current) {
            refreshFunction.current();
        }
    };

    const handleToggleTaxis = async (id, isEnabled) => {
        if (isEnabled) {
            await handleDisableTaxis(id);
        } else {
            await handleEnableTaxis(id);
        }
    };

    const handleToggle = async (id, isEnabled) => {
        if (isEnabled) {
            await handleDisable(id);
        } else {
            await handleEnable(id);
        }
    };

    const handleOpenToggleWizard = async (user) => {
        if (user.isEnabled) {
            setSelectedUser(user);
            setIsToggleWizardOpen(true);
        } else {
            try {
                await handleEnable(user.id);

            } catch (error) {
                console.error("Error enabling user:", error);
            }
        }
    };


    const handleUsersFetched = (fetchedUsers, total) => {
        setUsers(fetchedUsers);
        setTotalElements(total);
    };


    const handleUserUpdate = async (updatedUser) => {
        try {
            await adminToUpdateUser(updatedUser);

            if (refreshFunction.current) {
                refreshFunction.current();
            }
            setEditedUser(null);
            setEditDialogOpen(false);
            dispatch(showMessage({
                message: "Η ενημέρωση του χρήστη ήταν επιτυχής",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
        } catch (error) {
            console.error("Failed to update user:", error);
        }
    };

    return (
        <>
            <Typography variant="h6" align="center" gutterBottom>
                Πίνακας Χρηστών
            </Typography>
            <div style={{
                width: 'fit-content',
                margin: '0 auto'
            }}>
                <UsersExcelUpload onSuccessfulUpload={refreshFunction.current}/>
            </div>
            {" "}
            <div style={{width: "95%", margin: "0 auto"}}>
                <UsersFilters
                    onUsersFetched={handleUsersFetched}
                    page={page}
                    size={rowsPerPage}
                    setLoading={setLoading}
                    transformRoleName={transformRole}
                    handleSearch={handleEnable}
                    refreshFunction={refreshFunction}
                    resetPage={resetPage}
                />
            </div>
            <TableContainer component={Paper} style={{width: "95%", margin: "0 auto"}}>
                {loading && (
                    <CircularProgress
                        color="primary"
                        size={40}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    />
                )}
                <div className={`${loading ? "invisible" : "visible"}`}>
                    <div className="block lg:hidden">
                        {users && users.map((user, index) => (
                            <div key={user.id} style={{backgroundColor: index % 2 === 1 ? "#f5f5f5" : "transparent"}}
                                 className="border-b py-2">
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">Όνομα:</strong> <span>{user.firstName}</span>
                                </div>
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">Επώνυμο:</strong> <span>{user.lastName}</span>
                                </div>
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">Email:</strong> <span>{user.email}</span>
                                </div>
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">ΑΦΜ:</strong> <span>{user.afm}</span>
                                </div>
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">Ρόλος:</strong>
                                    <span>{transformRole(user.roleCollection[0].roleName)}</span>
                                </div>
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">Ενεργός:</strong>
                                    <Switch
                                        checked={user.isEnabled}
                                        onChange={() => handleOpenToggleWizard(user)}
                                        color="primary"
                                    />
                                </div>
                                <div className="flex justify-center items-center px-2">
                                    <strong className="mr-2">Sign in χωρίς Taxis</strong>
                                    <Switch
                                        checked={user.allowToLoginWithPassword}
                                        onChange={() => handleToggle(user.id, user.allowToLoginWithPassword)}
                                        color="primary"
                                    />
                                </div>
                                <div className="flex justify-center items-center px-2 py-2">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleOpenDialog(user)}
                                    >
                                        Αλλαγή Κωδικού
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Table className="hidden lg:table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="w-1/6 break-words">Όνομα</TableCell>
                                <TableCell className="w-1/6 break-words text-center">Επώνυμο</TableCell>
                                <TableCell className="w-1/6 break-words text-center">Email</TableCell>
                                <TableCell className="w-1/6 break-words text-center">ΑΦΜ</TableCell>
                                <TableCell className="w-1/6 break-words text-center">Ρόλος</TableCell>
                                <TableCell className="w-1/12 break-words text-center">Ενεργός</TableCell>
                                <TableCell className="w-1/6 break-words text-center">Sign in χωρίς Taxis</TableCell>
                                <TableCell className="w-1/6 break-words text-center">Ενέργειες</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users && users.map((user, index) => (
                                <TableRow
                                    key={user.id}
                                    style={{
                                        backgroundColor: index % 2 === 1 ? "#f5f5f5" : "transparent",
                                    }}
                                >
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell align="center">{user.lastName}</TableCell>
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">{user.afm}</TableCell>
                                    <TableCell align="center">
                                        {transformRole(user.roleCollection[0].roleName)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Switch
                                            checked={user.isEnabled}
                                            onChange={() => handleOpenToggleWizard(user)}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Switch
                                            checked={user.allowToLoginWithPassword}
                                            onChange={() => handleToggleTaxis(user.id, user.allowToLoginWithPassword)}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#003476',
                                                borderRadius: '20px',
                                                padding: '6px 12px',
                                                marginRight: '8px',
                                                fontSize: '0.75rem',
                                                marginBottom: '4px'
                                            }}
                                            onClick={() => handleOpenDialog(user)}
                                        >
                                            Αλλαγή Κωδικού
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#ed6c02',
                                                borderRadius: '20px',
                                                color: 'white',
                                                padding: '6px 12px',
                                                fontSize: '0.75rem',
                                                marginBottom: '4px'
                                            }}
                                            onClick={() => handleOpenEditDialog(user)}
                                        >
                                            Επεξεργασία
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={{
                                                backgroundColor: '#00BFFF',
                                                borderRadius: '20px',
                                                color: 'white',
                                                padding: '6px 12px',
                                                fontSize: '0.75rem'
                                            }}
                                            onClick={() => handleOpenTeamsDialog(user)}
                                        >
                                            Εμφάνιση Ομάδων
                                        </Button>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 50]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Γραμμές ανά σελίδα:"
                    labelDisplayedRows={({from, to, count}) =>
                        `${from}-${to} από ${count}`
                    }
                />
            </TableContainer>
            <UserEditDialog
                user={selectedUser}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onSave={handleUserUpdate}
                editedUser={editedUser}
                setEditedUser={setEditedUser}
                isProfilePage={false}
            />
            {(selectedUser && isToggleWizardOpen) && (
                <UserStatusToggleWizard

                    open={isToggleWizardOpen}
                    user={selectedUser}
                    onClose={() => setIsToggleWizardOpen(false)}
                    onToggle={async (id, isEnabled) => {
                        if (isEnabled) {
                            await handleEnable(id);
                        } else {
                            await handleDisable(id);
                        }
                        if (refreshFunction.current) {
                            refreshFunction.current();
                        }
                    }}
                />
            )}
            {(selectedUser && isTeamsDialogOpen) && (
                <UserTeamsDialog
                    open={isTeamsDialogOpen}
                    onClose={() => setIsTeamsDialogOpen(false)}
                    user={selectedUser}
                />
            )}
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Αλλαγή Κωδικού</DialogTitle>
                <DialogContent style={{position: "relative"}}>
                    {loading ? (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                                zIndex: 10,
                            }}
                        >
                            <CircularProgress/>
                        </div>
                    ) : null}

                    <Typography>
                        Χρήστης{" "}
                        {selectedUser
                            ? `${selectedUser.firstName} ${selectedUser.lastName}`
                            : ""}
                    </Typography>

                    <TextField
                        style={{width: "98%"}}
                        fullWidth
                        label="Κωδικός"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <TextField
                        style={{width: "98%"}}
                        fullWidth
                        label="Επιβεβαίωση Κωδικού"
                        type="password"
                        variant="outlined"
                        margin="dense"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    {validationError && (
                        <Typography color="error">{validationError}</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseDialog}
                        color="primary"
                        disabled={loading}
                    >
                        Ακύρωση
                    </Button>
                    <Button
                        onClick={handleChangePassword}
                        color="primary"
                        disabled={loading}
                    >
                        Επιβεβαίωση
                    </Button>


                </DialogActions>
            </Dialog>
        </>
    );
};

export default UsersTable;
