import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import CheckForChanges from "./CheckForChanges";
import { setSelectedUserForDirectAssignment, setShowCheckForChanges } from "store/editTicketSlice";
import { useDispatch } from "react-redux";
import getUsersForDirectAssignment from "app/Api Calls/GetUsersForDirectAssignment";
import { fetchTeamById } from "app/Api Calls/GetTeamById";
import fetchThematicById from "app/Api Calls/GetThematicById";

const DirectAssignment = ({ onUpdate }) => {
  const dispatch = useDispatch();
  const selectedUserForDirectAssignment = useSelector((state) => state.editTicketSlice.selectedUserForDirectAssignment);
  const [usersForDirectAssignment, setUsersForDirectAssignment] = useState([]);
  const [groupedUsers, setGroupedUsers] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [isFetchComplete, setIsFetchComplete] = useState(false);
  const thematicSelection = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedThematic
  );
  const showCheckForChanges = useSelector((state) => state.editTicketSlice.showCheckForChanges);
  const subThematicSelection = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedSubthematic
  );
  const [selectedThematic, setSelectedThematic] = useState("");
  const [selectedThema, setSelectedThema] = useState("");
  const [selectedSubThema, setSelectedSubThema] = useState("");
  const [allThematics, setAllThematics] = useState([]);
  const [availableThematics, setAvailableThematics] = useState([]);
  const [availableThemes, setAvailableThemes] = useState([]);
  const [availableSubThemes, setAvailableSubThemes] = useState([]);
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [showCarrier, setShowCarrier] = useState(false);
  const [shouldSkipChecks, setShouldSkipChecks] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [hasThematics, setHasThematics] = useState(true);
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const fetchUsersForDirectAssignment = async () => {
      setIsLoadingUsers(true);

      try {
        const response = await getUsersForDirectAssignment();


        if (response && Array.isArray(response)) {
          const flattenedUsers = response.flatMap((group) =>
            group.userTicketAssignmentList.map((user) => ({
              ...user,
              team: group.name,
              teamId: group.id,
            }))
          );


          setUsersForDirectAssignment(flattenedUsers);
          const grouped = groupByRole(flattenedUsers);
          setGroupedUsers(grouped);
        } else {
          console.warn("userTicketAssignmentList not found in response");
        }
      } catch (err) {
        console.error("Error fetching users for direct assignment:", err);
      } finally {
        setIsLoadingUsers(false);
      }
    };

    fetchUsersForDirectAssignment();
  }, []);


  useEffect(() => {
    if (selectedThematic) {
      const childrenThemes = allThematics.filter(
        (t) => t.parentId === selectedThematic.id
      );
      setAvailableThemes(childrenThemes);
    }

    // Handle changes in selectedThema
    if (selectedThema && selectedThematic || !hasThematics && selectedThema) {
      const childrenSubThemes = allThematics.filter(
        (t) => t.parentId === selectedThema.id
      );
      setAvailableSubThemes(childrenSubThemes);
    }

    // Disable or enable the Confirm button
    if (selectedThematic && selectedThema && selectedSubThema || !hasThematics && selectedThema && selectedSubThema) {
      setShouldSkipChecks(true);
      setDisableConfirmButton(false);
    } else if (allThematics?.length > 0 && hasThematics || availableThemes?.length > 0 && !hasThematics) {
      setDisableConfirmButton(true);
      setShouldSkipChecks(false);
    }
  }, [selectedThematic, selectedThema, selectedSubThema, allThematics, hasThematics]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleResetofFields = () => {
    setOpenModal(false);
    setAllThematics([]);
    setAvailableThematics([]);
    setAvailableThemes([]);
    setAvailableSubThemes([]);
    setSelectedThematic("");
    setSelectedThema("");
    setSelectedSubThema("");
    setShouldSkipChecks(false);
    setDisableConfirmButton(false);
    setHasThematics(true);
    dispatch(setSelectedUserForDirectAssignment(null));
  }

  const handleCarrierSelection = (selectedCarrierId) => {
    // Find the selected carrier data
    const selectedCarrierData = teamData.carriersWithThematicsDto.find(
      (carrier) => carrier.id === selectedCarrierId
    );

    if (selectedCarrierData) {
      // Set selected carrier state
      setSelectedCarrier(selectedCarrierData);

      // Filter thematics based on selected carrier and set them
      const rootThematics = selectedCarrierData.thematicCollection.filter(
        (t) => t.parentId === null
      );
      setAvailableThematics(rootThematics);
    }
  };

  // const handleDirectAssignmentChange = (e) => {
  //   const value = e.target.value;
  //   const user = usersForDirectAssignment.find((u) => u.id === value);
  //   dispatch(setSelectedUserForDirectAssignment(user));
  //   setOpenModal(true);
  // };

  // function to transform role names.
  const transformRoleName = (roleName) => {
    return roleName
      .replace("ROLE_", "")
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Function to group users by their roles
  const groupByRole = (users) => {
    const grouped = {};

    for (const user of users) {
      const transformedRole = transformRoleName(user.roleName);
      const lastName = user.lastName;

      if (!grouped[transformedRole]) {
        grouped[transformedRole] = {};
      }

      if (!grouped[transformedRole][lastName]) {
        grouped[transformedRole][lastName] = [];
      }

      grouped[transformedRole][lastName].push(user);
    }

    return grouped;
  };


  const handleConfirmAssignment = async () => {

    try {
      if (!shouldSkipChecks) {
        if (!selectedUserForDirectAssignment) return;

        const teamData = await fetchTeamById(selectedUserForDirectAssignment.teamId);

        for (const carrier of teamData.carriersWithThematicsDto) {
          const existingThematicIds = new Set(carrier.thematicCollection.map(t => t.id));

          // Find all parent IDs in the carrier's thematic collection
          const parentIds = carrier.thematicCollection
            .filter(t => t.parentId !== null)
            .map(t => t.parentId);

          // Determine which parent IDs are missing
          const missingParentIds = parentIds.filter(parentId => !existingThematicIds.has(parentId));

          // Fetch and add missing parent thematics
          for (const parentId of new Set(missingParentIds)) {
            const missingParentThematic = await fetchThematicById(parentId);
            carrier.thematicCollection.push(missingParentThematic);
          }
        }

        const thematicExistsInTeam = teamData.carriersWithThematicsDto.some(
          (carrier) => carrier.thematicCollection.some(
            (thematic) => thematic.id === thematicSelection.value
          )
        );

        const subThematicExistsInTeam = teamData.carriersWithThematicsDto.some(
          (carrier) => carrier.thematicCollection.some(
            (thematic) => thematic.id === subThematicSelection.value
          )
        );

        // If thematicExistsInTeam is true, go ahead with the rest of your logic
        if (!thematicExistsInTeam) {


          // If neither thematic nor subThematic exists in the team
          if (teamData.carriersWithThematicsDto?.length > 1) {
            setShowCarrier(true);
            return;
          } else if (teamData.carriersWithThematicsDto?.length === 1) {
            const selectedCarrierData = teamData.carriersWithThematicsDto[0];
            setAllThematics(selectedCarrierData.thematicCollection);

            let rootThematics = selectedCarrierData.thematicCollection.filter(t => t.parentId === null);

            if (rootThematics?.length > 0) {
              setAvailableThematics(rootThematics);
              setHasThematics(true);
              return;
            }
            if (!subThematicExistsInTeam) {
              const parentIds = new Set(
                selectedCarrierData.thematicCollection
                  .filter(t => t.parentId !== null)
                  .map(t => t.parentId)
              );

              const themes = selectedCarrierData.thematicCollection.filter(t => parentIds.has(t.id));

              if (themes?.length > 0) {
                setAvailableThemes(themes);
                setHasThematics(false);
                return;
              }
            }
          }
        }
      }

      setIsLoading(false);
      dispatch(setShowCheckForChanges(true));
      handleCloseModal();
    } catch (error) {
      console.error("There was an error updating the ticket:", error);
    }

  };

  const sortedUsersForDirectAssignment = usersForDirectAssignment
    .slice()
    .sort((a, b) => {
      const roleComparison = a.roleName.localeCompare(b.roleName);
      if (roleComparison !== 0) {
        return roleComparison;
      }
      return a.lastName.localeCompare(b.lastName);
    });

  const handleSubThemaChange = (event) => {
    const newSubThema = event.target.value;
    setSelectedSubThema(newSubThema);
  };


  return (
    <div>
      {showCheckForChanges ? (
          <CheckForChanges
              editMode={true}
              onCompletion={onUpdate}
              onCloseModal={handleResetofFields}
              selectedSubThema={selectedSubThema.id}
              GroupAssignment={false}
          />
      ) : null}

      <div style={{ display: "flex", alignItems: "center", height: '42px', maxHeight: "42px" }}>
        <FormControl
          variant="outlined"
          style={{
            width: "80px",
            backgroundColor: "white",
            maxHeight: "42px"

          }}
        >
          <InputLabel htmlFor="role-select">Ρόλος</InputLabel>

          <Select
            label="Ρόλος"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
            inputProps={{
              name: "role",
              id: "role-select",

            }}
            style={{
              height: "42px",
            }}
          >
            <MenuItem value="">
              <em>Όλοι</em>
            </MenuItem>
            {Array.from(
              new Set(usersForDirectAssignment.map((user) => user.roleName))
            ).map((role) => (
              <MenuItem value={role} key={role}>
                {transformRoleName(role)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Autocomplete
          sx={{
            width: "300px",
            backgroundColor: "white",
            maxHeight: "42px"

          }}
          options={sortedUsersForDirectAssignment.filter(
            (user) => !selectedRole || user.roleName === selectedRole
          )}
          groupBy={(option) => transformRoleName(option.roleName)}
          getOptionLabel={(user) =>
            `${user.firstName} ${user.lastName} (${user.team})`
          }
          value={selectedUserForDirectAssignment}
          onChange={(_, newValue) => {
            dispatch(setSelectedUserForDirectAssignment(newValue));
            if (newValue) {
              setOpenModal(true);
            }
          }}
          renderOption={(props, option) => (
            <li {...props} key={`${option.id}-${option.teamId}`}>
              {`${option.firstName} ${option.lastName} (${option.team})`}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Απευθείας Ανάθεση"
              variant="outlined"

              InputProps={{

                startAdornment: isLoadingUsers ? (
                  <InputAdornment position="start">
                    <CircularProgress size={20} />
                  </InputAdornment>
                ) : null,

                ...params.InputProps,
                style: { height: '42px', ...params.InputProps.style }
              }}
            />
          )}
        />
      </div>
      {usersForDirectAssignment?.length === 0 && isFetchComplete ? (
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ textAlign: "center" }}
        >
          Δεν υπάρχουν διαθέσιμες απευθείας αναθέσεις
        </Typography>
      ) : null}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "60vh",
            minWidth: "60vh",
          },
        }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Απευθείας Ανάθεση"}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              {(!availableThematics?.length && hasThematics) || (!availableThemes?.length && !hasThematics) ? (
                <DialogContentText
                  sx={{ marginBottom: "16px" }}
                  id="alert-dialog-description"
                >
                  {`Είστε σίγουροι ότι θέλετε να κάνετε ανάθεση του ticket στον χρήστη ${selectedUserForDirectAssignment?.firstName} ${selectedUserForDirectAssignment?.lastName}?`}
                </DialogContentText>
              ) : (
                <DialogContentText
                  sx={{ marginBottom: "16px" }}
                  id="alert-dialog-description"
                >
                  Πρέπει να αλλάξετε Θεματική ενότητα στο ticket
                </DialogContentText>
              )}

              {showCarrier && (
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", marginBottom: "16px" }}
                >
                  <InputLabel htmlFor="carrier-select">Φορέας</InputLabel>
                  <Select
                    id="carrier-select"
                    value={selectedCarrier?.id}
                    onChange={(e) => handleCarrierSelection(e.target.value)}
                    inputProps={{ id: "carrier-select" }}
                  >
                    {teamData.carriersWithThematicsDto.map((carrier, index) => (
                      <MenuItem value={carrier.id} key={index}>
                        {carrier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {hasThematics && availableThematics?.length > 0 && (
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", marginBottom: "16px" }}
                >
                  <InputLabel htmlFor="thematic-select">Θεματική Ενότητα</InputLabel>
                  <Select
                    id="thematic-select"
                    value={selectedThematic}
                    onChange={(e) => setSelectedThematic(e.target.value)}
                    inputProps={{ id: "thematic-select" }}
                  >
                    {availableThematics.map((thematic, index) => (
                      <MenuItem value={thematic} key={index}>
                        {thematic.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {(!hasThematics || (selectedThematic && selectedThematic !== "")) && (
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", marginBottom: "16px" }}
                >
                  <InputLabel htmlFor="theme-select">Θέμα</InputLabel>
                  <Select
                    id="theme-select"
                    value={selectedThema}
                    onChange={(e) => setSelectedThema(e.target.value)}
                    inputProps={{ id: "theme-select" }}
                  >
                    {availableThemes.map((theme, index) => (
                      <MenuItem value={theme} key={index}>
                        {theme.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {selectedThema && (
                <FormControl
                  variant="outlined"
                  sx={{ width: "100%", marginBottom: "16px" }}
                >
                  <InputLabel htmlFor="sub-theme-select">Υποθέμα</InputLabel>
                  <Select
                    id="sub-theme-select"
                    value={selectedSubThema}
                    onChange={handleSubThemaChange}
                    inputProps={{ id: "sub-theme-select" }}
                  >
                    {availableSubThemes.map((subTheme, index) => (
                      <MenuItem value={subTheme} key={index}>
                        {subTheme.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleResetofFields} color="primary">
            Όχι
          </Button>
          <Button
            onClick={handleConfirmAssignment}
            color="primary"
            autoFocus
            disabled={disableConfirmButton || isLoading}
          >
            Ναι
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default DirectAssignment;


// const activeFlags = ticket.ticketFlagCollection;

// const selectedResolution = null;
// const ticketId = ticket.id;
// const representativeId = ticket.representativeId;
// const erotisi = ticket.description;
// let thematicId = ticket.thematicId;
// const geographyId = ticket.geographyId;
// if (selectedSubThema) {
//   thematicId = selectedSubThema.id;
// }
// const beneficiaryId = ticket.beneficiaryId;
// const activeStatus = ticket.ticketStatusId;
// setIsLoading(true);
// await updateTicket(
//   ticketId,
//   selectedResolution,
//   activeFlags,
//   representativeId,
//   erotisi,
//   thematicId,
//   beneficiaryId,
//   activeStatus,
//   false, // shouldPerformAction
//   selectedUserForDirectAssignment.id,
//   selectedUserForDirectAssignment.teamId,
//   geographyId
// );