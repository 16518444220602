/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchThematicsByCarrierId,
  setSelectedSubSubthematic,
  setSelectedSubthematic,
  setSelectedThematic, setSubSubthematics, setSubthematics,
} from 'store/knowledgeBasePageSlice';
import CategoriesDropdown from '../CategoriesDropdown';
import settingsConfig from "app/configs/settingConfig";

function ThematicSelector({isCreatePage}) {
  const dispatch = useDispatch();
  const selectedCarrier = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
  const thematics = useSelector((state) => state.knowledgeBasePageSlice.thematics);
  const isLoadingThematics = useSelector(
    (state) => state.knowledgeBasePageSlice.isLoadingThematics
  );
  const selectedThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedThematic);
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const currentUserRole = useSelector((state) => state.user.roleName);


  useEffect(() => {
    if (selectedCarrier) {
      dispatch(fetchThematicsByCarrierId({ isCreatePage, carrierId: selectedCarrier.value }));

    }
    else {
      dispatch(setSelectedThematic(null));
      dispatch(setSelectedSubthematic(null));
      dispatch(setSelectedSubSubthematic(null));
      dispatch(setSubSubthematics([]));
      dispatch(setSubthematics([]));
    }
  }, [selectedCarrier, dispatch]);

  const handleChange = (selectedOption) => {
    dispatch(setSelectedThematic(selectedOption));
    dispatch(setSelectedSubthematic(null));
    dispatch(setSelectedSubSubthematic(null));
  };

  const isDropdownDisabled = () => {
    // Always enable the dropdown when isCreatePage is true
    if (isCreatePage) {
      return false;
    }

    // Disable the dropdown when the ticket's active status is 5
    if (activeStatus && activeStatus.id === 5) {
      return true;
    }

    // Combine supervisor and teamLeader roles into a single array for checking
    const allowedRoles = [...settingsConfig.roles.supervisor, ...settingsConfig.roles.teamLeader];

    // Check if the currentUserRole is among the allowedRoles
    const isAllowedRole = allowedRoles.includes(currentUserRole);

    // If the user's role is not allowed, disable the dropdown
    return !isAllowedRole;
  };




  return (
    <>
      {selectedCarrier && thematics.length > 0 && (
        <CategoriesDropdown
          options={thematics.map((thematic) => ({
            value: thematic.id,
            label: thematic.title,
            id:thematic.id
          }))}
          isDisabled={isDropdownDisabled()}
          value={selectedThematic}
          onChange={handleChange}
          placeholder="Θεματική Ενότητα"
          backgroundColor="white"
          isLoading={isLoadingThematics}
        />
      )}
    </>
  );
}

export default ThematicSelector;
