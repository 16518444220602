import { axiosApi } from "app/configs/axiosConfig";

const updateBeneficiary = async (beneficiaryData) => {
  try {
    const response = await axiosApi.put('/beneficiary/beneficiary/update', beneficiaryData);
    if (response.status === 200) {
      return response
    } else {
      throw new Error('Failed to update beneficiary');
    }
  } catch (error) {
    console.error('Error updating beneficiary:', error);
    throw error;
  }
};

export default updateBeneficiary;
