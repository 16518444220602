import React, { useState, useRef } from 'react';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Modal, Box, TableHead } from '@mui/material';
import { useSelector } from 'react-redux';

function ImportFromExcelButton() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const carriers = useSelector(state => state.gnosiakiCrudSlice.carriers);
  const fileInput = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
  
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const workbook = XLSX.read(bstr, { type: 'binary' });
  
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
  
      // Find the range of the data
      const range = XLSX.utils.decode_range(worksheet['!ref']);
  
      let data = [];
      // Iterate over each row
      for(let R = range.s.r; R <= range.e.r; ++R) {
        let row = [];
        // Iterate over each column
        for(let C = range.s.c; C <= range.e.c; ++C) {
          // Get the cell value
          let cell_address = {c: C, r: R};
          let cell_ref = XLSX.utils.encode_cell(cell_address);
          let cell = worksheet[cell_ref];
          // Push the cell value to the row array, empty if no value
          row.push(cell ? cell.v : '');
        }
        // Push each row to the data array
        data.push(row);
      }
    
      const requiredHeaders = ['Φορέας ID','Φορέας','Θεματική Ενότητα ID', 'Θεματική Ενότητα', 'Θέμα', 'Υποθέμα', 'Ερώτηση', 'Απάντηση'];
      const requiredHeadersString = requiredHeaders.join(', ');

      const fileHeaders = data[0]; // Assuming headers are in the first row
  
      if(JSON.stringify(requiredHeaders) === JSON.stringify(fileHeaders)){
        setRows(data);
        setOpen(true); // Open the modal after reading the file
      } else {
        alert(`Το αρχείο που προσπαθείτε να ανεβάσετε δεν είναι συμβατό. Το αρχείο Excel πρέπει να περιλαμβάνει τις εξής επικεφαλίδες: ${requiredHeadersString}`);
      }
    };
  
    reader.readAsBinaryString(file);
  }
  

  const handleUpload = () => {
    const validCarriers = carriers.map(carrier => carrier.name);
  
    for (let row of rows) {
      if (!validCarriers.includes(row[1])) {
        alert(`Ο Φορέας "${row[1]}" δεν υπάρχει στη βάση δεδομένων.`);
        return; 
      }
    }
  
    // console.log(rows);
  }

  const handleClose = () => setOpen(false); 

  return (
    <div>
      <input 
        ref={fileInput}
        type="file" 
        hidden 
        onChange={handleFileUpload} 
        accept=".xls,.xlsx"
      />

      <Button variant="contained" sx={{backgroundColor: '#003476 !important'}} onClick={() => fileInput.current.click()}>
        Εισαγωγή Απο Excel
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-modal-title"
        aria-describedby="scroll-modal-description"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} 
      >
        <Box 
          sx={{
            width: '90%', 
            maxHeight: '80%', 
            overflowY: 'auto', 
            bgcolor: 'background.paper', 
            boxShadow: 24, 
            p: 4
          }}
        >
          <Button variant="contained" color="primary" onClick={handleUpload}>
            Upload
          </Button>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    {row.map((cell, index) => (
                      <TableCell align="left" style={{border: '1px solid black'}} key={index}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </div>
  );
}

export default ImportFromExcelButton;
