import { axiosApi } from "app/configs/axiosConfig";

const actionCodesByStatusId = async (statusId) => {
  try {
    const response = await axiosApi.get(`/ticket/getTicketActionsByStatusId/${statusId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default actionCodesByStatusId;
