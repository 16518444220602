import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import resolutionMiddleware from './resolutionMiddleware';
import mandatoryFieldsMiddleware from './mandatoryFieldsMiddleware';

// const isDevelopment = process.env.NODE_ENV === 'development';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(
                mandatoryFieldsMiddleware,
            ),
        });

export default store;
