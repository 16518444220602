import React from 'react';
import {Box, Typography, Paper, IconButton} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import {FileLogo} from "app/main/tickets/CRUD Ticket/EditTicket/Files/Filelogo";

const AnnouncementFileSelect = ({files, setFiles, useOriginFileName = false}) => {

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files).map(file => ({
            file: file,
            name: file.name,
            size: file.size,
            type: file.type || file.name.split('.').pop(),
            isNew: true
        }));

        setFiles(prevFiles => {
            const existingFiles = prevFiles.map(f => `${f.name}-${f.size}`);
            const newFiles = selectedFiles.filter(file => !existingFiles.includes(`${file.name}-${file.size}`));
            return [...prevFiles, ...newFiles];
        });
    };

    const handleRemoveFile = (index) => {
        if (!useOriginFileName) {
            setFiles(prev => prev.filter((_, i) => i !== index));
        }
    };

    const fileInputProps = {
        accept: ".pdf, .doc, .docx, .xls, .xlsx, .jpeg, .jpg, .png",
        multiple: true,
        onChange: handleFileChange,
        style: {display: 'none'},
        id: 'file-input'
    };

    return (
        <Box sx={{marginTop: 1, marginBottom: 1}}>
            <input type="file" {...fileInputProps} />
            <label htmlFor="file-input">
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100px',
                    border: '1px dashed grey',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    gap: 2
                }}>
                    <CloudUploadIcon color="action"/>
                    <Typography variant="body1">Ανέβασμα αρχείου</Typography>
                </Box>
            </label>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2}}>
                {files.map((file, index) => (
                    <Paper key={index} sx={{p: 2, display: 'flex', alignItems: 'center', gap: 1, width: 'fit-content'}}>
                        <FileLogo extension={file.isNew ? file.type : file.extension}/>
                        <Typography variant="body2" sx={{mr: 1}}>
                            {file.isNew ? file.name : (useOriginFileName ? file.originFileName : file.extension)}
                        </Typography>
                        {!useOriginFileName && (
                            <IconButton onClick={() => handleRemoveFile(index)} size="small">
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        )}
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

export default AnnouncementFileSelect;
