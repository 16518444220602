import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Container,
    Typography,
    Pagination,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    Avatar,
    CircularProgress,
    Box,
    Button, InputLabel, FormControl, Select, MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useSelector, useDispatch} from "react-redux";
import searchTickets from "app/Api Calls/GetTicketsForAllRoles";
import getTicketHistory from "app/Api Calls/GetTicketHistory";
import {getSpecificUser} from "app/Api Calls/GetSpecificUser";
import fetchThematicById from "app/Api Calls/GetThematicById";
import {
    setTicketsData,
    setHistoryTickets,
    setThematics,
    setUsers,
    setActionCodes,
    setHistoryDataFetched,
    setSelectedCarrier, filterTickets, setPaginationData, setCurrentPage, setFollowupTicketId, setFollowUpModalState,
} from "store/historyTicketSlice";
import {fetchCommentsForTicket} from "app/Api Calls/Comments";
import {getActionCodeById} from "app/Api Calls/GetActionCodeById";
import {useNavigate} from "react-router-dom";
import settingsConfig from "app/configs/settingConfig";
import {jwtService} from "app/auth/jwtService";
import {fetchCarriers, setActiveTab} from "store/knowledgeBasePageSlice";
import {showMessage} from "store/messageSlice";

function IstorikoTicket({isEditPage = false, followUpMode = false}) {
    const dispatch = useDispatch();
    const [isFetching, setIsFetching] = useState(false);
    const beneficiaryId = useSelector(
        (state) => state.userInfoSlice.beneficiaryId
    );
    const {
        ticketsData,
        historyTickets,
        thematics,
        users,
        actionCodes,
        historyDataFetched,
        filteredTickets,
        pagination,
        followupTicketId
    } = useSelector((state) => state.historyTicketSlice);
    const [expandedTicketId, setExpandedTicketId] = useState(null);
    const [commentsByTicket, setCommentsByTicket] = useState({});
    const [isLoadingTickets, setIsLoadingTickets] = useState(true);
    const fetchedThematicIdsRef = useRef(new Set());
    const [noTickets, setNoTickets] = useState(false);
    const [copiedUUID, setCopiedUUID] = useState(null);
    const [isFilteredByStatus, setIsFilteredByStatus] = useState(followUpMode);
    const [hasBeneficiary, setHasBeneficiary] = useState(true);
    const userInfo = jwtService.getUser();
    const [usersCache, setUsersCache] = useState({});
    const carriers = useSelector((state) => state.knowledgeBasePageSlice.carriers)
    const selectedCarrier = useSelector((state) => state.historyTicketSlice.selectedCarrier)
    const { totalPages = 1, totalNumberOfTickets = 0, currentPage = 1 } = pagination[beneficiaryId] || {};
    const activeFlags = useSelector((state) => state.knowledgeBasePageSlice.activeFlags);
    const hasFollowUpFlag = activeFlags.some(flag => flag.code === 200 && flag.name === "Follow up");
    const showFollowUpButtonBox = filteredTickets.some(
        (ticket) => ticket.ticketStatus.id === 4 && hasFollowUpFlag && !isEditPage
    );

    const fetchUserDetailsIfNeeded = async (userId) => {
        if (!usersCache[userId]) {
            const user = await getSpecificUser(userId);
            setUsersCache((prevUsers) => ({
                ...prevUsers,
                [userId]: user,
            }));
        }
    };

    const fetchCommentsOnHover = async (ticketId) => {
        if (!commentsByTicket[ticketId]) {
            const fetchedComments = await fetchCommentsForTicket(ticketId);

            // Fetch user details for each comment if needed
            for (const comment of fetchedComments) {
                await fetchUserDetailsIfNeeded(comment.whoIsCreated);
            }

            setCommentsByTicket((prevComments) => ({
                ...prevComments,
                [ticketId]: fetchedComments,
            }));
        }
    };

    const handleSelectFollowUpTicket = (id, uuid) => {
        if (followupTicketId?.uuid === uuid) {
            dispatch(setFollowupTicketId({ id: null, uuid: null }));
        } else {
            dispatch(setFollowupTicketId({ id, uuid }));
        }
        if (followUpMode) {
            dispatch(setFollowUpModalState(false));
            dispatch(setSelectedCarrier(null));
            dispatch(showMessage({
                message: `Το Follow Up Ticket επιλέχθηκε: ${uuid}`,
                autoHideDuration: 5000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
        }
    };

    const getUniqueThematicIds = (newThematicIds) => {
        let uniqueIds = [];

        newThematicIds.forEach((id) => {
            if (
                id !== null &&
                id !== undefined &&
                !fetchedThematicIdsRef.current.has(id)
            ) {
                fetchedThematicIdsRef.current.add(id);
                uniqueIds.push(id);
            }
        });

        return uniqueIds;
    };


    const fetchTickets = useCallback(
        async (page) => {
            if (!beneficiaryId) {
                setHasBeneficiary(false);
                setNoTickets(true);
                return;
            }
            setIsLoadingTickets(true);

            try {
                const searchParams = {
                    beneficiaryId: beneficiaryId,
                    orderDto: [
                        {
                            orderByColumn: "createdAt",
                            ordrerOrientation: "desc",
                        },
                    ],
                };

                if (isFilteredByStatus) {
                    searchParams.ticketStatuses = [4];
                }

                if (selectedCarrier) {
                    searchParams.carrierId = selectedCarrier;
                }

                const additionalParams = {
                    limits: false,
                };

                const data = await searchTickets(page, 10, searchParams, additionalParams);
                const ticketsWithEditFlag = data.elements.map((ticket) => ({
                    ...ticket,
                    canEdit: calculateCanEditFlag(ticket),
                }));

                if (data.elements.length === 0) {
                    setNoTickets(true);
                    setHasBeneficiary(true);
                    dispatch(
                        setTicketsData({
                            beneficiaryId,
                            page,
                            data: [],
                            isFiltered: !!searchParams.ticketStatuses,
                            selectedCarrier: selectedCarrier,
                        })
                    );
                } else {
                    dispatch(
                        setTicketsData({
                            beneficiaryId,
                            page,
                            data: ticketsWithEditFlag,
                            isFiltered: !!searchParams.ticketStatuses,
                            selectedCarrier: selectedCarrier,
                        })
                    );
                }
                dispatch(filterTickets({
                    beneficiaryId,
                    currentPage: page + 1,
                    isFilteredByStatus,
                    selectedCarrier,
                }));

                // Update pagination for the beneficiary
                dispatch(setPaginationData({
                    beneficiaryId,
                    totalPages: data.totalPages,
                    totalNumberOfTickets: data.totalElements,
                    currentPage: page + 1
                }));

                const newThematicIds = data.elements.map((ticket) => ticket.thematic?.id);
                const thematicIds = getUniqueThematicIds(newThematicIds);

                await fetchAndUpdateThematics(thematicIds, thematics, dispatch);

            } catch (err) {
                console.error("Error fetching tickets:", err);
            } finally {
                setIsLoadingTickets(false);
            }
        },
        [beneficiaryId, isFilteredByStatus, selectedCarrier, currentPage]
    );

    useEffect(() => {
        const beneficiaryTicketsData = ticketsData[beneficiaryId] || {};
        const existingData = beneficiaryTicketsData[currentPage - 1];

        if (existingData && existingData.isFiltered === isFilteredByStatus && existingData.selectedCarrier === selectedCarrier) {
            setIsLoadingTickets(false);
            dispatch(filterTickets({
                beneficiaryId,
                currentPage,
                isFilteredByStatus,
                selectedCarrier,
            }));
            return;
        }

        // If no existing data or data does not match filter criteria, fetch new tickets
        fetchTickets(currentPage - 1);
    }, [currentPage, isFilteredByStatus, selectedCarrier, beneficiaryId]);


    // Function to set the selected Carrier
    const handleCarrierChange = (event) => {
        dispatch(setSelectedCarrier(event.target.value));
    };

    const fetchHistoryData = useCallback(
        async (ticketId) => {
            try {
                if (historyDataFetched[ticketId]) {
                    return;
                }
                setIsFetching(true);
                const historyData = await getTicketHistory(ticketId);

                const userIds = new Set();
                const actionCodeIds = new Set();
                const thematicIds = new Set();

                historyData.forEach((historyTicket) => {
                    if (historyTicket.userFrom !== null) {
                        userIds.add(historyTicket.userFrom);
                    }
                    if (
                        historyTicket.actionByUserId !== null &&
                        historyTicket.userFrom !== historyTicket.actionByUserId
                    ) {
                        userIds.add(historyTicket.actionByUserId);
                    }
                    userIds.add(historyTicket.userFrom);
                    userIds.add(historyTicket.userTo);
                    actionCodeIds.add(historyTicket.ticketActionCodeId);
                    if (historyTicket.thematicId && historyTicket.thematicId !== 0) {
                        thematicIds.add(historyTicket.thematicId);
                    }
                });

                const filteredUserIds = Array.from(userIds).filter((id) => id !== null);
                const filteredActionCodeIds = Array.from(actionCodeIds).filter((id) => id !== null);
                const newThematicIds = Array.from(thematicIds);
                const filteredThematicIds = getUniqueThematicIds(newThematicIds);

                const existingUserIds = Object.keys(users).map(id => parseInt(id));
                const usersToFetch = filteredUserIds.filter(id => !existingUserIds.includes(id));

                const existingActionCodeIds = Object.keys(actionCodes).map(id => parseInt(id));
                const actionCodesToFetch = filteredActionCodeIds.filter(id => !existingActionCodeIds.includes(id));

                const [userData, actionCodeData] = await Promise.all([
                    Promise.all(usersToFetch.map((userId) => getSpecificUser(userId))),
                    Promise.all(
                        actionCodesToFetch.map((actionCodeId) =>
                            getActionCodeById(actionCodeId)
                        )
                    ),
                ]);

                await fetchAndUpdateThematics(filteredThematicIds, thematics, dispatch);

                const newUsers = {};
                userData.forEach((data) => {
                    let roleName = '';

                    if (data.roleCollection.length > 0) {
                        const matchingRole = data.roleCollection.find(role =>
                            role.roleName.match(/ROLE_(FO|BO|SA)/)
                        );

                        if (matchingRole) {
                            const roleMatch = matchingRole.roleName.match(/ROLE_(FO|BO|SA)/);
                            if (roleMatch && roleMatch.length > 1) {
                                roleName = roleMatch[1];
                            }
                        }
                    }

                    const fullName = roleName ? `${data.firstName} ${data.lastName} (${roleName})` : `${data.firstName} ${data.lastName}`;
                    newUsers[data.id] = fullName;
                });
                const mergedUsers = {...users, ...newUsers};

                const newActionCodes = {};
                actionCodeData.forEach((data) => {
                    newActionCodes[data.id] = data.label;
                });
                const mergedActionCodes = {...actionCodes, ...newActionCodes};

                dispatch(setUsers(mergedUsers));
                dispatch(setActionCodes(mergedActionCodes));
                let updatedHistoryTickets = {...historyTickets};
                updatedHistoryTickets[ticketId] = {
                    data: historyData,
                    isFiltered: false,
                };

                dispatch(setHistoryTickets(updatedHistoryTickets));
                dispatch(setHistoryDataFetched({ticketId: ticketId}));
            } catch (err) {
                console.error(err);
            }
            setIsFetching(false);
        },
        [dispatch, historyDataFetched, historyTickets]
    );

    const calculateCanEditFlag = (ticket) => {
        let flag = false;

        const isTeamLeader = settingsConfig.roles.teamLeader.includes(userInfo?.roleName);
        const isAgent = settingsConfig.roles.agent.includes(userInfo?.roleName);
        const isAdmin = settingsConfig.roles.admin.includes(userInfo?.roleName);
        const isSupervisor = settingsConfig.roles.supervisor.includes(userInfo?.roleName);
        const isTicketClosed = ticket.ticketStatus.id === 5;

        if (isAdmin) {
            // Admins can edit all tickets
            flag = true;
        } else if (isSupervisor) {
            // Supervisors can edit closed tickets with the same group ID or matching carrier ID
            const ticketGroupId = ticket.assigneeUser.roleCollection && ticket.assigneeUser.roleCollection.length > 0
                ? ticket.assigneeUser.roleCollection[0].group.id
                : null;
            const userCarrierIds = userInfo?.carriers?.map(carrier => carrier.id) || [];
            const ticketCarrierId = ticket.thematic?.carrier?.id;

            if ((ticketGroupId === userInfo?.userGroupId) && (ticketCarrierId && userCarrierIds.includes(ticketCarrierId))) {
                flag = true;
            }
        } else if ((isTeamLeader || isAgent) && !isTicketClosed) {
            // Team Leaders and Agents can only see tickets not in status 5 and within the same group role ID
            const ticketGroupId = ticket.assigneeUser.roleCollection && ticket.assigneeUser.roleCollection.length > 0
                ? ticket.assigneeUser.roleCollection[0].group.id
                : null;
            if (ticketGroupId === userInfo?.userGroupId) {
                flag = true;
            }
            // For agents, additional check to ensure the ticket is assigned to them
            if (isAgent) {
                flag = ticket.assigneeUser.id === userInfo.id;
            }
        }

        return flag;
    };



    async function fetchAndUpdateThematics(
        thematicIds,
        currentThematics,
        dispatch
    ) {

        if (!carriers.length) {
            dispatch(fetchCarriers());
        }
        const thematicsToFetchSet = new Set(
            thematicIds.filter((id) => !currentThematics[id])
        );
        const allFetchedThematics = {...currentThematics};

        while (thematicsToFetchSet.size > 0) {
            const fetchedThematicData = await Promise.all(
                [...thematicsToFetchSet].map((id) => fetchThematicById(id))
            );

            // Clear the Set for the next iteration
            thematicsToFetchSet.clear();

            fetchedThematicData.forEach((thematic) => {
                if (thematic) {
                    // Save the thematic itself
                    allFetchedThematics[thematic.id] = thematic;

                    // If its parent is not in the store and hasn't been marked for fetching, mark it for fetching
                    if (
                        thematic.parentId &&
                        !allFetchedThematics[thematic.parentId] &&
                        !thematicsToFetchSet.has(thematic.parentId)
                    ) {
                        thematicsToFetchSet.add(thematic.parentId);
                    }
                }
            });
        }

        // Once all thematics and their parents are fetched, update the store
        dispatch(setThematics(allFetchedThematics));
    }

    function renderThematicHierarchy(thematicId, thematics) {
        const childThematic = thematics[thematicId];

        if (!childThematic) return "N/A";

        const parentThematic = childThematic.parentId
            ? thematics[childThematic.parentId]
            : null;

        return parentThematic
            ? `${parentThematic.title} > ${childThematic.title}`
            : childThematic.title;
    }



    const handlePageChange = (event, newPage) => {
        dispatch(setPaginationData({
            beneficiaryId,
            totalPages,
            totalNumberOfTickets,
            currentPage: newPage
        }));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, "0")}/${(
            date.getMonth() + 1
        )
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`;
    };

    const handleCopyToClipboard = (e, uuid) => {
        // Copy ticket.uuid to clipboard
        e.stopPropagation();
        navigator.clipboard.writeText(uuid);

        // Set the copiedUUID state to the UUID of the ticket that was copied
        setCopiedUUID(uuid);

        // Clear the copiedUUID after 2 seconds
        setTimeout(() => {
            setCopiedUUID(null);
        }, 2000);
    };

    function isFOorBO(userId, users) {
        const user = users[userId];
        return user && (user.includes('FO') || user.includes('BO'));
    }

    function isSA(userId, users) {
        const user = users[userId];
        return user && user.includes('SA');
    }



    // Function to navigate to the ticket details page
    const handleShowDetails = (ticketId) => {
        window.open(`/ticket/${ticketId}`, '_blank');
    };

    const handleEditTicket = (ticketId) => {
        window.open(`/edit-ticket/${ticketId}`, '_blank');
    };



    return (
        <Container
            sx={{
                width: "80vw",
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            {noTickets && !hasBeneficiary ? (
                <>
                    <Typography variant="h6">Δεν υπάρχουν tickets</Typography>
                </>
            ) : (
                <>
                    <Typography variant="h6">
                        Σύνολο {isFilteredByStatus ? 'Ανοιχτών' : ''} Tickets: {totalNumberOfTickets}
                    </Typography>
                    {hasBeneficiary && (
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', marginBottom: '10px' }}>
                            <Button
                                variant="contained"
                                disabled={isLoadingTickets}
                                sx={{
                                    backgroundColor: isFilteredByStatus ?  "green !important":"#003476 !important" ,
                                    color: "white",
                                    width: '220px',
                                }}
                                onClick={() => {
                                    setIsFilteredByStatus((prev) => !prev);
                                    dispatch(setPaginationData({
                                        beneficiaryId,
                                        totalPages: 0,
                                        totalNumberOfTickets :0,
                                        currentPage: 1
                                    }));
                                }}
                            >
                                Φίλτρο Ανοιχτών Tickets
                            </Button>

                            <FormControl
                                fullWidth sx={{ width: '220px' }}>
                                <InputLabel id="carrier-select-label">Φορέας</InputLabel>
                                <Select
                                    labelId="carrier-select-label"
                                    id="carrier-select"
                                    value={selectedCarrier || ""}
                                    label="Carrier"
                                    onChange={handleCarrierChange}
                                    sx={{
                                        backgroundColor: 'white',

                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Όλοι οι Φορείς</em>
                                    </MenuItem>
                                    {carriers.map((carrier) => (
                                        <MenuItem key={carrier.id} value={carrier.id}>
                                            {carrier.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}

                    {isLoadingTickets ? (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            height="100px"
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {filteredTickets.length > 0 ? (
                                filteredTickets.map((ticket, index) => (
                                    <Box
                                        key={ticket?.id}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '100%',
                                            alignItems: 'stretch',
                                            marginBottom: '16px',
                                        }}
                                    >
                                        {showFollowUpButtonBox && (
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'start',
                                                alignItems: 'flex-start',
                                                padding: '0 8px',
                                                width: '180px',
                                            }}>
                                                {ticket.ticketStatus.id === 4 && hasFollowUpFlag && !isEditPage && (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        sx={{
                                                            width: '160px',
                                                            height: '36px',
                                                            borderRadius: 20,
                                                            backgroundColor: followupTicketId?.uuid === ticket.uuid ? '#1E88E5 !important' : '#0D47A1 !important',
                                                            color: 'white',
                                                            textTransform: 'none',
                                                            fontWeight: 'bold',
                                                            '&:disabled': {
                                                                backgroundColor: '#d7d7d7 !important',
                                                            },
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleSelectFollowUpTicket(ticket.id, ticket.uuid);
                                                        }}
                                                    >
                                                        {followupTicketId?.uuid === ticket.uuid ? 'Επιλεγμένο Follow Up Ticket' : 'Επιλογή Follow Up Ticket'}
                                                    </Button>
                                                )}
                                            </Box>
                                        )}
                                        <Box sx={{ flex: 1 }}>
                                            <Accordion
                                                expanded={expandedTicketId === ticket.id}
                                                onChange={async () => {
                                                    if (expandedTicketId === ticket.id) {
                                                        setExpandedTicketId(null);
                                                    } else {
                                                        setExpandedTicketId(ticket.id);
                                                        await fetchHistoryData(ticket.id);
                                                    }
                                                }}
                                                sx={{
                                                    width: "100%",
                                                    marginBottom: "16px",
                                                    border: followupTicketId?.uuid === ticket.uuid ? '2px solid blue' : '1px solid #ddd',
                                                    boxShadow: followupTicketId?.uuid === ticket.uuid ? '0 0 10px rgba(0, 0, 255, 0.5)' : 'none',
                                                    borderRadius: '8px',
                                                }}
                                            >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Table sx={{ width: "100%" }}>
                                                        <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                                                            <TableRow>
                                                                <Tooltip
                                                                    title={
                                                                        <Box
                                                                            sx={{
                                                                                maxHeight: '90vh',
                                                                                maxWidth: '90vw',
                                                                                overflow: 'auto',
                                                                                padding: '10px',
                                                                                scrollbarWidth: 'thin',
                                                                                scrollbarColor: 'gray white',
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                variant="subtitle1"
                                                                                component="span"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: `Ερώτηση: ${ticket.description}`,
                                                                                }}
                                                                            />


                                                                            {commentsByTicket[ticket.id] && commentsByTicket[ticket.id].length > 0 ? (
                                                                                commentsByTicket[ticket.id].map((comment, index) => (
                                                                                    <div key={index} className="flex flex-col items-start gap-2 mb-2">
                                                                                        <Typography variant="subtitle1" component="div" style={{ marginBottom: "10px" }}>
                                                                                            Απάντηση
                                                                                        </Typography>
                                                                                        <div className="flex items-start gap-2">
                                                                                            <Avatar />
                                                                                            <div className="flex flex-col">
                                                                                                <p
                                                                                                    style={{ fontSize: '13px' }}
                                                                                                    className="mb-2"
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html: comment.note,
                                                                                                    }}
                                                                                                ></p>
                                                                                                <div className="text-md">
                                                                                                    <p>
                                                                                                        {(
                                                                                                            (comment.user?.firstName ||
                                                                                                                usersCache[comment.whoIsCreated]?.firstName ||
                                                                                                                "") +
                                                                                                            " " +
                                                                                                            (comment.user?.lastName ||
                                                                                                                usersCache[comment.whoIsCreated]?.lastName ||
                                                                                                                "")
                                                                                                        ).trim() || "Άγνωστος"}
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        {new Date(comment.createdAt).toLocaleDateString("el-GR", {
                                                                                                            day: "2-digit",
                                                                                                            month: "2-digit",
                                                                                                            year: "numeric",
                                                                                                        })}
                                                                                                        {new Date(comment.createdAt).toLocaleTimeString("el-GR", {
                                                                                                            hour: "2-digit",
                                                                                                            minute: "2-digit",
                                                                                                        })}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (
                                                                                <Typography variant="subtitle1">
                                                                                    Δεν υπάρχει απάντηση
                                                                                </Typography>
                                                                            )}
                                                                        </Box>
                                                                    }
                                                                    placement="bottom"
                                                                    arrow
                                                                    PopperProps={{
                                                                        modifiers: [
                                                                            {
                                                                                name: 'preventOverflow',
                                                                                options: {
                                                                                    altAxis: true,
                                                                                    mainAxis: true,
                                                                                    rootBoundary: 'document',
                                                                                    tether: false,
                                                                                    padding: 8,
                                                                                },
                                                                            },
                                                                            {
                                                                                name: 'flip',
                                                                                options: {
                                                                                    fallbackPlacements: ['top', 'right', 'left'],
                                                                                    rootBoundary: 'document',
                                                                                },
                                                                            },
                                                                        ],
                                                                    }}
                                                                >
                                                                    <TableCell onMouseEnter={() => fetchCommentsOnHover(ticket.id)}>
                                                                        Αριθμός Ticket
                                                                    </TableCell>
                                                                </Tooltip>

                                                                <TableCell>Ημερομηνία</TableCell>
                                                                <TableCell>Φορέας</TableCell>
                                                                <TableCell>Θέμα</TableCell>
                                                                <TableCell>Level</TableCell>
                                                                <TableCell>Ανατεθειμένο σε</TableCell>
                                                                <TableCell>Κατάσταση</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell
                                                                    className="relative cursor-pointer hover:bg-gray-300 z-50 whitespace-nowrap overflow-hidden text-ellipsis"
                                                                    onClick={(e) => handleCopyToClipboard(e, ticket.uuid)}
                                                                >
                                                                    {ticket.uuid}
                                                                    {copiedUUID === ticket.uuid && (
                                                                        <div
                                                                            className="absolute top-[10px] left-1/2 transform -translate-x-1/2 p-2 bg-green-500 text-white rounded z-50"
                                                                        >
                                                                            Αντιγράφηκε
                                                                        </div>
                                                                    )}
                                                                </TableCell>

                                                                <TableCell>
                                                                    {formatDate(ticket.createdAt)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {ticket.thematic?.carrier?.title || "N/A"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {thematics[ticket.thematic?.parentId]
                                                                        ? `${thematics[ticket.thematic?.parentId].title} > `
                                                                        : ""}
                                                                    {thematics[ticket.thematic?.id]?.title || "N/A"}
                                                                </TableCell>

                                                                <TableCell>
                                                                    {ticket.assigneeUser.roleCollection[0]?.group
                                                                        ?.name || "N/A"}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {ticket.assigneeUser.firstName} {ticket.assigneeUser.lastName}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={
                                                                        ticket.ticketStatus.id === 4 ||
                                                                        ticket.ticketStatus.name === "Ανοιχτό" ||
                                                                        ticket.ticketStatus.name === "Reopen"
                                                                            ? {
                                                                                backgroundColor: "#f54f4f",
                                                                                color: "white",
                                                                                textAlign: "center",
                                                                                fontWeight: "bold",
                                                                            }
                                                                            : {}
                                                                    }
                                                                >
                                                                    {ticket.ticketStatus.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    {isFetching ? (
                                                        <Box
                                                            display="flex"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            width="100%"
                                                            height="100px"
                                                        >
                                                            <CircularProgress />
                                                        </Box>
                                                    ) : (
                                                        <Table>
                                                            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                                                                <TableRow>
                                                                    <TableCell>Έγινε Από</TableCell>
                                                                    <TableCell>Από Χρήστη</TableCell>
                                                                    <TableCell>Προς Χρήστη</TableCell>
                                                                    <TableCell>Θέμα</TableCell>
                                                                    <TableCell>Ενέργεια</TableCell>
                                                                    <TableCell>Ημερομηνία</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {(() => {
                                                                    const currentHistoryData =
                                                                        historyTickets[ticket.id] || {};
                                                                    const currentHistoryTickets =
                                                                        currentHistoryData.data || [];

                                                                    const filteredTickets =
                                                                        currentHistoryTickets.filter(
                                                                            (historyTicket) =>
                                                                                historyTicket.ticketActionCodeId !== null ||
                                                                                historyTicket.userFrom !== null ||
                                                                                historyTicket.userTo !== null
                                                                        );
                                                                    if (filteredTickets.length === 0) {
                                                                        return (
                                                                            <TableRow>
                                                                                <TableCell
                                                                                    colSpan={5}
                                                                                    style={{ textAlign: "center" }}
                                                                                >
                                                                                    Δεν υπάρχει Ιστορικό
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    }

                                                                    return filteredTickets.map(
                                                                        (historyTicket, index) => (
                                                                            <TableRow key={historyTicket.id}>
                                                                                <TableCell>
                                                                                    {users[historyTicket.actionByUserId] ||
                                                                                        "N/A"}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {users[historyTicket.userFrom] || "N/A"}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {users[historyTicket.userTo] || "N/A"}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {renderThematicHierarchy(
                                                                                        historyTicket.thematicId,
                                                                                        thematics
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {
                                                                                        historyTicket.ticketActionCodeId
                                                                                            ? actionCodes[historyTicket.ticketActionCodeId] || "N/A"
                                                                                            : isFOorBO(historyTicket.userFrom, users) && isSA(historyTicket.userTo, users)
                                                                                                ? 'Μεταφορά σε SA'
                                                                                                : (historyTicket.userFrom && historyTicket.userTo ? 'Απευθείας Ανάθεση' : 'N/A')
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatDate(historyTicket.createdAt)}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    );
                                                                })()}
                                                            </TableBody>
                                                        </Table>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'start',
                                            alignItems: 'flex-start',
                                            padding: '0 8px',

                                        }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: '120px',
                                                    height: '36px',
                                                    borderRadius: 20,
                                                    backgroundColor: '#2e7d32 !important',
                                                    textTransform: 'none',
                                                    mb: 1.5,
                                                    '&:disabled': {
                                                        backgroundColor: 'grey',
                                                    },
                                                }}
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleShowDetails(ticket.id);
                                                }}
                                            >
                                                Εμφάνιση
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                    width: '120px',
                                                    height: '36px',
                                                    borderRadius: 20,
                                                    mb: 1.5,
                                                    backgroundColor: '#ed6c02 !important',
                                                    textTransform: 'none',
                                                    '&:disabled': {
                                                        backgroundColor: '#d7d7d7 !important',
                                                    },
                                                }}
                                                disabled={!ticket.canEdit}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditTicket(ticket.id);
                                                }}
                                            >
                                                Επεξεργασία
                                            </Button>
                                        </Box>
                                    </Box>
                                ))
                            ) : (
                                <Typography variant="h6">Δεν υπάρχουν tickets</Typography>
                            )}
                        </>
                    )}
                    {totalNumberOfTickets > 10 && (
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            color="primary"
                            onChange={handlePageChange}
                            sx={{ marginTop: "20px", alignSelf: "center" }}
                        />
                    )}
                </>
            )}
        </Container>
    );
}

export default IstorikoTicket;