import { axiosApi } from "app/configs/axiosConfig";

export const getGeographyByCarrierId = async (carrierId) => {
    try {
        const response = await axiosApi.get(`/domain/geographyByCarrierId/${carrierId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching geographies:", error);
        throw error;
    }
}

export const getGeographyById = async (id) => {
    try {
        const response = await axiosApi.get(`/domain/teamGeography/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching geographies:", error);
        throw error;
    }
}

