import { axiosApi } from "app/configs/axiosConfig";

export const AdminChangePasswordOfUser = async (userId, newPassword) => {
  try {
    const response = await axiosApi.patch(`/admin/changePasswordOfUser/${userId}`, {
      newPassword
    });
    return response.status;
  } catch (error) {
    console.error("Failed to change password: ", error);
    throw error;
  }
};
