// ErrorPage.js
import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
    const error = useRouteError();
    console.error(error);

    const imageSrc = `${process.env.PUBLIC_URL}/logo/Ypoyrgeio1555.svg`;


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
            gap: '20px'
        }}>

            <img src={imageSrc} alt="Error" style={{maxWidth: '300px', width: '100%'}}/>
            <p>
               Η σελίδα που ζητήσατε δεν βρέθηκε. <br/>
                Παρακαλώ επικοινωνήστε με την ομάδα υλοποίησης.
            </p>
        </div>
    );
};

export default ErrorPage;
