const isEmptyOrNull = (val) => val === "" || val === null;

// Function to check if two values are different considering empty or null values
const valueHasChanged = (val1, val2) => {
    if (isEmptyOrNull(val1) && isEmptyOrNull(val2)) {
        return false; // No change if both are empty or null
    }
    return val1 !== val2; // Change if values are different
};

export const hasRepresentativeChanged = (originalData, currentData) => {
    return (
        valueHasChanged(originalData.firstName, currentData.firstName) ||
        valueHasChanged(originalData.lastName, currentData.lastName) ||
        valueHasChanged(originalData.email, currentData.email) ||
        valueHasChanged(originalData.phone, currentData.phone) ||
        valueHasChanged(originalData.mobile, currentData.mobile) ||
        valueHasChanged(originalData.afm, currentData.afm)
    );
};
