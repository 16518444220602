export const checkForTicketChanges = (
    originalTicket,
    selectedSubSubthematic,
    selectedThematic,
    activeFlags,
    selectedGeography,
    subSubthematics
) => {
    let thematicChanged = false;

    if (selectedSubSubthematic?.value) {
        thematicChanged = selectedSubSubthematic.value !== originalTicket?.thematicId;
    } else if (selectedThematic?.value) {
        thematicChanged = (selectedThematic.value !== originalTicket?.thematicId) && (!subSubthematics || subSubthematics.length === 0);
    }

    const flagsChanged = (() => {
        if (activeFlags?.length !== originalTicket?.ticketFlagCollection?.length) {
            return true;
        }

        const allFlagsMatch = activeFlags.every(flag =>
            originalTicket.ticketFlagCollection.some(tFlag =>
                tFlag.code === flag.code
            )
        );

        return !allFlagsMatch;
    })();

    const geographyChanged = selectedGeography && (selectedGeography?.value !== originalTicket?.geographyId);

    // Return an object detailing each type of change
    return {
        thematicChanged,
        flagsChanged,
        geographyChanged
    };
};
