import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import getAllRoles from 'app/Api Calls/GetAllRoles';

function RolesFetched({ selectedRole = '', setSelectedRole }) {
  const [options, setOptions] = useState([]);

 useEffect(() => {
  // Function to fetch roles and format them
  const fetchAndFormatRoles = async () => {
    try {
      const response = await getAllRoles();
      if (response && response.data) {
        const formattedRoles = response.data.map(role => ({
          value: role.roleName,
          label: role.label || role.roleName.replace(/ROLE_/g, '').replace(/_/g, ' ')
        }));
        setOptions(formattedRoles);
      } else {
        throw new Error('Failed to fetch roles');
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  fetchAndFormatRoles();
}, []);


  const handleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel>Role</InputLabel>
      <Select value={selectedRole} onChange={handleChange} required label="Role">
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default RolesFetched;