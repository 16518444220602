import React, { useState, useEffect } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import { Button, LinearProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { axiosApi } from "app/configs/axiosConfig";
import { fetchRoles } from "app/Api Calls/TeamsRequiredApis";
import { showMessage } from "store/messageSlice";


function UsersExcelUpload({ onSuccessfulUpload }) {
  const [roleEndpoints, setRoleEndpoints] = useState({});
  const [failedRegistrations, setFailedRegistrations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const apiEndpoints = {
    'Admin': "public/register/admin",
    'FO Supervisor': "public/register/frontOfficer/supervisor",
    'FO Team Leader': "public/register/frontOfficer/teamleader",
    'FO Agent': "public/register/frontOfficer/agent",
    'BO Supervisor': "public/register/backofficer/supervisor",
    'BO Team Leader': "public/register/backofficer/teamleader",
    'BO Agent': "public/register/backofficer/agent",
    'SA Supervisor': "public/register/specializedAgent/supervisor",
    'SA Team Leader': "public/register/specializedAgent/teamleader",
    'SA Agent': "public/register/specializedAgent/agent",
};

const roleMapping = {
    'ROLE_ADMIN': 'Admin',
    'ROLE_FO_SUPERVISOR': 'FO Supervisor',
    'ROLE_FO_TEAM_LEADER': 'FO Team Leader',
    'ROLE_FO_AGENT': 'FO Agent',
    'ROLE_BO_SUPERVISOR': 'BO Supervisor',
    'ROLE_BO_TEAM_LEADER': 'BO Team Leader',
    'ROLE_BO_AGENT': 'BO Agent',
    'ROLE_SA_SUPERVISOR': 'SA Supervisor',
    'ROLE_SA_TEAM_LEADER': 'SA Team Leader',
    'ROLE_SA_AGENT': 'SA Agent',
  };
  


  const userIdsToEnable = [];

  async function handleFileUpload(e) {
    setIsLoading(true);
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async function (evt) {
        try {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetname = workbook.SheetNames[0];
            const xlsData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname]);
            let registrationPromises = [];
            let localFailedRegistrations = [];
            let localSuccessfulRegistrations = [];
            for (let row of xlsData) {
              const user = {
                username: row["Username"] ? row["Username"].trim().replace(/ +/g, ' ') : "",
                firstName: row["Όνομα"] ? row["Όνομα"].trim().replace(/ +/g, ' ') : "",
                lastName: row["Επώνυμο"] ? row["Επώνυμο"].trim().replace(/ +/g, ' ') : "",
                phone: row["Τηλέφωνο"] ? String(row["Τηλέφωνο"]).trim().replace(/ +/g, ' ') : "",
                afm: row["ΑΦΜ"] ? String(row["ΑΦΜ"]).trim().replace(/ +/g, ' ') : "",
                email: row["Email"] ? row["Email"].trim().replace(/ +/g, ' ') : "",
                password: row["Password"] ? row["Password"].trim().replace(/ +/g, ' ') : "",
                role: row["Ρόλος"] ? row["Ρόλος"].trim().replace(/ +/g, ' ') : "",
            };
                registrationPromises.push(
                    onSubmit(user, localFailedRegistrations, localSuccessfulRegistrations)
                );
            }
            //Promise to do all the users in the excel file
            await Promise.all(registrationPromises);
            setFailedRegistrations(localFailedRegistrations);

            if (localSuccessfulRegistrations.length > 0) {
                onSuccessfulUpload();
            }
            // Check the local array instead of the state variable for fails.
            if (localFailedRegistrations.length > 0) {
                showErrorMessage();
            } else {
                showSuccessMessage();
            }

            // Once all are registered, enable all users
            await enableAllUsers();
        } catch (error) {
            console.error("An error occurred during file processing:", error);
            showErrorMessage();  
        } finally {
            setIsLoading(false);
        }
    };

    reader.readAsBinaryString(file);
}

async function onSubmit(
  user,
  localFailedRegistrations,
  localSuccessfulRegistrations
) {
  const { username, firstName, lastName, phone, afm, email, password, role } =
    user;

  // Check if ΑΦΜ is an empty string, null, or undefined
  if (!afm || afm === "") {
    console.error("ΑΦΜ is missing or invalid for user:", username);
    localFailedRegistrations.push(user);
    return; // Exit the function early
  }

  let afmValue = afm;

  if (String(afmValue).length !== 9) {
    afmValue = null;
  }

  const userData = {
    username: username || null,
    firstName,
    lastName,
    phone: phone !== "" ? phone : null,
    afm:afmValue,
    password,
    email: email || null,
    companyId: 0,
  };

  const endpoint = roleEndpoints[role];
  if (!endpoint) {
    console.error("Invalid role or role not mapped to an endpoint:", role);
    return;
  }

  try {
    const response = await axiosApi.post(endpoint, userData);
    const responseData = response.data;
    const userId = responseData.id;
    userIdsToEnable.push(userId);
    localSuccessfulRegistrations.push(user);
  } catch (error) {
    console.error("Error during user registration:", error);
    localFailedRegistrations.push(user);
  }
}


  function downloadFailedRegistrations() {
    const mappedFailedRegistrations = failedRegistrations.map(user => ({
        "Username": user.username,
        "Όνομα": user.firstName,
        "Επώνυμο": user.lastName,
        "Τηλέφωνο": user.phone,
        "ΑΦΜ": user.afm,
        "Email": user.email,
        "Password": user.password,
        "Ρόλος": user.role,
    }));

    const ws = XLSX.utils.json_to_sheet(mappedFailedRegistrations);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Αποτυχημένοι users για ανέβασμα");
    XLSX.writeFile(wb, "Αποτυχημένοι users για ανέβασμα.xlsx");
}


  async function enableAllUsers() {
    // Use Promise.all() to enable users in parallel
    let enablePromises = userIdsToEnable.map((userId) => enableUser(userId));
    await Promise.all(enablePromises);
  }

  async function enableUser(userId) {
    try {
      await axiosApi.patch(`/admin/user/enable/${userId}`);
    } catch (error) {
      if (error.response) {
        console.error("Error details:", error.response.data);
      }
    }
  }

  const fetchAllRoles = async () => {
    const rolesData = await fetchRoles();
    const newRoleEndpoints = rolesData.reduce((acc, role) => {
      const mappedRoleName = roleMapping[role.roleName];
      if (apiEndpoints[mappedRoleName]) {
        acc[mappedRoleName] = apiEndpoints[mappedRoleName];
      }
      return acc;
    }, {});
    
    setRoleEndpoints(newRoleEndpoints);
  };
  

  const showSuccessMessage = () => {
    dispatch(
      showMessage({
        message: "Οι χρήστες ανέβηκαν επιτυχώς",
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "success",
      })
    );
  };

  const showErrorMessage = () => {
    dispatch(
      showMessage({
        message: "Οι χρήστες δεν ανέβηκαν επιτυχώς",
        autoHideDuration: 2000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
        variant: "error",
      })
    );
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        style={{ backgroundColor: "#175935", color: "white", marginBottom: "10px" }}
        onClick={() => document.getElementById("excel-upload").click()}
      >
        Ανέβασμα Excel Αρχείου
      </Button>
      {isLoading && <LinearProgress style={{ flex: 1, marginLeft: "10px" }} />}

      <input
        type="file"
        id="excel-upload"
        style={{ display: "none" }}
        accept=".xls,.xlsx"
        onChange={handleFileUpload}
      />
      {failedRegistrations.length > 0 && (
        <div>
          <h3>Λίστα αποτυχημένων users</h3>
          <ul style={{marginBottom: "10px"}}>
            {failedRegistrations.map((user) => (
              <li key={user.username}>
                {user.username}{" "}{user.lastName} ({user.afm})
              </li>
            ))}
          </ul>
          {/* Button to download the list as an Excel document */}
          <Button
            style={{marginBottom: "10px"}}
            variant="contained"
            color="secondary"
            onClick={downloadFailedRegistrations}
          >
            Download Αποτυχίες
          </Button>
        </div>
      )}
    </div>
  );
}

export default UsersExcelUpload;
