import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Typography,
  Pagination,
  CircularProgress,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setBeneficiaryModalPage } from "store/ticketFetchSlice";

function BeneficiarySelectionModal({
  open,
  beneficiaries,
  onSelect,
  totalPages,
  onSearchAgain,
  loading
}) {
  const dispatch = useDispatch();
  const beneficiaryModalPage = useSelector((state) => state.ticketFetchSlice.beneficiaryModalPage);

  const handlePageChange = (event, newPage) => {
    dispatch(setBeneficiaryModalPage(newPage - 1));
    onSearchAgain(newPage - 1);
  };

  const resetPageAndClose = () => {
    dispatch(setBeneficiaryModalPage(0));
    onSelect(null);
  };

  const startIndex = beneficiaryModalPage * 10;

  return (
    <Dialog   sx={{
      maxWidth: '80vw', 
      width: 'auto',
      maxHeight: '80vh', 
      height: 'auto',
      m: 'auto'
    }} open={open} onClose={resetPageAndClose}>
      <DialogTitle>Επιλογή Δικαιούχου</DialogTitle>
      <DialogContent
       
      >
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContentText>
              Πολλοί δικαιούχοι βρέθηκαν με το επώνυμο που δώσατε. Παρακαλούμε
              επιλέξτε έναν από την παρακάτω λίστα:
            </DialogContentText>
            <List>
              {beneficiaries.map((beneficiary, index) => (
                <ListItem
                  button
                  onClick={() => onSelect(beneficiary.id)}
                  key={beneficiary.id}
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{

                          cursor: "pointer",
                          "&:hover": {
                            color: "primary.main",
                          },
                        }}
                      >
                        {`#${startIndex + index + 1} Ονοματεπώνυμο: ${beneficiary.firstName} ${beneficiary.lastName
                          } ΑΦΜ: ${beneficiary.afm}`}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 2,
              }}
            >
              {totalPages > 1 && (
              <Pagination color="primary" page={beneficiaryModalPage + 1} count={totalPages} onChange={handlePageChange} />
              )}
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={resetPageAndClose} color="primary">
          Κλείσιμο
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BeneficiarySelectionModal;
