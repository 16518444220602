import { axiosApi } from "app/configs/axiosConfig";

const assignTeamToTicket = async (ticketId, teamId) => {
  try {
    const response = await axiosApi.patch(`/ticket/${ticketId}/team/${teamId}`);
    return response.status;
  } catch (error) {
    console.error('Error assigning team to ticket:', error);
    throw new Error('Problem with sa assign');
  }
};

export default assignTeamToTicket;
