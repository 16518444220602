import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setAllUserRoles,
    setFetchedUsers,
    setInputValue,
    setSelectedRole,
    setSelectedUserId,
} from "store/ticketFetchSlice";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { fetchUsersWithId } from "app/Api Calls/TeamsRequiredApis";
import fetchRoles from "app/Api Calls/Roles";

function UserFilterSelect() {
    const dispatch = useDispatch();
    const inputValue = useSelector((state) => state.ticketFetchSlice.inputValue);
    const fetchedUsers = useSelector(
        (state) => state.ticketFetchSlice.fetchedUsers
    );
    const selectedRole = useSelector(
        (state) => state.ticketFetchSlice.selectedRole
    );
    const allUsersRoles = useSelector(
        (state) => state.ticketFetchSlice.allUsersRoles || []
    );
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

    useEffect(() => {
        const fetchAllRoles = async () => {
            try {
                const roles = await fetchRoles();
                dispatch(setAllUserRoles(roles));
            } catch (error) {
                console.error("Failed to fetch roles:", error);
            }
        };

        fetchAllRoles();
    }, [dispatch]);

    useEffect(() => {
        if (inputValue.length > 2) {
            const fetchAllUsers = async () => {
                try {
                    const response = await fetchUsersWithId(0, 100, {
                        lastName: inputValue,
                    });
                    const adaptedUsers = response.elements.map((user) => ({
                        ...user,
                        roleName: user.roleCollection[0].roleName,
                    }));
                    dispatch(setFetchedUsers(adaptedUsers));
                } catch (error) {
                    console.error("Failed to fetch users:", error);
                }
            };
            fetchAllUsers();
        }
    }, [inputValue, dispatch]);

    const handleUserChange = (_, value) => {
        dispatch(setSelectedUserId(value ? value.id : ""));
        dispatch(setSelectedRole(""));
    };

    const handleInputChange = (_, newInputValue) => {
        dispatch(setInputValue(newInputValue));
    };

    function formatRoleName(roleName) {
        const parts = roleName.replace("ROLE_", "").split("_");
        const formattedName = parts
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
            .join(" ");
        return formattedName;
    }

    function groupAndSortUsers(users) {
        const withRoles = users.map((user) => ({
            ...user,
            roleName: user.roleCollection
                ? user.roleCollection[0].roleName
                : user.roleName,
        }));

        withRoles.sort(
            (a, b) =>
                a.roleName.localeCompare(b.roleName) ||
                a.firstName.localeCompare(b.firstName)
        );

        return withRoles;
    }

    const sortedUsers = groupAndSortUsers(fetchedUsers);
    const filteredUsers = selectedRole
        ? sortedUsers.filter((user) => user.roleName === selectedRole)
        : sortedUsers;

        return (
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <FormControl
                    variant="outlined"
                    style={{ width: "20%", marginRight: "0px" }}
                >
                    <InputLabel htmlFor="role-select">Ρόλος</InputLabel>
                    <Select
                        label="Ρόλος"
                        value={selectedRole}
                        disabled={ticketsLoading}
                        onChange={(e) => dispatch(setSelectedRole(e.target.value))}
                        inputProps={{
                            name: "role",
                            id: "role-select",
                        }}
                        style={{ height: "42px" }}
                    >
                        <MenuItem value="">
                            <em>Όλοι</em>
                        </MenuItem>
                        {allUsersRoles.map((role) => (
                            <MenuItem value={role.roleName} key={role.id}>
                                {formatRoleName(role.roleName)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Autocomplete
                    sx={{ width: "80%", height: "42px" }}
                    id="user-autocomplete"
                    options={filteredUsers}
                    disabled={ticketsLoading}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    groupBy={(option) => formatRoleName(option.roleName)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Χρήστης"
                            variant="outlined"
                            disabled={ticketsLoading}
                            size="small"
                            style={{ height: "42px" }}
                        />
                    )}
                    renderOption={(props, user) => (
                        <li {...props} key={`${user.teamId}-${user.id}`}>
                          {`${user.firstName} ${user.lastName} `}
                        </li>
                      )}
                    inputValue={inputValue}
                    freeSolo
                    onInputChange={handleInputChange}
                    noOptionsText="Δεν βρέθηκε χρήστης"
                    onChange={handleUserChange}
                />
            </div>
        );
 }

export default UserFilterSelect;
