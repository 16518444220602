import { useEffect, useState } from 'react';

function TodayDate() {
  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString('el-GR');
    setTodayDate(currentDate);
  }, []);

  return (
    <div>
      <span style={{ fontSize: '14px' }}><strong>Ημερομηνία Καταχώρησης:</strong></span> 
      <span style={{ fontSize: '14px' }}>{todayDate}</span>
    </div>
  );
}

export default TodayDate;
