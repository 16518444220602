import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { setListTicketCurrentPage, setSearchValues, setTicketNumber } from 'store/ticketFetchSlice';

const TicketNumberSearch = () => {
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const search = useSelector(state => state.ticketFetchSlice.searchValues.ticketNumber?.value || '');
  const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

  const handleSearch = () => {
    if (search.trim() === '') {
      setError(true);
    } else {
      setError(false);
      dispatch(setSearchValues({ fieldName: 'ticketNumber', value: search }));
      dispatch(setTicketNumber(search));
      dispatch(setListTicketCurrentPage(0)); 
    }
  };
 
  const handleClearSearch = () => {
    setError(false);
    dispatch(setSearchValues({ fieldName: 'ticketNumber', value: null }));
    dispatch(setTicketNumber(null));
  };

  const handleChange = (event) => {
    if (event.target.value.trim() !== '') {
      setError(false);
    }
    dispatch(setSearchValues({ fieldName: 'ticketNumber', value: event.target.value }));
  };

  return (
    <div>
      <TextField
        value={search}
        onChange={handleChange}
        variant="outlined"
        label="Αριθμός Ticket"
        disabled={ticketsLoading}
        size="small"
        sx={{ width: '100%' }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} disabled={ticketsLoading}>
                <SearchIcon sx={{ backgroundColor: '#003476', color: '#fff', borderRadius: '50%' }} />
              </IconButton>
              {search && (
                <IconButton onClick={handleClearSearch} disabled={ticketsLoading}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {error && (
        <Typography color="error" variant="caption">
          Το πεδίο δεν μπορεί να είναι άδειο
        </Typography>
      )}
    </div>
  );
};

export default TicketNumberSearch;
