import { axiosApi } from "app/configs/axiosConfig";

// eslint-disable-next-line consistent-return
const fetchRoles = async () => {
  try {
    const response = await axiosApi.get('/domain/roles');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export default fetchRoles;
