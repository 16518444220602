import React, {useState, useEffect} from 'react';
import {
    Dialog, TextField, Button, Box, Typography, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import {showMessage} from "store/messageSlice";
import {getAnnouncementFiles, postAnnouncementFile, putAnnouncement} from "app/Api Calls/AnnouncmentsCalls";
import LoadingOverlay from "app/Announcments/Admin/Create/LoadingPageOveride";
import {Editor} from "react-draft-wysiwyg";
import AnnouncmentFileSelect from "app/Announcments/Admin/Create/AnnouncmentFileSelect";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {stateFromHTML} from "draft-js-import-html";
import {useDispatch} from "react-redux";
import draftToHtml from "draftjs-to-html";

const EditAnnouncementDialog = ({carriers, open, handleClose, announcement, refreshAnnouncements}) => {
    const [files, setFiles] = useState([]);
    const [announcementData, setAnnouncementData] = useState({
        id: null,
        title: '',
        bodyText: EditorState.createEmpty(),
        validUntil: '',
        isPublished: false,
        sticky: false,
        carrierId: '',
        isActive: true
    });
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const convertDateForInput = (isoDate) => {
        if (!isoDate) return '';
        const date = new Date(isoDate);
        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().slice(0, 16);
        return localDate;
    };

    useEffect(() => {
        if (announcement) {
            const contentState = stateFromHTML(announcement.bodyText);
            setAnnouncementData({
                id: announcement.id,
                title: announcement.title,
                bodyText: EditorState.createWithContent(contentState),
                validUntil: convertDateForInput(announcement.validUntil),
                isPublished: announcement.isPublished,
                sticky: announcement.sticky,
                carrierId: announcement.carrierId,
                isActive: announcement.isActive
            });
            getAnnouncementFiles(announcement.id)
                .then(fetchedFiles => setFiles(fetchedFiles.map(file => ({...file, isNew: false}))))
                .catch(error => console.error('Error fetching announcement files:', error));
        }
    }, [announcement]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        let finalValue = value;

        if (name === 'isActive' || name === 'isPublished' || name === 'sticky') {
            finalValue = value === 'true';
        }
        setAnnouncementData(prev => ({
            ...prev,
            [name]: finalValue
        }));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        // Convert EditorState to HTML
        const htmlContent = draftToHtml(convertToRaw(announcementData.bodyText.getCurrentContent()));
        const validUntil = announcementData.validUntil ? announcementData.validUntil : null;

        const dataToSend = {
            ...announcementData,
            bodyText: htmlContent,
            validUntil: validUntil
        };

        try {
            await putAnnouncement(dataToSend);
            dispatch(showMessage({
                message: "Η ενημέρωση της ανακοίνωσης ήταν επιτυχής",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
            if (files.some(file => file.isNew)) {
                const newFiles = files.filter(file => file.isNew);
                const uploadPromises = newFiles.map(file => postAnnouncementFile(dataToSend.id, file.file));
                await Promise.all(uploadPromises);
                console.log('New files uploaded successfully');
            }
            handleClose();
            refreshAnnouncements();
        } catch (error) {
            console.error('Error updating announcement:', error);
            dispatch(showMessage({
                message: "Η ενημέρωση της ανακοίνωσης ήταν ανεπιτυχής",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
        } finally {
            setLoading(false);
        }
    };

    const handleEditorChange = (editorState) => {
        setAnnouncementData(prev => ({
            ...prev,
            bodyText: editorState
        }));
    };

    return (
        <Dialog sx={{
            '& .MuiDialog-paper': {
                width: '80vw',
                height: '90vh',
                maxWidth: 'none',
                maxHeight: 'none',
                backgroundColor: '#EDF0F6 !important',
                pt: 1,
                px: 4,
                pb: 1,
            }
        }} open={open} onClose={handleClose}>
            <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                <LoadingOverlay open={loading}/>
                <Typography variant="h6">Επεξεργασία ανακοίνωσης</Typography>
                <TextField
                    margin="normal"
                    fullWidth
                    label="Τίτλος"
                    name="title"
                    value={announcementData.title}
                    onChange={handleChange}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Φορέας</InputLabel>
                    <Select
                        name="carrierId"
                        value={announcementData.carrierId}
                        label="Φορέας"
                        onChange={handleChange}
                    >
                        {carriers.map((carrier) => (
                            <MenuItem key={carrier.id} value={carrier.id}>
                                {carrier.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div style={{border: '1px solid #F1F1F1', padding: '5px', minHeight: '200px', marginTop: '20px'}}>
                    <Editor
                        editorState={announcementData.bodyText}
                        onEditorStateChange={handleEditorChange}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorStyle={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            padding: "5px",
                            minHeight: "200px",
                            maxHeight: "200px",
                            width: "100%",
                            cursor: "text"
                        }}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                            inline: {
                                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                bold: {className: 'bordered-option-classname'},
                                italic: {className: 'bordered-option-classname'},
                                underline: {className: 'bordered-option-classname'},
                                strikethrough: {className: 'bordered-option-classname'},
                                monospace: {className: 'bordered-option-classname'},
                            },
                            blockType: {
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                            },
                            fontSize: {className: 'bordered-option-classname'},
                            fontFamily: {
                                options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                            },
                            list: {
                                inDropdown: false,
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                                options: ['unordered', 'ordered', 'indent', 'outdent'],
                            },
                            textAlign: {
                                inDropdown: false,
                                className: 'bordered-option-classname',
                                dropdownClassName: 'dropdown-classname',
                                options: ['left', 'center', 'right', 'justify'],
                            },
                            history: {
                                inDropdown: false,
                                className: 'bordered-option-classname',
                                options: ['undo', 'redo'],
                            },
                        }}

                    />
                </div>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: 2,
                    flexWrap: 'wrap'
                }}>
                    <TextField
                        margin="normal"
                        label="Ενεργό έως"
                        type="datetime-local"
                        InputLabelProps={{shrink: true}}
                        name="validUntil"
                        value={announcementData.validUntil}
                        onChange={handleChange}
                        sx={{minWidth: 240, flexGrow: 1}}
                    />
                    <FormControl margin="normal" sx={{minWidth: 240, flexGrow: 1}}>
                        <InputLabel>Is Active?</InputLabel>
                        <Select
                            name="isActive"
                            value={announcementData.isActive ? 'true' : 'false'}
                            label="Is Active?"
                            onChange={handleChange}
                        >
                            <MenuItem value="false">Όχι</MenuItem>
                            <MenuItem value="true">Ναι</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" sx={{minWidth: 240, flexGrow: 1}}>
                        <InputLabel>Is Published?</InputLabel>
                        <Select
                            name="isPublished"
                            value={announcementData.isPublished ? 'true' : 'false'}
                            label="Is Published?"
                            onChange={handleChange}
                        >
                            <MenuItem value="false">Όχι</MenuItem>
                            <MenuItem value="true">Ναι</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl margin="normal" sx={{minWidth: 240, flexGrow: 1}}>
                        <InputLabel>Sticky?</InputLabel>
                        <Select
                            name="sticky"
                            value={announcementData.sticky ? 'true' : 'false'}
                            label="Sticky?"
                            onChange={handleChange}
                        >
                            <MenuItem value="false">Όχι</MenuItem>
                            <MenuItem value="true">Ναι</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <AnnouncmentFileSelect files={files} setFiles={setFiles} useOriginFileName={true}/>

                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{
                            backgroundColor: !announcementData.title || !announcementData.bodyText.getCurrentContent().hasText() || !announcementData.carrierId ? '#c0c0c0 !important' : '#003476 !important',
                            color: !announcementData.title || !announcementData.bodyText.getCurrentContent().hasText() || !announcementData.carrierId ? 'darkgray !important' : 'white !important',

                        }}
                        disabled={
                            !announcementData.title || !announcementData.bodyText.getCurrentContent().hasText() || !announcementData.carrierId
                        }

                    >
                        Αποθήκευση
                    </Button>
                    <Button onClick={handleClose} variant="contained"
                            sx={{bgcolor: "#d32f2f !important", color: "white"}}>
                        Κλείσιμο
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default EditAnnouncementDialog;
