import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import App from './App';
import store from './store';
import theme from './themes/theme';
import { CssBaseline } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from "./app/401/ErrorPage";

const router = createBrowserRouter([
    { path: '*', element: <App />,errorElement: <ErrorPage />, },
]);

const Root = () => {
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                        <RouterProvider router={router}>
                            <App />
                        </RouterProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
};

export default Root;
