import { Box, CircularProgress, Typography, Backdrop } from '@mui/material';

const LoadingOverlay = ({ open }) => (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <Box sx={{
            bgcolor: 'white',
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            borderRadius: '4px',
            boxShadow: 3,
            zIndex: (theme) => theme.zIndex.drawer + 2 
        }}>
            <CircularProgress color="inherit" />
            <Typography sx={{ mt: 2 }}>Υποβολή Ανακοίνωσης</Typography>
        </Box>
    </Backdrop>
);

export default LoadingOverlay;
