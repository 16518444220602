
function formatDateToISOStringStartOfDay(date) {
    if (!(date instanceof Date)) {
        throw new Error('date must be a Date object');
    }

    let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0));
    let isoString = utcDate.toISOString();

    return isoString.replace('.000Z', '+00:00');
}
export default formatDateToISOStringStartOfDay;