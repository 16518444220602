import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { setLevel } from 'store/ticketFetchSlice';

const LevelClosedFilter = () => {
  const dispatch = useDispatch();
  const currentLevel = useSelector((state)=> state.ticketFetchSlice.level);
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

    const levels = [
    { id: 2, name: "FO" },
    { id: 3, name: "BO" },
    { id: 4, name: "SA" },
  ];

  const handleChange = (event) => {
    const selectedLevelId = event.target.value;
    dispatch(setLevel(selectedLevelId));
  };

  const handleClear = (event) => {
    event.stopPropagation();
    dispatch(setLevel(null));
  };
  
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel 
        id="level-label" 
        sx={{ backgroundColor: '#edf0f6' }} 
      >
        Level
      </InputLabel>
      <Select
        labelId="level-label"
        id="level-select"
        value={currentLevel || ''}
        onChange={handleChange}
        size='small'
        label="Level"
        disabled={ticketsLoading}
        input={
          <OutlinedInput
            id="level-select-outlined"
            endAdornment={
              currentLevel && (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClear} style={{ marginRight: 8 }} disabled={ticketsLoading}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }
          />
        }
      >
        {levels.map((level) => (
          <MenuItem key={level.id} value={level.id} disabled={ticketsLoading}>
            {level.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LevelClosedFilter;
