import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  searchTicketsAsync,
  setBeneficiaryToFilterId,
  setSearchParams,
  setSearchValues,
} from "store/ticketFetchSlice";
import {
  getBeneficiaryByAfm,
  getBeneficiaryByAmka,
  getBeneficiaryBySurname,
  getBeneficiaryByMobile,
  getBeneficiaryByPhone,
} from "app/Api Calls/FiltersList";
import {
  validateAFM,
  validateAMKA,
  validateMobile,
  validatePhone,
  validateSurname,
} from "app/main/Fields/ValidateFields";
import { showMessage } from "store/messageSlice";
import ClearIcon from "@mui/icons-material/Clear";
import BeneficiarySelectionModal from "./BeneficiarySelectionModal";

function BeneficiaryInput(applyFilters) {
  const dispatch = useDispatch();
  const ListTicketCurrentPage = useSelector(
    (state) => state.ticketFetchSlice.ListTicketCurrentPage
  );
  const size = useSelector((state) => state.ticketFetchSlice.size);
  const beneficiaryModalPage = useSelector((state) => state.ticketFetchSlice.beneficiaryModalPage);
  const searchParams = useSelector(
    (state) => state.ticketFetchSlice.searchParams
  );
  const [error, setError] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const search = useSelector(
    (state) => state.ticketFetchSlice.searchValues.beneficiary?.value
  );
  const searchType = useSelector(
    (state) => state.ticketFetchSlice.searchValues.beneficiary?.searchType
  );
  const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const handleChange = (event) => {
    dispatch(
      setSearchValues({
        fieldName: "beneficiary",
        value: event.target.value,
      })
    );
  };

  const handleSearchTypeChange = (event) => {
    dispatch(
      setSearchValues({
        fieldName: "beneficiary",
        searchType: event.target.value,
      })
    );
    setError(false);
  };

  const searchBySurname = async (page) => {
    if (validateSurname(search) === null) {
      const beneficiarySurname = await getBeneficiaryBySurname(
        search,
        page,
        size
      );
      if (beneficiarySurname?.elements?.length > 1) {
        setBeneficiaries(beneficiarySurname.elements);
        setTotalPages(beneficiarySurname.totalPages);
        setIsModalOpen(true);
        return true; 
      } else {
        return beneficiarySurname?.elements?.[0]?.id;
      }
    }
    return false;
  }

  const handleSearch = async () => {
    let multipleBeneficiariesFound = false;
    if (searchType === "" || search === "") {
      setError(true);
      setSearchClicked(true);
      return;
    }

    let beneficiaryId;
    setError(false);
    setSearchClicked(true);
    setLoading(true);
    switch (searchType) {
      case "ΑΦΜ":
        if (validateAFM(search) === null) {
          const beneficiaryAfm = await getBeneficiaryByAfm(search, 0, size);
          beneficiaryId = beneficiaryAfm?.id;
        }
        break;
      case "ΑΜΚΑ":
        if (validateAMKA(search) === null) {
          const beneficiaryAmka = await getBeneficiaryByAmka(search, 0, size);
          beneficiaryId = beneficiaryAmka?.id;
        }
        break;
      case "Επώνυμο":
        const result = await searchBySurname(beneficiaryModalPage);
        if (result === true) {
          multipleBeneficiariesFound = true;
        } else {
          beneficiaryId = result;
        }
        break;

      case "Τηλέφωνο":
        if (validatePhone(search) === null) {
          const beneficiaryPhone = await getBeneficiaryByPhone(search, 0, size);
          if (beneficiaryPhone?.length > 1) {
            setBeneficiaries(beneficiaryPhone);
            setIsModalOpen(true);
            multipleBeneficiariesFound = true;
          } else {
            beneficiaryId = beneficiaryPhone?.[0]?.id;
          }
        }
        break;

      case "Κινητό":
        if (validateMobile(search) === null) {
          const beneficiaryMobile = await getBeneficiaryByMobile(
            search,
            0,
            size
          );
          if (beneficiaryMobile?.length > 1) {
            setBeneficiaries(beneficiaryMobile);
            setIsModalOpen(true);
            multipleBeneficiariesFound = true;
          } else {
            beneficiaryId = beneficiaryMobile?.[0]?.id;
          }
        }
        break;
    }

    try {
      if (beneficiaryId) {
        dispatch(setBeneficiaryToFilterId(beneficiaryId));
        dispatch(
          showMessage({
            message: "Ο Ωφελούμενος βρέθηκε",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          })
        );
      } else if (!multipleBeneficiariesFound) {
        dispatch(
          showMessage({
            message: "Ο Ωφελούμενος δεν βρέθηκε",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          message: "An error occurred",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const getHelperText = () => {
    if (searchClicked && !searchType) {
      return "Επιλέξτε το κριτήριο αναζήτησης";
    }

    if (searchClicked && !search) {
      return "Το πεδίο είναι κενό";
    }

    switch (searchType) {
      case "ΑΦΜ":
        return validateAFM(search);
      case "ΑΜΚΑ":
        return validateAMKA(search);
      case "Επώνυμο":
        return validateSurname(search);
      case "Τηλέφωνο":
        return validatePhone(search);
      case "Κινητό":
        return validateMobile(search);
      default:
        return "";
    }
  };

  const handleClearSearch = () => {
    dispatch(
      setSearchValues({
        fieldName: "beneficiary",
        searchType: null,
        value: null,
      })
    );
    setError(false);
    setSearchClicked(false);
    const updatedSearchParams = { ...searchParams, beneficiaryId: null };
    dispatch(setSearchParams(updatedSearchParams));
    dispatch(setBeneficiaryToFilterId(null));
    dispatch(
      searchTicketsAsync({
        ListTicketCurrentPage,
        size,
        searchParams: updatedSearchParams,
      })
    );
  };

  const handleBeneficiarySelect = (beneficiaryId) => {
    if (beneficiaryId) {
      dispatch(setBeneficiaryToFilterId(beneficiaryId));
      //   dispatch(
      //     showMessage({
      //       message: "Ο Ωφελούμενος βρέθηκε",
      //       autoHideDuration: 2000,
      //       anchorOrigin: {
      //         vertical: "top",
      //         horizontal: "center",
      //       },
      //       variant: "success",
      //     })
      //   );
    }
    setIsModalOpen(false);
  };

  const isSearchDisabled = !search || getHelperText() !== null;

  return (
    <Box display="flex" alignItems="baseline">
       <BeneficiarySelectionModal
          open={isModalOpen}
          beneficiaries={beneficiaries}
          onSelect={handleBeneficiarySelect}
          totalPages={totalPages}
          onSearchAgain={searchBySurname}
          loading={loading}
        />
      <Box >
       
        <FormControl fullWidth>
          <InputLabel>Δικαιούχος</InputLabel>
          <Select
            sx={{ width: "120px", borderTopRightRadius: "0px", borderBottomRightRadius: "0px",}}
            value={searchType || ""}
            onChange={handleSearchTypeChange}
            label="Δικαιούχος"
            size="small"
            disabled={ticketsLoading}
          >
            <MenuItem value={"ΑΦΜ"}>ΑΦΜ</MenuItem>
            <MenuItem value={"ΑΜΚΑ"}>ΑΜΚΑ</MenuItem>
            <MenuItem value={"Επώνυμο"}>Επώνυμο</MenuItem>
            <MenuItem value={"Τηλέφωνο"}>Τηλέφωνο</MenuItem>
            <MenuItem value={"Κινητό"}>Κινητό</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box flexGrow={3}>
        <TextField
          value={search || ""}
          onChange={handleChange}
          variant="outlined"
          helperText={getHelperText()}
          error={error && searchType === ""}
          disabled={ticketsLoading}
          FormHelperTextProps={{
            style: { color: getHelperText() ? 'red' : 'inherit' }
          }}
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch} disabled={ticketsLoading}>
                  {!isSearchDisabled && (
                    <SearchIcon
                      sx={{
                        backgroundColor: "#003476 !important",
                        color: "#fff !important",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </IconButton>

                {search && (
                  <IconButton onClick={handleClearSearch} disabled={ticketsLoading}>
                    <ClearIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
}

export default BeneficiaryInput;
