import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import CarrierTable from './CarrierTable';
import ImportFromExcelButton from './ImportFromExcelButton';
import ExportToExcelAll from './ExportToExcelAll';
import { useDispatch } from 'react-redux';
import { moveData } from 'store/gnosiakiCrudSlice';

function ThematicsCarriersTableV2() {
  const dispatch = useDispatch();


  const onDragEnd = (result) => {
    
    const { source, destination, type } = result;
    
    if (!destination) {
      console.log("Drag ended outside a droppable destination. Exiting.");
      return;
    }
    
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      console.log("Drag ended in the original location. Exiting.");
      return;
    }
    
    const payload = {
      sourceListName: source.droppableId,
      destinationListName: destination.droppableId,
      sourceIndex: source.index,
      destinationIndex: destination.index,
      sourceId: source.droppableId.split("_")[1],
      destinationId: destination.droppableId.split("_")[1]
    };
  
    console.log("Generated Payload:", payload);
  
    dispatch(moveData(payload));
  };
  
  
  

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="box-border p-4">
        <div className="flex space-x-4 mb-4">
          <ImportFromExcelButton />
          <ExportToExcelAll />
        </div>
        <div className="w-full flex flex-col items-start justify-start space-y-1">
          <CarrierTable />
        </div>
      </div>
    </DragDropContext>
  );
}

export default ThematicsCarriersTableV2;
