import {axiosApi} from "../configs/axiosConfig";

 export async function adminToUpdateUser(userData) {
    try {
        const response = await axiosApi.patch(`admin/user`, userData);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error("Failed to update user:", error.response ? error.response.data : error.message);
        throw error;
    }
}

export async function updateUser(userData) {
    try {
        const response = await axiosApi.patch(`user`, userData);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error("Failed to update user:", error.response ? error.response.data : error.message);
        throw error;
    }
}