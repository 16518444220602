import React, {useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThematicsTable from './ThematicsTable';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { fetchAllCarriers } from 'app/Api Calls/CarriersThematicsCalls';
import { setCarriers, setExpandedCarrier, setSelectedCarrier } from 'store/gnosiakiCrudSlice';

function CarrierTable() {
  const dispatch = useDispatch();
  const { carriers, expandedCarrier } = useSelector(state => state.gnosiakiCrudSlice);
  const triggerRefetch = useSelector((state)=> state.gnosiakiCrudSlice.refreshTrigger)
  const prevTriggerRefetchRef = useRef();

  useEffect(() => {
    if (prevTriggerRefetchRef.current === undefined || prevTriggerRefetchRef.current !== triggerRefetch) {

      fetchAllCarriers()
          .then((data) => {
            dispatch(setCarriers(data));
          })
          .catch((error) => console.error("There was an error!", error));
      prevTriggerRefetchRef.current = triggerRefetch.count;
    }
  }, [dispatch,triggerRefetch]);

  const handleCarrierClick = (carrierId) => {
    dispatch(setSelectedCarrier(carrierId));
    dispatch(setExpandedCarrier(carrierId));
  };

  return (
    <Droppable droppableId="carriers" type="carrier">
      {(provided) => (
        <div className='w-full' ref={provided.innerRef} {...provided.droppableProps}>
          {carriers.map((carrier, index) => (
            <Draggable key={"carrier-" + carrier.id} draggableId={String(carrier.id)} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <div className="flex items-center justify-between bg-white py-2 px-4 my-1 cursor-pointer">
                    {/* Carrier information container */}
                    <div
                      className="flex items-center w-full"
                      onClick={() => handleCarrierClick(carrier.id)}
                    >

                      
                      <div className="w-auto px-2">
                        <div className="flex items-center">
                          <span>Φορέας </span>
                          <span className="ml-2">
                            {expandedCarrier[carrier.id] ? '-' : '+'}
                          </span>
                        </div>
                      </div>
                      <div className="px-2 ml-32">{carrier.title}</div>
                    </div>

                    {/* Edit and Delete buttons container */}
                    {/* <div
                      className="flex-shrink-0"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <EditDeleteButtons
                        thematic={carrier}
                        isCarrier={true}
                      />
                    </div> */}
                  </div>

                  {expandedCarrier[carrier.id] && (
                    <ThematicsTable
                      carrierId={carrier.id}
                    />
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default CarrierTable;