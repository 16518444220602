// thematicCarriersSlice.js
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};


const initialState = {
  carriers: [],
  selectedCarrier: null,
  thematics: [],
  selectedThematic: null,
  expandedCarrier: {},
  expandedThematic: {},
  subthematics: [],
  subSubThematics: [],
  selectedSubThematic: null,
  expandedSubThematic: {},
  subThematicQnaData: [],
  subSubThematicQnaData: {},
  selectedSubSubThematic: null,
  expandedSubSubThematic: {},
  searchTerm: "",
  thematicsByCarrier: {},
  subthematicsByThematic: {}, 
  subSubThematicsBySubThematic: {},
  refreshTrigger: {
    count: 0,
    parentId: null,
    carrierId: null,
  },
};

export const gnosiakiCrudSlice = createSlice({
  name: 'gnosiakiCrud',
  initialState,
  reducers: {
    setSelectedCarrier: (state, action) => { state.selectedCarrier = action.payload; },
    setSelectedThematic: (state, action) => { state.selectedThematic = action.payload; },
    setExpandedCarrier: (state, action) => {
      const carrierId = action.payload;
      state.expandedCarrier[carrierId] = !state.expandedCarrier[carrierId];
    },
    setExpandedThematic: (state, action) => {
      const thematicId = action.payload;
      state.expandedThematic[thematicId] = !state.expandedThematic[thematicId];
    },
    setExpandedSubThematic: (state, action) => {
      console.log("Payload:", action.payload); 
      const subThematicId = action.payload;
      state.expandedSubThematic[subThematicId] = !state.expandedSubThematic[subThematicId];
    },
    setExpandedSubSubThematic: (state, action) => {
      const subSubThematicId = action.payload;
      // Toggle the expanded state for the sub-sub-thematic
      if (state.expandedSubSubThematic[subSubThematicId]) {
        delete state.expandedSubSubThematic[subSubThematicId]; // Or set to false, depending on preference
      } else {
        state.expandedSubSubThematic[subSubThematicId] = true;
      }
    },
    setSubthematics: (state, action) => {
      const { parentThematicId, subthematics } = action.payload;
      state.subthematicsByThematic[parentThematicId] = subthematics;
    },
    setSubSubThematics: (state, action) => {
      const { parentSubThematicId, subSubThematics } = action.payload;
      state.subSubThematicsBySubThematic[parentSubThematicId] = subSubThematics.length === 0 ? null : subSubThematics;
    },
    
    setThematics: (state, action) => {
      const { carrier, thematics } = action.payload;
      state.thematicsByCarrier[carrier] = thematics;
    },
    setCarriers: (state, action) => {
      state.carriers = action.payload;
    },
    setSubThematicQnaData: (state, action) => {
      const { parentSubThematicId, subThematicQnaData } = action.payload;
      const updatedData = subThematicQnaData.map(item => ({ ...item, parentSubThematicId }));
      state.subThematicQnaData = [...state.subThematicQnaData, ...updatedData];
    },
    setSubSubThematicQnaData: (state, action) => {
      const { parentSubSubThematicId, subSubThematicQnaData } = action.payload;
      state.subSubThematicQnaData[parentSubSubThematicId] = subSubThematicQnaData;
    },
    setSelectedSubThematic: (state, action) => { state.selectedSubThematic = action.payload; },
    setSelectedSubSubThematic: (state, action) => { state.selectedSubSubThematic = action.payload; },
    setSearchTerm: (state, action) => { state.searchTerm = action.payload; },
    triggerGlobalRefresh: (state, action) => {
      state.refreshTrigger.count += 1;
      // Update parentId or carrierId based on what's provided in the payload.
      state.refreshTrigger.parentId = action.payload.parentId;
      state.refreshTrigger.carrierId = action.payload.carrierId;
    },
    reorderData: (state, action) => {
      const { listName, sourceIndex, destinationIndex } = action.payload;
      if (!destinationIndex && destinationIndex !== 0) return;
      
      state[listName] = reorder(
        state[listName],
        sourceIndex,
        destinationIndex
      );
    },
    moveData: (state, action) => {
      // console.log("State at the beginning of moveData:", JSON.stringify(state));
      // console.log("Action Payload:", action.payload);
  
      const {
          sourceListName,
          destinationListName,
          sourceIndex,
          destinationIndex,
          sourceId,
          destinationId
      } = action.payload;
  
      let sourceList, destinationList;
  
      // Handle the same list case
      if (sourceListName === destinationListName) {
          const list = state[sourceListName];
          if (!list) {
              console.error("List is undefined. Check your state.", state);
              return state;  // <-- Return unchanged state
          }
          const [movedItem] = list.splice(sourceIndex, 1);
          list.splice(destinationIndex, 0, movedItem);
          return state;
      } 
      
      // Handle different source and destination lists
      const [baseSourceListName, sourceIdSplit] = sourceListName.split('_');
      const [baseDestinationListName, destIdSplit] = destinationListName.split('_');
  
      // Determine the source list
      if (baseSourceListName === 'thematics') {
          sourceList = state.thematicsByCarrier[sourceIdSplit];
      } else if (baseSourceListName === 'subThematics') {
          sourceList = state.subthematicsByThematic[sourceIdSplit];
      } else if (baseSourceListName === 'subSubThematics') {
          sourceList = state.subSubThematicsBySubThematic[sourceIdSplit];
      } else {
          console.error("Invalid base source list name:", baseSourceListName);
      }
  
      if (!sourceList) {
          console.error("Source list is undefined. Check your id and state.", sourceIdSplit, state);
          return state;  // <-- Return unchanged state
      }
  
      // Determine the destination list
      if (baseDestinationListName === 'thematics') {
          destinationList = state.thematicsByCarrier[destIdSplit];
      } else if (baseDestinationListName === 'subThematics') {
          destinationList = state.subthematicsByThematic[destIdSplit];
      } else if (baseDestinationListName === 'subSubThematics') {
          destinationList = state.subSubThematicsBySubThematic[destIdSplit];
      } else {
          console.error("Invalid destination list name:", baseDestinationListName);
      }
  
      if (!destinationList) {
          console.error("Destination list is undefined. Check your id and state.", destIdSplit, state);
          return state;  // <-- Return unchanged state
      }
  
      // Perform the move operation
      const [movedItem] = sourceList.splice(sourceIndex, 1);
      destinationList.splice(destinationIndex, 0, movedItem);
  
      return state;
  }

  },

});

export const resetState = createAsyncThunk('gnosiakiCrudSlice/resetState', async (_, { dispatch }) => {
  dispatch(setSelectedCarrier(null));
  dispatch(setExpandedCarrier(null));
  dispatch(setThematics([]));
  dispatch(setSubthematics([]));
  dispatch(setSubSubThematics([]));
  dispatch(setSubThematicQnaData([]));
  dispatch(setSubSubThematicQnaData([]));
  dispatch(triggerGlobalRefresh(0));
});

// Export actions
export const {
  setCarriers,
  setSelectedCarrier,
  setThematics,
  setSelectedThematic,
  setExpandedCarrier,
  setExpandedThematic,
  setSubthematics,
  setSubSubThematics,
  setSelectedSubThematic,
  setExpandedSubThematic,
  setSubThematicQnaData,
  setSubSubThematicQnaData,
  setSelectedSubSubThematic,
  setExpandedSubSubThematic,
  setSearchTerm,
  reorderData , 
  moveData,
  triggerGlobalRefresh
} = gnosiakiCrudSlice.actions;

// Export reducer
export default gnosiakiCrudSlice.reducer;
