import React, {useEffect, useState} from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import BadgeIcon from '@mui/icons-material/Badge'; // Import for roleName
import {useNavigate} from 'react-router-dom';
import {Button, Divider, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import {jwtService} from 'app/auth/jwtService';
import UserEditDialog from "app/main/Panels/UserPanel/UserEditDialog";
import {getSpecificUser} from "app/Api Calls/GetSpecificUser";
import {updateUser} from "app/Api Calls/UpdateUser";
import {showMessage} from "store/messageSlice";
import {useDispatch} from "react-redux";
import PhoneIcon from "@mui/icons-material/Phone";


function Profile() {
    const dispatch = useDispatch();
    const user = jwtService.getUser();
    const navigate = useNavigate();
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [editedUser, setEditedUser] = useState(null);
    const [initialUser, setInitialUser] = useState(null);

    useEffect(() => {
        const fetchUser = async () => {
            if (user && user.id) {
                try {
                    const userFromApi = await getSpecificUser(user.id);
                    setInitialUser(userFromApi);
                } catch (error) {
                    console.error('Failed to fetch user:', error);
                    // Handle the error appropriately
                }
            }
        };

        fetchUser();
    }, [user.id]);


    const handlePasswordChange = () => {
        navigate('/change-password');
    };

    const handleEditProfile = () => {
        setIsEditDialogOpen(true);
    };

    const handleClose = () => {
        setIsEditDialogOpen(false);
    };

    const handleSave = async () => {
        const {id, ...userData} = editedUser; // Destructure editedUser to exclude id
        // Call updateUser method with userData
        try {
            await updateUser(userData);
            // Show success message
            dispatch(showMessage({
                message: "Η ενημέρωση του χρήστη ήταν επιτυχής",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
            // Refetch the user data
            const userFromApi = await getSpecificUser(user.id);
            setInitialUser(userFromApi);
        } catch (error) {
            console.error("Failed to update user:", error);
            // Handle error accordingly
        } finally {
            setIsEditDialogOpen(false); // Close the dialog
        }
    };


    // Extracting the role name, assuming the first role is the primary one for simplicity
    const roleName = initialUser?.roleCollection?.[0]?.roleName || 'N/A';

    return (
        <div className="flex justify-center items-center min-h-screen mt-[-100px]">
            <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-md">
                <h1 className="text-3xl mb-4">Προφίλ</h1>
                <List component="nav" aria-label="user details">
                    <ListItem>
                        <ListItemIcon>
                            <AccountCircleIcon/>
                        </ListItemIcon>
                        <ListItemText primary={`${initialUser?.firstName} ${initialUser?.lastName}`}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>
                            <EmailIcon/>
                        </ListItemIcon>
                        <ListItemText primary={initialUser?.email}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>
                            <BadgeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={roleName}/>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <ListItemIcon>
                            <FingerprintIcon/>
                        </ListItemIcon>
                        <ListItemText primary={initialUser?.afm}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary={initialUser?.phone} />
                    </ListItem>

                </List>
                <Button
                    onClick={handlePasswordChange}
                    className="bg-[#003476] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
                >
                    Αλλαγή Κωδικού
                </Button>
                <Button
                    onClick={handleEditProfile}
                    className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
                >
                    Επεξεργασία Στοιχείων
                </Button>
                <UserEditDialog
                    user={initialUser}
                    open={isEditDialogOpen}
                    onClose={() => setIsEditDialogOpen(false)}
                    onSave={handleSave}
                    editedUser={editedUser}
                    setEditedUser={setEditedUser}
                    isProfilePage={true}
                />
            </div>
        </div>
    );
}

export default Profile;
