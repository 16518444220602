import React, { useState, useEffect } from 'react';
import AnnouncementForm from "app/Announcments/Admin/Create/AnnouncmentForm";
import CarriersAnouncmentsPage from "app/Announcments/Admin/CarriersAnouncmentsPage";


const AnnouncementsAdminPage = () => {
    const [refreshFlag, setRefreshFlag] = useState(false);

    const refreshAnnouncements = () => {
        setRefreshFlag(!refreshFlag);
    };

    return (
        <>
            <AnnouncementForm refreshAnnouncements={refreshAnnouncements} />
            <CarriersAnouncmentsPage refreshFlag={refreshFlag} refreshAnnouncements={refreshAnnouncements} />
        </>
    );
};

export default AnnouncementsAdminPage;
