import { axiosApi } from "app/configs/axiosConfig";


const updateMultipleTickets = async (tickets) => {
  let localStorageUserId = null;
  const userString = localStorage.getItem('user');
  const cleanedString = userString.replace(/\\/g, '').replace(/"{/g, '{').replace(/}"/g, '}');
  const user = JSON.parse(cleanedString);
  localStorageUserId = user.uuid;

  const generatePayloadForTicket = (ticket) => {
    const {
        ticketId, activeFlags, represetativeId,
      erotisi, thematic, beneficiaryId, activeStatus, assigneeUser,team
    } = ticket;

    const ticketFlagCollection = activeFlags 
    ? activeFlags.map((flag) => ({
        code: flag.code,
        name: flag.name,
      }))
    : [];


    const flags = {
      ticketFlg: activeFlags.includes('ticketFlg') ? 1 : 0,
      priorityFlg: activeFlags.includes('priorityFlg') ? 1 : 0,
      representativeFlg: activeFlags.includes('representativeFlg') ? 1 : 0,
      followUpFlg: activeFlags.includes('followUpFlg') ? 1 : 0,
      applicationFlg: activeFlags.includes('applicationFlg') ? 1 : 0,
    };

    return {
      id: ticketId,
      title: null,
      shortDescription: null,
      description: erotisi || ' ',
      thematic: thematic || null,
      beneficiary: beneficiaryId || null,
      representative: represetativeId || null,
      ...flags,
      ticketFlagCollection,
      assigneeUser: assigneeUser,
      ticketStatus: activeStatus,
      team: team

    };
  };

  const payload = tickets.map(generatePayloadForTicket);

  try {
    const response = await axiosApi.put(`/ticket/updateUser`, payload);
    
   } catch (error) {
    console.log(error);
    throw error;
  }
};

export default updateMultipleTickets;
