import React, { useEffect, useState, useCallback, useRef } from 'react';
import getTicketHistory from 'app/Api Calls/GetTicketHistory';
import { getSpecificUser } from 'app/Api Calls/GetSpecificUser';
import {
    Table, TableBody, TableCell, TableHead, TableRow, Paper, Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Typography,
} from "@mui/material";
import fetchThematicById from 'app/Api Calls/GetThematicById';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getActionCodeById } from 'app/Api Calls/GetActionCodeById';

const TicketHistoryDetails = ({ ticketId }) => {
    const [isFetching, setIsFetching] = useState(false);
    const [localUsers, setLocalUsers] = useState({});
    const [localActionCodes, setLocalActionCodes] = useState({});
    const [localHistoryTickets, setLocalHistoryTickets] = useState({});
    const [localHistoryDataFetched, setLocalHistoryDataFetched] = useState({});
    const [localThematics, setLocalThematics] = useState({});
    const fetchedThematicIdsRef = useRef(new Set());
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen((prevOpen) => !prevOpen);
    };

    const getUniqueThematicIds = (newThematicIds) => {
        let uniqueIds = [];

        newThematicIds.forEach((id) => {
            if (
                id !== null &&
                id !== undefined &&
                !fetchedThematicIdsRef.current.has(id)
            ) {
                fetchedThematicIdsRef.current.add(id);
                uniqueIds.push(id);
            }
        });

        return uniqueIds;
    };


    const fetchAndUpdateThematics = useCallback(
        async (thematicIds) => {
            let thematicsToFetchSet = new Set(thematicIds.filter((id) => !localThematics[id]));
            const allFetchedThematics = { ...localThematics };

            while (thematicsToFetchSet.size > 0) {
                const fetchedThematicData = await Promise.all(
                    [...thematicsToFetchSet].map((id) => fetchThematicById(id))
                );

                // Clear the Set for the next iteration
                thematicsToFetchSet.clear();

                fetchedThematicData.forEach((thematic) => {
                    if (thematic) {
                        // Save the thematic itself
                        allFetchedThematics[thematic.id] = thematic;

                        // Check if its parent is not in the local state and hasn't been marked for fetching
                        if (
                            thematic.parentId &&
                            !allFetchedThematics[thematic.parentId] &&
                            !thematicsToFetchSet.has(thematic.parentId)
                        ) {
                            thematicsToFetchSet.add(thematic.parentId);
                        }
                    }
                });
            }

            // At this point, we have fetched all thematics including the parents based on the parentId property. 
            // Now, we can fetch parents of the thematics (based on the parentId)
            const parentIdsToFetch = Object.values(allFetchedThematics)
                .filter(thematic => thematic.parentId && !allFetchedThematics[thematic.parentId])
                .map(thematic => thematic.parentId);

            if (parentIdsToFetch.length > 0) {
                const fetchedParentThematics = await Promise.all(parentIdsToFetch.map(id => fetchThematicById(id)));
                fetchedParentThematics.forEach(parentThematic => {
                    if (parentThematic) {
                        allFetchedThematics[parentThematic.id] = parentThematic;
                    }
                });
            }

            // Update the local state with the fetched thematics, including the parents
            setLocalThematics(allFetchedThematics);
        },
        [localThematics]
    );

    const fetchSingleTicketHistory = useCallback(
        async (ticketId) => {
            try {
                if (localHistoryDataFetched[ticketId]) {
                    return;
                }

                setIsFetching(true);

                const historyData = await getTicketHistory(ticketId);
                const userIds = new Set();
                const actionCodeIds = new Set();
                const thematicIds = new Set();

                historyData.forEach((historyTicket) => {
                    if (historyTicket.userFrom !== null) {
                        userIds.add(historyTicket.userFrom);
                    }
                    if (
                        historyTicket.actionByUserId !== null &&
                        historyTicket.userFrom !== historyTicket.actionByUserId
                    ) {
                        userIds.add(historyTicket.actionByUserId);
                    }
                    userIds.add(historyTicket.userTo);
                    actionCodeIds.add(historyTicket.ticketActionCodeId);
                    if (historyTicket.thematicId && historyTicket.thematicId !== 0) {
                        thematicIds.add(historyTicket.thematicId);
                    }
                });

                const filteredUserIds = Array.from(userIds).filter((id) => id !== null);
                const filteredActionCodeIds = Array.from(actionCodeIds).filter((id) => id !== null);
                const newThematicIds = Array.from(thematicIds);
                const filteredThematicIds = getUniqueThematicIds(newThematicIds);

                const existingUserIds = Object.keys(localUsers).map(id => parseInt(id));
                const usersToFetch = filteredUserIds.filter(id => !existingUserIds.includes(id));

                const existingActionCodeIds = Object.keys(localActionCodes).map(id => parseInt(id));
                const actionCodesToFetch = filteredActionCodeIds.filter(id => !existingActionCodeIds.includes(id));

                const [userData, actionCodeData] = await Promise.all([
                    Promise.all(usersToFetch.map((userId) => getSpecificUser(userId))),
                    Promise.all(
                        actionCodesToFetch.map((actionCodeId) =>
                            getActionCodeById(actionCodeId)
                        )
                    ),
                ]);

                await fetchAndUpdateThematics(filteredThematicIds);
                console.log(userData);
                const newUsers = {};
                userData.forEach((data) => {
                    let roleName = '';

                    if (data.roleCollection.length > 0) {
                        const matchingRole = data.roleCollection.find(role =>
                            role.roleName.match(/ROLE_(FO|BO|SA)/)
                        );

                        if (matchingRole) {
                            const roleMatch = matchingRole.roleName.match(/ROLE_(FO|BO|SA)/);
                            if (roleMatch && roleMatch.length > 1) {
                                roleName = roleMatch[1];
                            }
                        }
                    }

                    const fullName = roleName ? `${data.firstName} ${data.lastName} (${roleName})` : `${data.firstName} ${data.lastName}`;
                    newUsers[data.id] = fullName;
                });
                const mergedUsers = { ...localUsers, ...newUsers };


                const newActionCodes = {};
                actionCodeData.forEach((data) => {
                    newActionCodes[data.id] = data.label;
                });
                const mergedActionCodes = { ...localActionCodes, ...newActionCodes };

                setLocalUsers(mergedUsers);
                setLocalActionCodes(mergedActionCodes);
                let updatedHistoryTickets = { ...localHistoryTickets };
                updatedHistoryTickets[ticketId] = {
                    data: historyData,
                    isFiltered: false,
                };

                setLocalHistoryTickets(updatedHistoryTickets);
                setLocalHistoryDataFetched(prevState => ({ ...prevState, [ticketId]: true }));
            } catch (err) {
                console.error(err);
            }
            setIsFetching(false);
        },
        [localHistoryDataFetched, localUsers, localActionCodes, localHistoryTickets]
    );

    useEffect(() => {
        if (isAccordionOpen) {
            fetchSingleTicketHistory(ticketId);
        }
    }, [isAccordionOpen]);

    function isFOorBO(userId) {
        const user = localUsers[userId];
        return user && (user.includes('FO') || user.includes('BO'));
    }

    function isSA(userId) {
        const user = localUsers[userId];
        return user && user.includes('SA');
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, "0")}/${(
            date.getMonth() + 1
        )
            .toString()
            .padStart(2, "0")}/${date.getFullYear()}`;
    };


    return (
        <div>
            <Accordion style={{ backgroundColor: 'lightgray' }} expanded={isAccordionOpen} onChange={() => setIsAccordionOpen((prev) => !prev)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} style={{ padding: '0 16px' }}>
                    <Typography variant="h6">Ιστορικό Ticket</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                    {isFetching ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </div>
                    ) : localHistoryTickets[ticketId] && localHistoryTickets[ticketId].data && localHistoryTickets[ticketId].data.length > 0 ? (
                        <Paper className="shadow-md">
                            <Table className="min-w-full">
                                <TableHead>
                                    <TableRow>
                                        {['Έγινε Από', 'Από Χρήστη', 'Προς Χρήστη', 'Θέμα', 'Ενέργεια', 'Ημερομηνία'].map((header) => (
                                            <TableCell key={header} className="bg-[#f5f5f5] px-2 pt-2 pb-2 border">
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {localHistoryTickets[ticketId].data.map((historyTicket, index) => (
                                        <TableRow key={index} className={index % 2 === 1 ? 'bg-[#eeeeee]' : ''}>
                                            <TableCell className="px-2 pt-2 pb-2 border">{localUsers[historyTicket.actionByUserId]}</TableCell>
                                            <TableCell className="px-2 pt-2 pb-2 border">{localUsers[historyTicket.userFrom]}</TableCell>
                                            <TableCell className="px-2 pt-2 pb-2 border">{localUsers[historyTicket.userTo]}</TableCell>
                                            <TableCell className="px-2 pt-2 pb-2 border">
                                                {localThematics[historyTicket.thematicId] ? (
                                                    localThematics[historyTicket.thematicId].parentId ? (
                                                        `${localThematics[localThematics[historyTicket.thematicId].parentId].title} -> ${localThematics[historyTicket.thematicId].title}`
                                                    ) : (
                                                        localThematics[historyTicket.thematicId].title
                                                    )
                                                ) : null}
                                            </TableCell>
                                            <TableCell className="px-2 pt-2 pb-2 border">
                                                {
                                                    localActionCodes[historyTicket.ticketActionCodeId]
                                                        ? localActionCodes[historyTicket.ticketActionCodeId]
                                                        : (
                                                            isFOorBO(historyTicket.userFrom) && isSA(historyTicket.userTo)
                                                                ? 'Μεταφορά σε SA'
                                                                : (historyTicket.userFrom && historyTicket.userTo ? 'Απευθείας Ανάθεση' : '')
                                                        )
                                                }
                                            </TableCell>
                                            <TableCell className="px-2 pt-2 pb-2 border">{formatDate(historyTicket.createdAt)}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    ) : (
                        <p className="p-4 bg-white">Δεν υπάρχει διαθέσιμο Ιστορικό.</p>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );



}

export default TicketHistoryDetails;

