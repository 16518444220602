import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { fetchActionCodesByStatusId, fetchActionCodesByTicketId, setFilteredResolutionOptions, setResolutionOptions, setSelectedResolution } from "store/resolutionsSlice";


function TicketStatus({ isEdit, ticketStatusId, ticketId }) {
  const dispatch = useDispatch();
  const ticketStatuses = useSelector((state) => state.ticketSlice.statuses);
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
  const isFormSubmitted = useSelector((state) => state.ticketSlice.isFormSubmitted);
  const selectedCarrier = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
  const resolutionOptions = useSelector((state) => state.resolutionsSlice.resolutionOptions);
  const [isLoaded, setIsLoaded] = useState(false);
  const justFetched = useRef(false);
  const filteredResolutionOptions = useSelector((state) => state.resolutionsSlice.filteredResolutionOptions);
  const selectedResolution = useSelector((state) => state.resolutionsSlice.selectedResolution);

  useEffect(() => {
    const fetchActionCodes = async () => {
      if (isEdit) {
        const currentStatus = ticketStatuses.find((status) => status.id === ticketStatusId);
        if (currentStatus) {
          dispatch({ type: "ticketSlice/setActiveStatus", payload: currentStatus });
          dispatch(fetchActionCodesByTicketId(ticketId)).then(() => {
            setIsLoaded(true);
            justFetched.current = true;
          });
        }
      } else {
        if (!isFormSubmitted) {
          const newStatus = ticketStatuses.find((status) => status.id === 4);
          if (newStatus) {
            dispatch({ type: "ticketSlice/setActiveStatus", payload: newStatus });
            dispatch(fetchActionCodesByStatusId(newStatus.id)).then(() => {
              setIsLoaded(true);
              justFetched.current = true;
            });
          }
        } else {
          dispatch({ type: "ticketSlice/setActiveStatus", payload: null });
        }
      }
    };

    fetchActionCodes();
  }, [isFormSubmitted, ticketStatuses, dispatch, isEdit, ticketStatusId, ticketId]);

  useEffect(() => {
    // Initialize with all resolution options
    let filteredOptions = resolutionOptions;

    // Apply geolocation-based filtering if a carrier is selected
    if (selectedCarrier && selectedCarrier.useGeo) {
      const geoOptions = resolutionOptions.filter(option => option.useGeo);

      // Map non-geo options to their geo counterparts if available
      filteredOptions = resolutionOptions
          .filter(option => !option.useGeo)
          .map(nonGeoOption => {
            const foundGeoOption = geoOptions.find(geoOption => geoOption.label === nonGeoOption.label);
            return foundGeoOption || nonGeoOption;
          });
    } else {
      // Filter out geo options if the carrier does not use geo
      filteredOptions = resolutionOptions.filter(option => !option.useGeo);
    }

    // Exclude specific options if not in edit mode and selectedCarrier.value == 2
    if (selectedCarrier?.value == 2) {
      filteredOptions = filteredOptions.filter(option => option.label !== "Μεταφορά σε SA");
    }

    // Dispatch the filtered options to the store
    dispatch(setFilteredResolutionOptions(filteredOptions));

    // Update selected resolution if its geo preference doesn't match the carrier's
    if (selectedCarrier && selectedResolution && selectedResolution.useGeo !== selectedCarrier.useGeo) {
      const newSelectedResolution = resolutionOptions.find(option =>
          option.label === selectedResolution.label && option.useGeo === selectedCarrier.useGeo
      );

      if (newSelectedResolution) {
        const newResolutionForDispatch = {
          value: newSelectedResolution.label,
          label: newSelectedResolution.label,
          labelEn: newSelectedResolution.labelEn,
          toTicketStatusId: newSelectedResolution.toTicketStatusId,
          id: newSelectedResolution.id,
        };

        dispatch(setSelectedResolution(newResolutionForDispatch));
      }
    }

    justFetched.current = false;

  }, [dispatch, selectedCarrier, resolutionOptions]);





  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {activeStatus ? (
        <div style={{ fontSize: '14px' }}><strong>Κατάσταση:</strong> {activeStatus.name}</div>
      ) : (
        <div style={{ fontSize: '14px' }}>Δεν βρέθηκε Κατάσταση</div>
      )}
    </div>
  );
      }

export default TicketStatus;
