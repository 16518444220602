// src/features/userAssignmentSlice.js
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import updateMultipleTickets from "app/Api Calls/UpdateMultipleTickets";
import {searchTeams} from "app/Api Calls/SearchTeams";

export const updateTickets = createAsyncThunk(
    'userAssignment/updateTickets',
    async (assignedTickets, {getState}) => {
        // Here you can access state if needed, for example, to get user ID
        const {userAssignment} = getState();
        const payload = assignedTickets.map(ticket => ({
            ticketId: ticket.id,
            title: ticket.title || null,
            shortDescription: ticket.shortDescription || null,
            description: ticket.description || ' ',
            thematic: ticket.thematic.id || null,
            beneficiary: ticket.beneficiaryId || null,
            representative: ticket.representativeId || null,
            activeFlags: ticket.ticketFlagCollection,
            assigneeUser: ticket.assigneeUser,
            ticketStatus: ticket.ticketStatus.id,
            team: ticket.teamId,
        }));

        await updateMultipleTickets(payload);
    }
);

export const setTeams = createAsyncThunk(
    'userAssignment/setTeams',
    async (userId, thunkAPI) => {
        const response = await searchTeams(0, 100, null, null, null, null, userId, null);
        if (response && response.elements) {
            return response.elements;
        } else {
            throw new Error('No teams found.');
        }
    }
);


const initialState = {
    tickets: [],
    teamUserAssignments: [],
    teamAssignments: [],
    selectedTeam: null,
    userTeams: []
};

export const userAssignmentSlice = createSlice({
    name: 'userAssignment',
    initialState,
    reducers: {
        setTickets: (state, action) => {
            state.tickets = action.payload;
        },
        assignUserToTeam: (state, action) => {
            const {teamId, userId} = action.payload;
            state.teamUserAssignments[teamId] = userId;
        },
        setTeamAssignments: (state, action) => {
            state.teamAssignments = action.payload;
        },
        setSelectedTeam: (state, action) => {
            state.selectedTeam = action.payload;
        },
        setUserTeams: (state, action) => {
            state.userTeams = action.payload;
        },
        resetUserAssignmentSlice: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTickets.pending, (state, action) => {
                state.updateTicketsStatus = 'loading';
            })
            .addCase(updateTickets.fulfilled, (state, action) => {
                state.updateTicketsStatus = 'succeeded';
            })
            .addCase(updateTickets.rejected, (state, action) => {
                state.updateTicketsStatus = 'failed';
            })
            .addCase(setTeams.fulfilled, (state, action) => {
                state.userTeams = action.payload;
            })
            .addCase(setTeams.pending, (state, action) => {
            })
            .addCase(setTeams.rejected, (state, action) => {
            });
    },
});

export const {
    setUserTeams,
    setSelectedTeam,
    setTickets,
    assignUserToTeam,
    setTeamAssignments,
    resetUserAssignmentSlice
} = userAssignmentSlice.actions;

export default userAssignmentSlice.reducer;

