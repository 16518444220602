import React, { useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage, selectMessage, isMessageOpen } from 'store/messageSlice';

const Message = () => {
  const dispatch = useDispatch();
  const messageDetails = useSelector(selectMessage);
  const messageOpen = useSelector(isMessageOpen);

  useEffect(() => {
    if (messageDetails && messageOpen) {
      const timer = setTimeout(() => {
        dispatch(hideMessage());
      }, messageDetails.autoHideDuration || 2000);
      return () => clearTimeout(timer);
    }
  }, [dispatch, messageDetails, messageOpen]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(hideMessage());
  };

  return messageDetails ? (
      <Snackbar
          open={messageOpen}
          autoHideDuration={messageDetails.autoHideDuration}
          onClose={handleClose}
          anchorOrigin={messageDetails.anchorOrigin}
      >
        <Alert
            onClose={handleClose}
            severity={messageDetails.variant || 'info'}
            sx={{
              width: '100%',
                backgroundColor: '#2f2f31',
                color: 'white',
              '& .MuiAlert-icon': {
                color: 'white !important',
              },
              ...(messageDetails.variant === 'error' && {
                backgroundColor: '#b71c1c',
                color: 'white',
              }),
              ...(messageDetails.variant === 'warning' && {
                backgroundColor: '#ffb300',
                color: 'white',
              }),
              ...(messageDetails.variant === 'success' && {
                backgroundColor: '#4e9a51',
                color: 'white',
              }),
            }}
        >
          <strong>{messageDetails.message}</strong>
        </Alert>
      </Snackbar>
  ) : null; // Return null if there's no message to display
};

export default Message;
