import { axiosApi } from "app/configs/axiosConfig";

const getUsersForDirectAssignment = async () => {
  try {
    const response = await axiosApi.get('/user/getUsersForDirectAssignment');
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getUsersForDirectAssignment;
