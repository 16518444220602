import {createSlice} from "@reduxjs/toolkit";


export const beneficiaryFetchSlice = createSlice({
    name: 'beneficiaryFetchSlice',
    initialState: {
        lastBeneficiarySearchParams: {
            afm: null,
            amka: null,
            phone: null,
            mobile: null
        },
        fetchedBeneficiaries: [],
        isLoadingBeneficiary: false,
        beneficiaryTickets: {}
    },

    reducers: {
        setBeneficiaryLastSearchParams: (state, action) => {
            Object.keys(action.payload).forEach(key => {
                const value = action.payload[key];
                state.lastBeneficiarySearchParams[key] = value;
            });
        },
        setFetchedBeneficiaries: (state, action) => {
            if (Array.isArray(action.payload)) {
                const uniqueNewBeneficiaries = action.payload.filter(newBeneficiary =>
                    !state.fetchedBeneficiaries.some(existingBeneficiary => existingBeneficiary.id === newBeneficiary.id)
                );
                state.fetchedBeneficiaries = [...state.fetchedBeneficiaries, ...uniqueNewBeneficiaries];
            } else if (action.payload) {
                if (!state.fetchedBeneficiaries.some(existingBeneficiary => existingBeneficiary.id === action.payload.id)) {
                    state.fetchedBeneficiaries.push(action.payload);
                }
            }
        },
        clearSpecificLastSearchParam: (state, action) => {
            const param = action.payload;
            if (param !== "phone" && param !== "mobile") {
                if (state.lastBeneficiarySearchParams.hasOwnProperty(param)) {
                    state.lastBeneficiarySearchParams[param] = null;
                }
            }
        },
        setIsLoadingBeneficiary: (state, action) => {
            state.isLoadingBeneficiary = action.payload;
        },
        clearFetchedBeneficiaries: (state) => {
            state.fetchedBeneficiaries = [];
        },
        setAllBeneficiaryLastSearchParams: (state, action) => {
            state.lastBeneficiarySearchParams = {
                ...action.payload,
            };
        },


        clearBeneficiaryLastSearchParams: (state) => {
            state.lastBeneficiarySearchParams.afm = null;
            state.lastBeneficiarySearchParams.amka = null;
            state.lastBeneficiarySearchParams.phone = null;
            state.lastBeneficiarySearchParams.mobile = null;
        },
        setBeneficiaryTickets: (state, action) => {
            const { beneficiaryId, tickets } = action.payload;
            if (!state.beneficiaryTickets[beneficiaryId]) {
                state.beneficiaryTickets[beneficiaryId] = {};
            }
            tickets.forEach(ticket => {
                state.beneficiaryTickets[beneficiaryId][ticket.carrierId] = ticket;
            });
        }
    }
});

export const {
    clearSpecificLastSearchParam,
    setIsLoadingBeneficiary,
    setBeneficiaryLastSearchParams,
    clearBeneficiaryLastSearchParams,
    setAllBeneficiaryLastSearchParams,
    setFetchedBeneficiaries,
    clearFetchedBeneficiaries,
    setBeneficiaryTickets
} = beneficiaryFetchSlice.actions;
export default beneficiaryFetchSlice.reducer;
