import { axiosApi } from "app/configs/axiosConfig";

export const uploadFileToTicket = async (ticketId, file, isPublished) => {
    const formData = new FormData();
    formData.append('filedata', file);

    try {
        const response = await axiosApi.post(`/file/ticket/${ticketId}/${isPublished}`, formData);
        return response;
    } catch (error) {
        throw error;
    }
};

