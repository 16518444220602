import {axiosApi} from "app/configs/axiosConfig";


export const removeUserFromTeams = async (userId, teams) => {
    try {
        const response = await axiosApi.post('/team/multiple/remove/user', {
            userId,
            teams,
        });

        return response.data;
    } catch (error) {
        console.error('Error removing user from teams:', error);
        throw error;
    }
};

export const addUserToTeams = async (userId, teams) => {
    try {
        const response = await axiosApi.post('/team/multiple/add/user', {
            userId,
            teams,
        });

        return response.data;
    } catch (error) {
        console.error('Error adding user to teams:', error);
        throw error;
    }
};