import { IconButton } from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useRef } from "react";

const FileUploadButton = ({ onFilesSelected , disabled }) => {
    const fileInputRef = useRef(null);
  
    const triggerFileInput = () => {
      fileInputRef.current.click();
    };
  
    return (
      <div className="FileUploadButton">
        <input 
          ref={fileInputRef} 
          type="file" 
          onChange={onFilesSelected} 
          multiple 
          accept="image/*,application/pdf" 
          style={{ display: 'none' }} 
        />
        <IconButton 
          onClick={triggerFileInput}
          disabled={disabled}
          sx={{
            padding: "10px",
            width: '32px', 
            height: '32px',
            backgroundColor: "#fff",
            borderRadius: 0,
            border: "1px solid #F1F1F1",
            "&:hover": {
             boxShadow: " 1px 1px 0px #BFBDBD "
            }
          }}
        >
          <UploadFileIcon />
        </IconButton>
      </div>
    );
};

export default FileUploadButton;
