import { axiosApi } from "app/configs/axiosConfig";

export async function fetchAllGroupRoles() {
  try {
    const response = await axiosApi.get('/domain/groupRoles');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch group roles:', error);
    return null;
  }
}