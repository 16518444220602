import React, { useState, useEffect } from "react";
import { Modal, Box, TextField, Button, Typography } from "@mui/material";
import { searchBeneficiaries } from "app/Api Calls/FiltersList";
import updateBeneficiary from "app/Api Calls/UpdateBeneficiary";
import { showMessage } from "store/messageSlice";
import { useDispatch } from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const EditBeneficiaryModal = ({ open, handleClose, beneficiary, refreshData }) => {
    const [editedBeneficiary, setEditedBeneficiary] = useState({ ...beneficiary });
    const [initialHasPhoneOrMobile, setInitialHasPhoneOrMobile] = useState(false);
    const [errors, setErrors] = useState({});
    const [conflictMessage, setConflictMessage] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        setEditedBeneficiary({ ...beneficiary });
        setConflictMessage("");
        setErrors({});

        // Check if the initial beneficiary has either phone or mobile filled
        if (beneficiary.phone || beneficiary.mobile) {
            setInitialHasPhoneOrMobile(true);
        } else {
            setInitialHasPhoneOrMobile(false);
        }
    }, [beneficiary]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedBeneficiary({ ...editedBeneficiary, [name]: value });
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let tempErrors = { ...errors };

        // Perform individual field validation
        switch (name) {
            case "afm":
                if (beneficiary.afm && !value) {
                    tempErrors.afm = "Το ΑΦΜ είναι υποχρεωτικό.";
                } else if (value && !/^\d{9}$/.test(value)) {
                    tempErrors.afm = "Το ΑΦΜ πρέπει να αποτελείται από 9 ψηφία.";
                } else {
                    delete tempErrors.afm;
                }
                break;
            case "amka":
                if (beneficiary.amka && !value) {
                    tempErrors.amka = "Το ΑΜΚΑ είναι υποχρεωτικό.";
                } else if (value && !/^\d{11}$/.test(value)) {
                    tempErrors.amka = "Το ΑΜΚΑ πρέπει να αποτελείται από 11 ψηφία.";
                } else {
                    delete tempErrors.amka;
                }
                break;
            case "firstName":
                if (
                    value &&
                    (value.length < 3 ||
                        /[^a-zA-Zα-ωΑ-Ω\s-]/.test(value) ||
                        /\d/.test(value) ||
                        (value.match(/-/g) || []).length > 1 ||
                        /^-|-$/.test(value))
                ) {
                    tempErrors.firstName =
                        "Το όνομα πρέπει να αποτελείται από τουλάχιστον 3 χαρακτήρες, να μην περιέχει αριθμούς ή ειδικά σύμβολα (εκτός από -) και να περιέχει μόνο ένα - που δεν βρίσκεται στην αρχή ή στο τέλος.";
                } else {
                    delete tempErrors.firstName;
                }
                break;
            case "lastName":
                if (
                    value &&
                    (value.length < 3 ||
                        /[^a-zA-Zα-ωΑ-Ω\s-]/.test(value) ||
                        /\d/.test(value) ||
                        (value.match(/-/g) || []).length > 1 ||
                        /^-|-$/.test(value))
                ) {
                    tempErrors.lastName =
                        "Το επώνυμο πρέπει να αποτελείται από τουλάχιστον 3 χαρακτήρες, να μην περιέχει αριθμούς ή ειδικά σύμβολα (εκτός από -) και να περιέχει μόνο ένα - που δεν βρίσκεται στην αρχή ή στο τέλος.";
                } else {
                    delete tempErrors.lastName;
                }
                break;
            case "email":
                if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    tempErrors.email = "Το email δεν είναι έγκυρο.";
                } else {
                    delete tempErrors.email;
                }
                break;
            case "phone":
                if (value && !/^\d{10}$/.test(value)) {
                    tempErrors.phone = "Το τηλέφωνο πρέπει να αποτελείται από 10 ψηφία.";
                } else {
                    delete tempErrors.phone;
                }
                break;
            case "mobile":
                if (value && (!/^\d{10}$/.test(value) || !value.startsWith("69"))) {
                    tempErrors.mobile = "Το κινητό πρέπει να αποτελείται από 10 ψηφία και να ξεκινάει από 69.";
                } else {
                    delete tempErrors.mobile;
                }
                break;
            default:
                break;
        }

        // Check if both phone and mobile are empty when they must not be
        if (
            initialHasPhoneOrMobile &&
            (!editedBeneficiary.phone || editedBeneficiary.phone === "") &&
            (!editedBeneficiary.mobile || editedBeneficiary.mobile === "")
        ) {
            tempErrors.phone = "Το τηλέφωνο ή το κινητό είναι υποχρεωτικό.";
            tempErrors.mobile = "Το τηλέφωνο ή το κινητό είναι υποχρεωτικό.";
        }

        setErrors(tempErrors);
    };


    const checkForConflicts = async () => {
        if (editedBeneficiary.afm !== beneficiary.afm) {
            const afmResult = await searchBeneficiaries("ΑΦΜ", editedBeneficiary.afm, 0, 1);
            if (afmResult && afmResult.elements.length > 0 && afmResult.elements[0].id !== beneficiary.id) {
                setConflictMessage(`Υπάρχει ήδη δικαιούχος με το ίδιο ΑΦΜ: ${afmResult.elements[0].afm}`);
                return true;
            }
        }

        if (editedBeneficiary.amka !== beneficiary.amka) {
            const amkaResult = await searchBeneficiaries("ΑΜΚΑ", editedBeneficiary.amka, 0, 1);
            if (amkaResult && amkaResult.elements.length > 0 && amkaResult.elements[0].id !== beneficiary.id) {
                setConflictMessage(`Υπάρχει ήδη δικαιούχος με το ίδιο ΑΜΚΑ: ${amkaResult.elements[0].amka}`);
                return true;
            }
        }

        return false;
    };

    const handleSave = async () => {
        const hasConflict = await checkForConflicts();

        if (!hasConflict) {
            try {
                const updatedBeneficiary = {
                    ...beneficiary,
                    ...editedBeneficiary,
                };

                const response = await updateBeneficiary(updatedBeneficiary);
                if (response && response.status === 200) {
                    dispatch(showMessage({
                        message: 'Τα στοιχεία του δικαιούχου ενημερώθηκαν επιτυχώς',
                        autoHideDuration: 8000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "success",
                    }));
                    refreshData();
                    handleClose();
                } else {
                    throw new Error('Failed to update beneficiary');
                }
            } catch (error) {
                console.error("Error updating beneficiary:", error);
                dispatch(showMessage({
                    message: 'Υπήρξε πρόβλημα κατά την ενημέρωση του δικαιούχου',
                    autoHideDuration: 8000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                }));
            }
        }
    };

    const hasChanges = JSON.stringify(beneficiary) !== JSON.stringify(editedBeneficiary);

    const isFormValid = () => {
        // Check if the phone and mobile are valid
        const phoneValid = (!errors.phone || editedBeneficiary.phone === "") && editedBeneficiary.phone !== undefined;
        const mobileValid = (!errors.mobile || editedBeneficiary.mobile === "") && editedBeneficiary.mobile !== undefined;

        // Ensure that if the form originally had a phone or mobile filled, one of them is still filled
        const hasValidPhoneOrMobile = initialHasPhoneOrMobile
            ? (
                (editedBeneficiary.phone && editedBeneficiary.phone !== "" && !errors.phone && (editedBeneficiary.mobile === "" || !errors.mobile)) ||
                (editedBeneficiary.mobile && editedBeneficiary.mobile !== "" && !errors.mobile && (editedBeneficiary.phone === "" || !errors.phone))
            )
            : true;

        // Exclude phone and mobile from the error check
        const otherErrors = Object.keys(errors).filter(key => key !== 'phone' && key !== 'mobile').length > 0;

        // The form is valid if either phone or mobile is valid, one of them is still filled, and there are no other errors
        return (phoneValid || mobileValid) && hasValidPhoneOrMobile && !otherErrors;
    };


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-beneficiary-modal"
            aria-describedby="modal-to-edit-beneficiary-details"
        >
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    Επεξεργασία Δικαιούχου
                </Typography>
                {conflictMessage && (
                    <Typography color="error" variant="body2" marginBottom={2}>
                        {conflictMessage}
                    </Typography>
                )}
                <TextField
                    fullWidth
                    label="Όνομα"
                    margin="normal"
                    name="firstName"
                    value={editedBeneficiary.firstName || ''}
                    onChange={handleChange}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                />
                <TextField
                    fullWidth
                    label="Επώνυμο"
                    margin="normal"
                    name="lastName"
                    value={editedBeneficiary.lastName || ''}
                    onChange={handleChange}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                />
                <TextField
                    fullWidth
                    label="ΑΦΜ"
                    margin="normal"
                    name="afm"
                    value={editedBeneficiary.afm || ''}
                    onChange={handleChange}
                    error={!!errors.afm}
                    helperText={errors.afm}
                />
                <TextField
                    fullWidth
                    label="ΑΜΚΑ"
                    margin="normal"
                    name="amka"
                    value={editedBeneficiary.amka || ''}
                    onChange={handleChange}
                    error={!!errors.amka}
                    helperText={errors.amka}
                />
                <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    value={editedBeneficiary.email || ''}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                />
                <TextField
                    fullWidth
                    label="Τηλέφωνο"
                    margin="normal"
                    name="phone"
                    value={editedBeneficiary.phone || ''}
                    error={!!errors.phone}
                    onChange={handleChange}
                    helperText={errors.phone}
                />
                <TextField
                    fullWidth
                    label="Κινητό"
                    margin="normal"
                    name="mobile"
                    value={editedBeneficiary.mobile || ''}
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                    onChange={handleChange}
                />
                <Box mt={2} display="flex" justifyContent="space-between">
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ backgroundColor: "#d32f2f !important", color: "#fff" }}
                    >
                        Κλείσιμο
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"
                        sx={{ backgroundColor: "#34a853 !important", color: "#fff" }}
                        disabled={!hasChanges || !isFormValid()}                    >
                        Αποθήκευση Αλλαγών
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditBeneficiaryModal;
