import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpandedThematic,
  setSelectedThematic,
  setThematics,
} from "store/gnosiakiCrudSlice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import SubThematicsTable from "./SubThematicsTable";
import EditDeleteButtons from "./EditDeleteButton";
import { fetchThematicsByCarrierIdCall } from "app/Api Calls/CarriersThematicsCalls";

function ThematicsTable({ carrierId }) {
  const dispatch = useDispatch();
  const { expandedCarrier, thematicsByCarrier, expandedThematic } = useSelector((state) => state.gnosiakiCrudSlice);
  const thematics = thematicsByCarrier[carrierId] || [];
  const [refreshThematics, setRefreshThematics] = useState(false);
  const triggerRefetch = useSelector((state)=> state.gnosiakiCrudSlice.refreshTrigger)
  const prevTriggerRefetchRef = useRef();

  useEffect(() => {
    const shouldRefetch = (triggerRefetch.carrierId === carrierId || refreshThematics || !thematicsByCarrier[carrierId]?.length || prevTriggerRefetchRef.current !== triggerRefetch.count)
    if (shouldRefetch) {
      fetchThematicsByCarrierIdCall(carrierId)
          .then(data => {
            dispatch(setThematics({ carrier: carrierId, thematics: data }));
            if(refreshThematics) {
              setRefreshThematics(false);
            }
          })
          .catch(error => console.error("There was an error!", error));

      // Update the previous trigger refetch to the current count after fetching
      prevTriggerRefetchRef.current = triggerRefetch.count;
    }


  }, [expandedCarrier, thematicsByCarrier, dispatch, carrierId, refreshThematics, triggerRefetch]);
  

  const handleThematicClick = (thematicId) => {
    dispatch(setSelectedThematic(thematicId));
    dispatch(setExpandedThematic(thematicId));
  };

  const handleThematicUpdated = () => {
    setRefreshThematics(true);
  };

  return (
    <Droppable droppableId={`thematics_${carrierId}`} type="thematic">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {thematics.map((thematic, index) => (
            <Draggable key={thematic.id} draggableId={String(thematic.id)} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <div className="flex items-center justify-between bg-white py-2 px-4 my-2 cursor-pointer ml-16">
                    {/* Thematic information container */}
                    <div
                      className="flex items-center w-full"
                      onClick={() => handleThematicClick(thematic.id)}
                    >
                      <div className="w-auto px-2">Θεματική Ενότητα {expandedThematic[thematic.id] ? "-" : "+"}</div>
                      <div className="px-2 ml-4">{thematic.title}</div>
                    </div>

                    {/* Edit and Delete buttons container */}
                    <div className="flex-shrink-0">
                      <EditDeleteButtons
                        thematic={thematic}
                        isCarrier={false}
                        onUpdate={handleThematicUpdated}
                      />
                    </div>
                  </div>

                  {expandedThematic[thematic.id] && (
                    <SubThematicsTable
                      thematicId={thematic.id}
                    />
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default ThematicsTable;
