import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

function CarrierList({ carriers, selectedCarrierId, onCarrierSelect }) {
    return (
        <List sx={{
            width: '30%',
            bgcolor: '#003476',
            margin: 0,
            borderColor: 'divider',
            overflowY: 'auto',
            height: '90vh'
        }}>
            {carriers.map((carrier, index) => (
                <ListItem
                    key={index}
                    onClick={() => onCarrierSelect(carrier)}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': { bgcolor: '#ffffff', color: '#000000' },
                        bgcolor: selectedCarrierId === carrier.id ? '#ffffff' : '#003476',
                        color: selectedCarrierId === carrier.id ? 'black' : '#ffffff',
                        fontWeight: selectedCarrierId === carrier.id ? 'bold' : 'normal',
                    }}
                >
                    <ListItemText primary={carrier.title} />
                </ListItem>
            ))}
        </List>
    );
}

export default CarrierList;
