import {axiosApi} from "../configs/axiosConfig";


export const getUserCarriers = async (id) => {
    try {
        const response = await axiosApi.get(`/user/getUserCarriers/${id}`);
        return response.data;
    } catch (error) {
        console.error(`Failed to get user Carriers: ${error}`);
    }
};
