import React from 'react';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import DocIcon from '@mui/icons-material/Description';
import TableChartIcon from '@mui/icons-material/TableChart';
import FolderIcon from '@mui/icons-material/Folder';

export const FileLogo = ({ extension }) => {
    switch (extension) {
        case 'pdf':
        case 'application/pdf':
            return <PdfIcon fontSize="large" color="error" />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'image/jpg':
        case 'image/jpeg':
        case 'image/png':
            return <ImageIcon fontSize="large" color="primary" />;
        case 'doc':
        case 'docx':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <DocIcon fontSize="large" color="primary" />;
        case 'xls':
        case 'xlsx':
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <TableChartIcon fontSize="large" color="success" />;
        default:
            return <FolderIcon fontSize="large" color="action" />;
    }
};
