import { createSlice } from '@reduxjs/toolkit';

// Modify the initialState to support a queue of messages
const initialState = {
  queue: [], // This will store an array of message objects
  currentMessage: null, // This will store the message currently being displayed
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      // Enqueue the new message
      state.queue.push({
        message: action.payload.message,
        autoHideDuration: action.payload.autoHideDuration || 2000,
        anchorOrigin: action.payload.anchorOrigin || {
          vertical: 'top',
          horizontal: 'center',
        },
        variant: action.payload.variant || '',
      });
      // If there is no current message, dequeue the first message to display
      if (!state.currentMessage) {
        state.currentMessage = state.queue.shift();
      }
    },
    hideMessage: (state) => {
      // When a message is hidden, check if there are more messages in the queue
      if (state.queue.length > 0) {
        // Dequeue the next message to display
        state.currentMessage = state.queue.shift();
      } else {
        // If no more messages, clear the current message
        state.currentMessage = null;
      }
    },
  },
});

export const { showMessage, hideMessage } = messageSlice.actions;
export const selectMessage = (state) => state.message.currentMessage;
export const isMessageOpen = (state) => !!state.message.currentMessage;

export default messageSlice.reducer;
