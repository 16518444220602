import ThematicsCarriersTableV2 from "./ThematicsCarrierTableV2";
import TreeComponent from "./TreeComponent";


function KnowledgePageCRUD() {
  
    return (
      <div>
        <TreeComponent />
        <ThematicsCarriersTableV2/>
      </div>
    );
  }
  
  export default KnowledgePageCRUD;
  