import { axiosApi } from "app/configs/axiosConfig";

const updateRepresentative = async (representativeId, representativeData) => {
  try {
    // Convert all empty strings in the representativeData object to null
    const sanitizedRepresentativeData = sanitizeRepresentative(representativeData);

    // Include the representativeId inside the payload
    const payload = {
      ...sanitizedRepresentativeData,
      id: representativeId
    };

    const response = await axiosApi.put(`/representative`, payload);
    if (response.status === 200) {
      return response.data.id;
    } else {
      throw new Error('Failed to update representative');
    }
  } catch (error) {
    console.error('Error updating representative:', error);
    throw error; // Rethrowing the error to be handled by the caller
  }
};

const sanitizeRepresentative = (representativeData) => {
  const sanitizedData = { ...representativeData };
  Object.keys(sanitizedData).forEach(key => {
    if (sanitizedData[key] === "") {
      sanitizedData[key] = null;
    }
  });
  return sanitizedData;
}

export default updateRepresentative;
