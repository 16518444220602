function formatDateToISOStringEndOfDay(date) {
    if (!(date instanceof Date)) {
        throw new Error('date must be a Date object');
    }
    
    let utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59));
    let isoString = utcDate.toISOString();
    
    return isoString.replace('.000Z', '+00:00');
}

export default formatDateToISOStringEndOfDay;