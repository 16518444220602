import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { fetchSourceApplicationsAsync, setSelectedSourceApplication } from 'store/ticketFetchSlice';

const SourceApplicationFilter = () => {
    const dispatch = useDispatch();
    const sourceApplications = useSelector((state) => state.ticketFetchSlice.sourceApplications);
    const selectedSourceApplicationId = useSelector((state) => state.ticketFetchSlice.selectedSourceApplication);
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);

    useEffect(() => {
        if (!sourceApplications || sourceApplications?.length === 0) {
            dispatch(fetchSourceApplicationsAsync());
        }
    }, [dispatch, sourceApplications]);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        dispatch(setSelectedSourceApplication(selectedId));
    };

    const handleClear = (event) => {
        event.stopPropagation();
        dispatch(setSelectedSourceApplication(null));
    };

    // Filter source applications to show only IDs 1 and 4
    const filteredSourceApplications = Array.isArray(sourceApplications) ?
        sourceApplications.filter(app => [1, 4].includes(app.id)) : [];
    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel
                id="source-application-label"
                sx={{ backgroundColor: '#edf0f6' }}
            >
                Πλατφόρμα
            </InputLabel>
            <Select
                labelId="source-application-label"
                id="source-application-select"
                value={selectedSourceApplicationId || ''}
                onChange={handleChange}
                size='small'
                label="Source Application"
                disabled={ticketsLoading}
                input={
                    <OutlinedInput
                        id="source-application-select-outlined"
                        endAdornment={
                            selectedSourceApplicationId && (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleClear} style={{ marginRight: 8 }} disabled={ticketsLoading}>
                                        <ClearIcon />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    />
                }
            >
                {/* Render filtered source applications */}
                {filteredSourceApplications?.map((app) => (
                    <MenuItem key={app.id} value={app.id} disabled={ticketsLoading}>
                        {app.shortName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SourceApplicationFilter;
