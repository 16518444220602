import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {fetchCarrierAndThenSetThematic} from "store/knowledgeBasePageSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ThematicSelector from "app/main/Reusable Components/Ticket Reusable/ThematicSelector";
import SubThematicSelector from "app/main/Reusable Components/Ticket Reusable/SubthematicSelector";
import SubSubThematicSelector from "app/main/Reusable Components/Ticket Reusable/SubSubthematicSelector";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setHasThematicsChanged, setIsCarrierAndThematicsFetched, setPreviousTicketVersion} from "store/editTicketSlice";
import {useState} from "react";
import GeographySelector from "app/main/tickets/create-ticket/GeographySelector";
import CarrierSelector from "app/main/Reusable Components/Ticket Reusable/CarrierSelector";
import ThematicUpdateDialog from "./ThematicUpdateDialog";
import assignTeamToTicket from "app/Api Calls/AssignTeamToTicket";
import {searchTeams} from "app/Api Calls/SearchTeams";
import updateTicket from "app/Api Calls/UpdateTicket";
import Button from "@mui/material/Button";

function CarrierAndThematicAccordion({
 ticket, expandedPanels, onPanelToggle, panelRefs, ticketVersion,onUpdate
                                     }) {
    const dispatch = useDispatch();
    const selectedCarrier = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
    const [initialSubSubThematic, setInitialSubSubThematic] = useState(null);
    const previousTicketVersion = useSelector((state)  => state.editTicketSlice.previousTicketVersion)
    useEffect(() => {
        if (ticketVersion !== previousTicketVersion) {
            dispatch(setIsCarrierAndThematicsFetched(false));
            fetchCarrierAndThematicsData();

        }
    }, [ticketVersion]);

    //Fetch the carrier and thematics
    const fetchCarrierAndThematicsData = () => {
        if (ticket && ticket.thematicId) {
            dispatch(fetchCarrierAndThenSetThematic(ticket.thematicId));
            dispatch(setIsCarrierAndThematicsFetched(true));
            setInitialSubSubThematic(ticket.thematicId);
            dispatch(setPreviousTicketVersion(ticketVersion));
        }
    };


    //Check if the initial subSubthematic has changed
    const onSubSubThematicChange = (selectedThematicId) => {
        const hasChanged = initialSubSubThematic !== selectedThematicId;
        dispatch(setHasThematicsChanged(hasChanged));
    };


    return (<Accordion
            className="bg-gray-200"
            expanded={expandedPanels.includes("Θεματικές")}
            onChange={() => onPanelToggle("Θεματικές")}
            ref={panelRefs["Θεματικές"]}
        >

            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <h3>Θεματικές</h3>
            </AccordionSummary>
            <AccordionDetails>
                <div className="grid gap-4">

                    <CarrierSelector/>
                    {selectedCarrier?.useGeo && <GeographySelector isEditPage={true}/>}
                    <ThematicSelector isCreatePage={false}/>
                    <SubThematicSelector isCreatePage={false}/>
                    <SubSubThematicSelector isCreatePage={false} onChange={onSubSubThematicChange}/>
                </div>
            </AccordionDetails>
        </Accordion>);
}

export default CarrierAndThematicAccordion;
