import { axiosApi } from "app/configs/axiosConfig";

export async function searchTeams(page, size, groupRoleId, carrierId, thematicId, geographyId, userId = null,sourceApplicationId = null) {
    const endpoint = `/team/search/${page}/${size}`;

    // Create the params object dynamically. Only add userId if it's not null.
    let params = {
        carrierId: carrierId,
        groupRoleId: groupRoleId,
        thematicId: thematicId,
        geographyId: geographyId ,
        sourceApplicationId: sourceApplicationId
    };

    if (userId !== null) {
        params.userId = userId;
    }

    try {
        const response = await axiosApi.post(endpoint, params);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch team search:', error);
        return null;
    }
}

