import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // margin: '5px',
});

const SuccessMessage = styled(Typography)({
  color: 'green',
  fontSize: '13px',
});

const ErrorMessage = styled(Typography)({
  color: 'red',
  fontSize: '13px',
});

const List = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  listStyle: 'none',
  padding: 0,
});

const ListItem = styled('li')({
  color: 'red',
  fontSize: '13px',
  margin: '4px',
});

function MandatoryFieldsList() {
  const mandatoryFields = useSelector(
      (state) => state.resolutionsSlice.mandatoryFields
  );
  const allFieldsFilled = mandatoryFields.length > 0 && mandatoryFields.every((field) => field.filled);
  const resolutionOptions = useSelector((state) => state.resolutionsSlice.resolutionOptions);
  const selectedResolution = useSelector((state) => state.resolutionsSlice.selectedResolution);

  // if (!resolutionOptions || resolutionOptions.length === 0 || !selectedResolution) {
  //   return null;
  // }

  return (
      <Container>
        {allFieldsFilled ? (
            <SuccessMessage>
              Τα υποχρεωτικά πεδία είναι συμπληρωμένα
            </SuccessMessage>
        ) : (
            <>
              {mandatoryFields.length > 0 && (
                  <>
                    <Typography variant="subtitle1">Υποχρεωτικά πεδία:</Typography>
                    <List>
                      {mandatoryFields
                          .filter((field) => !field.filled)
                          .map((field, index) => (
                              <ListItem key={index}>
                                {field.greekLabel}
                              </ListItem>
                          ))}
                    </List>
                  </>
              )}
            </>
        )}
      </Container>
  );
}

export default MandatoryFieldsList;
