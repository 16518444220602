import React, {useEffect, useState} from 'react';
import {Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {fetchAllTeams, fetchRoles, fetchUsersRoleBased, fetchUsersWithId} from 'app/Api Calls/TeamsRequiredApis';

const UsersFilters = ({page, size, onUsersFetched, setLoading, transformRoleName, refreshFunction, resetPage}) => {
    const [roles, setRoles] = useState([]);
    const [teams, setTeams] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [selectedTeam, setSelectedTeam] = useState("");
    const [afm, setAfm] = useState("");
    const [lastName, setLastName] = useState("");
    const [prevFilters, setPrevFilters] = useState({ afm: "", lastName: "", selectedRole: "", selectedTeam: "" });

    const handleSearch = () => {
        searchWithValues(lastName, afm, selectedRole, selectedTeam, false);
    };

    const searchWithValues = async (searchLastName, searchAfm, searchSelectedRole, searchSelectedTeam, isFilterReset = false) => {
        // Trim the input values
        searchLastName = searchLastName.trim();
        searchAfm = searchAfm.trim();
        searchSelectedRole = searchSelectedRole ? searchSelectedRole : '';
        searchSelectedTeam = searchSelectedTeam ? searchSelectedTeam : '';

        if (!isFilterReset) {
            if (searchAfm !== prevFilters.afm || searchLastName !== prevFilters.lastName || searchSelectedRole !== prevFilters.selectedRole || searchSelectedTeam !== prevFilters.selectedTeam) {
                resetPage();
            }
            setPrevFilters({ afm: searchAfm, lastName: searchLastName, selectedRole: searchSelectedRole, selectedTeam: searchSelectedTeam });
        } else {
            resetPage();
            setPrevFilters({ afm: '', lastName: '', selectedRole: '', selectedTeam: '' });
        }
        setLoading(true);
        try {
            const requestBody = {
                lastName: searchLastName,
                afm: searchAfm,
                roleId: searchSelectedRole?.id,
                teamId: searchSelectedTeam?.id,
            };
            const response = await fetchUsersWithId(page, size, requestBody);

            let fetchedUsers = response.elements || [];
            let totalElements = response.totalElements;

            onUsersFetched(fetchedUsers, totalElements);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    refreshFunction.current = handleSearch;

//Run the search for each different page
    useEffect(() => {
        handleSearch();
    }, [page, size]);

    useEffect(() => {
        const fetchAllRolesAndTeams = async () => {
            try {
                const [fetchedRoles, fetchedTeams] = await Promise.all([fetchRoles(), fetchAllTeams()]);
                const transformedRoles = fetchedRoles.map(role => ({
                    ...role,
                    roleName: transformRoleName(role.roleName),
                }));
                setRoles(transformedRoles);
                setTeams(fetchedTeams); // Assuming fetchedTeams is in the desired format
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };
        fetchAllRolesAndTeams();
    }, []);


    const clearFiltersAndSearch = () => {
        setAfm('');
        setLastName('');
        setSelectedRole("");
        setSelectedTeam("");
        searchWithValues('', '', '', '', true);
    }

    return (
        <div style={{display: "flex", gap: "20px", marginBottom: "20px"}}>


            {/* AFM Input */}
            <TextField
                variant="outlined"
                label="ΑΦΜ"
                value={afm}
                style={{width: "25%"}}
                onChange={(e) => setAfm(e.target.value)}
            />

            {/* Surname Input */}
            <TextField
                variant="outlined"
                label="Επώνυμο"
                value={lastName}
                style={{width: "25%"}}
                onChange={(e) => setLastName(e.target.value)}
            />

            {/* Role Selection */}
            <FormControl variant="outlined" style={{ width: "25%" }}>
                <InputLabel id="role-select-label">Ρόλος</InputLabel>
                <Select
                    labelId="role-select-label"
                    label="Ρόλος"
                    value={selectedRole ? selectedRole.roleName : ""}
                    onChange={(e) => {
                        const roleObj = roles.find(role => role.roleName === e.target.value) || null;
                        setSelectedRole(roleObj);
                    }}
                    inputProps={{ name: "role", id: "role-select" }}
                >
                    {/* Option to clear the selection */}
                    <MenuItem value="">
                        <em>Όλοι</em>
                    </MenuItem>
                    {roles.map((role) => (
                        <MenuItem key={role.id} value={role.roleName}>{role.roleName.replace("ROLE_", "")}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="outlined" style={{ width: "40%" }}>
                <Autocomplete
                    id="team-autocomplete"
                    options={teams}
                    getOptionLabel={(option) => option.name || ""}
                    value={selectedTeam}
                    onChange={(event, newValue) => {
                        setSelectedTeam(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ομάδα"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                            }}
                        />
                    )}
                />
            </FormControl>

            {/* Search Button */}
            <Button
                disabled={!afm && !lastName && !selectedRole && !selectedTeam}
                variant="contained"
                style={{
                    backgroundColor: (!afm && !lastName && !selectedRole && !selectedTeam) ? '#c0c0c0' : '#003476',
                    borderRadius: '20px',
                    color: '#ffffff'
                }}
                onClick={handleSearch}
            >
                Αναζήτηση
            </Button>


            {(afm || lastName || selectedRole|| selectedTeam) && (
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#c62828',
                        borderRadius: '20px',
                        color: '#ffffff'
                    }}
                    onClick={clearFiltersAndSearch}
                >
                    Διαγραφή Φίλτρων
                </Button>
            )}

        </div>
    );
};

export default UsersFilters;


//   useEffect(() => {
//     const fetchAllRoles = async () => {
//       try {
//         const fetchedRoles = await fetchRoles();
//         const transformedRoles = fetchedRoles.map(role => {
//           return {
//             ...role,
//             roleName: transformRoleName(role.roleName)
//           };
//         });
//         setRoles(transformedRoles);
//       } catch (error) {
//         console.error("Failed to fetch roles:", error);
//       }
//     };
//     fetchAllRoles();
//   }, []);

{/* Role Selection
      <FormControl variant="outlined" style={{ width: "25%" }}>
        <InputLabel htmlFor="role-select">Ρόλος</InputLabel>
        <Select
          label="Ρόλος"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
          inputProps={{ name: "role", id: "role-select" }}
        >
          <MenuItem value="">
            <em>Όλοι</em>
          </MenuItem>
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.roleName}>
              {role.roleName.replace("ROLE_", "")}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */
}
