import { useCallback, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  setDebouncedSearchQuery,
  setSearchQuery,
  fetchSearchResults,
  setSearchResults,
} from 'store/knowledgeBasePageSlice';

function SearchGnosiaki() {
  const dispatch = useDispatch();

  const selectStyles = {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '13px',
      backgroundColor: 'white',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '13px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
      background: 'white', 
      padding: '0 4px',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '13px',
    },
  };

  const searchQuery = useSelector((state) => state.knowledgeBasePageSlice.searchQuery);
  const debouncedSearchQuery = useSelector((state) => state.knowledgeBasePageSlice.debouncedSearchQuery);
  const currentPage = useSelector((state) => state.knowledgeBasePageSlice.currentPage);
  const perPage = useSelector((state) => state.knowledgeBasePageSlice.perPage);
  const selectedSubSubthematic = useSelector((state) => state.knowledgeBasePageSlice.selectedSubSubthematic);
  const selectedSubthematic = useSelector((state) => state.knowledgeBasePageSlice.selectedSubthematic);
  const selectedThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedThematic);

  let thematicId = null;

  if (selectedSubSubthematic) {
    thematicId = selectedSubSubthematic.id;
  } else if (selectedSubthematic) {
    thematicId = selectedSubthematic.id;
  }

  const handleSearchQueryChange = useCallback(
    debounce((value) => {
      dispatch(setDebouncedSearchQuery(value));
    }, 1000),
    [dispatch]
  );

  useEffect(() => {
    if ((!debouncedSearchQuery || !searchQuery)) {
      dispatch(setSearchResults([]));
    } else {
      dispatch(
        fetchSearchResults({
          searchQuery: debouncedSearchQuery,
          currentPage,
          perPage,
          thematicId,
        })
      );
    }
  }, [debouncedSearchQuery, searchQuery, currentPage, perPage, thematicId, dispatch]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const trimmedValue = value.replace(/^\s+/g, '').replace(/\s\s+/g, ' ');
    dispatch(setSearchQuery(trimmedValue));
    handleSearchQueryChange(trimmedValue);
  };
  

  return (
    <div>
      <TextField
        id="outlined-basic"
        variant="outlined"
        fullWidth
        label="Αναζήτηση"
        value={searchQuery}
        onInput={handleInputChange}
        sx={selectStyles}
      />
    </div>
  );
}

export default SearchGnosiaki;
