// Fetch Parent Ticket by ID
import {axiosApi} from "app/configs/axiosConfig";

export const fetchParentTicketById = async (ticketId) => {
    try {
        const response = await axiosApi.get(`/ticket/getTicket/parent/${ticketId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Fetch Child Tickets by Parent ID
export const fetchChildTicketsByParentId = async (ticketId) => {
    try {
        const response = await axiosApi.get(`/ticket/getTicket/child/${ticketId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};