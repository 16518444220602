import { axiosApi } from "app/configs/axiosConfig";


export const enableUser = async (id) => {
  try {
    const response = await axiosApi.patch(`/admin/user/enable/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to enable user: ${error}`);
  }
};

export const disableUser = async (id) => {
  try {
    const response = await axiosApi.patch(`/admin/user/disable/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to disable user: ${error}`);
  }
};