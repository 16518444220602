import { axiosApi } from "app/configs/axiosConfig";

const searchRepresentative = async (searchParams, page = 0, size = 10) => {
    try {
        const response = await axiosApi.post(
            `/representative/search/${page}/${size}`,
            searchParams
        );

        const { data } = response;

        if (data && Array.isArray(data.elements)) {
            return {
                elements: data.elements,
                totalElements: data.totalElements
            };
        } else {
            return { elements: [], totalElements: 0 };
        }
    } catch (error) {
        console.error(error);
        return { elements: [], totalElements: 0 };
    }
};

export default searchRepresentative;
