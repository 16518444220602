import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { showMessage } from 'store/messageSlice';
import { jwtService } from 'app/auth/jwtService';
import { axiosApi } from 'app/configs/axiosConfig';

function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const userData = jwtService.getUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New passwords don't match");
      return;
    }

    const userRole = userData.role;
    const apiEndpoint =
      userRole === 'ROLE_ADMIN' ? '/admin/changePassword' : '/user/changePassword';

    try {
      await axiosApi.patch(apiEndpoint, {
        existingPassword: oldPassword,
        newPassword,
      });
      setErrorMessage('');
      dispatch(
        showMessage({
          message: 'O Κωδικός άλλαξε επιτυχώς',
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'success',
        })
      );
      navigate('/profile');
    } catch (error) {
      console.log('error', error);
      if (error.response.status === 406) {
        setErrorMessage('Λάθος Παλιός Κωδικός');
      } else {
        setErrorMessage('Κάτι Πήγε Στραβά');
      }
    }
  };

  const handleBackToProfile = () => {
    navigate('/profile');
  };

  return (
    <div className="min-h-screen  flex items-center justify-center mt-[-100px]">
      <div className="bg-white p-4 shadow-xl rounded shadow-md w-full max-w-md">
        <Button
          onClick={handleBackToProfile}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded "
        >
          Προφίλ
        </Button>
        <Typography variant="h4" className="mb-4 text-center">
          Αλλαγή Κωδικού
        </Typography>
        <form onSubmit={handleChangePassword}>
          <TextField
            label="Παλιός Κωδικός"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
            fullWidth
            className="mb-4"
          />
          <TextField
            label="Νέος Κωδικός"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            fullWidth
            className="mb-4"
          />
          <TextField
            label="Επιβεβαίωση Νέου Κωδικού"
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            required
            fullWidth
            className="mb-4"
          />
          <Button
            type="submit"
            variant="contained"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 w-full"
            fullWidth
          >
            Αλλαγή Κωδικού
          </Button>
        </form>
        {errorMessage && (
          <Typography color="error" variant="body1" className="mt-4 text-center">
            {errorMessage}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ChangePassword;
