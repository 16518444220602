import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions, Box, CircularProgress
} from '@mui/material';
import ConfirmToggleStep from "app/main/Panels/UserPanel/ActiveWizard/ConfirmToggleStep";
import TicketSearchStep from "app/main/Panels/UserPanel/ActiveWizard/TicketSearchStep";
import {useDispatch, useSelector} from "react-redux";
import {resetUserAssignmentSlice, updateTickets} from "store/userAssignmentSlice";
import {showMessage} from "store/messageSlice";
import TeamSearchActiveWizardStep from "app/main/Panels/UserPanel/ActiveWizard/TeamSearchActiveWizardStep";

const UserToggleStatusWizard = ({ open, user, onClose,onToggle }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [forceRemountKey, setForceRemountKey] = useState(0);
    const teamUserAssignments = useSelector(state => state.userAssignmentSlice.teamUserAssignments);
    const tickets = useSelector((state)=> state.userAssignmentSlice.tickets)
    const dispatch = useDispatch();
    const { updateTicketsStatus, teamAssignments } = useSelector(state => state.userAssignmentSlice);
    const loading = updateTicketsStatus === 'loading';

    const handleUserAssignment = async () => {
        const assignedTickets = tickets.flatMap(ticket => {
            const userAssigned = teamUserAssignments[ticket.teamId];
            if (userAssigned) {
                return [{
                    ...ticket,
                    assigneeUser: userAssigned,
                }];
            }
            return [];
        });

        try {
            // Dispatch updateTickets and wait for it to finish
            await dispatch(updateTickets(assignedTickets)).unwrap();

            // If successful, show success message
            dispatch(showMessage({
                message: "Τα ticket ανατέθηκαν με επιτυχία",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
            handleNoTicketsFound(user.id , !user.isEnabled);
        } catch (error) {
            // If there's an error, show error message
            dispatch(showMessage({
                message: "Κάποιο πρόβλημα συνέβη με τις αναθέσεις των tickets",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
        }
    };


    const areAllTeamsAssigned = () => {
        if (teamUserAssignments.length === 0) {
            return false;
        }
        const teamsWithTickets = teamAssignments.filter(assignment => assignment.tickets.length > 0);
        const teamIdsWithTickets = new Set(teamsWithTickets.map(assignment => assignment.teamId));

        return [...teamIdsWithTickets].every(teamId => teamUserAssignments.hasOwnProperty(teamId) && teamUserAssignments[teamId] != null);
    };


    const handleClose = () => {
        setForceRemountKey(prevKey => prevKey + 1);
        setCurrentStep(1);
        dispatch(resetUserAssignmentSlice())
        onClose();
    };

    const handleNoTicketsFound = async (userId, isEnabled) => {
        await onToggle(userId, !user.isEnabled);
        handleClose();
    };

    const handleConfirm = () => setCurrentStep(2);
    const proceedToTeamSearch = () => setCurrentStep(3);
    const goBack = () => setCurrentStep(currentStep - 1);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                style: {
                    width: '60vw',
                    height: '60vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                },
            }}
        >
            <DialogTitle>Αλλαγή Κατάστασης Χρήστη</DialogTitle>
            <DialogContent dividers>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {currentStep === 1 && <ConfirmToggleStep key={forceRemountKey} user={user} onConfirm={handleConfirm} />}
                        {currentStep === 2 && <TicketSearchStep key={forceRemountKey} user={user} teamId={null} onNoTicketsFound={handleNoTicketsFound} />}
                        {currentStep === 3 && (
                            <TeamSearchActiveWizardStep
                                key={forceRemountKey}
                                selectedUser={user}
                                selectedUserRoleID={user.roleCollection?.[0]?.id}
                                onNoTicketsFound={handleNoTicketsFound}
                            />
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                {!loading && (
                    <>
                        {currentStep > 1 && <Button onClick={goBack} color="primary" disabled={loading}>Πίσω</Button>}
                        {currentStep === 1 && (
                            <>
                                <Button onClick={handleConfirm} color="primary" disabled={loading}>Επιβεβαίωση</Button>
                                <Button onClick={handleClose} color="error">Κλείσιμο</Button>
                            </>
                        )}
                        {currentStep === 2 && (
                            <>
                                <Button onClick={proceedToTeamSearch} color="primary" disabled={loading}>Επιβεβαίωση</Button>
                                <Button onClick={handleClose} color="error">Κλείσιμο</Button>
                            </>
                        )}
                        {currentStep === 3 && (
                            <>
                                <Button onClick={handleUserAssignment} color="primary" disabled={!areAllTeamsAssigned() || loading}>Επιβεβαίωση</Button>
                                <Button onClick={handleClose} color="error" disabled={loading}>Κλείσιμο</Button>
                            </>
                        )}
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default UserToggleStatusWizard;
