import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { jwtService } from 'app/auth/jwtService';

function SignOutPage() {
  useEffect(() => {
    setTimeout(() => {
      jwtService.logout("Αποσυνδεθήκατε");
    },1000);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-w-0 h-screen" style={{ backgroundColor: '#edf0f6' }}>
      <Paper className="flex flex-col items-center justify-center rounded-2xl shadow" style={{ width: '320px', height: '320px', padding: '24px' }}>
        <img className="w-48" src={`${process.env.PUBLIC_URL}/logo/govgrthumb.png`} alt="logo" />
        <Typography className="text-2xl font-extrabold tracking-tight leading-tight text-center mt-4">
          Έχετε Αποσυνδεθεί!
        </Typography>
      </Paper>
    </div>
  );
}

export default SignOutPage;
