// store.js
import { combineReducers } from '@reduxjs/toolkit';
import userSlice, { logoutUser } from './userSlice';
import messageSlice from './messageSlice';
import dialogSlice from './dialogSlice';
import ticketFetchSlice from './ticketFetchSlice';
import ticketCreateSlice from './ticketCreateSlice';
import knowledgeBasePageSlice from './knowledgeBasePageSlice';
import resolutionsSlice from './resolutionsSlice';
import beneficiaryFetchSlice from './beneficiaryFetchSlice';
import editTicketSlice from './editTicketSlice';
import historyTicketSlice from './historyTicketSlice';
import userInfoSlice from './userInfoSlice';
import teamsSlice from './teamsSlice';
import gnosiakiCrudSlice from './gnosiakiCrudSlice';
import userAssignmentSlice from './userAssignmentSlice';
import representativeFetchSlice from "store/representaticeFetchSlice";

const appReducer = combineReducers({
    user: userSlice,
    message: messageSlice,
    dialog: dialogSlice,
    ticketFetchSlice: ticketFetchSlice ,
    ticketSlice: ticketCreateSlice,
    knowledgeBasePageSlice: knowledgeBasePageSlice,
    resolutionsSlice: resolutionsSlice,
    beneficiaryFetchSlice: beneficiaryFetchSlice,
    editTicketSlice: editTicketSlice,
    historyTicketSlice: historyTicketSlice,
    userInfoSlice: userInfoSlice,
    teamsSlice: teamsSlice ,
    gnosiakiCrudSlice: gnosiakiCrudSlice,
    userAssignmentSlice: userAssignmentSlice,
    representativeFetchSlice: representativeFetchSlice
    
});
const rootReducer = (state, action) => {
    // Check if the logoutUser action is dispatched
    if (action.type === logoutUser.type) {
      // Reset the state to undefined to trigger initial state re-creation for each reducer
      state = undefined;
    }
  
    // Pass the state and action to the combined reducer
    return appReducer(state, action);
  };
  
  export default rootReducer;

