import React, { useState } from 'react';
import CarriersListAnnouncments from "app/Announcments/Admin/CarriersListAnnouncments";
import AnnouncmentsListAdmin from "app/Announcments/Admin/AnnouncmentsListAdmin";

const CarriersAnouncmentsPage = ({refreshFlag ,refreshAnnouncements}) => {
    const [selectedCarrier, setSelectedCarrier] = useState(null);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <CarriersListAnnouncments refreshFlag={refreshFlag} refreshAnnouncements={refreshAnnouncements} selectedCarrier={selectedCarrier} setSelectedCarrier={setSelectedCarrier} />
        </div>
    );
};

export default CarriersAnouncmentsPage;
