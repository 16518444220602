import React from 'react';
import { TextField, MenuItem, CircularProgress, Box, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

function LoadingIndicator() {
  return <CircularProgress size={20} />;
}

function CategoriesDropdown({ options, value, onChange, placeholder, isLoading, backgroundColor, isMultiSelect, isDisabled, noOptionsMessage }) {
  const selectStyles = {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      paddingTop: '10px',
      paddingBottom: '10px',
      fontSize: '14px',
      backgroundColor: 'white',
    },
    '& .MuiInputLabel-outlined': {
      fontSize: '14px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
      background: backgroundColor,
      padding: '0 4px',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '14px',
    },
  };

  const menuItemStyles = {
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  };

  const valueTextStyles = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (isMultiSelect) {
      const selectedOptions = options.filter((option) => selectedValue.includes(option.value));
      onChange(selectedOptions);
    } else {
      const selectedOption = options.find((option) => option.value === selectedValue);
      onChange(selectedOption);
    }
  };

  const handleClear = () => {
    onChange(null);
  };

  if (options && options.length > 0) {
    return (
      <Box className="category-select-wrapper" position="relative">
       <TextField
  select
  label={placeholder}
  value={isMultiSelect ? (value || []).map((v) => v.value) : value ? value.value : ''}
  onChange={handleChange}
  variant="outlined"
  disabled={isDisabled}
  sx={selectStyles}
  InputProps={{
    sx: valueTextStyles,
  }}
  SelectProps={{
    multiple: isMultiSelect,
    MenuProps: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      PaperProps: {
        style: {
          maxHeight: 300,
        },
      },
    },
    defaultValue: isMultiSelect ? [] : '',
  }}
>

          {options.map((option, index) => (
            <MenuItem
              key={`${option.label}-${option.value}-${index}`}
              value={option.value}
              sx={menuItemStyles}
            >
              {option.label.length > 100 ? `${option.label.slice(0, 100)}...` : option.label}
            </MenuItem>
          ))}
        </TextField>
        {isLoading && (
          <Box
            position="absolute"
            top="50%"
            right={35}
            padding={0}
            sx={{ transform: 'translateY(-50%)' }}
          >
            <LoadingIndicator />
          </Box>
        )}
        {value && !isLoading && !isDisabled && (
          <IconButton
            size="small"
            onClick={handleClear}
            sx={{
              position: 'absolute',
              top: '50%',
              right: 35,
              transform: 'translateY(-50%)',
              padding: 0,
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  } else {
    return (
      <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center' }}>
        {noOptionsMessage}
      </Typography>
    );
  }
}

export default CategoriesDropdown;
