import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubSubThematicQnA from './SubSubThematicQnA';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import EditDeleteButtons from './EditDeleteButton';
import { fetchQnaByThematicId, fetchSubSubthematic } from 'app/Api Calls/CarriersThematicsCalls';
import { setExpandedSubSubThematic, setSelectedSubSubThematic, setSubSubThematicQnaData, setSubSubThematics, setSubThematicQnaData } from '../../../store/gnosiakiCrudSlice';

export function SubSubThematicsTable({ subThematicId, children }) {
  const dispatch = useDispatch();
  const subSubThematics = useSelector(
    (state) => state.gnosiakiCrudSlice.subSubThematicsBySubThematic[subThematicId] || []
  );
  const expandedSubSubThematic = useSelector(
    (state) => state.gnosiakiCrudSlice.expandedSubSubThematic
  );
  const subSubThematicsBySubThematic = useSelector((state) => state.gnosiakiCrudSlice.subSubThematicsBySubThematic);
  const [refreshSubSubThematics, setRefreshSubSubThematics] = useState(false);
  const refreshTrigger = useSelector(state => state.gnosiakiCrudSlice.refreshTrigger);
  const prevTriggerCount = useRef();

  useEffect(() => {
    const hasSubSubThematicsChanged = refreshTrigger.parentId === subThematicId && prevTriggerCount.current !== refreshTrigger.count;
    const shouldRefetch = hasSubSubThematicsChanged || refreshSubSubThematics || !subSubThematicsBySubThematic[subThematicId]?.length;

    if (subThematicId && shouldRefetch) {
      fetchSubSubthematic(subThematicId)
          .then(data => {
            if (data.length === 0) {
              // If no sub-subthematics, try fetching QnA data
              fetchQnaByThematicId(subThematicId).then(qnaData => {
                dispatch(setSubSubThematicQnaData({
                  parentSubSubThematicId: subThematicId,
                  subSubThematicQnaData: qnaData
                }));
              });
            } else {
              // If sub-subthematics exist, update the Redux state
              dispatch(setSubSubThematics({
                parentSubThematicId: subThematicId,
                subSubThematics: data
              }));
            }
            setRefreshSubSubThematics(false);
          })
          .catch(error => console.error("There was an error!", error));

      // Update the ref to the current count after fetching
      prevTriggerCount.current = refreshTrigger.count;
    } else if (!subThematicId) {
      // Reset the state if there is no subThematicId
      dispatch(setSubSubThematics([]));
      dispatch(setSelectedSubSubThematic(null));
    }
  }, [subThematicId, dispatch, subSubThematicsBySubThematic, refreshSubSubThematics, refreshTrigger]);



  const handleSubSubThematicClick = (subSubThematicId) => {
    dispatch(setExpandedSubSubThematic(subSubThematicId));
  };

  const handleThematicUpdated = () => {
    setRefreshSubSubThematics(true); 
  };


  return (
    <Droppable droppableId={`subSubThematics_${subThematicId}`} type="subSubThematic">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {subSubThematics.map((subSubThematic, index) => (
            <Draggable key={subSubThematic.id} draggableId={String(subSubThematic.id)} index={index}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <div
                    className="flex items-center justify-between bg-white py-2 px-4 my-2 cursor-pointer ml-40"
                    onClick={() => handleSubSubThematicClick(subSubThematic.id)}
                  >
                    {/* SubSubThematic information container */}
                    <div className="flex items-center w-full">
                      <div className="w-auto px-2">
                        Υποθέμα{" "}
                        {expandedSubSubThematic[subSubThematic.id] ? "-" : "+"}
                      </div>
                      <div className="px-2 ml-32">{subSubThematic.title}</div>
                    </div>

                    {/* Edit and Delete buttons container */}
                    <div className="flex-shrink-0">
                      <EditDeleteButtons
                        thematic={subSubThematic}
                        isCarrier={false}
                        onUpdate={handleThematicUpdated}
                      />
                    </div>
                  </div>

                  {expandedSubSubThematic[subSubThematic.id] && (
                      <SubSubThematicQnA subSubThematicId={subSubThematic.id} />
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}


export default SubSubThematicsTable;
