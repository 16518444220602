export const hasBeneficiaryDataChanged = (originalData, currentData) => {
    // Function to check if the object is empty or null
    const isEmptyOrNullObject = (obj) => !obj || Object.keys(obj).length === 0;

    // If either originalData or currentData is empty/null, return false
    if (isEmptyOrNullObject(originalData) || isEmptyOrNullObject(currentData)) {
        return false;
    }

    const isEmptyOrNull = (val) => val === "" || val === null;

    // Helper function to check if two values are different considering empty or null values
    const valueHasChanged = (val1, val2) => {
        return !isEmptyOrNull(val1) || !isEmptyOrNull(val2) ? val1 !== val2 : false;
    };

    return (
        valueHasChanged(originalData.firstName, currentData.firstName) ||
        valueHasChanged(originalData.lastName, currentData.lastName) ||
        valueHasChanged(originalData.phone, currentData.phone) ||
        valueHasChanged(originalData.mobile, currentData.mobile) ||
        valueHasChanged(originalData.email, currentData.email) ||
        valueHasChanged(originalData.address, currentData.address) ||
        valueHasChanged(originalData.afm, currentData.afm) ||
        valueHasChanged(originalData.amka, currentData.amka)
    );
};
