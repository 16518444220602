import { axiosApi } from "app/configs/axiosConfig";


export const getTicketFiles = async (ticketId) => {
    try {
        const response = await axiosApi.get(`/file/ticket/${ticketId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching ticket details:', error);
        throw error;
    }
};


export const downloadFile = async (fileId) => {
  try {
    const response = await axiosApi.get(`/file/download/${fileId}`);

    if (response.data && response.data.url) {
      // Extract the URL from the response
      const fileUrl = response.data.url;
      return fileUrl ;
    }
  } catch (error) {
    throw error;
  }
};

  











