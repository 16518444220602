import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchAllCarriers,
  fetchSubthematic,
  fetchThematicsByCarrierIdCall,
} from 'app/Api Calls/CarriersThematicsCalls';
import fetchRoles from 'app/Api Calls/Roles';
import {
  fetchAllTeams,
  fetchAvailableSourcesApplication,
  fetchGroupRoles,
  fetchTeamAssignmentMethods,
  fetchTeamGeographies,
  fetchUsersRoleBased,
} from 'app/Api Calls/TeamsRequiredApis';
import { axiosApi } from 'app/configs/axiosConfig';

export const fetchTeamGeographiesAsync = createAsyncThunk(
  'teams/fetchTeamGeographies',
  fetchTeamGeographies 
);

export const fetchTeamAssignmentMethodsAsync = createAsyncThunk(
  'teams/fetchTeamAssignmentMethods',
  fetchTeamAssignmentMethods
);

export const fetchRolesAsync = createAsyncThunk('teams/fetchRoles', fetchRoles);

export const fetchGroupRolesAsync = createAsyncThunk('teams/fetchGroupRoles', fetchGroupRoles);

export const fetchAvailableSourcesApplicationAsync = createAsyncThunk(
  'teams/fetchAvailableSourcesApplication',
  fetchAvailableSourcesApplication
);

export const fetchAllTeamsAsync = createAsyncThunk('teams/fetchAllTeams', fetchAllTeams);

export const fetchUsersRoleBasedAsync = createAsyncThunk(
  'teams/fetchUsers',
  async ({ page, size, requestBody }, { rejectWithValue }) => {
    try {
      const response = await fetchUsersRoleBased(page, size, requestBody);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchUsersForCreateAsync = createAsyncThunk(
  'teams/fetchUsersForCreate',
  async ({ page, size, requestBody }, { rejectWithValue }) => {
    try {
      const response = await fetchUsersRoleBased(page, size, requestBody);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const fetchCarriers = createAsyncThunk(
  'teams/fetchCarriers',
  async (setIndex, { rejectWithValue }) => {
    try {
      const carriers = await fetchAllCarriers();
      return { setIndex, carriers };
    } catch (error) {
      console.error(error);
      return rejectWithValue([]);
    }
  }
);

export const fetchThematicsByCarrierId = createAsyncThunk(
  'teams/fetchThematicsByCarrierId',
  async (payload, { rejectWithValue }) => {
    try {
      const { carrierId, setIndex } = payload;
      const thematics = await fetchThematicsByCarrierIdCall(carrierId);
      return { setIndex, thematics };
    } catch (error) {
      console.error(error);
      return rejectWithValue([]);
    }
  }
);

export const fetchSubthematicsByParentId = createAsyncThunk(
  'teams/fetchSubthematicsByParentId',
  async (parentId, { getState, rejectWithValue }) => {
    try {
      // Getting the carrierSets from the state
      const carrierSets = getState().teamsSlice.carrierSets;
      
      // Checking if the subthematics with the specific parentId have already been fetched
      const alreadyFetched = carrierSets.some(carrierSet => 
        carrierSet.subthematics.some(subthematic => 
          subthematic.parentId === parentId
        )
      );

      // If subthematics with the specific parentId have already been fetched, skipping the API call
      if (alreadyFetched) {
        return { parentId, subthematics: [] };
      }

      // Fetching subthematics from the API
      const subthematics = await fetchSubthematic(parentId);
      
      // Returning the fetched subthematics to be handled by fulfilled reducer
      return { parentId, subthematics };
    } catch (error) {
      // Handling errors like network issues, or API errors
      return rejectWithValue(error);
    }
  }
);





export const fetchUsersWithId = createAsyncThunk('teams/fetchUsersWithId', async ({ page, size, id }) => {
  const requestBody = { id };
  const response = await axiosApi.post(`/user/search/${page}/${size}`, requestBody);
  return response.data.elements;
});

const teamsSlice = createSlice({
  name: 'teams',
  initialState: {
    teamList: [],
    teamGeographies: [],
    teamAssignmentMethods: [],
    roles: [],
    users: [],
    usersForCreate: [],
    groupRoles: [],
    availableSourcesApplication: [],
    page: 0,
    size: 100,
    carrierSets: [
      { carriers: [], thematics: [] ,subthematics:[] },
    ],
    selectedCarrierSets: [
      { selectedCarrier: null, selectedThematics: null,subthematics:[] },
    ],
    availableCarriers: [],
    selectedGeography: null,
    selectedGroupRole: null,
    selectedCarrier: null,
    teamsPage: 0,
    inputValue: "",
    fetchedUsers: [],
    selectedRole: "",
    allUsersRoles: [],
    selectedUserId: null,
    selectedTeamId: null,
    teamsToSearch: [], // for searching with team name
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setTeamsPage: (state, action) => {
      state.teamsPage = action.payload;
    },
    setSelectedTeamId(state, action) {
      state.selectedTeamId = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    resetUsersForCreate: (state) => {
      state.usersForCreate = []; 
    },
    setSelectedCarrierSets: (state, action) => {
      state.selectedCarrierSets = action.payload;
    },
    addNewestSet: (state) => {
      state.carrierSets.push({ carriers: [], thematics: [],subthematics:[] });
    },
    resetUsers: (state) => {
      state.users = []; 
    },
    setSelectedGeography: (state, action) => {
      state.selectedGeography = action.payload;
    },
    setTeamsToSearch(state, action) {
      state.teamsToSearch = action.payload;
    },
    setSelectedGroupRole: (state, action) => {
      state.selectedGroupRole = action.payload;
    },
    setSelectedCarrier: (state, action) => {
      state.selectedCarrier = action.payload;
    },
    setInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
    setFetchedUsers: (state, action) => {
      state.fetchedUsers = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setAllUserRoles: (state, action) => {
      state.allUsersRoles = action.payload;
    },
    setSelectedUserId: (state, action) => {
      state.selectedUserId = action.payload;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamGeographiesAsync.fulfilled, (state, action) => {
        state.teamGeographies = action.payload;
      })
      .addCase(fetchTeamAssignmentMethodsAsync.fulfilled, (state, action) => {
        state.teamAssignmentMethods = action.payload;
      })
      .addCase(fetchRolesAsync.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(fetchGroupRolesAsync.fulfilled, (state, action) => {
        state.groupRoles = action.payload;
      })
      .addCase(fetchAvailableSourcesApplicationAsync.fulfilled, (state, action) => {
        state.availableSourcesApplication = action.payload;
      })
      .addCase(fetchAllTeamsAsync.fulfilled, (state, action) => {
        state.teamList = action.payload;
      })
      .addCase(fetchUsersRoleBasedAsync.fulfilled, (state, action) => {
        state.users = action.payload.elements;
      })
      .addCase(fetchUsersForCreateAsync.fulfilled, (state, action) => {
        state.usersForCreate = [...state.usersForCreate, ...action.payload.elements];
        state.usersForCreate.sort((a, b) => a.lastName.localeCompare(b.lastName));
      })
      
      
      .addCase(fetchThematicsByCarrierId.pending, (state) => {
        state.isLoadingThematics = true;
      })
      .addCase(fetchThematicsByCarrierId.fulfilled, (state, action) => {
        const { setIndex, thematics } = action.payload;
        state.carrierSets[setIndex].thematics = thematics;
      })
      .addCase(fetchSubthematicsByParentId.fulfilled, (state, action) => {
        const { parentId, subthematics } = action.payload;
        
        state.carrierSets.forEach(carrierSet => {
      
          const existingSubthematics = carrierSet.subthematics.some(sub => sub.parentId === parentId);
      
          if (!existingSubthematics) {
            carrierSet.subthematics.push(...subthematics);
          }
        });
      })

      .addCase(fetchCarriers.fulfilled, (state, action) => {
        const { setIndex, carriers } = action.payload;
        state.carrierSets[setIndex].carriers = carriers;
      });
  },
});

export const {setTeamsToSearch,setSelectedTeamId,resetUsersForCreate, setPage, setSize, setSelectedCarrierSets ,addNewestSet , resetUsers, setUsers,setSelectedGeography, setSelectedGroupRole, setSelectedCarrier,setTeamsPage,setInputValue,
  setFetchedUsers,
  setSelectedRole,
  setAllUserRoles,setSelectedUserId } = teamsSlice.actions;

export default teamsSlice.reducer;
