import { axiosApi } from "app/configs/axiosConfig";

export const createThematic = async ({ title, isActive , externalId, parentId, carrierId }) => {
  return await axiosApi.post('/thematic', { title, isActive, externalId, parentId, carrierId });
}

export const createCarrier = async ({ title, isActive , customId }) => {
  return await axiosApi.post('/carrier', { title, isActive , customId });
}


export const createQuestionNAnswer = async ({ title, question, answer, isActive, thematicId, isPublished }) => {
  return await axiosApi.post('/questionNAnswer', { title, question, answer, isActive, thematicId , isPublished});
}

export const getAllThematics = async () => {
  try {
    const response = await axiosApi.get('/thematic/all');
    return response.data;
  } catch (error) {
    console.log('Failed to fetch thematics:', error);
    throw error;
  }
};