import React, {useEffect, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    setSelectedGeography,
    setSelectedGroupRole,
    setSelectedCarrier,
    setTeamsPage,
    setInputValue,
    setSelectedUserId,
    setTeamsToSearch, setSelectedTeamId
} from 'store/teamsSlice';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    InputAdornment,
    IconButton,
    OutlinedInput,
    Button,
    Autocomplete, TextField, CircularProgress
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import UserTeamsFilter from './UserTeamsFilter';
import {fetchAllTeams} from "app/Api Calls/TeamsRequiredApis";
import settingsConfig from "app/configs/settingConfig";

// const useStyles = makeStyles({
//     selectStyle: {
//         backgroundColor: "#fff",
//         height: "12px"
//     },
//     containerMargin: {
//         marginBottom: "24px"
//     }
// });

function TeamFilters() {
    const dispatch = useDispatch();
    const teamGeographies = useSelector(state => state.teamsSlice.teamGeographies);
    const groupRoles = useSelector(state => state.teamsSlice.groupRoles);
    const carriers = useSelector(state => state.teamsSlice.carrierSets[0].carriers);
    const sortedCarriers = [...carriers].sort((a, b) => a.title.localeCompare(b.title));
    const selectedGeographyRedux = useSelector(state => state.teamsSlice.selectedGeography);
    const selectedGroupRoleRedux = useSelector(state => state.teamsSlice.selectedGroupRole);
    const selectedCarrierRedux = useSelector(state => state.teamsSlice.selectedCarrier);
    const teamsToSearch = useSelector(state => state.teamsSlice.teamsToSearch);
    const selectedTeamId = useSelector(state => state.teamsSlice.selectedTeamId);
    const [loading, setLoading] = useState(false);
    const sortedGeographies = teamGeographies
        .map(geo => {
            // Find the corresponding carrier for the geography
            const carrier = sortedCarriers.find(carrier => carrier.id === geo.carrierId);
            return {
                ...geo,
                carrierTitle: carrier ? carrier.title : ''
            };
        })
        // Sort geographies first by carrier title, then by geography name
        .sort((a, b) => {
            const carrierCompare = a.carrierTitle.localeCompare(b.carrierTitle);
            if (carrierCompare !== 0) return carrierCompare;
            return a.name.localeCompare(b.name);
        });

    useEffect(() => {
        setLoading(true);
        fetchAllTeams().then(teams => {
            dispatch(setTeamsToSearch(teams));
            setLoading(false);
        });
    }, [dispatch]);

    const teamsWithGroupName = useMemo(() => {
        const groupMapping = {};

        teamsToSearch.forEach(team => {
            const GroupRoleId = team.groupRoleId;
            const groupName = settingsConfig.groupRoles.find(role => role.id === GroupRoleId)?.name.trim() || 'Unknown Group';

            if (!groupMapping[groupName]) {
                groupMapping[groupName] = [];
            }

            groupMapping[groupName].push({
                ...team,
                groupName,
            });
        });
        return Object.values(groupMapping).flat();
    }, [teamsToSearch]);


    const handleFilterChange = (action, value) => {
        dispatch(setTeamsPage(0));
        dispatch(action(value));
    }


    const clearAllFilters = () => {
        dispatch(setTeamsPage(0));
        dispatch(setSelectedGeography(null));
        dispatch(setSelectedGroupRole(null));
        dispatch(setSelectedCarrier(null));
        dispatch(setSelectedUserId(null));
        dispatch(setInputValue(""));
        dispatch(setSelectedTeamId(null));
    };

    const handleChange = (event, newValue) => {
        dispatch(setTeamsPage(0));
        dispatch(setSelectedGeography(null));
        dispatch(setSelectedGroupRole(null));
        dispatch(setSelectedCarrier(null));
        dispatch(setSelectedUserId(null));
        dispatch(setInputValue(""));
        dispatch(setSelectedTeamId(newValue ? newValue.id : null));
    };

    return (
        <Grid container spacing={6} className={`filter-container`}>
            {/* Geography Filter */}
            {/* Carrier Filter and Name Filter */}
            <Grid item xs={3}>
                <Autocomplete
                    disablePortal
                    id="teams-autocomplete"
                    options={teamsWithGroupName}
                    groupBy={(option) => option.groupName}
                    getOptionLabel={(option) => option.name}
                    value={teamsWithGroupName.find(team => team?.id === selectedTeamId) || null}
                    onChange={handleChange}
                    sx={{
                        marginBottom: "16px",
                        "& .MuiAutocomplete-inputRoot": {
                            bgcolor: "white",
                        },
                        "& .MuiOutlinedInput-root": {
                            bgcolor: "white",
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Όνομα Ομάδας"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="carrier-select-outlined">Φορέας</InputLabel>
                    <Select
                        sx={{backgroundColor: "white"}}
                        disabled={selectedTeamId !== null}
                        value={selectedCarrierRedux || ""}
                        onChange={e => handleFilterChange(setSelectedCarrier, e.target.value)}
                        label="Carrier"

                        input={
                            <OutlinedInput
                                id="carrier-select-outlined"
                                endAdornment={
                                    selectedCarrierRedux && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    dispatch(setSelectedCarrier(null));
                                                }}
                                                style={{marginRight: 8}}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            />
                        }
                    >
                        {carriers.map(carrier => (
                            <MenuItem key={carrier.id} value={carrier.id}>{carrier.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            {/* Group Role Filter */}
            <Grid item xs={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="group-role-select-outlined">Ρόλος Ομάδας</InputLabel>
                    <Select
                        disabled={selectedTeamId !== null}
                        sx={{backgroundColor: "white"}}
                        value={selectedGroupRoleRedux || ""}
                        onChange={e => handleFilterChange(setSelectedGroupRole, e.target.value)}
                        label="Group Role"
                        input={
                            <OutlinedInput
                                id="group-role-select-outlined"
                                endAdornment={
                                    selectedGroupRoleRedux && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    dispatch(setSelectedGroupRole(null));
                                                }}
                                                style={{marginRight: 8}}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            />
                        }
                    >
                        {groupRoles.map(role => (
                            <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>


            <Grid item xs={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="geography-select-outlined">Γεωγραφία</InputLabel>
                    <Select
                        disabled={selectedTeamId !== null}
                        sx={{backgroundColor: "white"}}
                        value={selectedGeographyRedux || ""}
                        onChange={e => handleFilterChange(setSelectedGeography, e.target.value)}
                        label="Geography"
                        input={
                            <OutlinedInput
                                id="geography-select-outlined"
                                endAdornment={
                                    selectedGeographyRedux && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    dispatch(setSelectedGeography(null));
                                                }}
                                                style={{marginRight: 8}}>
                                                <ClearIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }
                            />
                        }
                    >
                        {sortedGeographies.map(geo => (
                            <MenuItem key={geo.id} value={geo.id}>
                                {geo.name}{geo.carrierTitle && ` (${geo.carrierTitle})`}
                            </MenuItem>
                        ))}
                    </Select>

                </FormControl>
            </Grid>

            <Grid item xs={3}>
                <UserTeamsFilter/>
            </Grid>
            {/* Clear All Filters Button */}
            <Grid item>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: "#003476 !important",
                        marginBottom: "10px",
                        color: "#fff",
                    }}
                    onClick={() => {
                        clearAllFilters();
                    }}
                    fullWidth
                >
                    Καθαρισμός Φίλτρων
                </Button>
            </Grid>
        </Grid>
    );

}

export default TeamFilters;
