import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
    name: 'dialog',
    initialState: {
      isOpen: false,
      options: {
        children: 'Hi',
      },
    },
    reducers: {
      openDialog: (state, action) => {
        state.isOpen = true;
        state.options = action.payload;
      },
      closeDialog: (state) => {
        state.isOpen = false;
      },
    },
  });

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectDialogState = (state) => state.dialog.isOpen;
export const selectDialogOptions = (state) => state.dialog.options;

export default dialogSlice.reducer;
