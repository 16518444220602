import { axiosApi } from "app/configs/axiosConfig";

const getAllRoles = async (id) => {
    try {
      const response = await axiosApi.get(`domain/roles`);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.error('Error fetching roles:', error);
      throw error;
    }
  };

  export default getAllRoles;