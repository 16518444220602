import React, { useState, useEffect } from 'react';
import {Box, Typography} from '@mui/material';
import CarrierList from './CarrierList';
import { fetchAllCarriers } from "app/Api Calls/CarriersThematicsCalls";
import axios from 'axios';
import AnnouncementsDisplay from "app/Announcments/AnnouncementsDisplay";
import {getAnnouncementsByCarrierId} from "app/Api Calls/AnnouncmentsCalls";

function AnnouncementsPage() {
    const [carriers, setCarriers] = useState([]);
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchAllCarriers().then(setCarriers).catch(error => console.error('Failed to load carriers:', error));
    }, []);

    useEffect(() => {
        if (selectedCarrier) {
            setLoading(true);
            getAnnouncementsByCarrierId(selectedCarrier.id)
                .then(response => {
                    setAnnouncements(response);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Failed to fetch announcements:', error);
                    setLoading(false);
                });
        }
    }, [selectedCarrier]);



    return (
        <Box sx={{ display: 'flex', m: 0, p: 0, height: '90vh' }}>
            <CarrierList
                carriers={carriers}
                selectedCarrierId={selectedCarrier?.id}
                onCarrierSelect={setSelectedCarrier}
            />
            <Box sx={{ width: '70%', overflowY: 'auto', bgcolor: '#ffffff', margin: 0, height: '90vh' }}>
                {!selectedCarrier && (
                    <Typography variant="h6" sx={{ m: 2 }}>Ανακοινώσεις</Typography>
                )}
                {!selectedCarrier && (
                    <Typography variant="subtitle1" sx={{ m: 2 }}>Επιλέξτε Φορέα</Typography>
                )}
                {!loading && selectedCarrier && (
                    <AnnouncementsDisplay announcements={announcements} selectedCarrier={selectedCarrier} />
                )}
            </Box>
        </Box>
    );
}

export default AnnouncementsPage;
