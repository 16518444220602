import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import Pagination from '@mui/material/Pagination';

const MaterialPagination = forwardRef(({ currentPage, totalPages, setPageAction }, ref) => {
  const dispatch = useDispatch();

  const handlePageChange = (event, value) => {
    dispatch(setPageAction(value - 1));
    if (ref?.current) {
      const offsetTop = ref.current.offsetTop;
      window.scrollTo({
        top: offsetTop - 30,
        behavior: "smooth"
      });
    }
  };

  return (
    <div className="flex justify-center">
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          color="primary"
          page={currentPage + 1}
          onChange={handlePageChange}
          siblingCount={2}
          boundaryCount={1}
          className="flex justify-center self-end"
        />
      )}
    </div>
  );
});

export default MaterialPagination;
