import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { fetchUsersWithId } from "app/Api Calls/TeamsRequiredApis";
import fetchRoles from "app/Api Calls/Roles";
import { setAllUserRoles, setFetchedUsers, setInputValue, setSelectedRole, setSelectedUserId } from "store/teamsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function UserTeamsFilter() {
    const dispatch = useDispatch();
    const inputValue = useSelector((state) => state.teamsSlice.inputValue);
    const fetchedUsers = useSelector((state) => state.teamsSlice.fetchedUsers);
    const selectedRole = useSelector((state) => state.teamsSlice.selectedRole);
    const allUsersRoles = useSelector((state) => state.teamsSlice.allUsersRoles);
    const selectedTeamId = useSelector(state => state.teamsSlice.selectedTeamId);

    useEffect(() => {
    const fetchAllRoles = async () => {
      try {
        const roles = await fetchRoles();
        dispatch(setAllUserRoles(roles));
      } catch (error) {
        console.error("Failed to fetch roles:", error);
      }
    };

    fetchAllRoles();
  }, []);

  useEffect(() => {
    if (inputValue.length > 2) {
      const fetchAllUsers = async () => {
        try {
          const response = await fetchUsersWithId(0, 100, {
            lastName: inputValue,
          });
          const adaptedUsers = response.elements.map((user) => ({
            ...user,
            roleName: user.roleCollection[0].roleName,
          }));
          dispatch(setFetchedUsers(adaptedUsers));
        } catch (error) {
          console.error("Failed to fetch users:", error);
        }
      };
      fetchAllUsers();
    }
  }, [inputValue]);

  const handleUserChange = (_, value) => {
    dispatch(setSelectedUserId(value ? value.id : null));
  };

  const handleInputChange = (_, newInputValue) => {
    dispatch(setInputValue(newInputValue));  };

  function formatRoleName(roleName) {
    const parts = roleName.replace("ROLE_", "").split("_");
    return parts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(" ");
  }

  function groupAndSortUsers(users) {
    return users
      .map((user) => ({
        ...user,
        roleName: user.roleCollection ? user.roleCollection[0].roleName : user.roleName,
      }))
      .sort((a, b) =>
        a.roleName.localeCompare(b.roleName) || a.firstName.localeCompare(b.firstName),
      );
  }

  const sortedUsers = groupAndSortUsers(fetchedUsers);
  const filteredUsers = selectedRole
    ? sortedUsers.filter((user) => user.roleName === selectedRole)
    : sortedUsers;

    return (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <FormControl variant="outlined" style={{ width: "20%", marginRight: "0px", backgroundColor: "white" }}>
            <InputLabel htmlFor="role-select">Ρόλος</InputLabel>
            <Select
                disabled={selectedTeamId !== null}
              label="Ρόλος"
              value={selectedRole}
              onChange={(e) => dispatch(setSelectedRole(e.target.value))}
              inputProps={{
                name: "role",
                id: "role-select",
                style: { backgroundColor: "white" }
              }}
            >
              <MenuItem value="">
                <em>Όλοι</em>
              </MenuItem>
              {allUsersRoles.map((role) => (
                <MenuItem value={role.roleName} key={role.id} style={{ backgroundColor: "white" }}>
                  {formatRoleName(role.roleName)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Autocomplete
              disabled={selectedTeamId !== null}
            sx={{ width: "80%", '& .MuiAutocomplete-inputRoot': { backgroundColor: 'white' }}}
            id="user-autocomplete"
            options={filteredUsers}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
            groupBy={(option) => formatRoleName(option.roleName)}
            renderInput={(params) => (
              <TextField {...params} label="Χρήστης" variant="outlined"   sx={{
                width: "80%",
                '& .MuiAutocomplete-inputRoot': {
                  backgroundColor: 'white',
                
                }
              }} InputProps={{ ...params.InputProps, style: { backgroundColor: 'white' } }} />
            )}
            inputValue={inputValue}
            freeSolo
            onInputChange={handleInputChange}
            noOptionsText="Δεν βρέθηκε χρήστης"
            onChange={handleUserChange}
          />
        </div>
      );
      
}

export default UserTeamsFilter;
