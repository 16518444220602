import { axiosApi } from "app/configs/axiosConfig";

const sortData = (data) => data.sort((a, b) => a.title.localeCompare(b.title, 'el'));


export const fetchCarrierByThematicId = async (carrierId) => {
  try {
    const response = await axiosApi.get(`/carrier/${carrierId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching carrier:', error);
    throw error;
  }
};

export const updateThematic = async (id, title, isActive, carrierId, externalId, parentId,isPublished) => {
  try {

    const payload = { id: id, title: title, isActive: isActive, carrierId: carrierId, externalId: externalId, parentId: parentId, isPublished: isPublished }
    const response = await axiosApi.put('/thematic', payload

    );
  } catch (error) {
    console.error('Error updating thematic:', error);
    throw error;
  }
};

export const fetchSubthematic = async (parentId) => {
  try {
    const response = await axiosApi.get(`/thematic/byParentId/${parentId}`);
    return sortData(response.data);
  } catch (error) {
    console.error('Error fetching subthematic:', error);
    throw error;
  }
};

export const fetchSubSubthematic = async (parentId) => {
  try {
    const response = await axiosApi.get(`/thematic/byParentId/${parentId}`);
    return sortData(response.data);
  } catch (error) {
    console.error('Error fetching subsubthematic:', error);
    throw error;
  }
};

export const fetchQnaByThematicId = async (id) => {
  try {
    const response = await axiosApi.get(`/questionNAnswer/getByThematic/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching questions and answers:', error);
    throw error;
  }
};

export const fetchThematicsByCarrierIdCall = async (carrierId) => {
  try {
    const response = await axiosApi.get(`/thematic/byCarrierId/${carrierId}`);
    return sortData(response.data).filter((thematic) => thematic.parentId === null);
  } catch (error) {
    console.error('Error fetching thematics:', error);
    throw error;
  }
};

export const fetchAllCarriers = async () => {
  try {
    const response = await axiosApi.get('/carrier/all');
    return sortData(response.data);
  } catch (error) {
    console.error('Error fetching all carriers:', error);
    throw error;
  }
};

export const getAllQuestionsAndAnswers = async () => {
  try {
    const response = await axiosApi.get('/questionNAnswer');
    return response.data;
  } catch (error) {
    console.error('Error fetching all questions and answers:', error);
    throw error;
  }
};

