import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpandedSubThematic,
  setSelectedSubThematic,
  setSubthematics,
} from "../../../store/gnosiakiCrudSlice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import SubSubThematicsTable from "./SubSubThematicsTable";
import SubThematicQnA from "./SubThematicQna";
import EditDeleteButtons from "./EditDeleteButton";
import { fetchSubthematic } from "app/Api Calls/CarriersThematicsCalls";

export function SubThematicsTable({
  thematicId,
  children,
}) {
  const dispatch = useDispatch();
  const {
    subthematicsByThematic,
    expandedSubThematic,
    subSubThematicsBySubThematic,
  } = useSelector((state) => state.gnosiakiCrudSlice);
  const subthematics = subthematicsByThematic[thematicId] || [];
  const [refreshThematics, setRefreshThematics] = useState(false);
  const triggerRefetch = useSelector(state => state.gnosiakiCrudSlice.refreshTrigger);
  const prevTriggerCount = useRef();

  useEffect(() => {
    const hasSubthematicsChanged = triggerRefetch.parentId === thematicId && prevTriggerCount.current !== triggerRefetch.count;
    const shouldRefetch = (hasSubthematicsChanged || refreshThematics || !subthematicsByThematic[thematicId]?.length);

    if (thematicId && shouldRefetch) {
      fetchSubthematic(thematicId)
          .then(data => {
            dispatch(setSubthematics({
              parentThematicId: thematicId,
              subthematics: data,
            }));
            setRefreshThematics(false); // Resetting the manual refresh flag
          })
          .catch(error => console.error("There was an error!", error));
      // Update the ref to the current count after fetching to avoid unnecessary refetches
      prevTriggerCount.current = triggerRefetch.count;
    } else if (!thematicId) {
      dispatch(setSubthematics([]));
      dispatch(setSelectedSubThematic(null));
    }
  }, [thematicId, dispatch, subthematicsByThematic, refreshThematics, triggerRefetch]);



  const handleSubThematicClick = (subThematicId) => {
    dispatch(setSelectedSubThematic(subThematicId));
    dispatch(setExpandedSubThematic(subThematicId));
  };

  const handleThematicUpdated = () => {
    setRefreshThematics(true);
  };

  return (
    <Droppable droppableId={`subThematics_${thematicId}`} type="subThematic">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {subthematics.map((subThematic, index) => (
            <Draggable
              key={subThematic.id}
              draggableId={String(subThematic.id)}
              index={index}
            >
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <div
                    className="flex items-center justify-between bg-white py-2 px-4 my-2 cursor-pointer ml-32"
                    onClick={() => handleSubThematicClick(subThematic.id)}
                  >
                    {/* Container for SubThematic information with click handler */}
                    <div
                      className="flex items-center w-full"
                    >
                      <div className="w-auto px-2">
                        <div className="flex items-center">
                          Θέμα
                          {expandedSubThematic[subThematic.id] === true && subthematics.length > 0 ? "-" : "+"}
                        </div>
                      </div>
                      <div className="px-2 ml-32">{subThematic.title}</div>
                    </div>

                    {/* Separate container for Edit and Delete buttons */}
                    <div
                      className="flex-shrink-0"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <EditDeleteButtons
                        thematic={subThematic}
                        isCarrier={false}
                        onUpdate={handleThematicUpdated}
                      />
                    </div>
                  </div>



                  {expandedSubThematic[subThematic.id] === true &&
                    (subSubThematicsBySubThematic[subThematic.id] === null ? (
                      <SubThematicQnA subThematicId={subThematic.id} />
                    ) : (
                      <SubSubThematicsTable
                        subThematicId={subThematic.id}
                      />
                    ))}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default SubThematicsTable;
