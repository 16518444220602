import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import {updateBeneficiaryData} from "store/userInfoSlice";


function BeneficiaryDataChangedModal({ open, handleClose, originalBeneficiaryData, currentBeneficiaryData, handleSubmitWithBeneficiaryUpdate, showError }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const excludedFields = ['adt', 'ama', 'id', 'ada', 'amoga'];

  const fieldLabels = {
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    phone: 'Τηλέφωνο',
    mobile: 'Κινητό',
    email: 'Email',
    address: 'Διεύθυνση',
    afm: 'ΑΦΜ',
    amka: 'ΑΜΚΑ',
  };

  const findDifferences = () => {
    const differences = [];

    const fieldsToCheck = Object.keys(fieldLabels);

    for (let key of fieldsToCheck) {
      if (!excludedFields.includes(key) && originalBeneficiaryData[key] !== currentBeneficiaryData[key]) {
        differences.push({
          field: key,
          originalValue: originalBeneficiaryData[key],
          currentValue: currentBeneficiaryData[key],
        });
      }
    }

    return differences;
  };

  const differences = findDifferences();

  const handleSubmit = () => {
    handleSubmitWithBeneficiaryUpdate();
  };

  const handleCloseModal = () => {
    if (handleClose) {
      handleClose();
      dispatch(updateBeneficiaryData(originalBeneficiaryData));
    }
  };


  const minHeight = '300px';

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    sx={{
      '& .MuiDialog-paper': {
        width: '600px',
        padding: '14px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      },
      '& .MuiTypography-root': {
        fontFamily: 'Arial',
        fontSize: '14px'
      }
    }}
  >
      <DialogContent
        style={{
          minHeight,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <h2 style={{ textAlign: 'center' }}> <strong>Τα δεδομένα του δικαιούχου έχουν αλλάξει</strong></h2>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <table className="w-full mt-4 mb-0.5">
                <thead>
                  <tr>
                    <th>Πεδίο</th>
                    <th>Προηγούμενη Τιμή</th>
                    <th>Νέα Τιμή</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(originalBeneficiaryData).map((field, index) => (
                    !excludedFields.includes(field) && (
                      <tr key={index} style={{ textAlign: 'center' }}>
                        <td>{fieldLabels[field]}</td>
                        <td>{originalBeneficiaryData[field]}</td>
                        <td style={{ color: differences.some(difference => difference.field === field) ? '#ff0000' : 'inherit', padding: '0.25rem' }}>
                          {currentBeneficiaryData[field]}
                        </td>
                      </tr>
                    )
                  ))}
                </tbody>
              </table>


              <p style={{ textAlign: 'center' }}>
                <strong style={{ color: '#FF0000' }}>ΠΡΟΣΟΧΗ!</strong> Εάν πατήσετε ναι θα αλλάξουν τα στοιχεία του Δικαιούχου και στα υπόλοιπα ticket εάν υπάρχουν. Θέλετε να αποθηκεύσετε τις αλλαγές;
              </p>
            </div>
          </>
        )}
      </DialogContent>

      {!isLoading && (
    <DialogActions style={{ justifyContent: 'flex-end !important' }}> 
      
      <Button 
        onClick={handleSubmit} 
        style={{ backgroundColor: '#003476', color: 'white', marginLeft: '10px' }} 
        disabled={isLoading}
      >
        Ναι
      </Button>
      <Button 
        onClick={handleCloseModal} 
        style={{ backgroundColor: '#003476', color: 'white' }} 
        disabled={isLoading} 
        autoFocus
      >
        Όχι
      </Button>
    </DialogActions>
  )}

    </Dialog>
  );
}

export default BeneficiaryDataChangedModal;
