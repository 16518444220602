import { axiosApi } from "app/configs/axiosConfig";

export async function getSpecificUser(id) {
    try {
        const response = await axiosApi.get(`/user/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch user:', error);
        return null;
    }
}
