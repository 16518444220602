import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Paper,
    Button, CircularProgress, Box,
} from "@mui/material";
import EditBeneficiaryModal from "./EditBeneficiaryModal";

const BeneficiariesTable = ({ beneficiaries, refreshData, loading }) => {
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = (beneficiary) => {
        setSelectedBeneficiary(beneficiary);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedBeneficiary(null);
    };

    return (
        <Paper elevation={3}>
            <TableContainer sx={{ maxHeight: "75vh" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Όνομα</TableCell>
                            <TableCell>Επώνυμο</TableCell>
                            <TableCell>ΑΦΜ</TableCell>
                            <TableCell>ΑΜΚΑ</TableCell>
                            <TableCell>Τηλέφωνο</TableCell>
                            <TableCell>Κινητό</TableCell>
                            <TableCell>Ενέργειες</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={7} align="center">
                                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                        <CircularProgress />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : beneficiaries.length > 0 ? (
                            beneficiaries.map((beneficiary) => (
                                <TableRow key={beneficiary.id}>
                                    <TableCell>{beneficiary.firstName}</TableCell>
                                    <TableCell>{beneficiary.lastName}</TableCell>
                                    <TableCell>{beneficiary.afm}</TableCell>
                                    <TableCell>{beneficiary.amka}</TableCell>
                                    <TableCell>{beneficiary.phone}</TableCell>
                                    <TableCell>{beneficiary.mobile}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpenModal(beneficiary)}
                                            sx={{ backgroundColor: "#f97316 !important" }}
                                        >
                                            Επεξεργασία
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={7} align="center">
                                    Δεν υπάρχουν δεδομένα
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedBeneficiary && (
                <EditBeneficiaryModal
                    open={modalOpen}
                    handleClose={handleCloseModal}
                    beneficiary={selectedBeneficiary}
                    refreshData={refreshData}
                />
            )}
        </Paper>
    );
};

export default BeneficiariesTable;
