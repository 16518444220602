import { axiosApi } from "app/configs/axiosConfig";

const fetchTicketStatuses = async () => {
  try {
    const url = `/domain/ticketStatuses`;
    const response = await axiosApi.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching ticket statuses:", error);
    throw error; 
  }
};

export default fetchTicketStatuses;
