import { axiosApi } from "app/configs/axiosConfig";

const getAllGeographies = async () => {
  try {
    const response = await axiosApi.get('/domain/teamGeographies');
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default getAllGeographies;
