import { axiosApi } from "app/configs/axiosConfig";

const createRepresentative = async (representative) => {
  try {
    // Convert all empty strings in the representative object to null
    const sanitizedRepresentative = sanitizeRepresentative(representative);

    const response = await axiosApi.post('/representative', sanitizedRepresentative);
    return response.data.id;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const sanitizeRepresentative = (representative) => {
  // Create a copy of the representative object to avoid mutating the original
  const sanitized = { ...representative };

  // Delete the id if it exists in the representative object
  if ('id' in sanitized) {
    delete sanitized.id;
  }

  // Convert all empty strings in the representative object to null
  Object.keys(sanitized).forEach(key => {
    if (sanitized[key] === "") {
      sanitized[key] = null;
    }
  });

  return sanitized;
}

export default createRepresentative;
