import { axiosApi } from "app/configs/axiosConfig";

const getTicketActionsByTicketId = async (ticketId) => {
  try {
    const response = await axiosApi.get(`/ticket/getTicketActionsByTicketId/${ticketId}`);
    if (response.status === 200) {
      return response.data;
    }
    else if (response.status === 404) {
      throw new Error('Δεν βρέθηκε ομάδα ή μέλος ομάδας');
    }
    else if (response.status === 406) {
      throw new Error('Η ενέργεια δεν γίνεται δεκτή');
    }
    else {
      throw new Error('Απροσδόκητη απόκριση διακομιστή');
    }
  } catch (error) {
    throw error; // propagate the error
  }
};

export default getTicketActionsByTicketId;
