import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TeamSearchStep from "app/main/Panels/UserPanel/TeamWizard/TeamSearchStep";
import TicketSearchStep from "app/main/Panels/UserPanel/ActiveWizard/TicketSearchStep";
import SelectedTeamDetailsStep from "app/main/Panels/UserPanel/TeamWizard/SelectedTeamDetailsStep";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { resetUserAssignmentSlice } from "store/userAssignmentSlice";
import AddToTeamsDialog from "app/main/Panels/UserPanel/TeamWizard/AddToTeamsDialog";
import DeleteFromTeamStep from "app/main/Panels/UserPanel/TeamWizard/DeleteFromTeamStep";


const UserTeamDialog = ({ open, onClose, user }) => {
    const dispatch = useDispatch();
    const {tickets , selectedTeam} = useSelector(state => state.userAssignmentSlice);
    const [currentStep, setCurrentStep] = useState('teamSearch');

    const handleClose = () => {
        dispatch(resetUserAssignmentSlice());
        setCurrentStep('teamSearch'); // Reset back to the initial step
        onClose();
    };

    const handleDeleteUser = () => {
        setCurrentStep('deleteFromTeam');
    };

    const handleDeletionComplete = () => {
        setCurrentStep('teamSearch'); // Return to the initial step or close the dialog
        onClose(); // Optionally close the dialog or refresh data
    };

    const handleCloseAndGoBack = () => {
        setCurrentStep('teamSearch');
    };



    const handleTeamSelected = () => {
        setCurrentStep('ticketSearch'); // Move to ticket search upon team selection
    };

    const handleTicketsConfirmed = () => {
        setCurrentStep('selectedTeamDetails'); // Show selected team details after ticket actions are confirmed
    };

    const handleAddToTeams = () => {
        setCurrentStep('addToTeams'); // Transition to the add to teams step
    };



    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            PaperProps={{
                style: {
                    width: '60vw',
                    height: '60vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: '20px'
                },
            }}
        >
            {currentStep === 'teamSearch' && (
                <TeamSearchStep
                    open={Boolean(user)}
                    onClose={handleClose}
                    user={user}
                    onDelete={handleDeleteUser}
                    onAddToTeams={handleAddToTeams}
                />
            )}
            {currentStep === 'deleteFromTeam' && (
                <DeleteFromTeamStep
                    user={user}
                    onDeleted={handleDeletionComplete}
                    onGoBack={() => setCurrentStep('selectedTeamDetails')}
                    onTeamSelect={handleTeamSelected}
                />
            )}
            {currentStep === 'ticketSearch' && selectedTeam && (
                <TicketSearchStep
                    user={user}
                    onNoTicketsFound={handleClose}
                    onTicketsConfirmed={handleTicketsConfirmed}
                />
            )}
            {currentStep === 'selectedTeamDetails' && selectedTeam && (
                <SelectedTeamDetailsStep
                    user={user}
                    onClose={handleClose}
                    handleGoBack={handleCloseAndGoBack}
                />
            )}
            {currentStep === 'addToTeams' && (
                <AddToTeamsDialog
                    open={true}
                    user={user}
                    handleGoBack={handleCloseAndGoBack}
                />
            )}
            <DialogActions>

                {currentStep === 'ticketSearch' && (
                    <>
                        <Button
                            onClick={handleTicketsConfirmed}
                            color="primary"
                            disabled={tickets?.length === 0}
                        >
                            Επιβεβαίωση
                        </Button>

                    </>
                )}
                {currentStep !== 'teamSearch' && (
                    <Button onClick={handleCloseAndGoBack} color="primary">
                        Πίσω
                    </Button>
                )}

                    <Button onClick={handleClose} color="error">
                        Κλείσιμο
                    </Button>

            </DialogActions>
        </Dialog>
    );
};

export default UserTeamDialog;
