
const getResolutionOptions =  (userRole, actionCodes) => {
    let resolutionOptions = [
      { value: 'draft', label: 'Draft' },
      { value: 'general-information', label: 'Γενική Πληροφόρηση' },
      { value: 'personalized-information', label: 'Προσωποποιημένη Πληροφόρηση ' },
      { value: 'e-services-guidance', label: 'Καθοδήγηση σε Ηλεκτρονική Υπηρεσία' },
      { value: 'appointment-closed', label: 'Κλείσιμο Ραντεβού σε υπηρεσία ' },
      { value: 'wrong-number', label: 'Λάθος αριθμός' },
      { value: 'line-failure', label: 'Έπεσε η γραμμή ' },
    ];
  
    
  
    const actionCodeMap = {
      'ROLE_FO_AGENT': [
        { id: 100, ticketStatusId: 1, value: 'open-ticket', label: 'ROLE_FO_AGENT opens a ticket' },
        { id: 101, ticketStatusId: 3, value: 'close-ticket', label: 'ROLE_FO_AGENT closes the ticket' },
        { id: 115, ticketStatusId: 4, value: 'to-fo-team-leader', label: 'ROLE_FO_AGENT moves the ticket to ROLE_FO_TEAM_LEADER' },
        { id: 116, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_FO_AGENT moves the ticket to ROLE_FO_AGENT' },
        { id: 117, ticketStatusId: 4, value: 'to-bo-team-leader', label: 'ROLE_FO_AGENT moves the ticket to ROLE_BO_TEAM_LEADER' },
        { id: 118, ticketStatusId: 4, value: 'to-bo-agent', label: 'ROLE_FO_AGENT moves the ticket to ROLE_BO_AGENT' },
        { id: 119, ticketStatusId: 3, value: 'cancel-ticket', label: 'ROLE_FO_AGENT cancels the ticket' },
        { id: 120, ticketStatusId: 1, value: 'create-draft-ticket', label: 'ROLE_FO_AGENT creates a draft ticket' },
        { id: 121, ticketStatusId: 2, value: 'put-on-hold', label: 'ROLE_FO_AGENT puts the ticket on hold' },
      ],
      'ROLE_FO_SUPERVISOR': [
        { id: 102, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_FO_SUPERVISOR reopens the ticket' },
        { id: 104, ticketStatusId: 4, value: 'assign-to-fo-team-leader', label: 'ROLE_FO_SUPERVISOR assigns the ticket to ROLE_FO_TEAM_LEADER' },
        { id: 105, ticketStatusId: 4, value: 'assign-to-fo-agent', label: 'ROLE_FO_SUPERVISOR assigns the ticket to ROLE_FO_AGENT' },
        { id: 106, ticketStatusId: 4, value: 'to-bo-team-leader', label: 'ROLE_FO_SUPERVISOR moves the ticket to ROLE_BO_TEAM_LEADER' },
        { id: 107, ticketStatusId: 4, value: 'to-bo-agent', label: 'ROLE_FO_SUPERVISOR moves the ticket to ROLE_BO_AGENT' },
        { id: 108, ticketStatusId: 4, value: 'to-sa-team-leader', label: 'ROLE_FO_SUPERVISOR moves the ticket to ROLE_SA_TEAM_LEADER' },
        { id: 109, ticketStatusId: 4, value: 'to-sa-agent', label: 'ROLE_FO_SUPERVISOR moves the ticket to ROLE_SA_AGENT' },
      ],
      'ROLE_FO_TEAM_LEADER': [
        { id: 103, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_FO_TEAM_LEADER reopens the ticket' },
        { id: 110, ticketStatusId: 4, value: 'assign-to-fo-agent', label: 'ROLE_FO_TEAM_LEADER assigns the ticket to ROLE_FO_AGENT' },
        { id: 111, ticketStatusId: 4, value: 'to-bo-team-leader', label: 'ROLE_FO_TEAM_LEADER moves the ticket to ROLE_BO_TEAM_LEADER' },
        { id: 112, ticketStatusId: 4, value: 'to-bo-agent', label: 'ROLE_FO_TEAM_LEADER moves the ticket to ROLE_BO_AGENT' },
        { id: 113, ticketStatusId: 4, value: 'to-sa-team-leader', label: 'ROLE_FO_TEAM_LEADER moves the ticket to ROLE_SA_TEAM_LEADER' },
        { id: 114, ticketStatusId: 4, value: 'to-sa-agent', label: 'ROLE_FO_TEAM_LEADER moves the ticket to ROLE_SA_AGENT' },
      ],
      'ROLE_BO_AGENT': [
        { id: 203, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_BO_AGENT returns the ticket to ROLE_FO_AGENT' },
        { id: 205, ticketStatusId: 3, value: 'close-ticket', label: 'ROLE_BO_AGENT closes the ticket' },
        { id: 206, ticketStatusId: 4, value: 'to-fo-team-leader', label: 'ROLE_BO_AGENT returns the ticket to be Closed to ROLE_FO_TEAM_LEADER' },
        { id: 207, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_BO_AGENT returns the ticket to be Closed to ROLE_FO_AGENT' },
        { id: 227, ticketStatusId: 3, value: 'cancel-ticket', label: 'ROLE_BO_AGENT cancels the ticket' },
        { id: 228, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_BO_AGENT reopens the ticket' },
        { id: 229, ticketStatusId: 2, value: 'put-on-hold', label: 'ROLE_BO_AGENT puts the ticket on hold' },
      ],
      'ROLE_BO_SUPERVISOR': [
        { id: 208, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_BO_SUPERVISOR reopens the ticket' },
        { id: 210, ticketStatusId: 4, value: 'assign-to-bo-team-leader', label: 'ROLE_BO_SUPERVISOR assigns the ticket to ROLE_BO_TEAM_LEADER' },
        { id: 211, ticketStatusId: 4, value: 'assign-to-bo-agent', label: 'ROLE_BO_SUPERVISOR assigns the ticket to ROLE_BO_AGENT' },
        { id: 212, ticketStatusId: 4, value: 'to-fo-team-leader', label: 'ROLE_BO_SUPERVISOR moves the ticket to ROLE_FO_TEAM_LEADER' },
        { id: 213, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_BO_SUPERVISOR moves the ticket to ROLE_FO_AGENT' },
        { id: 214, ticketStatusId: 4, value: 'to-sa-team-leader', label: 'ROLE_BO_SUPERVISOR moves the ticket to ROLE_SA_TEAM_LEADER' },
        { id: 215, ticketStatusId: 4, value: 'to-sa-agent', label: 'ROLE_BO_SUPERVISOR moves the ticket to ROLE_SA_AGENT' },
      ],
      'ROLE_BO_TEAM_LEADER': [
        { id: 209, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_BO_TEAM_LEADER reopens the ticket' },
        { id: 216, ticketStatusId: 4, value: 'assign-to-bo-agent', label: 'ROLE_BO_TEAM_LEADER assigns the ticket to ROLE_BO_AGENT' },
        { id: 217, ticketStatusId: 4, value: 'to-fo-team-leader', label: 'ROLE_BO_TEAM_LEADER moves the ticket to ROLE_FO_TEAM_LEADER' },
        { id: 218, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_BO_TEAM_LEADER moves the ticket to ROLE_FO_AGENT' },
        { id: 219, ticketStatusId: 4, value: 'to-sa-team-leader', label: 'ROLE_BO_TEAM_LEADER moves the ticket to ROLE_SA_TEAM_LEADER' },
        { id: 220, ticketStatusId: 4, value: 'to-sa-agent', label: 'ROLE_BO_TEAM_LEADER moves the ticket to ROLE_SA_AGENT' },
      ],
      'ROLE_SA_AGENT': [
        { id: 308, ticketStatusId: 3, value: 'close-ticket', label: 'ROLE_SA_AGENT closes the ticket' },
        { id: 327, ticketStatusId: 3, value: 'cancel-ticket', label: 'ROLE_SA_AGENT cancels the ticket' },
        { id: 328, ticketStatusId: 1, value: 'create-draft-ticket', label: 'ROLE_SA_AGENT creates a draft ticket' },
        { id: 329, ticketStatusId: 2, value: 'put-on-hold', label: 'ROLE_SA_AGENT puts the ticket on hold' },
      ],
      'ROLE_SA_SUPERVISOR': [
        { id: 309, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_SA_SUPERVISOR reopens the ticket' },
        { id: 311, ticketStatusId: 4, value: 'assign-to-sa-team-leader', label: 'ROLE_SA_SUPERVISOR assigns the ticket to ROLE_SA_TEAM_LEADER' },
        { id: 312, ticketStatusId: 4, value: 'assign-to-sa-agent', label: 'ROLE_SA_SUPERVISOR assigns the ticket to ROLE_SA_AGENT' },
        { id: 313, ticketStatusId: 4, value: 'to-bo-team-leader', label: 'ROLE_SA_SUPERVISOR moves the ticket to ROLE_BO_TEAM_LEADER' },
        { id: 314, ticketStatusId: 4, value: 'to-fo-team-leader', label: 'ROLE_SA_SUPERVISOR moves the ticket to ROLE_FO_TEAM_LEADER' },
        { id: 315, ticketStatusId: 4, value: 'to-bo-agent', label: 'ROLE_SA_SUPERVISOR moves the ticket to ROLE_BO_AGENT' },
        { id: 316, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_SA_SUPERVISOR moves the ticket to ROLE_FO_AGENT' },
      ],
      'ROLE_SA_TEAM_LEADER': [
        { id: 310, ticketStatusId: 2, value: 'reopen-ticket', label: 'ROLE_SA_TEAM_LEADER reopens the ticket' },
        { id: 317, ticketStatusId: 4, value: 'assign-to-bo-team-leader', label: 'ROLE_SA_TEAM_LEADER assigns the ticket to ROLE_BO_TEAM_LEADER' },
        { id: 318, ticketStatusId: 4, value: 'assign-to-fo-team-leader', label: 'ROLE_SA_TEAM_LEADER assigns the ticket to ROLE_FO_TEAM_LEADER' },
        { id: 319, ticketStatusId: 4, value: 'to-bo-agent', label: 'ROLE_SA_TEAM_LEADER moves the ticket to ROLE_BO_AGENT' },
        { id: 320, ticketStatusId: 4, value: 'to-fo-agent', label: 'ROLE_SA_TEAM_LEADER moves the ticket to ROLE_FO_AGENT' },
      ],
    };
    
    
    
  
  
    
    if (userRole === 'ROLE_FO_AGENT') {
      resolutionOptions = resolutionOptions.slice(0, 7); 
      resolutionOptions.push({ value: 'to-bo', label: 'Μεταφορά σε Bo' }); 
    } else if (userRole === 'ROLE_FO_TEAM_LEADER') {
      resolutionOptions = resolutionOptions.slice(0, 7); 
      resolutionOptions.push({ value: 'to-bo', label: 'Μεταφορά σε Bo' });
    } else if (userRole === 'ROLE_FO_SUPERVISOR') {
      resolutionOptions = resolutionOptions.slice(0, 7); 
      resolutionOptions.push({ value: 'to-bo', label: 'Μεταφορά σε Bo' });
       resolutionOptions.push({ value: 'to-fo-agent', label: 'Μεταφορά σε Fo agent' });
       resolutionOptions.push({ value: 'to-Fo-team-leader', label: 'Μεταφορά σε Fo Team Leader ' });
    } else if (userRole === 'ROLE_BO_AGENT') {
      resolutionOptions = resolutionOptions.slice(0, 7); 
      resolutionOptions.push({ value: 'to-fo', label: 'Μεταφορά σε Fo' });
      // resolutionOptions.push({ value: 'to-bo-team-leader', label: 'Μεταφορά σε Bo Team Leader' }); 
      resolutionOptions.push({ value: 'to-sa', label: 'Μεταφορά σε Sa' }); 
    } else if (userRole === 'ROLE_BO_TEAM_LEADER') {
      resolutionOptions = resolutionOptions.slice(0, 7); 
      // resolutionOptions.push({ value: 'to-bo-supervisor', label: 'Μεταφορά σε Bo Supervisor' }); 
      // resolutionOptions.push({ value: 'to-sa', label: 'Μεταφορά σε Sa' }); 
    } else if (userRole === 'ROLE_SA_AGENT') {
      resolutionOptions.push({ value: 'to-bo', label: 'Μεταφορά σε Bo' }); 
      resolutionOptions.push({ value: 'to-sa', label: 'Μεταφορά σε Fo' }); 
      resolutionOptions.push({
        value: 'to-sa-team-leader',
        label: 'Μεταφορά σε Sa Team Leader ',
      });
    } else if (userRole === 'ROLE_SA_TEAM_LEADER') {
      resolutionOptions = resolutionOptions.slice(0, 7); 
      resolutionOptions.push({ value: 'to-fo', label: 'Μεταφορά σε Bo' });
      resolutionOptions.push({ value: 'to-bo', label: 'Μεταφορά σε Bo' });
    } else if (userRole === 'ROLE_SA_SUPERVISOR') {
      resolutionOptions.push({ value: 'to-fo', label: 'Μεταφορά σε Fo ' });
      resolutionOptions.push({ value: 'to-bo', label: 'Μεταφορά σε Bo ' });
    }
    return resolutionOptions;
  };
  
  export default getResolutionOptions;