import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getTicketActionsByTicketId from "app/Api Calls/GetTicketActionsByTicketId";
import actionCodesByStatusId from "app/Api Calls/ActionCodesByStatusId";
import getResolutionOptions from "app/main/tickets/create-ticket/ResolutionsOptions";

export const fetchResolutionOptions = createAsyncThunk(
  "resolution/fetchOptions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getResolutionOptions();
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchActionCodesByStatusId = createAsyncThunk(
  'ticket/fetchActionCodesByStatusId',
  async (statusId, thunkAPI) => {
    try {
      const actionsCodes = await actionCodesByStatusId(statusId);
      return actionsCodes;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchActionCodesByTicketId = createAsyncThunk(
  'ticket/fetchActionCodesByTicketId',
  async (ticketId, thunkAPI) => {
    try {
      const actionsCodes = await getTicketActionsByTicketId(ticketId);
      return actionsCodes;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const resolutionSlice = createSlice({
  name: "resolution",
  initialState: {
    resolutionOptions: [], 
    mandatoryFields: [],
    selectedResolution: null,
    loading: false,
    error: null,
    actionError: null,
    saModal: false,
    filteredResolutionOptions: [],
  },
  reducers: {
    setResolutionOptions: (state, action) => {
      state.resolutionOptions = action.payload;
    },
    setSaModal: (state, action) => {
      state.saModal = action.payload;
    },
    setMandatoryFields: (state, action) => {
      state.mandatoryFields = action.payload;
    },
    setSelectedResolution: (state, action) => {
      state.selectedResolution = action.payload;
    },
    setActionError(state, action) { 
      state.actionError = action.payload;
    },
    setFilteredResolutionOptions: (state, action) => {
      state.filteredResolutionOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResolutionOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchResolutionOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.resolutionOptions = action.payload; 
      })
      .addCase(fetchResolutionOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchActionCodesByStatusId.fulfilled, (state, action) => {
        state.resolutionOptions = action.payload;
      })
      .addCase(fetchActionCodesByStatusId.rejected, (state, action) => {
        state.actionError = action.payload; 
      })
      .addCase(fetchActionCodesByTicketId.fulfilled, (state, action) => {
        state.resolutionOptions = action.payload;
      })
      .addCase(fetchActionCodesByTicketId.rejected, (state, action) => {
        state.actionError = action.payload; 
      });
  },
});

export const { setResolutionOptions, setMandatoryFields, setSelectedResolution , setActionError,setSaModal,setFilteredResolutionOptions} =
  resolutionSlice.actions;

export default resolutionSlice.reducer;

