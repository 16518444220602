import React, {useState, useEffect, useRef} from "react";
import {Button, Avatar, Tooltip, Switch} from "@mui/material";
import {EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {useDispatch, useSelector} from "react-redux";
import {
    setAreCommentsFetched,
    setComment,
    setComments,
    setIsCommentSubmitted,
    setShouldFetchFiles,
} from "store/editTicketSlice";
import {
    fetchCommentsForTicket,
    submitComment,
} from "app/Api Calls/Comments";
import {getSpecificUser} from "app/Api Calls/GetSpecificUser";
import {convertToRaw} from "draft-js";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import {showMessage} from "store/messageSlice";
import FileUploadButton from "./Files/FileUploadButton";
import {uploadFileToTicket} from "app/Api Calls/UploadFile";
import {addUploadedImage, resetUploadedImages} from "store/userInfoSlice";
import {FileLogo} from "./Files/Filelogo";
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {jwtService} from "app/auth/jwtService";
import settingsConfig from "app/configs/settingConfig";
import linkifyHtml from "linkify-html";

function CommentsEditAccordion({id, sourceApplication, status}) {
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const areCommentsFetched = useSelector(
        (state) => state.editTicketSlice.areCommentsFetched
    );
    const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
    const comments = useSelector((state) => state.editTicketSlice.comments);
    const comment = useSelector((state) => state.editTicketSlice.comment);
    const [errors, setErrors] = useState({});
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [actualFiles, setActualFiles] = useState([]);
    const [fileSwitches, setFileSwitches] = useState([]);
    const user = jwtService.getUser();
    const userRole = user?.roleName;

    useEffect(() => {
        if (areCommentsFetched) {
            return;
        }

        const fetchCommentsData = async () => {
            try {
                const fetchedComments = await fetchCommentsForTicket(id);
                const userIdsToFetch = new Set();

                // Identify all unique user IDs
                fetchedComments.forEach(comment => {
                    if (comment.whoIsCreated) {
                        userIdsToFetch.add(comment.whoIsCreated);
                    }
                });

                const fetchedUsers = {};

                // Fetch data for each unique user ID
                await Promise.all(Array.from(userIdsToFetch).map(async (userId) => {
                    fetchedUsers[userId] = await getSpecificUser(userId);
                }));

                const fetchedCommentsWithUser = fetchedComments.map(comment => {
                    return {...comment, user: fetchedUsers[comment.whoIsCreated]};
                });

                dispatch(setComments(fetchedCommentsWithUser));
                dispatch(setAreCommentsFetched(true));
            } catch (error) {
                console.error('Error fetching comments:', error);
                // Handle the error appropriately
                // Example: Set an error state, show error message, etc.
            }
        };

        fetchCommentsData();
    }, [areCommentsFetched, id, dispatch]);


    const handleCommentChange = (editorState) => {
        setEditorState(editorState);

        const rawContentState = convertToRaw(editorState.getCurrentContent());
        let htmlContent = draftToHtml(rawContentState);
        htmlContent = DOMPurify.sanitize(htmlContent);

        dispatch(setComment(htmlContent));
    };

    const validateComment = (comment) => {
        const tempErrors = {};
        const commentText = comment.replace(/<\/?[^>]+(>|$)/g, "");

        if (!commentText.trim()) {
            tempErrors.comment = " H Aπάντηση δεν μπορεί να είναι κενή";
        } else if (commentText.length < 5) {
            tempErrors.comment = "Η Απάντηση πρέπει να έχει τουλάχιστον 5 χαρακτήρες";
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleCommentSubmit = async (event) => {
        event.preventDefault();

        const isFormValid = validateComment(comment);
        if (!isFormValid) return;

        setIsSubmitting(true);

        try {
            await submitComment(id, comment);
            dispatch(setIsCommentSubmitted(true));
            dispatch(setComment(""));
            dispatch(setAreCommentsFetched(false));
            setEditorState(EditorState.createEmpty());
        } catch (err) {
            console.error(err);
            dispatch(
                showMessage({
                    message: "Απέτυχε η δημιουργία απάντησης",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "error",
                })
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    //File stuff
  const uploadFiles = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    for (let i = 0; i < actualFiles.length; i++) {
      try {
        const file = actualFiles[i];
        const isPublished = fileSwitches[i] ? 1 : 0;
        await uploadFileToTicket(id, file, isPublished);
        dispatch(setShouldFetchFiles(true));
        // Other success handling
      } catch (error) {
        console.error("Error uploading file:", error);
        // Error handling
      }
    }

    dispatch(resetUploadedImages());
    setFiles([]);
    setActualFiles([]);
    setIsLoading(false);
    setFileSwitches([]);
  };

    const removeFile = (indexToRemove) => {
        setFiles((prevFiles) => prevFiles.filter((_, index) => index !== indexToRemove));
        setActualFiles((prevActualFiles) => prevActualFiles.filter((_, index) => index !== indexToRemove));
        setFileSwitches((prevFileSwitches) => prevFileSwitches.filter((_, index) => index !== indexToRemove));
    };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    // Update local state with the actual file objects for upload
    const updatedActualFiles = [...actualFiles, ...selectedFiles];
    setActualFiles(updatedActualFiles);

    // Create an array of file metadata
    const fileMetadata = selectedFiles.map(file => ({
      name: file.name,
      type: file.type,
      size: file.size
    }));

    // Update local state with the file blobs for display purposes
    const fileBlobs = selectedFiles.map(file => URL.createObjectURL(file));
    setFiles(prevFiles => [...prevFiles, ...fileBlobs]);

    // Initialize the switch state to true (1) for each selected file
    setFileSwitches(prevSwitches => [...prevSwitches, ...selectedFiles.map(() => true)]);

    // Dispatch the metadata to the Redux store
    fileMetadata.forEach(meta => dispatch(addUploadedImage(meta)));
  };




  const handleSwitchChange = (index) => {
        const updatedSwitches = [...fileSwitches];
        updatedSwitches[index] = !updatedSwitches[index];
        setFileSwitches(updatedSwitches);
    };

    const isBOUser = (roleName) => {
        const allRoles = [
            ...settingsConfig.roles.supervisor,
            ...settingsConfig.roles.teamLeader,
            ...settingsConfig.roles.agent,
        ];

        const foRoles = [
            'ROLE_FO_SUPERVISOR',
            'ROLE_FO_TEAM_LEADER',
            'ROLE_FO_AGENT'
        ];

        const boRoles = allRoles.filter(role => !foRoles.includes(role));
        const isRoleBO = boRoles.includes(roleName);
        const isIdStartsWith4 = sourceApplication === 4

        if (activeStatus?.id === 5) {
            return false;
        }

        return isRoleBO && isIdStartsWith4;
    };

//Detect hyperlinks and make them clickable
    const processCommentText = (text) => {
        const options = {
            defaultProtocol: 'https',
            attributes: {
                rel: 'noopener noreferrer',
                target: '_blank',
                style: 'color: #003476;'
            }
        };
        return linkifyHtml(text, options);
    };

    return (
        <div>
            <h3>
                <strong>Απάντηση Ticket</strong>
            </h3>
            <div
                className="w-full flex flex-col h-full"
                style={{maxHeight: "600px", overflowY: "auto"}}
            >
                <div className="comments-section overflow-auto">
                    {comments && comments.length > 0 ? (
                        comments.map((comment, index) => (
                            <div key={index} className="flex items-start gap-2 mb-2">
                                <Avatar/>
                                <div className="flex flex-col">
                                    <p

                                        dangerouslySetInnerHTML={{__html: processCommentText(comment.note)}}
                                    ></p>
                                    <div className="text-sm text-gray-500">
                                        <p>
                                            {`${comment.user?.firstName || ""} ${comment.user?.lastName || ""
                                            }`.trim() || "Άγνωστος"}
                                        </p>
                                        <p>
                                            {new Date(comment.createdAt).toLocaleDateString("el-GR", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                            })}{" "}
                                            {new Date(comment.createdAt).toLocaleTimeString("el-GR", {
                                                hour: "2-digit",
                                                minute: "2-digit",
                                            })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <h2 className="text-center">Δεν υπάρχει απάντηση</h2>
                    )}
                </div>
                <div className="flex flex-col mt-4">
                    <div className="flex items-start mb-4" style={{width: "100%"}}>
                        <Avatar style={{marginRight: "1rem"}}/>
                        <div style={{flex: "1"}}>

                            <Editor
                                editorState={editorState}
                                onEditorStateChange={handleCommentChange}
                                wrapperClassName="border rounded mb-4  max-h-[250px] h-[250px]"
                                editorClassName="p-4 overflow-y-auto break-words "
                                toolbarCustomButtons={
                                    isBOUser(userRole) ? [<FileUploadButton onFilesSelected={handleFileChange}
                                                                            disabled={activeStatus?.id === 5}/>] : []
                                }
                                readOnly={activeStatus && activeStatus.id === 5 && activeStatus.shortName === "Closed"}
                                editorStyle={{
                                    border: "1px solid #ddd",
                                    borderRadius: "4px",
                                    padding: "5px",
                                    minHeight: "200px",
                                    maxHeight: "200px",
                                    width: "100%",
                                    cursor: "text"
                                }}
                                toolbar={{
                                    options: ["inline", "list", "history"],
                                    inline: {
                                        options: ["bold", "italic", "underline"],
                                    },
                                    list: {
                                        options: ["unordered", "ordered"],
                                    },
                                    history: {
                                        inDropdown: false,
                                        className: undefined,
                                        component: undefined,
                                        dropdownClassName: undefined,
                                        options: ["undo", "redo"],
                                    },
                                }}
                            />

                            {errors.comment && (
                                <p className="text-center" style={{color: "red"}}>{errors.comment}</p>
                            )}
                        </div>
                    </div>
                    {activeStatus &&
                        activeStatus.id === 5 &&
                        activeStatus.shortName === "Closed" && (
                            <p style={{textAlign: "center", color: "red"}}>
                                Απενεργοποιημένη Προσθήκη Απάντησης
                            </p>
                        )}
                        {isBOUser(userRole) && (
                            <div style={{marginBottom: "10px"}}>
                                {files.length > 0 && (
                                    <>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                            {files.map((blobUrl, index) => {
                                                const file = actualFiles[index];
                                                return (
                                                    <div key={index} style={{
                                                        width: 'calc(33% - 10px)',
                                                        padding: '10px',
                                                        boxSizing: 'border-box',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '5px',
                                                        backgroundColor: '#f9f9f9'
                                                    }}>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center'
                                                            }}>
                                                                <FileLogo extension={file.type} />
                                                                <span style={{ marginLeft: '5px' }}>{file.name}</span>
                                                            </div>
                                                            <IconButton
                                                                onClick={() => removeFile(index)}
                                                                size="small"
                                                                style={{
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '50%',
                                                                    marginLeft: 8
                                                                }}
                                                                disabled={isLoading}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </div>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            marginTop: '10px'
                                                        }}>
                                                            <Tooltip
                                                                title={fileSwitches[index] ? 'Δημοσιευμένο σε πολίτη' : 'Δημοσιευμένο μόνο σε Εκπροσώπους Φορέων'}>
                                                                <Switch
                                                                    checked={fileSwitches[index]}
                                                                    onChange={() => handleSwitchChange(index)}
                                                                    color="primary"
                                                                    disabled={isLoading}
                                                                />
                                                            </Tooltip>
                                                            <span>{fileSwitches[index] ? 'Δημοσιευμένο σε πολίτη' : 'Δημοσιευμένο μόνο σε Εκπροσώπους Φορέων'}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', marginTop: "10px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: isLoading ? "#b0b0b0 !important" : "#003476 !important",
                                                    color: isLoading ? "#000000 !important" : "#ffffff !important",
                                                    '&:hover': {
                                                        backgroundColor: isLoading ? "#b0b0b0 !important" : "#00285A !important",
                                                    },
                                                    marginTop: "10px"
                                                }}
                                                onClick={uploadFiles}
                                                disabled={isLoading}
                                            >
                                                Προσθήκη Αρχείων
                                            </Button>
                                            {isLoading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{marginLeft: "10px"}}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleCommentSubmit}
                     size="small"
                     sx={{
                         backgroundColor: '#003476 !important',
                         color: 'white',
                         '&:hover': {
                             backgroundColor: '#00285A !important'
                         },
                         '&:disabled': {
                             backgroundColor: 'grey',
                             color: 'lightgrey',
                             '&:hover': {
                                 backgroundColor: 'grey'
                             }
                         }
                     }}
                     disabled={
                         (activeStatus && activeStatus.id === 5 && activeStatus.shortName === "Closed") || isSubmitting
                     }
                >
                    Προσθήκη Απάντησης
                </Button>
            </div>
        </div>
</div>
)
    ;
}

export default CommentsEditAccordion;
