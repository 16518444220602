// SubThematicQnA component
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { fetchQnaByThematicId, fetchSubSubthematic } from 'app/Api Calls/CarriersThematicsCalls';
import { setSelectedSubSubThematic, setSubSubThematics, setSubThematicQnaData } from 'store/gnosiakiCrudSlice';

function SubThematicQnA({ handleEdit, handleDelete }) {
    const dispatch = useDispatch() ;
    const subThematicQnaData = useSelector(
        (state) => state.gnosiakiCrudSlice.subThematicQnaData
      );
    const selectedSubThematic = useSelector((state) => state.gnosiakiCrudSlice.selectedSubThematic );

      useEffect(() => {
        if (selectedSubThematic) {
          fetchSubSubthematic(selectedSubThematic)
            .then((data) => {
              if (data.length === 0) {
                fetchQnaByThematicId(selectedSubThematic).then((data) =>
                  dispatch(setSubThematicQnaData(data))
                );
              } else {
                dispatch(setSubSubThematics(data));
              }
            })
            .catch((error) => console.error("There was an error!", error));
        } else {
          dispatch(setSubThematicQnaData([]));
          dispatch(setSubSubThematics([]));
          dispatch(setSelectedSubSubThematic(null));
        }
      }, [selectedSubThematic, dispatch]);

  return (
    <>
      {subThematicQnaData && subThematicQnaData.map((qna, index) => (
        <div
          key={"subSubThematicQna-" + index}
          className="flex items-center justify-center bg-gray-100 py-2 px-4 my-4 ml-80 border-b-4 border-indigo-500"
        >
          <div className="w-1/12 flex justify-center items-center px-2">
            Ερώτηση {index + 1}
          </div>
          <div className="w-3/12 flex justify-center items-center px-2">
            {qna.question}
          </div>
          <div
            className="w-8/12  justify-center items-center px-2"
            dangerouslySetInnerHTML={{
              __html: qna.answer,
            }}
          ></div>
          <div className="w-2/12 flex items-center justify-end space-x-2">
            <button
              className="bg-blue-500 text-white py-1 px-4 rounded focus:outline-none"
            //   onClick={(e) => {
            //     e.stopPropagation();
            //     handleEdit(qna.id, false);
            //   }}
            >
              Επεξεργασία
            </button>
            <button
              className="bg-red-500 text-white py-1 px-4 rounded focus:outline-none"
            //   onClick={(e) => {
            //     e.stopPropagation();
            //     handleDelete(qna.id, false);
            //   }}
            >
              Διαγραφή
            </button>
          </div>
          
        </div>
      ))}
    </>
  );
}

export default SubThematicQnA;
