import React, {useState, useEffect} from 'react';
import {
    fetchAllCarriers,
    fetchThematicsByCarrierIdCall,
    fetchSubSubthematic
} from "app/Api Calls/CarriersThematicsCalls";
import CategoriesDropdown from "../Reusable Components/CategoriesDropdown";
import Button from "@mui/material/Button";
import {Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";
import {createCarrier, createQuestionNAnswer, createThematic} from "app/Api Calls/GnosiakiCalls";
import {triggerGlobalRefresh} from "store/gnosiakiCrudSlice";
import {useDispatch} from "react-redux";
import DOMPurify from "dompurify";

const TreeComponent = () => {
    const [hierarchy, setHierarchy] = useState([{items: [], selectedId: null}]);
    const [newItemName, setNewItemName] = useState('');
    const [question, setQuestion] = useState('');
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const dispatch = useDispatch();
    const showQnASection = hierarchy.length > 3 && hierarchy[hierarchy.length - 1].items.length === 0;
    const [customId, setCustomId] = useState('');
    const fetchData = async (level, parentId = null) => {
        let data;
        if (level === 0) {
            data = await fetchAllCarriers();
        } else if (level === 1) {
            data = await fetchThematicsByCarrierIdCall(parentId);
        } else {
            data = await fetchSubSubthematic(parentId);
        }
        return data.map(item => ({value: item.id, label: item.title}));
    };

    const handleSelect = (level, selectedOption) => {
        const newHierarchy = hierarchy.slice(0, level + 1);
        const selectedId = selectedOption ? selectedOption.value : null;
        if (selectedId) {
            fetchData(level + 1, selectedId).then(data => {
                // Update the current level with the selected ID
                newHierarchy[level] = {...newHierarchy[level], selectedId};
                // Add the new level with fetched items
                newHierarchy.push({items: data, selectedId: null});
                setHierarchy(newHierarchy);
            });
        } else {
            // Handle clearing the selection (if necessary)
            newHierarchy[level] = {items: newHierarchy[level].items, selectedId: null};
            setHierarchy(newHierarchy);
        }
    };
    // Determine if the Q&A section should be shown

    const handleAddNewItem = async (level) => {
        try {
            let response;
            const parentId = level === 1 ? null : hierarchy[level - 2]?.selectedId;
            const carrierId = hierarchy[0]?.selectedId;

            if (level === 1) {
                response = await createCarrier({
                    title: newItemName,
                    isActive: true,
                    customId: customId,
                });
                dispatch(triggerGlobalRefresh({carrierId: response.data.id}));
            } else {
                response = await createThematic({
                    title: newItemName,
                    isActive: true,
                    externalId: null,
                    parentId: level === 2 ? null : parentId,
                    carrierId: carrierId
                });
                dispatch(triggerGlobalRefresh({parentId}));
            }

            setNewItemName('');
            setCustomId('');

            await refetchCurrentLevel(level, parentId);
        } catch (error) {
            console.error("Failed to add new item:", error);
        }
    };


    const refetchCurrentLevel = async (level, parentId) => {
        let newData;
        // Adjust the fetching logic based on the level
        if (level === 1) {
            newData = await fetchAllCarriers();

        } else if (level === 2) {
            newData = await fetchThematicsByCarrierIdCall(parentId);
        } else {
            // For levels beyond 1, fetch sub-thematics or deeper levels using parentId
            newData = await fetchSubSubthematic(parentId);

        }

        // Prepare the data for updating the hierarchy
        const newLevelData = {
            items: newData.map(dataItem => ({
                value: dataItem.id,
                label: dataItem.title || dataItem.name
            })), selectedId: null
        };

        // Update or add a new level in the hierarchy with the fetched data
        let updatedHierarchy;

        // Since 'level' is passed as 1-based, adjust to 0-based for indexing
        const adjustedLevel = level - 1;

        if (adjustedLevel < hierarchy.length) {
            // Update existing level if it's already part of the hierarchy
            updatedHierarchy = hierarchy.map((item, index) => index === adjustedLevel ? newLevelData : item);
        } else {
            // Append new level data if this level doesn't exist yet in the hierarchy
            updatedHierarchy = [...hierarchy, newLevelData];
        }

        setHierarchy(updatedHierarchy);
    };


    const handleAddQnA = async () => {
        try {
            let answerHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            answerHtml = DOMPurify.sanitize(answerHtml);
            answerHtml = answerHtml.replace(/\n+$/, '');

            if (answerHtml !== "") {
                const thematicId = hierarchy[hierarchy.length - 2]?.selectedId;
                const response = await createQuestionNAnswer({
                    title: question,
                    question,
                    answer: answerHtml,
                    isActive: 1,
                    thematicId,
                    isPublished: true
                });
                console.log(response); // Log the successful response
                setQuestion('');
                setEditorState(EditorState.createEmpty());
                dispatch(triggerGlobalRefresh({parentId: thematicId}));
            }
        } catch (error) {
            console.error("Failed to add QnA:", error);
        }
    };



    // Initial fetch for the root level
    useEffect(() => {
        fetchData(0).then(data => {
            setHierarchy([{items: data, selectedId: null}]);
        });
    }, []);

    const removeHtmlTags = (htmlString) => {
        const tmp = document.createElement("DIV");
        tmp.innerHTML = htmlString;
        return tmp.textContent || tmp.innerText || "";
    };

    return (
        <Card variant="outlined" sx={{maxWidth: "90vw", margin: 'auto', mt: 4}}>
            <CardContent>
                <Typography variant="h5" component="div" align="center" gutterBottom>
                    Προσθήκη Φορέων / Θεματικών / Ερωτοαπαντήσεων
                </Typography>
                {hierarchy.map((levelData, index) => (
                    <Grid container key={index} spacing={2} alignItems="center">
                        <Grid item xs={5} sx={{mb: 1}}>
                            <CategoriesDropdown
                                options={levelData.items}
                                value={levelData.items.find(item => item.value === levelData.selectedId)}
                                onChange={(selectedOption) => handleSelect(index, selectedOption)}
                                placeholder={
                                    index === 0 ? "Επιλέξτε Φορέα" :
                                        index === 1 ? "Εισάγετε Θεματική Ενότητα" :
                                            index === 2 ? "Εισάγετε Θέμα" :
                                                index === 3 ? "Εισάγετε Υποθέμα" :
                                                    "No options available"
                                }
                                isLoading={false}
                                backgroundColor="#fff"
                                isMultiSelect={false}
                                isDisabled={false}
                                // noOptionsMessage="No options available"

                            />
                        </Grid>
                        {
                            (index === hierarchy.length - 1 && hierarchy.length <= 4) && (
                                <Grid item xs={7}
                                      sx={{mb: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                                    <TextField
                                        fullWidth
                                        label="Εισάγετε κείμενο"
                                        value={newItemName}
                                        onChange={(e) => setNewItemName(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                    />
                                    {index === 0 && (
                                        <TextField
                                            fullWidth
                                            label="Custom ID"
                                            value={customId}
                                            onChange={(e) => setCustomId(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                        />
                                    )}
                                    <Button
                                        variant="contained"
                                        disabled={
                                            newItemName.length < 5 || (index === 0 && customId.trim().length === 0)
                                        }
                                        onClick={() => handleAddNewItem(index + 1)}
                                        sx={{
                                            height: '40px',
                                            padding: '0 12px',
                                            minWidth: 'fit-content',
                                            backgroundColor: (theme) => newItemName.length < 5 || (index === 0 && customId.trim().length === 0) ? '#d3d3d3 !important' : '#003476 !important',
                                            '&:hover': {
                                                backgroundColor: (theme) => newItemName.length < 5 || (index === 0 && customId.trim().length === 0) ? '#d3d3d3' : '#0056b3',
                                            },
                                            color: (theme) => theme.palette.getContrastText(newItemName.length < 5 || (index === 0 && customId.trim().length === 0) ? '#d3d3d3' : '#003476'),
                                        }}
                                    >
                                        ΠΡΟΣΘΗΚΗ
                                    </Button>

                                </Grid>
                            )
                        }

                    </Grid>
                ))}
                {showQnASection && (
                    <Grid container spacing={2} alignItems="center" sx={{mt: 2}}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Ερώτηση"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="body1" sx={{mb: 1}}>Απάντηση</Typography>
                            <Editor
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                placeholder="Προσθέστε Απάντηση"
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                        bold: {className: 'bordered-option-classname'},
                                        italic: {className: 'bordered-option-classname'},
                                        underline: {className: 'bordered-option-classname'},
                                        strikethrough: {className: 'bordered-option-classname'},
                                        monospace: {className: 'bordered-option-classname'},
                                    },
                                    blockType: {
                                        className: 'bordered-option-classname',
                                        dropdownClassName: 'dropdown-classname',
                                    },
                                    fontSize: {className: 'bordered-option-classname'},
                                    fontFamily: {
                                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                        className: 'bordered-option-classname',
                                        dropdownClassName: 'dropdown-classname',
                                    },
                                    list: {
                                        inDropdown: false,
                                        className: 'bordered-option-classname',
                                        dropdownClassName: 'dropdown-classname',
                                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                                    },
                                    textAlign: {
                                        inDropdown: false,
                                        className: 'bordered-option-classname',
                                        dropdownClassName: 'dropdown-classname',
                                        options: ['left', 'center', 'right', 'justify'],
                                    },
                                    history: {
                                        inDropdown: false,
                                        className: 'bordered-option-classname',
                                        options: ['undo', 'redo'],
                                    },
                                }}
                            />
                        </Grid>
                        <Button
                            variant="contained"
                            onClick={handleAddQnA}
                            sx={{
                                backgroundColor: (theme) =>
                                    question.length < 10 ||
                                    removeHtmlTags(draftToHtml(convertToRaw(editorState.getCurrentContent()))).length < 10
                                        ? '#d3d3d3 !important'
                                        : '#003476 !important',
                                color: (theme) =>
                                    question.length < 10 ||
                                    removeHtmlTags(draftToHtml(convertToRaw(editorState.getCurrentContent()))).length < 10
                                        ? theme.palette.getContrastText('#d3d3d3')
                                        : theme.palette.getContrastText('#003476'),
                            }}
                        >
                            Προσθήκη Ερώτησης
                        </Button>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default TreeComponent;
