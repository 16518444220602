import React, { useState, useEffect } from 'react';
import { getTicketFiles, downloadFile } from 'app/Api Calls/GetTicketFiles';
import { FileLogo } from './Filelogo';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getSpecificUser } from 'app/Api Calls/GetSpecificUser';
import fetchBeneficiaryById from 'app/Api Calls/FetchBeneficiaryById';
import { Avatar, Box, Typography } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import { useSelector } from 'react-redux';
import { setShouldFetchFiles } from 'store/editTicketSlice';
import { useDispatch } from 'react-redux';

const TicketFilesComponent = ({ ticketId, ticketUUID }) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [uploaderInfo, setUploaderInfo] = useState(null);
    const shouldFetchFiles = useSelector(state => state.editTicketSlice.shouldFetchFiles);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!ticketId) return;
        if (!ticketUUID.startsWith('4')) return;

        const fetchFiles = async () => {
            try {
                const data = await getTicketFiles(ticketId);
                setFiles(data);
                checkUploader(data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching ticket files:', error);
            }
        };

        fetchFiles();
        dispatch(setShouldFetchFiles(false));
    }, [ticketId, ticketUUID, shouldFetchFiles]);

    const checkUploader = async (files) => {
        const userInfoMap = {};
        const beneficiaryInfoMap = {};

        const uniqueUserIds = [...new Set(files.map(file => file.uploadedByUserId).filter(id => id !== null))];
        const uniqueBeneficiaryIds = [...new Set(files.map(file => file.uploadedByBeneficiaryId).filter(id => id !== null))];

        for (const userId of uniqueUserIds) {
            const userInfo = await getSpecificUser(userId);
            userInfoMap[userId] = { ...userInfo, uploaderType: 'user' };
        }

        for (const beneficiaryId of uniqueBeneficiaryIds) {
            const beneficiaryInfo = await fetchBeneficiaryById(beneficiaryId);
            beneficiaryInfoMap[beneficiaryId] = { ...beneficiaryInfo, uploaderType: 'beneficiary' };
        }

        const filesWithUploader = files.map(file => {
            const uploaderId = file.uploadedByUserId || file.uploadedByBeneficiaryId;
            const uploaderInfo = file.uploadedByUserId ? userInfoMap[uploaderId] : beneficiaryInfoMap[uploaderId];
            return { ...file, uploaderInfo };
        });

        setFiles(filesWithUploader);
    };

    const renderUploaderInfo = (uploaderInfo) => {
        const fullName = [uploaderInfo.firstName, uploaderInfo.lastName].filter(Boolean).join(' ');
        const uploaderTypeText = uploaderInfo.uploaderType === 'beneficiary' ? 'τον δικαιούχο' : 'τον χρήστη';
        const initials = [uploaderInfo.firstName, uploaderInfo.lastName]
            .filter(Boolean)
            .map(name => name[0])
            .join('');
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '16px', paddingTop: '8px' }}>
                <Avatar sx={{ bgcolor: '#003476', marginRight: '8px' }}>
                    {initials ? initials : <FaceIcon />}
                </Avatar>
                <Typography>
                    Ανέβηκε από {uploaderTypeText}: <strong>{fullName || 'N/A'}</strong>
                </Typography>
            </Box>
        );
    };

    const handleDownload = async (fileId, file, event) => {
        event.preventDefault();

        try {
            const fileUrl = await downloadFile(fileId);
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${file.originFileName}.${file.extension}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };


    if (files.length === 0) return null;

    return (
        <Accordion
            sx={{ backgroundColor: "#eeeeee" }}
            expanded={isAccordionOpen}
            onChange={() => setIsAccordionOpen(!isAccordionOpen)}
            className={!isAccordionOpen ? 'mb-4' : ''}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h4>Αρχεία</h4>
            </AccordionSummary>
            <AccordionDetails>
                <div className="p-8 bg-white">
                    <div className="w-full">
                        <div className="flex mb-4 font-bold">
                            <div className="w-2/6 pr-4">Χρήστης/Δικαιούχος</div>
                            <div className="w-2/6 pr-4">Όνομα Αρχείου</div>
                            <div className="w-1/6 pr-4">Μέγεθος</div>
                            <div className="w-1/6 pr-4">Ημερομηνία</div>
                            <div className="w-1/6 pr-4">Δημοσιευμένο σε</div>
                        </div>
                        {files.map((file) => (
                            <div key={file.id} className="flex items-center mb-8">
                                <div className="w-2/6 pr-4">
                                    {file.uploaderInfo && renderUploaderInfo(file.uploaderInfo)}
                                </div>
                                <div className="w-2/6 pr-4">
                                    <a
                                        href="#"
                                        onClick={(event) => {
                                            event.preventDefault();
                                            handleDownload(file.id, file, event);
                                        }}
                                        className="flex items-center text-gray-800 no-underline hover:underline"
                                        style={{textDecoration: 'none'}}
                                    >
                                        <FileLogo extension={file.extension}/>
                                        <span className="ml-2">{file.originFileName}.{file.extension}</span>
                                    </a>
                                </div>
                                <div className="w-1/6 pr-4">
                                    <p>{Math.round(file.sizeBytes / 1024)} KB</p>
                                </div>
                                <div className="w-1/6 pr-4">
                                    <p>
                                        {new Date(file.createdAt).toLocaleDateString('el-GR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        })}
                                    </p>
                                </div>
                                <div className="w-1/6 pr-4">
                                    <p>
                                        {file.uploadedByBeneficiaryId !== null
                                            ? 'Δημοσιευμένο απο πολίτη'
                                            : file.isPublished
                                                ? 'Δημοσιευμένο σε πολίτη'
                                                : 'Δημοσιευμένο μόνο στους εκπροσώπους Φορέων'}
                                    </p>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default TicketFilesComponent;
