import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { jwtService } from './jwtService';

const Authorization = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const user = jwtService.getUser();
    const isSignedIn = user && user.roleName && user.roleName.length > 0;
    const hasRequiredRole = allowedRoles ? allowedRoles.includes(user.roleName) : true;
    const isOnSignInPage = location.pathname === '/sign-in';

    // If the user is not signed in and not on the sign-in page, redirect to sign-in page
    if (!isSignedIn && !isOnSignInPage) {
      navigate('/sign-in');
    }
    // If the user is signed in and on the sign-in page, redirect to the homepage
    else if (isSignedIn && isOnSignInPage) {
      navigate('/');
    }
    // If the user is signed in but does not have the required role, redirect to the homepage
    else if (isSignedIn && !hasRequiredRole) {
      navigate('/');
    }
  }, [allowedRoles, location.pathname]);

  // Render children if the user has the required role
  return <>{children}</>;
};

export default Authorization;
