import React from "react";
import BeneficiaryInput from "./BeneficiaryInput";

function BeneficiaryPanelPage() {


    return (
        <div>
            <BeneficiaryInput/>
        </div>
    );
}

export default BeneficiaryPanelPage;
