import React from "react";
import { TablePagination } from "@mui/material";

const BeneficiaryPagination = ({
                                   totalElements,
                                   page,
                                   size,
                                   handlePageChange,
                                   handleRowsPerPageChange,
                               }) => {
    return (
        <TablePagination
            component="div"
            count={totalElements}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={size}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 50, 100]}
            labelRowsPerPage="Γραμμές ανά σελίδα:"
            labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} από ${count !== -1 ? count : `περισσότερο από ${to}`}`
            }
        />
    );
};

export default BeneficiaryPagination;
