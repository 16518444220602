import React from "react";
import BeneficiaryInput from "./BeneficiaryInput";
import FromToDateFilter from "./FromToDateFilter";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    resetFilters,
    searchTicketsAsync,
    setListTicketCurrentPage,
    setPreviousSearchParams,
    setSearchParams,
    setSelectedTicketStatus,
    initialState, setSearchValues
} from "store/ticketFetchSlice";

import {isEqual} from "lodash";
import {useRef} from "react";
import DirectAssignmentMultiple from "./DirectAssignmentMultiple";
import {jwtService} from "app/auth/jwtService";
import {showMessage} from "store/messageSlice";
import formatDateToISOStringEndOfDay from "app/main/Reusable Components/formatDateToISOStringEndOfDay";
import TicketNumberSearch from "./TicketNumberSearch";
import TicketFilterSelect from "./TicketStatusFilter";
import LevelClosedFilter from "./LevelClosedFilter";
import UserFilterSelect from "./UserFilter";
import CarrierFilter from "./CarrierFilter";
import SourceApplication from "./SourceApplication";
import TeamFilter from "./TeamFilter";
import SupervisorSwitch from "app/main/tickets/Filters/SupervisorSwitch";
import formatDateToISOStringStartOfDay from "app/main/Reusable Components/formatDateToISOStringStartOfDay";
import AssignedToUserSwitch from "./AssignedToUserSwitch";
import settingsConfig from "app/configs/settingConfig";

function AllFilters() {
    const dispatch = useDispatch();
    const fromDate = useSelector((state) => state.ticketFetchSlice.fromDate);
    const toDate = useSelector((state) => state.ticketFetchSlice.toDate);
    const ListTicketCurrentPage = useSelector(
        (state) => state.ticketFetchSlice.ListTicketCurrentPage
    );
    const previousSearchParams = useSelector(
        (state) => state.ticketFetchSlice.previousSearchParams
    );
    const selectedTicketStatus = useSelector(
        (state) => state.ticketFetchSlice.selectedTicketStatus
    );
    const size = useSelector((state) => state.ticketFetchSlice.size);
    const userData = jwtService.getUser();
    const assigneeUserId = userData?.uuid;
    const userRole = userData?.roleName;
    const ticketNumber = useSelector(
        (state) => state.ticketFetchSlice.ticketNumber
    );
    const beneficiaryId = useSelector(
        (state) => state.ticketFetchSlice.beneficiaryToFilterId
    );
    const carrier = useSelector((state) => state.ticketFetchSlice.carrier)
    const level = useSelector((state) => state.ticketFetchSlice.level)
    const selectedUserId = useSelector(
        (state) => state.ticketFetchSlice.selectedUserId
    );
    const fetchAfterMultipleAssignments = useSelector((state) => state.ticketFetchSlice.fetchAfterMultipleAssignments);
    const selectedSourceApplication = useSelector((state) => state.ticketFetchSlice.selectedSourceApplication)
    const selectedTeamId = useSelector((state) => state.ticketFetchSlice.selectedTeamIds)
    const shouldShowDirectAssignmentMultiple = [
        "ROLE_FO_SUPERVISOR",
        "ROLE_FO_TEAM_LEADER",
        "ROLE_BO_SUPERVISOR",
        "ROLE_BO_TEAM_LEADER",
        "ROLE_SA_SUPERVISOR",
        "ROLE_SA_TEAM_LEADER",

    ].includes(userRole);
    const orderBy = useSelector((state) => state.ticketFetchSlice.orderDto)
    const previousSearchParamsRef = useRef(previousSearchParams);
    const isSupervisor = useSelector((state) => state.ticketFetchSlice.isSupervisor);
    const { assignToMe, assignToTeam } = useSelector((state) => state.ticketFetchSlice.assignment);
    const assignment = useSelector((state) => state.ticketFetchSlice.assignment);
    const userTeamIds = useSelector((state) => state.user.teamIds);
    const ticketsLoading = useSelector((state)=> state.ticketFetchSlice.ticketsLoading);
    useEffect(() => {
        let searchParamsLocal = {};
        const additionalParams = {};

        if (
            assignToMe &&
            [
                ...settingsConfig.roles.teamLeader,
                ...settingsConfig.roles.agent
            ].includes(userRole)
        ) {
            searchParamsLocal = {
                ...searchParamsLocal,
                ticketStatuses:  selectedTicketStatus.map(status => status.id),
                assigneeUserId: userData?.id,
            };
        } else {
            searchParamsLocal = {
                ...searchParamsLocal,
                ticketStatuses:  selectedTicketStatus.map(status => status.id),
                assigneeUserId: null,
            };
        }

        if (
            assignToTeam &&
            [...settingsConfig.roles.teamLeader].includes(userRole)
        ) {
            searchParamsLocal = {
                ...searchParamsLocal,
                teams: userTeamIds,
            };
        } else if (selectedTeamId && selectedTeamId.length > 0) {
            searchParamsLocal = {
                ...searchParamsLocal,
                teams: selectedTeamId.map(team => (team.id)),
            };
        }
        if (fromDate && toDate) {
            const fromDateObject = parseISODate(fromDate);
            const toDateObject = parseISODate(toDate);

            searchParamsLocal = {
                ...searchParamsLocal,
                fromDate: formatDateToISOStringStartOfDay(fromDateObject),
                toDate: formatDateToISOStringEndOfDay(toDateObject),
            };
        }
        if (ticketNumber) {
            searchParamsLocal = {
                ...searchParamsLocal,
                uuid: ticketNumber.trim(),
            };
        }
        if (orderBy && orderBy.length > 0) {
            searchParamsLocal = {
                ...searchParamsLocal,
                orderDto: orderBy,
            };
        }
        // if (selectedGroupRoleId) {
        //     searchParamsLocal = {
        //         ...searchParamsLocal,
        //         fromGroupRoleId: selectedGroupRoleId,
        //     };
        // }
        if (selectedTicketStatus) {
            searchParamsLocal = {
                ...searchParamsLocal,
                ticketStatuses: selectedTicketStatus.map(status => status.id),
            };
        }
        if (carrier) {
            searchParamsLocal = {
                ...searchParamsLocal,
                carrierId: carrier,
            }
        }
        if (level) {
            searchParamsLocal = {
                ...searchParamsLocal,
                groupId: level,
            }
        }
        if (selectedSourceApplication) {
            searchParamsLocal = {
                ...searchParamsLocal,
                sourceApplicationId: selectedSourceApplication,
            }
        }
        if (beneficiaryId) {
            searchParamsLocal = {
                ...searchParamsLocal,
                beneficiaryId: beneficiaryId,
            };
        }


        if (selectedUserId) {

            searchParamsLocal = {
                ...searchParamsLocal,
                assigneeUserId: selectedUserId,
            };
        }

        if (isSupervisor) {
            additionalParams.limits = false;
        }

        const newFilterApplied = !isEqual(
            previousSearchParamsRef.current,
            searchParamsLocal
        );

        dispatch(setSearchParams(searchParamsLocal));

        if (newFilterApplied) {
            dispatch(setPreviousSearchParams(searchParamsLocal));
            dispatch(setListTicketCurrentPage(0));
        }

        const fetchParams = {
            ListTicketCurrentPage: newFilterApplied ? 0 : ListTicketCurrentPage,
            size,
            searchParams: searchParamsLocal,
            additionalParams
        };

        dispatch(searchTicketsAsync(fetchParams))
            .then((response) => {
                    if (response.payload && !response.payload.elements?.length && response.payload !== "Rejected with 401") {
                        dispatch(
                            showMessage({
                                message: "Δεν βρέθηκε ticket με τα κριτήρια αναζήτησης",
                                variant: "warning",
                                autoHideDuration: 2000,
                            })
                        );
                    }
                }
            ).catch((error) => {
            if (error.message !== 'Rejected with 401') {
                dispatch(
                    showMessage({
                        message: "Δεν βρέθηκε ticket με τα κριτήρια αναζήτησης",
                        variant: "warning",
                        autoHideDuration: 2000,
                    })
                );
            }
        });

        // Store the latest search parameters in the ref
        previousSearchParamsRef.current = searchParamsLocal;
    }, [
        fromDate,
        toDate,
        dispatch,
        ListTicketCurrentPage,
        size,
        assigneeUserId,
        ticketNumber,
        selectedTicketStatus,
        beneficiaryId,
        userRole,
        selectedUserId,
        fetchAfterMultipleAssignments,
        carrier,
        level,
        selectedSourceApplication,
        selectedTeamId,
        orderBy,
        isSupervisor,
        assignment
        // selectedGroupRoleId
    ]);

    const resetFiltersHandler = () => {
       if(!ticketsLoading){
            dispatch(resetFilters());
}
    };

    function parseISODate(dateString) {
        return dateString ? new Date(dateString) : null;
    }

    const refreshFiltersHandler = () => {
        const fetchParams = {
            ListTicketCurrentPage,
            size,
            searchParams: previousSearchParamsRef.current,
        };

        dispatch(searchTicketsAsync(fetchParams))
            .then((response) => {
                // Handle response
            })
            .catch((error) => {
                // Handle error
            });
    };

    const filterComponents = [
        <BeneficiaryInput/>,
        <TicketNumberSearch/>,
        <TicketFilterSelect/>,
        <FromToDateFilter/>,
        <CarrierFilter/>,
        <LevelClosedFilter/>,
        <SourceApplication/>,
        // !["ROLE_ADMIN"].includes(userRole) && <GroupRolesFilter/>,
        shouldShowDirectAssignmentMultiple && <DirectAssignmentMultiple/>,
        ["ROLE_ADMIN", "ROLE_FO_SUPERVISOR", "ROLE_BO_SUPERVISOR", "ROLE_SA_SUPERVISOR"].includes(
            userRole
        ) && <UserFilterSelect/>,
        ["ROLE_FO_SUPERVISOR", "ROLE_BO_SUPERVISOR", "ROLE_SA_SUPERVISOR", "ROLE_ADMIN", 'ROLE_FO_TEAM_LEADER', 'ROLE_BO_TEAM_LEADER', 'ROLE_SA_TEAM_LEADER'].includes(
            userRole
        ) && <TeamFilter/>,
        ["ROLE_FO_SUPERVISOR", "ROLE_BO_SUPERVISOR", "ROLE_SA_SUPERVISOR"].includes(
            userRole
        ) && <SupervisorSwitch/>,
        ['ROLE_FO_AGENT', 'ROLE_BO_AGENT', 'ROLE_SA_AGENT', 'ROLE_FO_TEAM_LEADER', 'ROLE_BO_TEAM_LEADER', 'ROLE_SA_TEAM_LEADER'].includes(userRole) &&
        <AssignedToUserSwitch/>

    ];

    return (
        <div className="flex flex-col mt-4">
            {/* Grouping reset and refresh buttons in a column and the filters in another row */}
            <div className="flex flex-col lg:flex-row lg:items-start lg:space-x-2 w-full">
                <div className="flex flex-col mb-2 lg:mb-0 lg:flex-grow">
                    <button
                        onClick={resetFiltersHandler}
                        className={`px-2 mb-4 ${ticketsLoading ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-red-600 text-white hover:bg-red-800'}`}
                        disabled={ticketsLoading}
                    >
                        Διαγραφή Φίλτρων
                    </button>
                    <button
                        onClick={refreshFiltersHandler}
                        className={`px-2 ${ticketsLoading ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-800'}`}
                        style={{height: 'fit-content'}}
                        disabled={ticketsLoading}
                    >
                        Ανανέωση
                    </button>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full lg:flex-grow">
                    {filterComponents.filter(Boolean).map((filter, index) => (
                        <div key={index}>{filter}</div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AllFilters;
