import React, {useState, useEffect} from 'react';
import {TextField, Button, MenuItem, FormControl, InputLabel, Select, Box, Typography} from '@mui/material';
import {EditorState, convertToRaw, ContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import {fetchAllCarriers} from "app/Api Calls/CarriersThematicsCalls";
import {postAnnouncement, postAnnouncementFile} from "app/Api Calls/AnnouncmentsCalls";
import AnnouncmentFileSelect from "app/Announcments/Admin/Create/AnnouncmentFileSelect";
import {useDispatch} from "react-redux";
import {showMessage} from "store/messageSlice";
import LoadingOverlay from "app/Announcments/Admin/Create/LoadingPageOveride";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";

const AnnouncementForm = (props) => {
    const [formData, setFormData] = useState({
        carrierId: '',
        validUntil: '',
        title: '',
        bodyText: EditorState.createEmpty(),
        isPublished: true,
        sticky: false,
        isActive: true
    });
    const [carriers, setCarriers] = useState([]);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);


    useEffect(() => {
        const loadCarriers = async () => {
            try {
                const carriersData = await fetchAllCarriers();
                setCarriers(carriersData);
            } catch (error) {
                console.error('Failed to fetch carriers:', error);
            }
        };
        loadCarriers();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === 'isActive' || name === 'isPublished' || name === 'sticky' ? value === 'true' : value,
        }));
    };

    const handleEditorChange = (editorState) => {
        setFormData(prev => ({
            ...prev,
            bodyText: editorState
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const bodyHtml = draftToHtml(convertToRaw(formData.bodyText.getCurrentContent()));
        const validUntil = formData.validUntil ? formData.validUntil : null;

        try {
            const announcementResponse = await postAnnouncement({
                ...formData,
                validUntil,
                bodyText: bodyHtml
            });
            dispatch(showMessage({
                message: 'Η ανακοίνωση δημοσιεύθηκε επιτυχώς',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            }));
            handleClose();
            props.refreshAnnouncements();
            // If there are files, upload them
            if (files.length > 0) {
                const uploadPromises = files.map(file => {
                    return postAnnouncementFile(announcementResponse.id, file.file);
                });

                try {
                    const fileUploadResponses = await Promise.all(uploadPromises);
                    console.log('All files uploaded successfully:', fileUploadResponses);
                    setFiles([]);
                } catch (error) {
                    console.error('Error uploading one or more files:', error);
                }
            }

        } catch (error) {
            console.error('Error posting announcement:', error);
            dispatch(showMessage({
                message: 'Υπάρχει πρόβλημα με την δημοσίευση ',
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
        } finally {
            setLoading(false);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        // Reset form data to initial state
        setFormData({
            carrierId: '',
            validUntil: '',
            title: '',
            bodyText: EditorState.createEmpty(),
            isPublished: false,
            sticky: false
        });
        setFiles([]);
    };

    return (
        <>
            <Button onClick={handleOpen} variant="contained" sx={{m: 2, bgcolor: "#003476 !important"}}>
                Δημιουργία Ανακοίνωσης
            </Button>
            <Dialog sx={{
                '& .MuiDialog-paper': {
                    width: '80vw',
                    height: '90vh',
                    maxWidth: 'none',
                    maxHeight: 'none',
                    p: 4,
                    backgroundColor: '#EDF0F6 !important',
                }
            }} open={open} onClose={handleClose}>
                <Box component="form" onSubmit={handleSubmit} sx={{mt: 3}}>
                    <LoadingOverlay open={loading}/>
                    <Typography variant="h6">Δημιουργία ανακοίνωσης</Typography>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Τίτλος"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Φορέας</InputLabel>
                        <Select
                            name="carrierId"
                            value={formData.carrierId}
                            label="Φορέας"
                            onChange={handleChange}
                        >
                            {carriers.map((carrier) => (
                                <MenuItem key={carrier.id} value={carrier.id}>
                                    {carrier.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{border: '1px solid #F1F1F1', padding: '5px', minHeight: '200px', marginTop: '20px'}}>
                        <Editor
                            editorState={formData.bodyText}
                            onEditorStateChange={handleEditorChange}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            editorStyle={{
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                padding: "5px",
                                minHeight: "200px",
                                maxHeight: "200px",
                                width: "100%",
                                cursor: "text"
                            }}
                            toolbar={{
                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                                inline: {
                                    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                    bold: {className: 'bordered-option-classname'},
                                    italic: {className: 'bordered-option-classname'},
                                    underline: {className: 'bordered-option-classname'},
                                    strikethrough: {className: 'bordered-option-classname'},
                                    monospace: {className: 'bordered-option-classname'},
                                },
                                blockType: {
                                    className: 'bordered-option-classname',
                                    dropdownClassName: 'dropdown-classname',
                                },
                                fontSize: {className: 'bordered-option-classname'},
                                fontFamily: {
                                    options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                                    className: 'bordered-option-classname',
                                    dropdownClassName: 'dropdown-classname',
                                },
                                list: {
                                    inDropdown: false,
                                    className: 'bordered-option-classname',
                                    dropdownClassName: 'dropdown-classname',
                                    options: ['unordered', 'ordered', 'indent', 'outdent'],
                                },
                                textAlign: {
                                    inDropdown: false,
                                    className: 'bordered-option-classname',
                                    dropdownClassName: 'dropdown-classname',
                                    options: ['left', 'center', 'right', 'justify'],
                                },
                                history: {
                                    inDropdown: false,
                                    className: 'bordered-option-classname',
                                    options: ['undo', 'redo'],
                                },
                            }}

                        />
                    </div>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 2,
                        flexWrap: 'wrap'
                    }}>
                        <TextField
                            margin="normal"
                            label="Ενεργό έως"
                            type="datetime-local"
                            InputLabelProps={{shrink: true}}
                            name="validUntil"
                            value={formData.validUntil}
                            onChange={handleChange}
                            sx={{minWidth: 240, flexGrow: 1}}
                        />
                        <FormControl margin="normal" sx={{ minWidth: 240, flexGrow: 1 }}>
                            <InputLabel>Is Active?</InputLabel>
                            <Select
                                name="isActive"
                                value={formData.isActive ? 'true' : 'false'}
                                label="Is Active?"
                                onChange={handleChange}
                            >
                                <MenuItem value="false">Όχι</MenuItem>
                                <MenuItem value="true">Ναι</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl margin="normal" sx={{ minWidth: 240, flexGrow: 1 }}>
                            <InputLabel>Is Published?</InputLabel>
                            <Select
                                name="isPublished"
                                value={formData.isPublished ? 'true' : 'false'}
                                label="Is Published?"
                                onChange={handleChange}
                            >
                                <MenuItem value="false">Όχι</MenuItem>
                                <MenuItem value="true">Ναι</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl margin="normal" sx={{ minWidth: 240, flexGrow: 1 }}>
                            <InputLabel>Sticky?</InputLabel>
                            <Select
                                name="sticky"
                                value={formData.sticky ? 'true' : 'false'}
                                label="Sticky?"
                                onChange={handleChange}
                            >
                                <MenuItem value="false">Όχι</MenuItem>
                                <MenuItem value="true">Ναι</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <AnnouncmentFileSelect files={files} setFiles={setFiles}/>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                backgroundColor: !formData.title || !formData.bodyText.getCurrentContent().hasText() || !formData.carrierId ? '#c0c0c0 !important' : '#003476 !important',
                                color: !formData.title || !formData.bodyText.getCurrentContent().hasText() || !formData.carrierId ? 'darkgray !important' : 'white !important',

                            }}
                            disabled={
                                !formData.title || !formData.bodyText.getCurrentContent().hasText() || !formData.carrierId
                            }

                        >
                            Δημιουργία
                        </Button>
                        <Button onClick={handleClose} variant="contained"
                                sx={{bgcolor: "#d32f2f !important", color: "white"}}>
                            Κλείσιμο
                        </Button>
                    </Box>
                </Box>
            </Dialog>

        </>
    );
};

export default AnnouncementForm;
