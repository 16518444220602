import { axiosApi } from "app/configs/axiosConfig";

const updateQuestionAnswer = async (qnaData) => {
    try {
        const response = await axiosApi.put('/questionNAnswer', qnaData);
        if (response.status === 200) {
            return response
        } else {
            throw new Error('Failed to update question and answer');
        }
    } catch (error) {
        console.error('Failed to update question and answer', error);
        throw error;
    }
};

export default updateQuestionAnswer;
