import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {
    setArticle,
    setCurrentPage,
    setTotalPages,
} from "store/knowledgeBasePageSlice";
import {appendErotisiEditorState, setErotisiEditorState, validateErotisiText} from "store/userInfoSlice";
import {CircularProgress} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {stateFromHTML} from "draft-js-import-html";
import {EditorState, Modifier} from "draft-js";
import MaterialPagination from "app/main//Reusable Components/MaterialPagination";
import linkifyHtml from "linkify-html";

function ResultsGnosiaki() {
    const dispatch = useDispatch();
    const results = useSelector((state) => state.knowledgeBasePageSlice.results);
    const searchResults = useSelector(
        (state) => state.knowledgeBasePageSlice.searchResults
    );
    const selectedQA = useSelector(
        (state) => state.knowledgeBasePageSlice.article
    );
    const currentPage = useSelector(
        (state) => state.knowledgeBasePageSlice.currentPage
    );
    const perPage = 4;
    const isLoading = useSelector(
        (state) => state.knowledgeBasePageSlice.isLoading
    );
    const searchQuery = useSelector(
        (state) => state.knowledgeBasePageSlice.searchQuery
    );
    const erotisi = useSelector((state) => state.userInfoSlice.erotisi)
    const totalPages = useSelector(
        (state) => state.knowledgeBasePageSlice.totalPages
    );
    const currentResults = searchResults.length
        ? searchResults
        : results.slice(currentPage * perPage, currentPage * perPage + perPage);
    // const [totalPages, setTotalPages] = useState(Math.ceil(results.length / perPage));
    const [isSearchResultsActive, setIsSearchResultsActive] = useState(false);

    useEffect(() => {
        if (searchQuery === "") {
            dispatch(setTotalPages(Math.ceil(results.length / perPage)));
            if (isSearchResultsActive) {
                setIsSearchResultsActive(false);
                dispatch(setCurrentPage(0));
            }
        } else {
            dispatch(setTotalPages(1));
            if (!isSearchResultsActive) {
                setIsSearchResultsActive(true);
                dispatch(setCurrentPage(0));
            }
        }
    }, [searchQuery, results, dispatch, isSearchResultsActive]);

    const handleItemClick = (item) => {
        dispatch(setArticle(item));
    };

    const handleClose = () => {
        dispatch(setArticle(null));
    };

    //Detect hyperlinks and make them clickable
    const processCommentText = (text) => {
        const options = {
            defaultProtocol: 'https',
            attributes: {
                rel: 'noopener noreferrer',
                target: '_blank',
                style: 'color: #003476;'
            }
        };
        return linkifyHtml(text, options);
    };

    const preprocessHtml = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        const elementsToReplace = ["figure", "table", "tbody", "tr", "td"];
        elementsToReplace.forEach((element) => {
            const elements = doc.getElementsByTagName(element);
            while (elements.length > 0) {
                const div = doc.createElement("div");
                div.innerHTML = elements[0].innerHTML;
                elements[0].parentNode.replaceChild(div, elements[0]);
            }
        });

        // Trim the leading spaces at the start of the body's innerHTML
        doc.body.innerHTML = doc.body.innerHTML.replace(/^\s+/, '');

        return doc.body.innerHTML;
    };


    const handleTransferAnswerToEditor = () => {
        if (selectedQA) {
            const newHtmlContent = preprocessHtml(`${selectedQA.question} <br> ${selectedQA.answer}`);
            const combinedHtmlContent = erotisi ? `${erotisi}<br>${newHtmlContent}` : newHtmlContent;
            dispatch(validateErotisiText(combinedHtmlContent));
            dispatch(appendErotisiEditorState(combinedHtmlContent));

            handleClose();
        }
    };


    return (
        <div className="w-full">
            {isLoading ? (
                <div className="flex justify-center items-center h-full">
                    <CircularProgress/>
                </div>
            ) : (
                <div>
                    {currentResults.length > 0 ? (
                        currentResults.map((item) => (
                            <button
                                type="button"
                                className="border mb-1 rounded-md cursor-pointer w-full bg-white hover:bg-cyan-100 text-left"
                                key={item.id}
                                onClick={() => handleItemClick(item)}
                            >
                                <strong>{item.question}</strong>
                                <br/>
                            </button>
                        ))
                    ) : (
                        <h2 className="text-center">Δεν βρέθηκαν αποτελέσματα</h2>
                    )}

                    <Dialog
                        open={!!selectedQA}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                        maxWidth={"md"}
                    >
                        {selectedQA && (
                            <>
                                <DialogTitle id="alert-dialog-title">
                                    {selectedQA.question}
                                </DialogTitle>
                                <DialogContent dividers>
                                    <div
                                        dangerouslySetInnerHTML={{__html: processCommentText(selectedQA.answer)}}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleTransferAnswerToEditor}
                                        color="primary"
                                    >
                                        Μεταφορά Ερώτησης και Απάντησης
                                    </Button>
                                    <Button onClick={handleClose} color="primary" autoFocus>
                                        Κλείσιμο
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                    </Dialog>
                    {currentResults.length > 0 && (
                        <MaterialPagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setPageAction={setCurrentPage}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

export default ResultsGnosiaki;
