import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import {checkForTicketChanges} from "app/main/Reusable Components/TicketChanges";
import {hasBeneficiaryDataChanged} from "app/main/Reusable Components/IsBeneficiaryDataChanged";
import {
    setBeneficiaryChanges,
    setEditOriginalBeneficiary,
    setHasUnsavedChanges,
    setIsCommentSubmitted,
    setIsUserInfoFetched, setRepresentativeChanges
} from "store/editTicketSlice";
import {searchTeams} from "app/Api Calls/SearchTeams";
import updateTicket from "app/Api Calls/UpdateTicket";
import assignTeamToTicket from "app/Api Calls/AssignTeamToTicket";
import createBeneficiary from "app/Api Calls/CreateBeneficiary";
import updateBeneficiary from "app/Api Calls/UpdateBeneficiary";
import createRepresentative from "app/Api Calls/CreateRepresentative";
import updateRepresentative from "app/Api Calls/UpdateRepresentative";
import {showMessage} from "store/messageSlice";
import {hasRepresentativeChanged} from "app/main/Reusable Components/HasRepresentaticeChanged";
import {CircularProgress} from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {setBeneficiaryId} from "store/userInfoSlice";
import {fetchTeamById} from "app/Api Calls/GetTeamById";
import fetchThematicById from "app/Api Calls/GetThematicById";

const ChangeDetectedDialog = ({ticketId, onUpdate, showUnsavedChangedDialog, onCloseDialog}) => {
    const dispatch = useDispatch();
    const ticket = useSelector((state) => state.editTicketSlice.ticket);
    const selectedSubSubthematic = useSelector((state) => state.knowledgeBasePageSlice.selectedSubSubthematic);
    const selectedSubThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedSubthematic);
    const activeFlags = useSelector((state) => state.knowledgeBasePageSlice.activeFlags);
    const selectedGeography = useSelector((state) => state.ticketSlice.selectedGeography);
    const originalBeneficiaryData = useSelector((state) => state.editTicketSlice.editOriginalBeneficiary);
    const currentBeneficiary = useSelector((state) => state.userInfoSlice.currentBeneficiary);
    const [dialogLoading, setDialogLoading] = useState(false);
    const team = useSelector((state) => state.editTicketSlice.team);
    const hasThematic = team?.carriersWithThematicsDto.some(carrier => carrier.thematicCollection.some(thematic => thematic.id === selectedSubSubthematic?.value));
    const representativeFilled = useSelector(
        (state) => state.userInfoSlice.representativeFilled
    );
    const subSubthematics = useSelector((state) => state.knowledgeBasePageSlice.subSubthematics);
    const representativeId = useSelector(
        (state) => state.userInfoSlice.representativeId
    );
    const originalRepresentativeData = useSelector(
        (state) => state.userInfoSlice.originalRepresentativeData
    );
    const currentRepresentative = useSelector((state) => state.userInfoSlice.currentRepresentative)
    const userFilled = useSelector((state) => state.userInfoSlice.userFilled);
    const hasBeneficiaryFetched = useSelector(
        (state) => state.userInfoSlice.hasBeneficiaryFetched
    );

    const shouldCreateBeneficiary = useSelector(
        (state) => state.userInfoSlice.shouldCreateBeneficiary
    );
    const selectedResolution = useSelector(
        (state) => state.resolutionsSlice.selectedResolution
    );

    const callerId = useSelector((state) => state.ticketSlice.callerId);
    const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);

    const beneficiaryId = useSelector(
        (state) => state.userInfoSlice.beneficiaryId
    );
    const originalAsignee = useSelector((state) => state.editTicketSlice.originalAsignee);
    const differencesFromOriginalBeneficiary = useSelector((state) => state.beneficiaryFetchSlice.differencesFromOriginalBeneficiary)
    const showGeographyChangeMessage = selectedGeography && ticket.geographyId !== selectedGeography.value;
    const shouldCreateRepresentative = useSelector((state) => state.representativeFetchSlice.shouldCreateRepresentative)
    const editOriginalRepresentative =  useSelector((state) => state.editTicketSlice.editOriginalRepresentative);

    function isObjectEmpty(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
    }

    const isRepresentativeDataChanged = hasRepresentativeChanged(editOriginalRepresentative, currentRepresentative);
    const hasThematicChanges = useSelector((state) => state.editTicketSlice.hasThematicsChanged)
    //Knowledge Base fields
    const thematicId = useSelector(
        (state) =>
            state.knowledgeBasePageSlice.selectedSubSubthematic?.value ||
            state.knowledgeBasePageSlice.selectedSubthematic?.value ||
            state.knowledgeBasePageSlice.selectedThematic?.value ||
            null
    );
    const erotisi = useSelector((state) => state.userInfoSlice.erotisi);
    const [loading, setLoading] = useState(false);
    let isBeneficiaryDataChanged = false;
    if (hasBeneficiaryFetched || !shouldCreateBeneficiary) {
        isBeneficiaryDataChanged = hasBeneficiaryDataChanged(originalBeneficiaryData, currentBeneficiary);

    }
    const [flagsHaveChanged, setFlagsHaveChanged] = useState(false);
    const [thematicHaveChanged, setThematicHaveChanged] = useState(false);
    const[geographyChanged,setGeographyChanged]  = useState(false);
    const editOriginalBeneficiary = useSelector((state) => state.editTicketSlice.editOriginalBeneficiary)
    useEffect(() => {
        if (ticket) {
            const {thematicChanged, flagsChanged, geographyChanged} = checkForTicketChanges(
                ticket,
                selectedSubSubthematic,
                selectedSubThematic,
                activeFlags,
                selectedGeography,
                subSubthematics
            );
         const beneficiaryChanges = hasBeneficiaryDataChanged(editOriginalBeneficiary, currentBeneficiary);
         const representativeChanges = hasRepresentativeChanged(editOriginalRepresentative,currentRepresentative)
            dispatch(setBeneficiaryChanges(beneficiaryChanges));
            dispatch(setRepresentativeChanges(representativeChanges));
            setFlagsHaveChanged(flagsChanged);
            setThematicHaveChanged(thematicChanged);
            setGeographyChanged(geographyChanged)
            const hasChanges = thematicChanged || flagsChanged || geographyChanged || beneficiaryChanges || representativeChanges;

            dispatch(setHasUnsavedChanges(hasChanges));

        }
    }, [
        ticket,
        selectedSubSubthematic,
        selectedSubThematic,
        activeFlags,
        selectedGeography,
       currentBeneficiary,
        editOriginalBeneficiary,
        checkForTicketChanges,
        hasBeneficiaryDataChanged,
        hasRepresentativeChanged,
        currentRepresentative
    ]);

    const handleClose = () => {
        showUnsavedChangedDialog(false)
    };

    const handleBeneficiary = async () => {
        let newBeneficiaryId = null;
        let newRepresentativeId = null;

        const areAllValuesNull = (obj) => {
            return Object.values(obj).every(value => value === null);
        };

        try {
            // Handling beneficiary creation or retrieval
            if (userFilled && !hasBeneficiaryFetched && shouldCreateBeneficiary) {
                newBeneficiaryId = await createBeneficiary(currentBeneficiary);
            } else if (
                isBeneficiaryDataChanged &&
                currentBeneficiary &&
                originalBeneficiaryData &&
                originalBeneficiaryData.id === currentBeneficiary.id
            ) {
                try {
                    await updateBeneficiary(currentBeneficiary);
                    dispatch(setEditOriginalBeneficiary([]));
                } catch (error) {
                    console.error('An error occurred during beneficiary update:', error);
                    showErrorMessage(new Error('BENEFICIARY_UPDATE_FAILED'));
                }
            } else if (!shouldCreateBeneficiary) {
                newBeneficiaryId = currentBeneficiary.id;
                dispatch(setEditOriginalBeneficiary([]));
            }

            // Handling representative creation or update
            if (representativeFilled && areAllValuesNull(editOriginalRepresentative)) {
                try {
                    newRepresentativeId = await createRepresentative(currentRepresentative);
                } catch (error) {
                    console.error('An error occurred during representative creation:', error);

                }
            } else if (isRepresentativeDataChanged) {
                try {
                    await updateRepresentative(currentRepresentative?.id,currentRepresentative);
                } catch (error) {
                    console.error('An error occurred during representative update:', error);
                }
            } else if (!shouldCreateRepresentative) {
                newRepresentativeId = editOriginalRepresentative.id;
            }

        } catch (error) {
            console.error("An error occurred:", error);
            return {newBeneficiaryId, newRepresentativeId};
        }

        return {newBeneficiaryId, newRepresentativeId};
    };


    const updateTicketAndHandleThematicChange = async () => {
        setLoading(true);
        const teamId = team ? team.id : null;
        let updateSucceeded = false;

        // Assume handleBeneficiary() and other necessary data preparations are done here
        const {newBeneficiaryId, newRepresentativeId} = await handleBeneficiary();

        try {
            // First, update the ticket regardless of the thematic changes
            const updateResponse = await updateTicket(
                ticketId,
                selectedResolution,
                activeFlags,
                newRepresentativeId !== null ? newRepresentativeId : representativeId,
                erotisi,
                thematicId,
                newBeneficiaryId !== null ? newBeneficiaryId : beneficiaryId,
                activeStatus,
                true,
                originalAsignee,
                teamId,
                selectedGeography && selectedGeography.value ? selectedGeography.value : null
            );

            // Assume update is successful at this point
            updateSucceeded = true;
            const sourceApplicationId = updateResponse && updateResponse.sourceApplication ? updateResponse.sourceApplication.id : null;

            let thematicExistsInTeam = true;

            // Only fetch the team's thematics if the thematic has changed
            if (hasThematicChanges && teamId) {
                const teamData = await fetchTeamById(teamId);

                for (const carrier of teamData.carriersWithThematicsDto) {
                    const existingThematicIds = new Set(carrier.thematicCollection.map(t => t.id));

                    const parentIdsArray = carrier.thematicCollection
                        .filter(t => t.parentId !== null)
                        .map(t => t.parentId);

                    // Ensuring parentIds are unique
                    const parentIds = [...new Set(parentIdsArray)];

                    const missingParentIds = parentIds.filter(parentId => !existingThematicIds.has(parentId));

                    for (const parentId of new Set(missingParentIds)) {
                        const missingParentThematic = await fetchThematicById(parentId);
                        carrier.thematicCollection.push(missingParentThematic);
                    }
                }

                thematicExistsInTeam = teamData.carriersWithThematicsDto.some(
                    (carrier) => carrier.thematicCollection.some(
                        (thematic) => thematic.id === thematicId
                    )
                );
            }

            // Search for a new team if the thematic does not exist in the current team or if geography has changed
            if (!thematicExistsInTeam || geographyChanged) {
                const geographyId = selectedGeography && selectedGeography.value ? selectedGeography.value : null;
                const response = await searchTeams(0, 10, team.groupRole.id, ticket.carrierId, (thematicExistsInTeam && !geographyChanged) ? null : selectedSubSubthematic.value, geographyId, null, sourceApplicationId);

                if (response && response.elements && response.elements.length > 0) {
                    const firstTeam = response.elements[0];

                    // Check if the new team's ID is different from the current team's ID before reassigning
                    if (firstTeam.id !== teamId) {
                        try {
                            await assignTeamToTicket(ticket.id, firstTeam.id);
                            console.log('Ticket successfully reassigned based on thematic or geography change.');
                        } catch (error) {
                            console.error("Error reassigning team:", error);
                            // Dispatch an error message
                            dispatch(showMessage({
                                message: "Αποτυχία Καταχώρησης Ομάδας",
                                autoHideDuration: 2000,
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                variant: "error",
                            }));
                        }
                    } else {
                        console.log('The selected team is the same as the current team. No reassignment necessary.');
                    }
                } else {
                    dispatch(showMessage({
                        message: "Αδυναμία εύρεσης ομάδας",
                        autoHideDuration: 2000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    }));
                    console.error('No matching teams found. Continuing without reassignment.');
                }
            }

            if (updateSucceeded) {
                showSuccessMessage();
                onUpdate();
            }
        } catch (error) {
            console.error("An error occurred:", error);
            showErrorMessage(new Error("TICKET_CREATION_OR_UPDATE_FAILED"));
        } finally {
            setLoading(false);
        }
    };



    const showSuccessMessage = () => {
        dispatch(
            showMessage({
                message: "Το ticket καταχωρήθηκε επιτυχώς",
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "success",
            })
        );
    };

    const showErrorMessage = (error) => {
        let message;
        switch (error.message) {
            case "COMMENT_NOT_SUBMITTED":
                message =
                    "Προσθέστε Απάντηση στο Ticket για να μπορέσετε να εκτελέσετε ενέργεια σε αυτό";
                break;
            case "REPRESENTATIVE_CREATION_OR_UPDATE_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση ή ενημέρωση του αντιπροσώπου";
                break;
            case "USER_NOT_FOUND":
                message = "Δεν βρέθηκε χρήστης για ανάθεση του ticket";
                break;
            case "BENEFICIARY_CREATION_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση του δικαιούχου";
                break;
            case "TICKET_CREATION_OR_UPDATE_FAILED":
                message = "Πρόβλημα κατα την καταχώρηση ή ενημέρωση του ticket";
                break;
            case "Δεν βρέθηκε χρήστης για ανάθεση του ticket":
                message = "Δεν βρέθηκε χρήστης για ανάθεση του ticket";
                break;
            case "Ο χρήστης δεν έχει δικαιώματα ενεργειών":
                message = "Ο χρήστης δεν έχει δικαιώματα ενεργειών";
                break;
            case "Problem with sa assign":
                message = "Πρόβλημα κατά την ανάθεση SA στο ticket"
                break;
            case "BENEFICIARY_UPDATE_FAILED":
                message = "Πρόβλημα κατα την ενημέρωση του δικαιούχου";
                break;
            case "RESOLUTION_ERROR":
                message =
                    "Υπήρξε πρόβλημα κατά την αποθήκευση της ενέργειας του ticket";
                break;
            case "TICKET_CREATION_ERROR":
                message = "Υπήρξε πρόβλημα κατά την δημιουργία του ticket";
                break;
            default:
                message = "Υπήρξε πρόβλημα κατά την καταχώρηση του ticket";
        }
        dispatch(
            showMessage({
                message,
                autoHideDuration: 2000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            })
        );
    };


    return (
        <Dialog open={showUnsavedChangedDialog} onClose={onCloseDialog}>
            <DialogTitle>Έχετε αλλαγές στο ticket:</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <DialogContentText component={"div"}>
                        {/* Initialize an empty array to hold messages */}
                        {(() => {
                            const messages = [];
                            // Add the thematic change message
                            if (thematicHaveChanged && !hasThematic) {
                                // If the thematic hasn't changed AND the selected thematic will change the service team
                                messages.push("Η Θεματική άλλαξε και θα πρέπει να αλλάξει η ομάδα εξυπηρέτησης.");
                            } else if (thematicHaveChanged) {
                                messages.push("Η Θεματική έχει αλλάξει");
                            }

                            // Add the geography change message
                            if (showGeographyChangeMessage) {
                                messages.push("Η γεωγραφία έχει αλλάξει.");
                            }

                            if (flagsHaveChanged) {
                                messages.push("Τα Flags έχουν αλλάξει.");
                            }

                            if(isBeneficiaryDataChanged){
                                messages.push("Τα στοιχεία του δικαιούχου έχουν αλλάξει");
                            }

                            if(isRepresentativeDataChanged){
                                messages.push("Τα στοιχεία του αντιπροσώπου έχουν αλλάξει");

                            }

                            // Add a prompt to proceed with changes
                            messages.push("Θέλετε να προχωρήσετε στην αποθήκευση τους;");

                            // Map each message to a ListItem component
                            return (
                                <List>
                                    {messages.map((message, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={message}/>
                                        </ListItem>
                                    ))}
                                </List>
                            );
                        })()}
                    </DialogContentText>

                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(setHasUnsavedChanges(false));
                        dispatch(setBeneficiaryId(null));
                        onUpdate();

                    }}
                    disabled={loading}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
                >
                    Διαγραφή αλλαγών
                </Button>
                <Button onClick={onCloseDialog} disabled={loading}>ΠΙΣΩ</Button>
                <Button onClick={() => {
                    updateTicketAndHandleThematicChange()
                }}
                        autoFocus disabled={loading}>
                    ΑΠΟΘΗΚΕΥΣΗ
                </Button>
            </DialogActions>
        </Dialog>

    );
};

export default ChangeDetectedDialog;
