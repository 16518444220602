import { axiosApi } from "app/configs/axiosConfig";


export const enableTaxis = async (id) => {
  try {
    const response = await axiosApi.patch(`/admin/user/allowToLoginWithPassword/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to enable user: ${error}`);
  }
};

export const disableTaxis = async (id) => {
  try {
    const response = await axiosApi.patch(`/admin/user/disallowToLoginWithPassword/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to disable user: ${error}`);
  }
};

