import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getSpecificUser } from "app/Api Calls/GetSpecificUser";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import { useDispatch } from "react-redux";
import {
  setSaModal,
  setSelectedResolution,
} from "store/resolutionsSlice";
import CircularProgress from "@mui/material/CircularProgress";
import getAllGeographies from "app/Api Calls/GetAllGeographies";
import { getGeographyByCarrierId } from "app/Api Calls/GetGeographyByCarrierId";
import { searchTeams } from "app/Api Calls/SearchTeams";
import { jwtService } from "app/auth/jwtService";

const MultiStepSelectModal = ({ onFinish }) => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [specificUser, setSpecificUser] = useState({});
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const saModal = useSelector((state) => state.resolutionsSlice.saModal);
  const user = jwtService.getUser();
  const userRole = user.roleName;
  const dispatch = useDispatch();
  const [selectedGeography, setSelectedGeography] = useState("");
  const carrier = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedCarrier
  );
  const thematic = useSelector(
    (state) => state.knowledgeBasePageSlice.selectedThematic
  );
  const groupRoleId = 4;
  const [isLoadingSa, setIsLoadingSa] = useState(false);
  const [geographies, setGeographies] = useState([]);
  const [isLoadingTeams, setIsLoadingTeams] = useState(false);
  const selectedCarrier = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
  useEffect(() => {
    async function fetchData() {
      // Check if there's a selected carrier and if it has a value
      if (selectedCarrier && selectedCarrier.value) {
        try {
          // Fetch geographies using the new API service
          const fetchedGeographies = await getGeographyByCarrierId(selectedCarrier.value);
          const sortedGeographies = fetchedGeographies.sort((a, b) => a.name.localeCompare(b.name));
        
        setGeographies(sortedGeographies);
        } catch (error) {
          console.error("Error fetching geographies:", error);
        }
      }
  
      // Fetch user details
      const user = jwtService.getUser();
  
      try {
        const userDetails = await getSpecificUser(user.id);
        setSpecificUser(userDetails);
      } catch (error) {
        console.error("Error fetching specific user:", error);
      }
    }
  
    fetchData();
  }, [saModal]);

  const fetchUserTeams = async (currentPage = 0, accumulatedTeams = []) => {
    if (!specificUser || !selectedGeography) return;
  
    const geographyIdToSend = selectedGeography === "0" ? 0 : selectedGeography;
    setIsLoadingTeams(true);
  
    try {
      const response = await searchTeams(
        currentPage,
        size,
        groupRoleId,
        carrier.value,
        thematic.value,
        geographyIdToSend
      );
  
      const updatedTeams = [...accumulatedTeams, ...response.elements];
  
      if (response.totalElements > updatedTeams.length) {
        await fetchUserTeams(currentPage + 1, updatedTeams);
      } else {
        const sortedTeams = updatedTeams.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
  
        setTeams(sortedTeams);
      }
    } catch (error) {
      console.error("Error fetcing teams:", error);
    } finally {
      setIsLoadingTeams(false);
    }
  };
  
  useEffect(() => {
    fetchUserTeams();
  }, [specificUser, selectedGeography]);


  const handleTeamSelect = (teamId) => {
    if (selectedTeam === teamId) {
      setSelectedTeam(null);
    } else {
      setSelectedTeam(teamId);
    }
  };

  const handleCloseModal = () => {
    dispatch(setSaModal(false));
    dispatch(setSelectedResolution(null));
    setSelectedTeam(null);
    setSelectedGeography("")
    setTeams([]);
  };

  const handleYesClick = async () => {
    if (selectedTeam) {
      setIsLoadingSa(true);
      await onFinish(selectedTeam);
      handleCloseModal();
      setIsLoadingSa(false);
    }
  };

  return (
    <Dialog open={saModal} fullWidth maxWidth="md">
      <DialogTitle>Επιλογή ανάθεσης στο τρίτο επίπεδο</DialogTitle>
      <DialogContent
        sx={{
          paddingTop: "10px !important",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isLoadingSa ? (
          <div style={{ marginTop: "40px" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Select
    value={selectedGeography}
    onChange={(e) => setSelectedGeography(e.target.value)}
    fullWidth
    displayEmpty
>
    <MenuItem value="">Επιλέξτε Γεωγραφική Ενότητα</MenuItem>
    <MenuItem value="0">Καμία</MenuItem>
    {geographies.map((geo) => (
        <MenuItem key={geo.id} value={geo.id}>
            {geo.name}
        </MenuItem>
    ))}
</Select>

  
            {selectedGeography && (
              isLoadingTeams ? (
                <div style={{ marginTop: "40px" }}>
                  <CircularProgress />
                </div>
              ) : (
                <List sx={{ width: "100%" }} component="nav">
                  {teams.length > 0 ? (
                    teams
                      .filter(
                        (team, index, self) =>
                          index === self.findIndex((t) => t.id === team.id)
                      )
                      .map((team) => (
                        <div
                          key={team.id}
                          style={{
                            backgroundColor:
                              selectedTeam === team.id ? "#cfcfcf" : "#f7f7f7",
                            margin: "10px 0",
                            border:
                              selectedTeam === team.id ? "1px solid red" : "none",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <ListItem
                            button
                            onClick={() => handleTeamSelect(team.id)}
                          >
                            <ListItemText primary={team.name} />
                          </ListItem>
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography variant="subtitle1">
                                <MapIcon style={{ marginRight: "5px" }} />
                                Χωρικότητα
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ backgroundColor: "#e6e6e6" }}>
                              {team.geographyCollection && team.geographyCollection.length > 0 ? (
                                team.geographyCollection.map((geo) => (
                                  <div key={geo.id}>- {geo.name}</div>
                                ))
                              ) : (
                                <div>Καμία Χωρικότητα</div>
                              )}
                            </AccordionDetails>
                          </Accordion>
  
                          <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography variant="subtitle1">
                                <FilterVintageIcon style={{ marginRight: "5px" }} />
                                Θεματικές Ενότητες
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              style={{ backgroundColor: "#d9d9d9", maxHeight: "200px", overflowY: "auto" }}
                            >
                              {team.thematicCollection.filter((thematic) => thematic.parentId === null).map((thematic) => (
                                <div key={thematic.id}>* {thematic.title}</div>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      ))
                  ) : (
                    <Typography align="center" color="textSecondary">
                      Δεν βρέθηκε Ομάδα
                    </Typography>
                  )}
                </List>
              )
            )}
          </>
        )}
     </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary" disabled={isLoadingSa}>
          Όχι
        </Button>
        <Button onClick={handleYesClick} color="primary" disabled={!selectedTeam || isLoadingSa}>
          Ναι
        </Button>
      </DialogActions>
    </Dialog>
  );
                  }

export default MultiStepSelectModal;
