import React, { useEffect, useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { jwtService } from './jwtService';
import { logoutUser, setUser } from 'store/userSlice';
import { showMessage } from 'store/messageSlice';
import SplashScreen from './SplashScreen';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    jwtService.on('onAutoLogin', () => {
      jwtService
          .signInWithToken()
          .then((userData) => {
            success(userData);
          })
          .catch((error) => {
            console.error("Error during auto login:", error.message);
            pass(error.message);
            dispatch(logoutUser());
          });
    });

    jwtService.on('onLogin', (userData) => {
      navigate('/');
      success(userData, 'Συνδεθήκατε');
    });

    jwtService.on('onLogout', (message) => {
      dispatch(logoutUser());
      pass(message);
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);
      dispatch(logoutUser());
    });

    jwtService.on('onNoAccessToken', (message) => {
      pass(message);
    });

    jwtService.init();

    function success(userData, message) {
      if (message) {
        dispatch(showMessage({
          message,

        }));
      }

      if (userData) {
        Promise.all([
          dispatch(setUser(userData)),
        ]).then(() => {
          setWaitAuthCheck(false);
          setIsAuthenticated(true);
        });
      } else {
        pass('Failed to set user data');
      }
    }

    function pass(message) {
      if (message) {
        dispatch(
            showMessage({
              message:  message ,
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              variant: "",
            }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
      // navigate('/sign-in');
    }
  }, [dispatch]);

  return waitAuthCheck ? (
      <SplashScreen />
  ) : (
      <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
