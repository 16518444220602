import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
    setAllRepresentativeLastSearchParams,
    // Update these actions to those relevant for handling representatives in your store

    setHasRepresentativeFetched, setShouldCreateRepresentative,

} from "store/representaticeFetchSlice";
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
    // Update these actions to those relevant for fetching representatives
    setRepresentativeLastSearchParams,
    setFetchedRepresentatives, setIsLoadingRepresentative,clearSpecificLastSearchParam,
} from "store/representaticeFetchSlice";
import { showMessage } from "store/messageSlice";
import searchRepresentative from "app/Api Calls/searchRepresentative";
import {
    resetSearchParameteRepresentative,

    setOriginalRepresentativeData,
    updateRepresentativeData
} from "store/userInfoSlice";

function FetchRepresentative() {
    const afm = useSelector((state) => state.userInfoSlice.representativeAfm);
    const phone = useSelector((state) => state.userInfoSlice.representativePhone);
    const isAfmValid = useSelector((state) => state.userInfoSlice.isRepresentativeAfmValid);
    const isPhoneValid = useSelector((state) => state.userInfoSlice.isRepresentativePhoneValid);
    const mobile = useSelector((state) => state.userInfoSlice.representativeMobile);
    const isMobileValid = useSelector((state) => state.userInfoSlice.isRepresentativeMobileValid);
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [representativeData, setRepresentativeData] = useState(null);
    const [selectedRepresentative, setSelectedRepresentative] = useState(null);
    const isLoading = useSelector((state) => state.representativeFetchSlice.isLoadingRepresentative);
    const lastSearchParams = useSelector(
        (state) => state.representativeFetchSlice.lastRepresentativeSearchParams
    );
    const fetchedRepresentatives = useSelector(
        (state) => state.representativeFetchSlice.fetchedRepresentatives
    );
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [showAlert, setShowAlert] = useState(false);
    const currentRepresentative = useSelector((state)=> state.userInfoSlice.currentRepresentative)

    useEffect(() => {
        const validInputs = {
            afm: isAfmValid ? afm : null,
            phone: isPhoneValid ? phone : null,
            mobile: isMobileValid ? mobile : null
        };

        const reassessCreateOrUpdate = () => {
            const matchesFetchedRepresentative = fetchedRepresentatives.some(rep =>
                Object.entries(validInputs).some(([key, validInputValue]) =>
                    validInputValue && rep[key] === validInputValue
                )
            );

            const modificationWithoutConflict = Object.entries(validInputs).some(([key, validInputValue]) => {
                const potentialUpdateRepresentative = fetchedRepresentatives.find(rep =>
                    Object.entries(validInputs).every(([innerKey, innerValue]) =>
                        key !== innerKey ? (!innerValue || rep[innerKey] === innerValue) : true
                    )
                );

                return validInputValue && !fetchedRepresentatives.some(otherRep => otherRep[key] === validInputValue) && potentialUpdateRepresentative;
            });

            const isUpdateScenario = matchesFetchedRepresentative || modificationWithoutConflict;
            dispatch(setShouldCreateRepresentative(!isUpdateScenario));
            dispatch(setHasRepresentativeFetched(isUpdateScenario));
        };

        const triggerSearchAndUpdateLastSearch = (key, value) => {
            if (validInputs[key] && value !== lastSearchParams[key]) {
                fetchRepresentativeData(key, value);
                dispatch(setRepresentativeLastSearchParams({ [key]: value }));
            }
        };

        const clearIfModifiedAndReassess = (key, value) => {
            if (value !== '' && value !== lastSearchParams[key]) {
                dispatch(clearSpecificLastSearchParam(key));
                reassessCreateOrUpdate();
            }
        };

        Object.entries(validInputs).forEach(([key, value]) => {
            if (value) {
                // Only trigger search if the lastSearchParams don't match or they haven't been set
                if (!lastSearchParams[key] || lastSearchParams[key] !== value) {
                    triggerSearchAndUpdateLastSearch(key, value);
                }
            } else {
                clearIfModifiedAndReassess(key, value);
            }
        });

        reassessCreateOrUpdate();
    }, [afm, phone,mobile,isMobileValid, isAfmValid, isPhoneValid, dispatch, fetchedRepresentatives, lastSearchParams]);


    const fetchRepresentativeData = async (key, value) => {
        dispatch(setIsLoadingRepresentative(true));
        try {
            const response = await searchRepresentative({ [key]: value }, page, size);
            if (response && response.totalElements > 0) {
                dispatch(setRepresentativeLastSearchParams({ [key]: value }));
                dispatch(setFetchedRepresentatives(response.elements));
                if (response.elements.length === 1) {
                    setSelectedRepresentative(response.elements[0]);
                }
                setRepresentativeData(response.elements);
                setShowDialog(true);
            }
        } catch (error) {
            console.error("Error fetching representative:", error);
        } finally {
            dispatch(setIsLoadingRepresentative(false));
        }
    };

    const handleConfirm = async () => {
        if (selectedRepresentative) {
            // Perform the update with the selected representative's data
            dispatch(setOriginalRepresentativeData(selectedRepresentative));
            dispatch(updateRepresentativeData(selectedRepresentative));
            dispatch(setHasRepresentativeFetched(true));
            dispatch(setShouldCreateRepresentative(false));
            const {afm, phone, mobile} = selectedRepresentative;
            const newSearchParams = {
                afm: afm || null,
                phone: phone || null,
                mobile: mobile || null,
            };
            dispatch(setAllRepresentativeLastSearchParams(newSearchParams));
            setShowDialog(false);
            setShowAlert(true);
            dispatch(
                showMessage({
                    message: `Τα στοιχεία του αντιπροσώπου έχουν συμπληρωθεί`,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                })
            );
        }
    };

    const handleCloseDialog = () => {
        // Function to find if any field matches between two representatives
        const isAnyFieldMatching = (representative1, representative2) => {
            const fieldsToCompare = [...new Set([...Object.keys(representative1), ...Object.keys(representative2)])];

            return fieldsToCompare.some(field => representative1[field] === representative2[field]);
        };

        // Iterate over fetched representatives to check if any field matches with currentRepresentative
        const matchingFields = new Set();

        fetchedRepresentatives.forEach(fetchedRepresentative => {
            if (isAnyFieldMatching(currentRepresentative, fetchedRepresentative)) {
                // If any field matches, add this field to the matchingFields set
                Object.keys(currentRepresentative).forEach(key => {
                    if (currentRepresentative[key] === fetchedRepresentative[key]) {
                        matchingFields.add(key);
                    }
                });
            }
        });

        // Clear matching search parameters based on the identified matching fields, except phone and mobile
        matchingFields.forEach(field => {
            if (field !== 'phone' && field !== 'mobile') {
                dispatch(resetSearchParameteRepresentative(field));
                dispatch(clearSpecificLastSearchParam(field));
            }
        });

        const fieldsToClear = {};
        matchingFields.forEach(field => {
            if (field !== 'phone' && field !== 'mobile') {
                fieldsToClear[field] = '';
            }
        });

        dispatch(setShouldCreateRepresentative(true));
        dispatch(setHasRepresentativeFetched(false));
        setRepresentativeData(null);
        setSelectedRepresentative(null);
        setShowDialog(false);
    };




    return (
        <>
            <Dialog
                open={showDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {!isLoading && (
                    <DialogTitle id="alert-dialog-title">
                        Ο αντιπρόσωπος έχει ξανακαλέσει και τα στοιχεία του βρίσκονται στην βάση δεδομένων. Να
                        συμπληρωθούν τα στοιχεία του;
                    </DialogTitle>
                )}
                <DialogContent>
                    {isLoading ? (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <RadioGroup
                            value={selectedRepresentative ? selectedRepresentative.id.toString() : ""}
                            onChange={(event) => {
                                const representative = representativeData.find(
                                    (rep) => rep.id.toString() === event.target.value
                                );
                                setSelectedRepresentative(representative);
                            }}
                        >
                            {representativeData &&
                                representativeData.map((data, index) => (
                                    <div key={index}>
                                        <FormControlLabel
                                            value={data.id.toString()}
                                            control={<Radio/>}
                                            label={
                                                <>
                                                    Όνομα: {data.firstName}
                                                    <br/>
                                                    Επώνυμο: {data.lastName}
                                                    <br/>
                                                    Email: {data.email}
                                                    <br/>
                                                    Τηλέφωνο: {data.phone}
                                                    <br/>
                                                    ΑΦΜ: {data.afm}
                                                    <br/>
                                                    Διεύθυνση: {data.address}
                                                </>
                                            }
                                        />
                                        <hr/>
                                    </div>
                                ))}
                        </RadioGroup>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" disabled={isLoading}>
                        Όχι
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus disabled={isLoading}>
                        Ναι
                    </Button>
                </DialogActions>
            </Dialog>
            {showAlert && (
                <Snackbar open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                    <Alert onClose={() => setShowAlert(false)} severity="success" sx={{width: '100%'}}>
                        Τα στοιχεία του αντιπροσώπου έχουν ενημερωθεί επιτυχώς.
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}
export default FetchRepresentative ;