import { axiosApi } from "app/configs/axiosConfig";

const performActionOnTicket = async (resolutionPayload) => {
  try {


    const resolutionResponse = await axiosApi.post('/ticket/perfromActionOnTicket', resolutionPayload);
    return resolutionResponse.data;
  } catch (error) {
    console.error(error);

    if (error.response) {
     
      switch(error.response.status) {
        case 404:
          throw new Error("Δεν βρέθηκε χρήστης για ανάθεση του ticket");
        case 406:
          throw new Error("Ο χρήστης δεν έχει δικαιώματα ενεργειών");
        case 500:
          throw new Error("Υπήρξε πρόβλημα κατά την αποθήκευση της ενέργειας του ticket");

        default:
          throw new Error("An error occurred");
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
      throw new Error("No response received from the server");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      throw new Error("An error occurred while setting up the request");
    }
  }
};

export default performActionOnTicket;
