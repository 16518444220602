import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import {
  fetchTeamGeographiesAsync,
  fetchTeamAssignmentMethodsAsync,
  fetchRolesAsync,
  fetchGroupRolesAsync,
  fetchAvailableSourcesApplicationAsync,
  fetchUsersRoleBasedAsync,
  setSelectedCarrierSets,
  fetchUsersForCreateAsync,
  resetUsersForCreate,
} from "store/teamsSlice";
import CarrierThematics from "./CarrierThematics";
import { showMessage } from "store/messageSlice";
import { createTeam } from "app/Api Calls/TeamsRequiredApis";

const formFieldStyle = {
  backgroundColor: "#fff",
  marginBottom: "10px",
  width: "100%",
  maxWidth: "33%",
  marginLeft: "10px",
  marginRight: "10px",
};

const inputStyle = {
  fontSize: "13px",
  backgroundColor: "white",
};

const labelStyle = {
  fontSize: "13px",
};

const inputLabelShrinkStyle = {
  transform: "translate(14px, -6px) scale(1)",
  padding: "0 4px",
};

const inputLabelFocusedStyle = {
  color: "rgba(0, 0, 0, 0.54)",
  fontSize: "13px",
};

const buttonStyle = {
  backgroundColor: "#003476",
  marginTop: "10px",
  color: "#fff",
};

function TeamCreate({ onTeamCreate }) {
  const dispatch = useDispatch();
  const {
    teamGeographies,
    teamAssignmentMethods,
    groupRoles,
    availableSourcesApplication,
    page,
    size,
    usersForCreate,
    selectedCarrierSets,
    carrierSets
  } = useSelector((state) => state.teamsSlice);

  const initialTeamState = {
    name: "",
    name_en: "",
    group_role_id: "",
    assignment_method_code: "",
    is_active: false,
    userCollection: [],
    geographyCollection: [],
    availableSourcesApplication: [],
  };

  const [loadingUsers, setLoadingUsers] = useState(false);
  const [team, setTeam] = useState(initialTeamState);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const carriers = carrierSets.reduce((acc, set) => [...acc, ...set.carriers], []);
  useEffect(() => {
    dispatch(fetchTeamGeographiesAsync());
    dispatch(fetchTeamAssignmentMethodsAsync());
    dispatch(fetchRolesAsync());
    dispatch(fetchGroupRolesAsync());
    dispatch(fetchAvailableSourcesApplicationAsync());
  }, [dispatch]);

  const handleChange = (event) => {
    const value =
      event.target.name === "is_active"
        ? event.target.checked
        : event.target.value;
    setTeam((prevTeam) => ({
      ...prevTeam,
      [event.target.name]: value,
    }));
  };

  const handleDropdownChange = async (name, event) => {
    let value = event.target.value;

    if (name === "geographyCollection") {
      if (value.includes("Καμία") && value.length > 1) {
        value = value.filter(val => val !== "Καμία");
      }
    }

    if (name === "group_role_id") {
      const selectedRoleId = value;
      setTeam((prevTeam) => ({
        ...prevTeam,
        [name]: selectedRoleId,
        userCollection: [],
      }));

      if (selectedRoleId) {
        dispatch(resetUsersForCreate());
        const allUsers = await fetchAllUsersForRole(selectedRoleId, size);
        setTeam(prevTeam => ({
          ...prevTeam,
          userCollection: allUsers,
        }));
      }

    } else if (name === "availableSourcesApplication") {
      setTeam((prevTeam) => ({
        ...prevTeam,
        [name]: value,
      }));
    } else {
      setTeam((prevTeam) => ({
        ...prevTeam,
        [name]: value,
      }));
    }
  };

  // Sorting geographies
  const sortedTeamGeographies = useMemo(() => {
    const sortedCarriers = [...carriers].sort((a, b) => a.title.localeCompare(b.title));

    return teamGeographies
      .map(geo => {
        const carrier = sortedCarriers.find(carrier => carrier.id === geo.carrierId);
        return {
          ...geo,
          carrierTitle: carrier ? carrier.title : 'Δεν εξυπηρετεί συγκεκριμένο Φορέα'
        };
      })
      .sort((a, b) => {
        return a.carrierTitle.localeCompare(b.carrierTitle) || a.name.localeCompare(b.name);
      });
  }, [teamGeographies, carriers]);

  const handleUserSelectChange = (event, newValue) => {
    setSelectedUsers(newValue);

    const selectedUserIds = newValue.map(user => user.id);

    setTeam(prevTeam => ({
      ...prevTeam,
      userCollection: selectedUserIds,
    }));
  };

  const fetchAllUsersForRole = async (selectedRoleId, size) => {
    setLoadingUsers(true);
    let page = 0;
    let allUsers = [];

    const fetchPage = async () => {
      const result = await dispatch(
        fetchUsersForCreateAsync({
          page,
          size,
          requestBody: { groupRoleId: selectedRoleId },
        })
      );

      if (result.payload) {
        allUsers = allUsers.concat(result.payload.data);
        if (result.payload.currentPage < result.payload.totalPages - 1) {
          page++;
          await fetchPage();
        }
      }
    };

    await fetchPage();

    setLoadingUsers(false);

    return allUsers;
  };

  const isFormValid = () => {
    if (
      team.name === "" ||
      team.name_en === "" ||
      team.group_role_id === "" ||
      team.userCollection.length === 0 ||
      team.assignment_method_code === "" ||
      team.geographyCollection === "" ||
      team.availableSourcesApplication === "" ||
      selectedCarrierSets.some(
        (set) =>
          set.selectedThematics == null || set.selectedThematics.length === 0
      )
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let geographyCollectionPayload = team.geographyCollection;

    if (team.geographyCollection === "Καμία") {
      geographyCollectionPayload = "none";
    } else if (Array.isArray(team.geographyCollection)) {
      geographyCollectionPayload = teamGeographies
          .filter((geography) => team.geographyCollection.includes(geography.id))
          .map((geography) => ({ id: geography.id }));
    }

    const payload = {
      name: team.name,
      nameEn: team.name_en,
      sourceApplicationCollection: availableSourcesApplication
          .filter((source) =>
              team.availableSourcesApplication.includes(source.id)
          )
          .map((source) => ({ id: source.id })),
      userCollection: team.userCollection.map((userId) => ({ id: userId })),
      geographyCollection: geographyCollectionPayload,
      thematicCollection: selectedCarrierSets.flatMap((set) => {
        const collection = [];
        const selectedSubthematicsValues = Object.values(set.selectedSubthematics || {}).flatMap(obj => obj.value || []);

        set.selectedThematics.forEach(selectedThematic => {
          const thematicIdValue = selectedThematic.value;

          // Fetching all the subthematics associated with this thematic from carrierSets
          const subthematicsWithSameParent = carrierSets[0].subthematics.filter(
              subthematic => subthematic.parentId === thematicIdValue
          );

          // Fetching the selected subthematics for this thematic from selectedSubthematicsValues
          const selectedSubthematicsForThisThematic = subthematicsWithSameParent.filter(
              subthematic => selectedSubthematicsValues.includes(subthematic.id)
          );

          // Check the number of selected subthematics for this thematic
          if (selectedSubthematicsForThisThematic.length === 0) {
            // No subthematics selected, add only the thematic ID
            collection.push({ thematicId: thematicIdValue });
          } else if (selectedSubthematicsForThisThematic.length === subthematicsWithSameParent.length) {
            // All subthematics are selected, add only the thematic ID
            collection.push({ thematicId: thematicIdValue });
          } else {
            // Some subthematics are selected, add their IDs
            collection.push(...selectedSubthematicsForThisThematic.map(subthematic => ({ thematicId: subthematic.id })));
          }
        });

        return collection;
      }),


      assignmentMethodCode: team.assignment_method_code,
      groupRoleId: team.group_role_id,
    };

    try {
      const response = await createTeam(payload);
      onTeamCreate();
      setTeam(initialTeamState);
      setSelectedUsers([]);
      dispatch(
          setSelectedCarrierSets([
            { selectedCarrier: null, selectedThematics: null, selectedSubthematics: null },
          ])
      );
      dispatch(
          showMessage({
            message: "Επιτυχής Καταχώρηση Ομάδας",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "success",
          })
      );
    } catch (error) {
      dispatch(
          showMessage({
            message: "Αποτυχία Καταχώρησης Ομάδας",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            variant: "error",
          })
      );
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "20px", marginBottom: "20px", margin: "0 auto", maxWidth: "90%" }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Δημιουργία Νέας Απαντητικής Ομάδας
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexBasis: "100%", justifyContent: "space-between", maxWidth: "100%", width: "100%" }}>
              <TextField
                label="Όνομα"
                name="name"
                value={team.name}
                onChange={handleChange}
                style={formFieldStyle}
                InputProps={{ style: inputStyle }}
                InputLabelProps={{ style: labelStyle }}
              />
              <TextField
                label="Όνομα (Αγγλικά)"
                name="name_en"
                value={team.name_en}
                onChange={handleChange}
                style={formFieldStyle}
                InputProps={{ style: inputStyle }}
                InputLabelProps={{ style: labelStyle }}
              />
              <FormControl style={formFieldStyle}>
                <InputLabel style={labelStyle}>Ρόλος Ομάδας</InputLabel>
                <Select
                  value={team.group_role_id}
                  onChange={(event) => handleDropdownChange("group_role_id", event)}
                  label="Ρόλος Ομάδας"
                  style={inputStyle}
                  inputProps={{ style: inputStyle }}
                >
                  {groupRoles.map((role) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: "flex", flexBasis: "100%", justifyContent: "space-between", maxWidth: "100%", width: "100%" }}>
              {team.group_role_id && (
                <Box style={{backgroundColor: "white", marginBottom: "10px",
                width: "100%",
                marginLeft: "10px", marginRight: "10px", ...{ flex: "1" } }}>
                  <Autocomplete
                    multiple
                    value={selectedUsers}
                    onChange={handleUserSelectChange}
                    options={usersForCreate}
                    getOptionLabel={(option) => `${option.id}-${option.firstName}-${option.lastName}`}
                    disabled={loadingUsers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Χρήστες"
                        placeholder={loadingUsers ? "Φόρτωση..." : "Διαλέξτε χρήστες"}
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        InputLabelProps={{ style: labelStyle }}
                      />
                    )}
                  />
                </Box>
              )}
            </div>

            <div style={{ display: "flex", flexBasis: "100%", justifyContent: "space-between", maxWidth: "100%", width: "100%" }}>
              <FormControl style={formFieldStyle}>
                <InputLabel style={labelStyle}>Μέθοδος Ανάθεσης</InputLabel>
                <Select
                  value={team.assignment_method_code}
                  onChange={(event) => handleDropdownChange("assignment_method_code", event)}
                  label="Μέθοδος Ανάθεσης"
                  style={inputStyle}
                  inputProps={{ style: inputStyle }}
                >
                  {teamAssignmentMethods.map((method) => (
                    <MenuItem key={method.code} value={method.code}>
                      {method.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={formFieldStyle}>
                <InputLabel style={labelStyle}>Γεωγραφική Περιοχή</InputLabel>
                <Select
                  multiple
                  value={team.geographyCollection}
                  onChange={(event) => handleDropdownChange('geographyCollection', event)}
                  label="Γεωγραφική Περιοχή"
                  style={inputStyle}
                  inputProps={{ style: inputStyle }}
                >
                  <MenuItem
                    value=""
                    disabled={team.geographyCollection.length > 0}
                  >
                    Καμία
                  </MenuItem>
                  {sortedTeamGeographies.map((geo) => (
                    <MenuItem key={geo.id} value={geo.id}>
                      {`${geo.name} (${geo.carrierTitle})`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={formFieldStyle}>
                <InputLabel style={labelStyle}>Πλατφόρμα</InputLabel>
                <Select
                  multiple
                  value={team.availableSourcesApplication}
                  onChange={(event) => handleDropdownChange("availableSourcesApplication", event)}
                  label="Πλατφόρμα"
                  style={inputStyle}
                  inputProps={{ style: inputStyle }}
                >
                  {availableSourcesApplication.map((source) => (
                    <MenuItem key={source.id} value={source.id}>
                      {source.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ display: "flex", flexBasis: "100%", justifyContent: "space-between", maxWidth: "100%", width: "100%" }}>
              <CarrierThematics />
            </div>
            <Button
              style={{ ...buttonStyle }}
              variant="contained"
              type="submit"
              disabled={!isFormValid()}
              sx={{
                backgroundColor: isFormValid() ? undefined : '#ccc !important',
                color: isFormValid() ? undefined : 'white !important',
              }}
            >
              Προσθήκη Ομάδας
            </Button>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default TeamCreate;
