import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Chip
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {setSearchValues, setSelectedTicketStatus} from 'store/ticketFetchSlice';

const TicketFilterSelect = () => {
    const ticketStatuses = useSelector((state) => state.ticketSlice.statuses);
    const selectedTicketStatuses = useSelector((state) => state.ticketFetchSlice.selectedTicketStatus || []);
    const dispatch = useDispatch();
    const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        const {value} = event.target;
        const selectedStatusObjects = value.map(id => {
            const status = ticketStatuses.find(status => status.id === id);
            return {
                id: status?.id,
                shortName: status?.shortName,
                name: status?.name,
                isActive: status?.isActive
            };
        }).filter(status => status.id !== undefined);

        const selectedStatusIds = selectedStatusObjects.map(status => status.id);
        dispatch(setSearchValues({fieldName: 'ticketStatus', value: selectedStatusIds}));
        dispatch(setSelectedTicketStatus(selectedStatusObjects));
        setOpen(false);
    };

    const handleClear = (event) => {
        event.stopPropagation();
        dispatch(setSearchValues({fieldName: 'ticketStatus', value: []}));
        dispatch(setSelectedTicketStatus([]));
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel
                id="status-label"
                sx={{backgroundColor: '#edf0f6'}}
            >
                Κατάσταση
            </InputLabel>
            <Select
                labelId="status-label"
                id="status-select"
                multiple
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={selectedTicketStatuses?.map(status => status.id) ?? []}
                onChange={handleChange}
                size='small'
                label="Κατάσταση"
                disabled={ticketsLoading}
                renderValue={(selected) => (
                    <div className="chips-container">
                        {selected.map((id) => {
                            const status = ticketStatuses?.find((status) => status.id === id);
                            return status ? <Chip key={id} label={status.name} size="small"/> : null;
                        })}
                    </div>
                )}
                input={
                    <OutlinedInput
                        id="status-select-outlined"
                        endAdornment={
                            selectedTicketStatuses.length > 0 && (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={handleClear} style={{marginRight: 8}}
                                                disabled={ticketsLoading}>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }
                    />
                }
            >
                {ticketStatuses.map((status) => (
                    <MenuItem
                        key={status.id}
                        value={status.id}
                        disabled={ticketsLoading}
                        sx={{
                            backgroundColor: selectedTicketStatuses.some(selectedStatus => selectedStatus.id === status.id)
                                ? 'rgba(0, 0, 0, 0.1)'
                                : 'inherit',
                            '&.Mui-selected': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
                            },
                        }}
                    >
                        {status.name}
                    </MenuItem>
                ))}
            </Select>
            <style>
                {`
                    @media (max-width: 1400px) {
                        .chips-container {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 4px;
                            max-width: calc(100% - 50px);
                        }
                        .chips-container > div {
                            flex: 1 0 calc(33.33% - 8px);
                        }
                    }
                `}
            </style>
        </FormControl>
    );
};

export default TicketFilterSelect;
