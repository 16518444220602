import { axiosApi } from "app/configs/axiosConfig";

const fetchTickets = async ({ ListTicketCurrentPage, size, requestBody }) => {
  try {
    const response = await axiosApi.post(`/ticket/findByOwnUserGroupRole/${ListTicketCurrentPage}/${size}`, requestBody);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};


export default fetchTickets;
