

const settingsConfig = {
 
  roles: {
    admin: 'ROLE_ADMIN',
    supervisor: ['ROLE_FO_SUPERVISOR', 'ROLE_BO_SUPERVISOR', 'ROLE_SA_SUPERVISOR'],
    teamLeader: ['ROLE_FO_TEAM_LEADER', 'ROLE_BO_TEAM_LEADER', 'ROLE_SA_TEAM_LEADER'],
    agent: ['ROLE_FO_AGENT', 'ROLE_BO_AGENT', 'ROLE_SA_AGENT'],
  },
  groupRoles: [
    {
      id: 2,
      shortName: "ROLE_FO",
      name: "Front Officer",
      isActive: true,
      roles: [
        { id: 2, roleName: "ROLE_FO_SUPERVISOR", description: "FO Supervisor" },
        { id: 3, roleName: "ROLE_FO_TEAM_LEADER", description: "FO Team Leader" },
        { id: 4, roleName: "ROLE_FO_AGENT", description: "FO Agent" },
      ]
    },
    {
      id: 3,
      shortName: "ROLE_BO",
      name: "Back Officer",
      isActive: true,
      roles: [
        { id: 5, roleName: "ROLE_BO_SUPERVISOR", description: "BO Supervisor" },
        { id: 6, roleName: "ROLE_BO_TEAM_LEADER", description: "BO Team Leader" },
        { id: 7, roleName: "ROLE_BO_AGENT", description: "BO Agent" },
      ]
    },
    {
      id: 4,
      shortName: "ROLE_SA",
      name: "Specialized Agent",
      isActive: true,
      roles: [
        { id: 8, roleName: "ROLE_SA_SUPERVISOR", description: "SA Supervisor" },
        { id: 9, roleName: "ROLE_SA_TEAM_LEADER", description: "SA Team Leader" },
        { id: 10, roleName: "ROLE_SA_AGENT", description: "SA Agent" },
      ]
    }
  ],
  defaultAuth: null,
};

export default settingsConfig;
