import { axiosApi } from "app/configs/axiosConfig";

const fetchBeneficiaryById = async (id) => {
    try {
      const response = await axiosApi.get(`/beneficiary/findBeneficiaryById/${id}`);
      if (response.status === 200) {
        return response.data;
      }
      throw new Error('Failed to fetch beneficiary');
    } catch (error) {
      console.error('Error fetching beneficiary:', error);
      throw error;
    }
  };

  export default fetchBeneficiaryById;