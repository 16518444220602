import React, { useState, useEffect } from "react";
import {
    CircularProgress,
    Box,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    Checkbox,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { fetchAllTeams } from "app/Api Calls/TeamsRequiredApis";
import { addUserToTeams } from "app/Api Calls/AddRemoveUserTeams";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {showMessage} from "store/messageSlice";

const AddToTeamsStep = ({ user, handleGoBack }) => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedTeams, setSelectedTeams] = useState([]);
    const userTeams = useSelector(state => state.userAssignmentSlice.userTeams);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchTeams = async () => {
            setLoading(true);
            try {
                const response = await fetchAllTeams();
                const userGroupRoleId = user.roleCollection?.[0]?.group?.id;

                // Filter teams to include only those matching the user's groupRoleId
                const filteredTeams = response.filter(team => team.groupRoleId === userGroupRoleId);

                setTeams(filteredTeams);
            } catch (error) {
                setError('Failed to load teams. Please try again.');
            } finally {
                setLoading(false);
            }
        };

        fetchTeams();
    }, []);

    const handleSelectTeam = (team) => {
        const currentIndex = selectedTeams.findIndex(selectedTeam => selectedTeam.id === team.id);
        const newSelectedTeams = [...selectedTeams];

        if (currentIndex === -1) {
            newSelectedTeams.push(team);
        } else {
            newSelectedTeams.splice(currentIndex, 1);
        }

        setSelectedTeams(newSelectedTeams);
    };

    const handleSubmit = async () => {
        if (selectedTeams.length > 0) {
            setLoading(true);
            try {
                await addUserToTeams(user?.id, selectedTeams.map(team => team.id));
                dispatch(showMessage({
                    message: "O χρήστης προστέθηκε στην  ομάδα επιτυχώς",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                }));
                handleGoBack();
            } catch (error) {
                setError('Failed to add user to teams. Please try again.');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Typography variant="h6">Προσθήκη σε ομάδες</Typography>
            {loading ? (
                <Box display="flex" justifyContent="center"><CircularProgress /></Box>
            ) : error ? (
                <Box display="flex" justifyContent="center"><Typography color="error">{error}</Typography></Box>
            ) : teams.length === 0 ? ( // Check if no teams match the user's group level
                <Box display="flex" justifyContent="center"><Typography>Δεν υπάρχουν ομάδες στο επίπεδο του χρήστη.</Typography></Box>
            ) : (
                <Box style={{ display: 'flex', flexDirection: 'column', height: '45vh' }}>
                    <Autocomplete
                        multiple
                        options={teams}
                        getOptionLabel={(option) => option.name}
                        value={selectedTeams}
                        onChange={(event, newValue) => {
                            setSelectedTeams(newValue);
                        }}
                        filterOptions={(options, params) => {
                            const filtered = options.filter(option =>
                                !userTeams.some(userTeam => userTeam.id === option.id) &&
                                option.name.toLowerCase().includes(params.inputValue.toLowerCase())
                            );
                            return filtered;
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Αναζήτηση ομάδων" variant="outlined" />
                        )}
                    />
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        sx={{ backgroundColor: "#003476 !important", marginTop: '20px', marginBottom: '20px' }}
                        disabled={selectedTeams.length === 0} // Disable button if no team is selected
                    >
                        Προσθήκη σε ομάδες
                    </Button>
                    <Typography variant="subtitle1">Όλες οι ομάδες:</Typography>
                    <List style={{ overflow: 'auto', flexGrow: 1 }}>
                        {teams.map((team) => (
                            <ListItem key={team.id} dense>
                                <Checkbox
                                    edge="start"
                                    checked={selectedTeams.some(selectedTeam => selectedTeam.id === team.id)}
                                    disabled={userTeams.some(userTeam => userTeam.id === team.id)}
                                    onChange={() => handleSelectTeam(team)}
                                    tabIndex={-1}
                                    disableRipple
                                />
                                <ListItemText primary={team.name} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};

export default AddToTeamsStep;
