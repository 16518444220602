import { axiosApi } from "app/configs/axiosConfig";

const fetchTicketById = async (id) => {
  try {
    const response = await axiosApi.get(`/ticket/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
   throw error;
  }
};

export default fetchTicketById;

