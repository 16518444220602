import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  IconButton,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDirectAssignmentInputValue, setFetchAfterMultipleAssignments, setSelectedCheckboxes, setSelectedUserForDirectAssignment } from "store/ticketFetchSlice";
import { showMessage } from "store/messageSlice";
import getUsersForDirectAssignment from "app/Api Calls/GetUsersForDirectAssignment";
import { fetchTeamById } from "app/Api Calls/GetTeamById";
import updateMultipleTickets from "app/Api Calls/UpdateMultipleTickets";
import { ClearIcon } from "@mui/x-date-pickers";

const DirectAssignmentMultiple = () => {
  // const user = JwtService.getUser();
  // const userData = JSON.parse(user);
  // const userId = userData?.uuid;
  const { directAssignmentSelectedUser: selectedUserForDirectAssignment, directAssignmentInputValue: inputValue } = useSelector((state) => state.ticketFetchSlice)
  const [isLoading, setIsLoading] = useState(false);
  const [usersForDirectAssignment, setUsersForDirectAssignment] = useState([]);
  const [groupedUsers, setGroupedUsers] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [noParentThematics, setNoParentThematics] = useState(false);

  
  const items = useSelector((state) => state.ticketFetchSlice.items);
  const [selectedRole, setSelectedRole] = useState("");
  const selectedCheckboxes = useSelector(
    (state) => state.ticketFetchSlice.selectedCheckboxes
  );
  const [selectedThematic, setSelectedThematic] = useState("");
  const [selectedThema, setSelectedThema] = useState("");
  const [selectedSubThema, setSelectedSubThema] = useState("");
  const [allThematics, setAllThematics] = useState([]);
  const [availableThematics, setAvailableThematics] = useState([]);
  const [availableThemes, setAvailableThemes] = useState([]);
  const [availableSubThemes, setAvailableSubThemes] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [showCarrier, setShowCarrier] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [isThematicSelectionNeeded, setIsThematicSelectionNeeded] =
    useState(false);
  const ticketsLoading = useSelector((state) => state.ticketFetchSlice.ticketsLoading);
  const [ticketsRequiringThematics, setTicketsRequiringThematics] = useState(
    []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUsersForDirectAssignment = async () => {
      try {
        const response = await getUsersForDirectAssignment();

        if (response && Array.isArray(response) && response.length > 0) {
          // Creating a new array with users and their associated team
          const allUsersWithTeams = response.flatMap((team) => {
            return (
              team.userTicketAssignmentList?.map((user) => ({
                ...user,
                teamName: team.name,
                teamId: team.id,
              })) || []
            );
          });

          if (allUsersWithTeams.length > 0) {
            setUsersForDirectAssignment(allUsersWithTeams);
            const grouped = groupByRole(allUsersWithTeams);
            setGroupedUsers(grouped);
          } else {
            console.warn("No users found in any team");
          }
        } else {
          console.warn("Unexpected response structure");
        }
      } catch (err) {
        console.error("Error fetching users for direct assignment:", err);
      }
    };

    fetchUsersForDirectAssignment();
  }, []);

  useEffect(() => {
    if (!teamData) return; // Exit the effect if teamData is not set yet

    // Combine all thematicCollections from all carriers in carriersWithThematicsDto
    const allThematicCollections =
      teamData.carriersWithThematicsDto?.reduce((acc, carrier) => {
        return acc.concat(carrier.thematicCollection);
      }, []) || [];

    const thematics = allThematicCollections.filter(
      (item) => item.parentId === null
    );
    setAllThematics(thematics);

    if (thematics.length === 0) {
      setNoParentThematics(true);

      // Gather all id values from allThematicCollections
      const allIds = new Set(allThematicCollections.map(item => item.id));

      // Find themes whose parentId is not in the list of ids
      const themesForMissingThematics = allThematicCollections.filter(
        (item) => !allIds.has(item.parentId)
      );
      setAvailableThemes(themesForMissingThematics);
    } else {
      setNoParentThematics(false);

      if (selectedThematic) {
        // If a thematic is selected, set its children as themes
        const themesForThematic = allThematicCollections.filter(
          (item) => item.parentId === selectedThematic
        );
        setAvailableThemes(themesForThematic);
      } else {
        // If no thematic is selected, reset the available themes
        setAvailableThemes([]);
      }
    }


    // If a theme is selected, set its children as sub-themes
    if (selectedThema) {
      const subThemesForTheme = allThematicCollections.filter(
        (item) => item.parentId === selectedThema
      );
      setAvailableSubThemes(subThemesForTheme);
    } else {
      // If no theme is selected, reset the available sub-themes
      setAvailableSubThemes([]);
    }

    // Logic for showing Carrier, if needed
    if (
      teamData.carriersWithThematicsDto &&
      teamData.carriersWithThematicsDto.length > 1
    ) {
      setShowCarrier(true);
    }
  }, [
    teamData,
    selectedThematic,
    selectedThema,
    isThematicSelectionNeeded,
    ticketsRequiringThematics,
  ]);

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(setSelectedUserForDirectAssignment(null));
    setIsThematicSelectionNeeded(false);
    setTicketsRequiringThematics([]);
    setSelectedThematic("");
    setSelectedThema("");
    setSelectedSubThema("");
  };

  const handleDirectAssignmentChange = (e) => {
    const value = e.target.value;
    const user = usersForDirectAssignment.find((u) => u.id === value);
    dispatch(setSelectedUserForDirectAssignment(user));
    setOpenModal(true);
  };

  // function to transform role names.
  const transformRoleName = (roleName) => {
    return roleName
      .replace("ROLE_", "")
      .split("_")
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(" ");
  };

  // Function to group users by their roles
  const groupByRole = (users) => {
    const grouped = {};

    for (const user of users) {
      const transformedRole = transformRoleName(user.roleName);
      if (!grouped[transformedRole]) {
        grouped[transformedRole] = [];
      }
      grouped[transformedRole].push(user);
    }
    return grouped;
  };

  const handleClearDirectAssignment = () => {
    dispatch(setSelectedUserForDirectAssignment(null));
  };

  const checkTeamAndThematics = async () => {
    if (!selectedUserForDirectAssignment) return false;

    if (selectedSubThema) {
      // If subThema is selected, the validation is successful, and we can proceed to assignment.
      return true;
    }

    setIsLoading(true);

    try {
      const teamNewData = await fetchTeamById(
        selectedUserForDirectAssignment.teamId
      );

      setTeamData(teamNewData);

      if (!teamNewData) {
        throw new Error("Fetched team data is empty");
      }

      const teamThematics = teamNewData.carriersWithThematicsDto
        ? teamNewData.carriersWithThematicsDto.flatMap(
          (carrier) => carrier.thematicCollection || []
        )
        : [];

      const ticketsWithoutThematics = selectedCheckboxes.filter((checkbox) => {
        const ticket = items.find((item) => item.uuid === checkbox.uuid);
        return (
          ticket &&
          !teamThematics.some((thematic) => thematic.id === ticket.thematic?.id)
        );
      });

      if (ticketsWithoutThematics.length > 0) {
        setIsThematicSelectionNeeded(true);
        setTicketsRequiringThematics(ticketsWithoutThematics);
        setIsLoading(false);
        return false; // Thematics need to be selected, cannot proceed to assignment.
      }

      return true; // Thematics are valid, can proceed to assignment.
    } catch (error) {
      console.error("Error while validating thematics:", error);
      dispatch(
        showMessage({
          message: "An error occurred. Please try again.",
          autoHideDuration: 2000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
          variant: "error",
        })
      );
      setIsLoading(false);
      return false; // Error occurred, cannot proceed to assignment.
    }
  };

  const handleConfirmAssignment = async () => {
    const canProceed = await checkTeamAndThematics();

    if (!canProceed) return; // Exit if cannot proceed

    setIsLoading(true);

    const ticketsToUpdate = [];

    for (const selectedCheckbox of selectedCheckboxes) {
      const ticketUuid = selectedCheckbox.uuid;
      const ticket = items.find((item) => item.uuid === ticketUuid);

      if (ticket) {
        const ticketId = ticket.id;
        const selectedResolution = null;
        const activeFlags = ticket.ticketFlagCollection.map((flag) => ({
          code: flag.code,
        }));

        const representativeId = ticket.representativeId;
        const erotisi = ticket.description;
        const thematicId = selectedSubThema ? selectedSubThema : (ticket.thematic && ticket.thematic.id);
        const beneficiaryId = ticket.beneficiary && ticket.beneficiary.id;
        const activeStatus = ticket.ticketStatus && ticket.ticketStatus.id;
        const teamId = selectedUserForDirectAssignment.teamId;
        const ticketPayload = {
          ticketId: ticketId,
          title: null,
          shortDescription: null,
          description: erotisi || ' ',
          thematic: thematicId || null,
          beneficiary: beneficiaryId || null,
          representative: representativeId || null,
          team: teamId,
          activeFlags: activeFlags,
          assigneeUser: selectedUserForDirectAssignment.id,
          ticketStatus: activeStatus,
        };

        ticketsToUpdate.push(ticketPayload);
      } else {
        console.log("No ticket found with UUID", ticketUuid);
      }
    }

    try {
      await updateMultipleTickets(ticketsToUpdate);

      // Dispatch fetchAfterMultipleAssignments
      dispatch(setFetchAfterMultipleAssignments(true));
      dispatch(
        showMessage({
          message: "Οι αναθέσεις έγιναν επιτυχώς",
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "success",
        })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        showMessage({
          message: `Ορισμένες αναθέσεις απέτυχαν: ${error.message}`,
          autoHideDuration: 2000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          variant: "error",
        })
      );
    }

    setSelectedThematic(null);
    setSelectedThema(null);
    setSelectedSubThema(null);
    setIsThematicSelectionNeeded(false);
    setTicketsRequiringThematics([]);
    setIsLoading(false);
    setOpenModal(false);
    dispatch(setSelectedUserForDirectAssignment(null));
    dispatch(setSelectedCheckboxes([]));
  };


  const handleThematicSelection = (event) => {
    const thematicId = event;
    setSelectedThematic(thematicId);
  };

  const handleThemaSelection = (event) => {
    const themaId = event;
    setSelectedThema(themaId);
  };

  return (
    <div style={{ width: "100%" }}>
      {usersForDirectAssignment.length > 0 ? (
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <FormControl
            variant="outlined"
            style={{
              flexBasis: "25%",
              width: "25%",
              marginRight: "0px",
              height: "42px",

            }}
          >
            <InputLabel htmlFor="role-select">Ρόλος</InputLabel>
            <Select
              label="Ρόλος"
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              inputProps={{
                name: "role",
                id: "role-select",
                
              }}
              sx={{
                height: 42, 
                '.MuiOutlinedInput-root': {
                  height: 42,
                }
              }}
              disabled={ticketsLoading}
            >
              <MenuItem value="">
                <em>Όλοι</em>
              </MenuItem>
              {Array.from(
                new Set(usersForDirectAssignment.map((user) => user.roleName))
              ).map((role, index) => (
                <MenuItem value={role} key={`${role}-${index}`} disabled={ticketsLoading}>
                  {transformRoleName(role)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Autocomplete
            id="direct-assignment-autocomplete"
            sx={{
              flexGrow: 1,
              minWidth: "150px",
              maxWidth: "100%",
              width: "auto",
              flexShrink: 1,
              height: "42px",
              '.MuiOutlinedInput-root': {
                height: 42,
              }
            }}
            disabled={ticketsLoading}
            options={usersForDirectAssignment
              .sort((a, b) => {
                return a.roleName.localeCompare(b.roleName);
              })
              .filter(
                (user) => !selectedRole || user.roleName === selectedRole
              )}
            groupBy={(option) => transformRoleName(option.roleName)}
            getOptionLabel={(user) =>
              `${user.firstName} ${user.lastName} - ${user.teamName}`
            }
            value={selectedUserForDirectAssignment}
            onChange={(_, newValue) => {
              // Check if any checkboxes (tickets) are selected
              if (!selectedCheckboxes || selectedCheckboxes.length === 0) {
                dispatch(
                  showMessage({
                    message:
                      "Δεν έχετε επιλέξει tickets για Απευθείας Ανάθεση.",
                    autoHideDuration: 2000,
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "center",
                    },
                    variant: "error",
                  })
                );
                return; // Exit the function if no checkboxes are selected
              }

              dispatch(setSelectedUserForDirectAssignment(newValue));
              setOpenModal(true); // Open the modal
            }}
            renderOption={(props, user) => (
              <li {...props} key={`${user.teamId}-${user.id}`}>
                {`${user.firstName} ${user.lastName} - ${user.teamName}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Απευθείας Ανάθεση"
                variant="outlined"
                disabled={ticketsLoading}
                style={{
                  width: "100%",
                  height: "42px",
                  padding: "0px"
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {inputValue && (
                        <IconButton
                          style={{ verticalAlign: "middle", padding: "0px" }}
                          onClick={() => dispatch(setDirectAssignmentInputValue(''))}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
              if (reason === 'input') {
                dispatch(setDirectAssignmentInputValue(newInputValue));
              }
            }}
            noOptionsText="Δεν βρέθηκε χρήστης"
          />

        </div>
      ) : (
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ textAlign: "center" }}
        >
          Δεν υπάρχουν διαθέσιμες απευθείας αναθέσεις
        </Typography>
      )}
      <Dialog
        PaperProps={{ style: { minWidth: "50vw", maxWidth: "90vw" } }}
        open={openModal}
        onClose={isLoading ? null : handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
          {"Απευθείας Ανάθεση"}
        </DialogTitle>
        <DialogContent
          style={{
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box"
          }}
        >
          {isThematicSelectionNeeded ? (
            <div>
              {/* Display information about which tickets need thematics */}
              <div style={{ marginBottom: "10px", width: "100%" }}>
                Tickets που χρειάζεται να αλλάξουν Θεματικές:
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    textAlign: "center",
                    gap: '5px',
                    boxSizing: "border-box"
                  }}
                >
                  {ticketsRequiringThematics.map((ticket) => (
                    <span
                      key={ticket.uuid}
                      style={{
                        flexBasis: '33%',
                        border: '1px solid #ccc',
                        padding: '2px 5px'
                      }}
                    >
                      {ticket.uuid}
                    </span>
                  ))}
                </div>
              </div>

              {!noParentThematics && (
                <FormControl fullWidth style={{ marginBottom: "20px", boxSizing: "border-box", maxWidth: "100%" }}>
                  <InputLabel id="thematic-label">Θεματική Ενότητα</InputLabel>
                  <Select
                    labelId="thematic-label"
                    value={selectedThematic}
                    onChange={(e) => handleThematicSelection(e.target.value)}
                  >
                    {allThematics.map((thematic) => (
                      <MenuItem key={thematic.id} value={thematic.id}>
                        {thematic.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}


              {/* If a Thematic is selected, show Dropdown for Themes */}
              {(noParentThematics || selectedThematic) && (
                <FormControl fullWidth style={{ marginBottom: "20px", boxSizing: "border-box", maxWidth: "100%" }}>
                  <InputLabel id="theme-label">Θέμα</InputLabel>
                  <Select
                    labelId="theme-label"
                    value={selectedThema}
                    onChange={(e) => handleThemaSelection(e.target.value)}
                  >
                    {availableThemes.map((theme) => (
                      <MenuItem key={theme.id} value={theme.id}>
                        {theme.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* If a Theme is selected, show Dropdown for Sub-Themes */}
              {selectedThema && (
                <FormControl fullWidth style={{ marginBottom: "20px", boxSizing: "border-box", maxWidth: "100%" }}>
                  <InputLabel id="sub-theme-label">Υποθέμα</InputLabel>
                  <Select
                    labelId="sub-theme-label"
                    value={selectedSubThema}
                    onChange={(e) => setSelectedSubThema(e.target.value)}
                  >
                    {availableSubThemes.map((subTheme) => (
                      <MenuItem key={subTheme.id} value={subTheme.id}>
                        {subTheme.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          ) : (
            <div
              className="dialogContent"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "220px",
                width: "100%",
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ textAlign: "center" }}
                  >
                    {`Είστε σίγουροι ότι θέλετε να κάνετε ανάθεση των επιλεγμένων tickets στον χρήστη ${selectedUserForDirectAssignment?.firstName} ${selectedUserForDirectAssignment?.lastName}?`}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    Επιλεγμένα tickets:{" "}
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        textAlign: "center",
                        gap: '5px',
                      }}
                    >
                      {selectedCheckboxes?.map((ticket) => (
                        <span
                          key={ticket.uuid}
                          style={{
                            flexBasis: '33%',
                            border: '1px solid #ccc',
                            padding: '2px 5px'
                          }}
                        >
                          {ticket.uuid}
                        </span>
                      ))}
                    </div>

                  </DialogContentText>
                </>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            disabled={isLoading}
          >
            Όχι
          </Button>
          <Button
            onClick={handleConfirmAssignment}
            color="primary"
            autoFocus
            disabled={
              isLoading || (isThematicSelectionNeeded && !selectedSubThema)
            }
          >
            Ναι
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DirectAssignmentMultiple;
