import React, {useState, useEffect} from 'react';
import {Box, List, ListItem, ListItemText, CircularProgress, Typography} from '@mui/material';
import {fetchAllCarriers} from "app/Api Calls/CarriersThematicsCalls";
import {getAnnouncementsByCarrierId} from "app/Api Calls/AnnouncmentsCalls";
import AnnouncementsListAdmin from "app/Announcments/Admin/AnnouncmentsListAdmin";

const CarriersListAnnouncements = ({refreshFlag, refreshAnnouncements, selectedCarrier, setSelectedCarrier}) => {
    const [carriers, setCarriers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetchAllCarriers()
            .then(data => {
                setCarriers(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Failed to load carriers:', error);
                setLoading(false);
            });
    }, []);


    return (
        <Box sx={{display: 'flex', width: '100%', height: '100vh'}}>
            <Box sx={{width: '20%', bgcolor: '#003476', color: '#ffffff', overflowY: 'auto'}}>
                {loading ? (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4}}>
                        <CircularProgress sx={{color:"white"}}/>
                    </Box>
                ) : (
                    <List sx={{padding: 0}}>
                        {carriers.map((carrier) => (
                            <ListItem
                                button
                                key={carrier.id}
                                onClick={() => setSelectedCarrier(carrier)}
                                sx={{
                                    bgcolor: selectedCarrier && selectedCarrier.id === carrier.id ? '#ffffff' : 'inherit',
                                    color: selectedCarrier && selectedCarrier.id === carrier.id ? '#003476' : '#ffffff',
                                    '&:hover': {
                                        bgcolor: '#ffffff',
                                        color: '#003476',
                                    }
                                }}
                            >
                                <ListItemText primary={carrier.title}/>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
            <Box sx={{width: '80%', overflowY: 'auto', bgcolor: '#fff'}}>
                <AnnouncementsListAdmin refreshFlag={refreshFlag} refreshAnnouncements={refreshAnnouncements}
                                        carriers={carriers} selectedCarrier={selectedCarrier}/>
            </Box>

        </Box>
    );
};

export default CarriersListAnnouncements;
