import { axiosApi } from "app/configs/axiosConfig";

const fetchThematicById = async (thematicId) => {
  try {
    const response = await axiosApi.get(`thematic/${thematicId}`);
    return response.data; 
  } catch (error) {
    console.error('Error fetching questions:', error);
    throw error;
  }
};

export default fetchThematicById;