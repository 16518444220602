import {axiosApi} from "app/configs/axiosConfig";

export async function getTicketsPerStatus(beneficiaryId) {
    const url = `/beneficiary/ticketsPerStatus/${beneficiaryId}`;
    try {
        const response = await axiosApi.get(url);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`Unexpected response code: ${response.status}`);
        }
    } catch (error) {
        console.error(`Error fetching tickets per status for beneficiary ${beneficiaryId}:`, error.message);
        throw error;
    }
}