import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { resetUserForm } from "./userInfoSlice";
import fetchTicketStatuses from "app/Api Calls/TicketStatuses";

export const fetchTicketStatusesAsync = createAsyncThunk(
  "ticketSlice/fetchTicketStatuses",
  async () => {
    const response = await fetchTicketStatuses();
    return response;
  }
);

const initialState = { 
  statuses: [], 
  selectedTicketStatus: '', 
  activeStatus: null, 
  isFormSubmitted: false,  
  callerId: '', 
  isCallerIdValid: false,
  geographies: [],          
  selectedGeography: null ,
  isCarrierOPEKA: false  ,
  isSubmitingTicket: false,
  isHistoryModalOpen: false
};

const ticketSlice = createSlice({
  name: "ticketSlice",
  initialState: initialState,
  reducers: {
    setActiveStatus: (state, action) => {
      state.activeStatus = action.payload;
    },
    setIsSubmitingTicket: (state, action) => {
      state.isSubmitingTicket = action.payload;
    },
    setFormSubmitted: (state, action) => {
      state.isFormSubmitted = action.payload;
    },
    setSelectedTicketStatus: (state, action) => {
      state.selectedTicketStatus = action.payload;
    },
    setHistoryModal: (state, action) => {
      state.isHistoryModalOpen = action.payload;
    },
    setCallerId: (state, action) => {
      if (action.payload === null) {
        state.callerId = '';
        state.isCallerIdValid = false;
      } else {
        const { value, isValid } = action.payload;
        state.callerId = value;
        state.isCallerIdValid = isValid;
      }
    },
    setGeographies: (state, action) => {     
      state.geographies = action.payload;
    },
    setSelectedGeography: (state, action) => {   
      state.selectedGeography = action.payload;
    },
    setIsCarrierOPEKA: (state, action) => {
      state.isCarrierOPEKA = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketStatusesAsync.fulfilled, (state, action) => {
        state.statuses = action.payload;
      })
      .addCase(resetUserForm, (state) => {
        state.callerId = initialState.callerId; 
      });
  }
});

export const { 
  setActiveStatus, 
  setFormSubmitted,
  setSelectedTicketStatus,
  setCallerId,
  setGeographies,          
  setSelectedGeography,
  setIsCarrierOPEKA,
  setIsSubmitingTicket,
  setHistoryModal
} = ticketSlice.actions;

export default ticketSlice.reducer;
