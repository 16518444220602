import React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const styles = {
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuBar: {
    width: '100%',
    height: '50px',
    backgroundColor: '#003476',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorCode: {
    fontSize: '27rem',
    color: '#003476',
  },
  logo: {
    maxHeight: 'calc(100% - 10px)',
    maxWidth: '100%',
  },
  
};

const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <Box style={styles.root}>
      <Box style={styles.menuBar}>
      <img 
          src={`${process.env.PUBLIC_URL}/logo/logo1555.png`} 
          alt='logo' 
          style={styles.logo}
        />

      </Box>

      <Typography style={styles.errorCode}><strong>404</strong></Typography>
      <Typography>Η σελίδα που ζητήσατε δεν βρέθηκε.</Typography>
      <Box mt={3}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => navigate('/')}
          style={{ marginRight: '10px', backgroundColor: '#003476', color: 'white' }}

        >
          Αρχική
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          style={{ backgroundColor: '#003476', color: 'white' }}
          onClick={() => navigate('/sign-in')}
        >
          Είσοδος
        </Button>
      </Box>
    </Box>
  );
};

export default Error404Page;
