import { axiosApi } from "app/configs/axiosConfig";

const fetchTeamGeographies = async () => {
  try {
    const response = await axiosApi.get('/domain/teamGeographies');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchTeamAssignmentMethods = async () => {
  try {
    const response = await axiosApi.get('/domain/teamAssignmentMethods');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchRoles = async () => {
  try {
    const response = await axiosApi.get('/domain/roles');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchGroupRoles = async () => {
  try {
    const response = await axiosApi.get('/domain/groupRoles');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchAvailableSourcesApplication = async () => {
  try {
    const response = await axiosApi.get('/domain/availableSourcesApplication');
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchAllTeams = async () => {
    try {
      const response = await axiosApi.get('/team/findAllAdmin');
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchUsersRoleBased = async (page, size, requestBody) => {
    try {
       
      const response = await axiosApi.post(`/user/searchRoleBased/${page}/${size}`, requestBody);
      // console.log(response.data);
      return response.data
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const fetchUsersWithId = async (page, size, requestBody) => {
    try {
       
      const response = await axiosApi.post(`/user/search/${page}/${size}`, requestBody);
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const fetchCarrierByThematicId = async (id) => {
    try {
      const response = await axiosApi.get(`/carrier/byThematicId/${id}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const fetchThematicByCarrierId = async (id) => {
    try {
      const response = await axiosApi.get(`/thematic/byCarrierId/${id}`);
      const sortData = (data) =>
        data.sort((a, b) => a.title.localeCompare(b.title, "el"));
      const sortedData = sortData(response.data);
      const filteredData = sortedData.filter(
        (thematic) => thematic.parentId === null
      );
      const transformedData = filteredData.map((thematic) => ({
        ...thematic,
        title: thematic.title.slice(0, 100),
      }));
      return transformedData;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
  

  
  
  const createTeam = async (requestBody) => {
    try {
      const response = await axiosApi.post('/team', requestBody);
  
      if (response.status === 200) {
        return response;
      } else {
        throw new Error('Non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error(error);
  
      throw error;
    }
  };
  

  const updateTeam = async (teamData) => {
    try {
      const response = await axiosApi.put('/team', teamData);
  
      if (response.status === 200) {
        return response;
      } else {
        throw new Error('Non-200 status code: ' + response.status);
      }
    } catch (error) {
      console.error(error);
  
      throw error;
    }
  };
  
  
  export {
    fetchTeamGeographies,
    fetchTeamAssignmentMethods,
    fetchRoles,
    fetchGroupRoles,
    fetchAvailableSourcesApplication,
    fetchAllTeams,
    fetchUsersRoleBased,
    createTeam,
    fetchUsersWithId,
    fetchCarrierByThematicId,
    fetchThematicByCarrierId,
    updateTeam
  };