import { Box } from "@mui/material";
import TicketList from "./TicketList";
import AllFilters from "../Filters/AllFilters";

function TicketsPage(props) {
  return (
    <Box >
      <AllFilters />
      <TicketList />
    </Box>
  );
}

export default TicketsPage;
