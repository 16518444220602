import { axiosApi } from "app/configs/axiosConfig";
import performActionOnTicket from "./PerformActionOnTicket";


const updateTicket = async (
  ticketId,
  selectedResolution,
  activeFlags,
  represetativeId,
  erotisi,
  thematicId,
  beneficiaryId,
  activeStatus,
  shouldPerformAction = true,
  assigneeUser = null,
  teamId = null,
  selectedGeographyId = null

  ) => {

    let localStorageUserId = null;
    if (!assigneeUser) {
      const userString = localStorage.getItem('user');
      const cleanedString = userString.replace(/\\/g, '').replace(/"{/g, '{').replace(/}"/g, '}');
      const user = JSON.parse(cleanedString);
      localStorageUserId = user.uuid;
    }

  const ticketFlagCollection = activeFlags.map(flag => ({
    code: flag.code,
    name: flag.name,
  }));


  const flags = {
    ticketFlg: activeFlags.includes('ticketFlg') ? 1 : 0,
    priorityFlg: activeFlags.includes('priorityFlg') ? 1 : 0,
    representativeFlg: activeFlags.includes('representativeFlg') ? 1 : 0,
    followUpFlg: activeFlags.includes('followUpFlg') ? 1 : 0,
    applicationFlg: activeFlags.includes('applicationFlg') ? 1 : 0,
  };
  // {
  //   "id": 0,
  //   "title": "string",
  //   "shortdescription": "string",
  //   "description": "string",
  //   "beneficiary": 0,
  //   "representative": 0,
  //   "thematic": 0,
  //   "ticketStatus": 0,
  //   "assigneeUser": 0,
  //   "callerId": 0,
  //   "ticketFlagCollection": [
  //     {
  //       "code": 0
  //     }
  //   ]
  // }
  const payload = {
    id: ticketId,
    title: null,
    shortDescription: null,
    description: erotisi || ' ',
    thematic: null,
    beneficiary: null,
    representative: null,
    ...flags,
    ticketFlagCollection,
    assigneeUser: assigneeUser,
     ticketStatus: activeStatus.id,
     team: teamId ,
     geographyId: selectedGeographyId
    // callerId : -1 , 
  };

  if (thematicId) {
    payload.thematic = thematicId;
  }

  if (beneficiaryId) {
    payload.beneficiary = beneficiaryId;
  }

  if (represetativeId) {
    payload.representative = represetativeId;
  }

  try {
    const response = await axiosApi.put(`/ticket`, payload);
    return response.data;


  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default updateTicket;


