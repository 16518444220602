import React, { useEffect, useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { fetchUsersWithId } from "app/Api Calls/TeamsRequiredApis";
import settingsConfig from "app/configs/settingConfig";

const TeamUsersDialog = ({ open, onClose, team }) => {
  const [users, setUsers] = useState([]);
  const fetchedUsersRef = useRef([]);
  const currentPageRef = useRef(0);

  useEffect(() => {
    const fetchAllUsers = async () => {
      // Clear fetched users when the team changes
      fetchedUsersRef.current = [];
      currentPageRef.current = 0;
  
      if (team) {
        let keepFetching = true;
  
        while (keepFetching) {
          try {
            const userPromises = team.userCollection.map(async (user) => {
              const requestBody = {
                id: user.id,
              };
              const response = await fetchUsersWithId(0, 30, requestBody);
  
              // Get the user data from elements[0]
              return response.elements[0];
            });
  
            const fetchedUsers = await Promise.all(userPromises);
            fetchedUsersRef.current = [
              ...fetchedUsersRef.current,
              ...fetchedUsers,
            ];
  
            const totalPages = fetchedUsers[0]?.totalPages || 1;
  
            if (totalPages > currentPageRef.current + 1) {
              currentPageRef.current++;
            } else {
              keepFetching = false;
            }
          } catch (error) {
            console.error(error);
            keepFetching = false;
          }
        }
        setUsers(fetchedUsersRef.current);
      }
    };
  
    fetchAllUsers();
  }, [team]);
  

  const resetData = () => {
    setUsers([]);
    fetchedUsersRef.current = [];
    currentPageRef.current = 0;
    if (onClose) onClose();  
  };

//make shortcuts for the roles
  const getRoleAbbreviation = (userRoles, settingsConfig) => {
    const roleMap = {
      ROLE_ADMIN: 'Admin',
      ROLE_FO_SUPERVISOR: 'FO-Sa',
      ROLE_BO_SUPERVISOR: 'BO-Sa',
      ROLE_SA_SUPERVISOR: 'SA-Sa',
      ROLE_FO_TEAM_LEADER: 'FO-TL',
      ROLE_BO_TEAM_LEADER: 'BO-TL',
      ROLE_SA_TEAM_LEADER: 'SA-TL',
      ROLE_FO_AGENT: 'FO-Agent',
      ROLE_BO_AGENT: 'BO-Agent',
      ROLE_SA_AGENT: 'SA-Agent',
    };

    const roles = userRoles.map(role => {
      for (const key in settingsConfig.roles) {
        const rolesOrRole = settingsConfig.roles[key];
        if (Array.isArray(rolesOrRole)) {
          if (rolesOrRole.includes(role.roleName)) {
            return roleMap[role.roleName] || role.roleName;
          }
        } else {
          if (rolesOrRole === role.roleName) {
            return roleMap[role.roleName] || role.roleName;
          }
        }
      }
      return ''; // Return an empty string if no role matches
    });

    return roles.join(', '); // Combine all roles with comma if multiple
  };

  return (
    <Dialog
      open={open}
      onClose={resetData}  
      aria-labelledby="team-users-dialog-title"
    >
      <DialogTitle id="team-users-dialog-title">Χρήστες Ομάδας</DialogTitle>
      <DialogContent>
        <List>
          {users.map((user, index) =>
            user && user.id && user.firstName && user.lastName ? (
              <ListItem key={user.id}>
                <ListItemText
                    primary={`${index + 1}. ${user.firstName} ${user.lastName} (${getRoleAbbreviation(user.roleCollection, settingsConfig)})`}
                />
              </ListItem>
            ) : null
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default TeamUsersDialog;
