import React, {useState, useEffect} from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Pagination,
    Box,
    CircularProgress,
    Typography,
    Grid,
} from "@mui/material";
import TeamEditDialog from "./TeamEditDialog";
import {fetchTeamById} from "app/Api Calls/GetTeamById";
import ExcelTeamUploader from "./ExcelTeamUploader";
import {searchTeams} from "app/Api Calls/SearchTeams";
import {useSelector} from "react-redux";
import TeamFilters from "./TeamFilters";
import {useDispatch} from "react-redux";
import {setTeamsPage} from "store/teamsSlice";
import TeamUsersDialog from "./TeamsUsersDialog";

const TeamsTable = ({refreshKey, onTeamUpdate}) => {
    const dispatch = useDispatch();
    const [teams, setTeams] = useState([]);
    const [currentTeam, setCurrentTeam] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [dialogType, setDialogType] = useState(null);
    const [editLoading, setEditLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [teamCache, setTeamCache] = useState({});
    const [lastFetchedKey, setLastFetchedKey] = useState(0);
    const selectedCarrier = useSelector(state => state.teamsSlice.selectedCarrier);
    const selectedGroupRole = useSelector(state => state.teamsSlice.selectedGroupRole);
    const selectedGeography = useSelector(state => state.teamsSlice.selectedGeography);
    const teamsPage = useSelector(state => state.teamsSlice.teamsPage);
    const selectedUserId = useSelector(state => state.teamsSlice.selectedUserId);
    const selectedTeamId = useSelector(state => state.teamsSlice.selectedTeamId);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const currentCacheKey = generateCacheKey(teamsPage, selectedCarrier, selectedGroupRole, selectedGeography, selectedUserId);

            // Check if a specific team is being requested
            if (selectedTeamId !== null) {
                try {
                    // Fetch data for the specific team
                    const teamData = await fetchTeamById(selectedTeamId);
                    setTeams([teamData]);
                    setTotalPages(1);
                    setTotalElements(1);
                    setTeamCache(prevCache => {
                        const newCache = {...prevCache};
                        delete newCache[currentCacheKey];
                        return newCache;
                    });
                } catch (error) {
                    console.error("Failed to fetch team by ID:", error);
                    // Handle error appropriately
                } finally {
                    setLoading(false);
                }
            } else {
                // Handle the normal team search logic

                if (refreshKey === lastFetchedKey && teamCache[currentCacheKey]) {
                    const cachedData = teamCache[currentCacheKey];
                    setTeams(cachedData.elements);
                    setTotalPages(cachedData.totalPages);
                    setTotalElements(cachedData.totalElements);
                    setLoading(false);
                } else {
                    try {
                        const data = await searchTeams(teamsPage, rowsPerPage, selectedGroupRole, selectedCarrier, null, selectedGeography, selectedUserId);
                        if (data) {
                            setTeamCache((prevCache) => ({...prevCache, [currentCacheKey]: data}));
                            setTeams(data.elements);
                            setTotalPages(data.totalPages);
                            setTotalElements(data.totalElements);
                            setLastFetchedKey(refreshKey);
                        }
                    } catch (error) {
                        console.error("Failed to search teams:", error);
                        // Optionally, handle the error more gracefully
                    } finally {
                        setLoading(false);
                    }
                }
            }
        };

        fetchData();
    }, [teamsPage, refreshKey, selectedCarrier, selectedGroupRole, selectedGeography, selectedUserId, selectedTeamId]);


    const generateCacheKey = (teamsPage, carrier, groupRole, geography, selectedUserId) => {
        return `${teamsPage}-${carrier}-${groupRole}-${geography}-${selectedUserId}`;
    }


    const handleEdit = async (team) => {
        setDialogType("edit");
        try {
            setEditLoading(true);
            const fetchedTeam = await fetchTeamById(team.id);
            setCurrentTeam(fetchedTeam);
        } catch (error) {
            console.error(error);
        } finally {
            setEditLoading(false);
        }
    };

    const handleOpenTeamUsers = (team) => {
        setDialogType("users");
        setCurrentTeam(team);
    };

    const handleClose = () => {
        setDialogType(null);
        setCurrentTeam(null);
    };


    return (
        <Box sx={{margin: "0 auto", maxWidth: "98%", padding: "20px"}}>
            <Typography variant="h5" align="center" gutterBottom>
                Λίστα με Υπάρχουσες Απαντητικές Ομάδες
            </Typography>

            <TeamFilters/>
            <Grid container justifyContent="space-between" alignItems="center" sx={{marginBottom: "10px"}}>
                <Grid item>
                    <ExcelTeamUploader/>
                </Grid>
                <Grid item>
                    <Typography variant="h6" align="right" sx={{fontSize: "14px"}}>
                        Συνολικός αριθμός ομάδων: {totalElements}
                    </Typography>
                </Grid>
            </Grid>

            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "calc(100vh - 250px)", // Adjust as needed
                        marginTop: 3,
                        marginBottom: 3,
                        borderRadius: 2,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)"  // Optional: to give it a raised appearance
                    }}
                >
                    <CircularProgress/>
                </Box>
            ) : (
                <TableContainer
                    component={Paper}
                    sx={{margin: "0 auto", maxWidth: "98%", padding: "20px"}}
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{border: "1px solid gray"}}>Αριθμός</TableCell>
                                <TableCell sx={{border: "1px solid gray"}}>Όνομα</TableCell>
                                <TableCell sx={{border: "1px solid gray"}}>
                                    Ρόλος Ομάδας
                                </TableCell>
                                <TableCell sx={{border: "1px solid gray"}}>
                                    Κωδικός Μεθόδου Ανάθεσης
                                </TableCell>
                                {/* <TableCell sx={{ border: "1px solid gray" }}>
                Κωδικός Μεθόδου Ανάθεσης
              </TableCell> */}
                                <TableCell sx={{border: "1px solid gray"}}>Ενέργειες</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teams

                                .map((team, index) => (
                                    <TableRow key={team.id}>
                                        <TableCell sx={{border: "1px solid gray"}}>
                                            {(teamsPage) * rowsPerPage + index + 1}
                                        </TableCell>
                                        <TableCell sx={{border: "1px solid gray"}}>
                                            {team.name}
                                        </TableCell>
                                        <TableCell sx={{border: "1px solid gray"}}>
                                            {team.groupRole.name}
                                        </TableCell>
                                        <TableCell sx={{border: "1px solid gray"}}>
                                            {team.assignmentMethod.description}
                                        </TableCell>
                                        {/* <TableCell sx={{ border: "1px solid gray" }}>
                    {team.assignmentMethod.description}
                  </TableCell> */}
                                        <TableCell sx={{border: "1px solid gray"}}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleEdit(team)}
                                                sx={{
                                                    backgroundColor: "#ed6c02 !important",
                                                    color: "#fff",
                                                    margin: "4px",
                                                }}
                                            >
                                                Επεξεργασία
                                            </Button>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: "#dc2626 !important",
                                                    color: "#fff",
                                                    margin: "4px",
                                                }}
                                            >
                                                Διαγραφή
                                            </Button>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleOpenTeamUsers(team)}
                                                sx={{
                                                    backgroundColor: "#003476 !important",
                                                    color: "#fff",
                                                    margin: "4px",
                                                }}
                                            >
                                                Χρήστες Ομάδας
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <Box sx={{display: "flex", justifyContent: "center", marginTop: 2}}>
                        <Pagination
                            count={totalPages}
                            page={teamsPage + 1}
                            onChange={(event, newPage) => dispatch(setTeamsPage(newPage - 1))}
                            showFirstButton
                            showLastButton

                        />

                    </Box>
                    <TeamEditDialog
                        open={dialogType === "edit"}
                        team={dialogType === "edit" ? currentTeam : null}
                        onClose={handleClose}
                        loading={editLoading}
                        onUpdateComplete={onTeamUpdate}
                    />

                    <TeamUsersDialog
                        open={dialogType === "users"}
                        onClose={handleClose}
                        team={dialogType === "users" ? currentTeam : null}
                    />
                </TableContainer>
            )}
        </Box>
    );
}

export default TeamsTable;
