import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setGeographies, setSelectedGeography } from 'store/ticketCreateSlice';
import CategoriesDropdown from 'app/main//Reusable Components/CategoriesDropdown';
import { setFetchedGeographyId } from 'store/editTicketSlice';
import getAllGeographies from 'app/Api Calls/GetAllGeographies';

function GeographySelector({ isEditPage }) {
  const dispatch = useDispatch();

  // Use Redux state instead of local state
  const geographies = useSelector(state => state.ticketSlice.geographies);
  const selectedGeography = useSelector(state => state.ticketSlice.selectedGeography);
  const selectedCarrierId = useSelector((state) => state.knowledgeBasePageSlice.selectedCarrier);
  const geographyIdFromTicket = useSelector(state => state.editTicketSlice.fetchedGeographyId);
  const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);

  useEffect(() => {
    const fetchGeographies = async () => {
      if (!selectedCarrierId || selectedCarrierId === null) {
        return;
      }

      const allGeographies = await getAllGeographies();

      // Sort geographies alphabetically by name
      const sortedGeographies = allGeographies.sort((a, b) => a.name.localeCompare(b.name));

      const filteredGeographies = sortedGeographies.filter(geo => geo.carrierId === selectedCarrierId.value);
      dispatch(setGeographies(filteredGeographies));
    };

    fetchGeographies();



  }, [dispatch, selectedCarrierId]);


  useEffect(() => {
    if (geographyIdFromTicket !== null && geographies.length > 0 && selectedCarrierId !== null) {
      const matchedGeography = geographies.find(geo => geo.id === geographyIdFromTicket);

      if (matchedGeography) {
        const geographyOption = {
          value: matchedGeography.id,
          label: matchedGeography.name
        };
        dispatch(setSelectedGeography(geographyOption));
      } else {
        console.warn(`No matching geography found for id ${geographyIdFromTicket}`);
      }
    }
  }, [geographyIdFromTicket, geographies, selectedCarrierId, dispatch]);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      dispatch(setSelectedGeography(selectedOption));
    } else {
      dispatch(setSelectedGeography(null));
    }
  };


  const dropdownOptions = geographies.map(geo => ({
    value: geo.id,
    label: geo.name
  }));

  if (dropdownOptions.length === 0) {
    dropdownOptions.push({ value: null, label: "Δεν υπάρχει γεωγραφία για τον συγκεκριμένο Φορέα" });
  }

  return (
    <div>
      <CategoriesDropdown
      isDisabled={activeStatus && activeStatus.id === 5}
        options={dropdownOptions}
        value={selectedGeography}
        onChange={handleChange}
        placeholder="Γεωγραφία"
        noOptionsMessage="Δεν υπάρχει γεωγραφία για τον συγκεκριμένο Φορέα"
      />

    </div>
  );
}

export default GeographySelector;
