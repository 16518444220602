import { axiosApi } from "app/configs/axiosConfig";
import { validateAFM, validateMobile, validateName, validatePhone, validateSurname } from "app/main/Fields/ValidateFields";

const createBeneficiary = async (beneficiary) => {
  try {
    const { id, isValid, ...updatedBeneficiary } = beneficiary;
    const { firstName, lastName, afm, phone, mobile } = updatedBeneficiary;

    const firstNameError = validateName(firstName) || (firstName ? null : 'Το όνομα είναι υποχρεωτικό');
    const lastNameError = validateSurname(lastName) || (lastName ? null : 'Το επώνυμο είναι υποχρεωτικό');
    const afmError = validateAFM(afm) || (afm ? null : 'Το ΑΦΜ είναι υποχρεωτικό');
    const phoneError = validatePhone(phone) || (phone ? null : 'Ο αριθμός τηλεφώνου είναι υποχρεωτικός');
    const mobileError = validateMobile(mobile) || (mobile ? null : 'Ο αριθμός κινητού είναι υποχρεωτικός');

    // Check that the four required fields are filled and valid
    if (firstNameError !== null || lastNameError !== null || afmError !== null || (phoneError !== null && mobileError !== null)) {
      console.log("Validation failed for the required fields.");
      throw new Error(`Validation failed for the required fields: ${firstNameError || ""} ${lastNameError || ""} ${afmError || ""} ${phoneError || mobileError || ""}`);
    }

    // Make API call if validation passes
    const response = await axiosApi.post('/beneficiary/beneficiary/create', beneficiary);
    return response.data.id;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default createBeneficiary;
