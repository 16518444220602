// SubSubThematicQnA component
import { fetchQnaByThematicId } from 'app/Api Calls/CarriersThematicsCalls';
import {React, useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSubSubThematicQnaData } from 'store/gnosiakiCrudSlice';
import EditQnADialog from "./EditQnADialog";


const SubSubThematicQnA = ({ subSubThematicId }) => {
    const dispatch = useDispatch();
    const allSubSubThematicQnaData = useSelector((state) => state.gnosiakiCrudSlice.subSubThematicQnaData);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [currentQnA, setCurrentQnA] = useState(null);
    const refreshTrigger = useSelector(state => state.gnosiakiCrudSlice.refreshTrigger);
    const prevTriggerCount = useRef();

    // Function to open the dialog with the selected QnA
    const handleEditClick = (qna) => {
        setCurrentQnA(qna);
        setEditDialogOpen(true);
    };

    const handleDialogClose = () => {
        setEditDialogOpen(false);
        setCurrentQnA(null);
    };


    const handleSave = async (editedQnA) => {
        try {
            const updatedData = await fetchQnaByThematicId(subSubThematicId);
            dispatch(setSubSubThematicQnaData({
                parentSubSubThematicId: subSubThematicId,
                subSubThematicQnaData: updatedData
            }));
        } catch (error) {
            console.error("There was an error refetching the updated QnA data:", error);
        }
    };

    // Access the QnA data for the passed subSubThematicId
    const qnaData = allSubSubThematicQnaData[subSubThematicId] || [];



    useEffect(() => {
        const hasTriggerChanged = prevTriggerCount.current !== refreshTrigger.count;
        const isTargetedRefresh = refreshTrigger.parentId === subSubThematicId;

        if (subSubThematicId && (!qnaData.length || hasTriggerChanged || isTargetedRefresh)) {
            fetchQnaByThematicId(subSubThematicId)
                .then((data) => {
                    dispatch(setSubSubThematicQnaData({
                        parentSubSubThematicId: subSubThematicId,
                        subSubThematicQnaData: data
                    }));
                })
                .catch((error) => console.error("There was an error!", error));
            prevTriggerCount.current = refreshTrigger.count;
        }
    }, [subSubThematicId, dispatch, qnaData.length, refreshTrigger]);


    return (
        <>
            {qnaData.length > 0 ? (
                qnaData.map((qna, index) => (
                    <div
                        key={"subSubThematicQna-" + subSubThematicId + "-" + index}
                        className="flex flex-col md:flex-row items-center justify-between bg-gray-100 py-2 px-4 my-4 ml-40 border-b-4 border-indigo-500"
                    >
                        {/* Ensure the container div here takes the full width */}
                        <div className="flex flex-col md:flex-row w-full">
                            <div className="md:w-1/12 px-2 mb-2 md:mb-0 flex items-center justify-center">
                                Ερώτηση {index + 1}
                            </div>
                            <div className="md:w-3/12 px-2 mb-2 md:mb-0 flex items-center justify-center">
                                {qna.question}
                            </div>
                            <div
                                className="md:w-6/12 px-2 mb-2 md:mb-0 flex items-center justify-center overflow-auto"
                                >
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: qna.answer,
                                    }}
                                />
                            </div>
                        </div>
                        <div class="md:ml-auto flex flex-col md:flex-shrink-0 space-y-2 mt-2 md:mt-0 md:w-2/12">
                            <button
                                className="bg-blue-500 text-white py-1 px-4 rounded focus:outline-none"
                                onClick={() => handleEditClick(qna)}
                            >
                                Επεξεργασία
                            </button>
                            <button
                                className="bg-red-500 text-white py-1 px-4 rounded focus:outline-none"
                                // onClick logic here
                            >
                                Διαγραφή
                            </button>
                        </div>
                    </div>
                ))
            ) : (
                <div className="flex flex-col md:flex-row items-center justify-between bg-gray-100 py-2 px-4 my-4 ml-40 border-b-4 border-indigo-500">
                    <div className="w-full text-center">Δεν υπάρχουν διαθέσιμες ερωτήσεις/απαντήσεις για αυτήν την θεματολογία.</div>
                </div>
            )}
            {editDialogOpen && currentQnA && (
                <EditQnADialog
                    open={editDialogOpen}
                    handleClose={handleDialogClose}
                    qna={currentQnA}
                    onSave={handleSave}
                />
            )}
        </>
    );
}

    export default SubSubThematicQnA;
